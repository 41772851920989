import { FC } from "react";

import { DateField } from "@/components/form/fields";
import { SurveyField } from "@/services/openapi";

import { OnboardingFieldWrapper } from "../../styles";
import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyDateField: FC<Props> = ({ field: { isRequired, title, id, type, value } }) => {
  return (
    <OnboardingFieldWrapper>
      <DateField
        name={generateSurveyFieldId(id!, type!)}
        rules={{ required: !!isRequired }}
        label={generateSurveyFieldTitle(title!, isRequired!)}
        // @ts-ignore
        defaultValue={value ? value : undefined}
      />
    </OnboardingFieldWrapper>
  );
};
