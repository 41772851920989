export const GlassErrorIcon = () => (
  <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_508_97)">
      <path
        d="M22.443 13c0 5.139-4.228 9.305-9.443 9.305S3.557 18.139 3.557 13c0-5.139 4.228-9.305 9.443-9.305S22.443 7.861 22.443 13z"
        fill="url(#paint0_linear_508_97)"
      />
    </g>
    <g filter="url(#filter1_bii_508_97)">
      <path
        d="M22.443 13c0 5.141-4.228 9.309-9.443 9.309S3.557 18.14 3.557 13c0-5.141 4.228-9.309 9.443-9.309S22.443 7.86 22.443 13z"
        fill="url(#paint1_linear_508_97)"
        fillOpacity={0.1}
      />
    </g>
    <g filter="url(#filter2_bii_508_97)">
      <path
        d="M20.122 4.029C15.094.153 7.828 1.028 3.895 5.982-.038 10.937.85 18.096 5.878 21.972c5.028 3.875 12.293 3 16.227-1.954 3.933-4.955 3.045-12.114-1.983-15.99z"
        fill="url(#paint2_linear_508_97)"
        fillOpacity={0.1}
      />
      <path
        d="M20.122 4.029C15.094.153 7.828 1.028 3.895 5.982-.038 10.937.85 18.096 5.878 21.972c5.028 3.875 12.293 3 16.227-1.954 3.933-4.955 3.045-12.114-1.983-15.99z"
        stroke="url(#paint3_linear_508_97)"
        strokeWidth={1.70075}
      />
    </g>
    <g filter="url(#filter3_biii_508_97)">
      <path
        d="M18.734 5.777C14.685 2.657 8.836 3.36 5.669 7.35c-3.167 3.99-2.452 9.753 1.597 12.873 4.048 3.12 9.898 2.416 13.065-1.573 3.166-3.99 2.452-9.753-1.597-12.873z"
        fill="url(#paint4_linear_508_97)"
        fillOpacity={0.01}
      />
      <path
        d="M19.15 5.238C14.807 1.892 8.535 2.645 5.136 6.927 1.732 11.215 2.502 17.41 6.85 20.762c4.342 3.346 10.614 2.593 14.013-1.689 3.405-4.288 2.634-10.484-1.714-13.835z"
        stroke="url(#paint5_radial_508_97)"
        strokeWidth={1.3606}
      />
    </g>
    <g filter="url(#filter4_biii_508_97)">
      <path
        d="M18.277 6.353C14.55 3.48 9.168 4.129 6.254 7.8c-2.914 3.672-2.257 8.976 1.469 11.847 3.726 2.872 9.109 2.224 12.023-1.447 2.914-3.672 2.257-8.976-1.47-11.847z"
        fill="url(#paint6_linear_508_97)"
        fillOpacity={0.01}
      />
    </g>
    <g filter="url(#filter5_b_508_97)">
      <path
        d="M2.457 18.497c3.163 5.89 10.448 8.203 16.27 5.167 5.823-3.036 7.979-10.272 4.816-16.161C20.38 1.613 13.096-.7 7.273 2.336 1.45 5.372-.706 12.608 2.457 18.497z"
        fill="url(#paint7_linear_508_97)"
        fillOpacity={0.01}
      />
    </g>
    <g filter="url(#filter6_i_508_97)">
      <path
        d="M14.714 12.996l2.08-2.128a1.202 1.202 0 00-.035-1.708 1.223 1.223 0 00-1.721.036L13 11.26l-2.037-2.065a1.223 1.223 0 00-1.72-.036 1.203 1.203 0 00-.037 1.71l2.08 2.127-2.066 2.095a1.22 1.22 0 00-.095 1.608c.46.59 1.339.64 1.863.104l2.011-2.056 2.012 2.056a1.24 1.24 0 001.863-.104 1.22 1.22 0 00-.095-1.608l-2.067-2.095.002-.001z"
        fill="url(#paint8_linear_508_97)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_508_97"
        x={0.655175}
        y={0.793603}
        width={28.8347}
        height={28.5581}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx={2.07265} dy={2.07265} />
        <feGaussianBlur stdDeviation={2.48718} />
        <feColorMatrix values="0 0 0 0 0.458586 0 0 0 0 0.25643 0 0 0 0 0.317077 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_508_97" result="shape" />
      </filter>
      <filter
        id="filter1_bii_508_97"
        x={-4.73371}
        y={-4.59919}
        width={35.4672}
        height={35.1985}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={4.1453} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_508_97" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={4.1453} />
        <feGaussianBlur stdDeviation={2.07265} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
        <feBlend in2="shape" result="effect2_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={-4.1453} />
        <feGaussianBlur stdDeviation={2.07265} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.203922 0 0 0 0 0.203922 0 0 0 0.15 0" />
        <feBlend in2="effect2_innerShadow_508_97" result="effect3_innerShadow_508_97" />
      </filter>
      <filter
        id="filter2_bii_508_97"
        x={-6.21364}
        y={-6.04397}
        width={38.427}
        height={38.0879}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={3.4015} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_508_97" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
        <feBlend in2="shape" result="effect2_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={-3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.898039 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.15 0" />
        <feBlend in2="effect2_innerShadow_508_97" result="effect3_innerShadow_508_97" />
      </filter>
      <filter
        id="filter3_biii_508_97"
        x={-31.6832}
        y={-31.5465}
        width={89.3662}
        height={89.093}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={17.0075} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_508_97" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={6.803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect2_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={3.06135} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.4 0 0 0 0 0.4 0 0 0 1 0" />
        <feBlend in2="effect2_innerShadow_508_97" result="effect3_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={0.6803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend in2="effect3_innerShadow_508_97" result="effect4_innerShadow_508_97" />
      </filter>
      <filter
        id="filter4_biii_508_97"
        x={-12.5727}
        y={-12.4472}
        width={51.1454}
        height={50.8943}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={8.50375} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_508_97" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={6.803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect2_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={3.06135} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.203922 0 0 0 0 0.203922 0 0 0 0.2 0" />
        <feBlend in2="effect2_innerShadow_508_97" result="effect3_innerShadow_508_97" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={0.6803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend in2="effect3_innerShadow_508_97" result="effect4_innerShadow_508_97" />
      </filter>
      <filter
        id="filter5_b_508_97"
        x={0.65985}
        y={0.65985}
        width={24.6803}
        height={24.6803}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.170075} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_508_97" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_508_97" result="shape" />
      </filter>
      <filter
        id="filter6_i_508_97"
        x={6.82604}
        y={8.82397}
        width={10.307}
        height={11.7537}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx={-2.0409} dy={3.4015} />
        <feGaussianBlur stdDeviation={2.21097} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.643137 0 0 0 0 0.854902 0 0 0 0 0.952941 0 0 0 0.6 0" />
        <feBlend in2="shape" result="effect1_innerShadow_508_97" />
      </filter>
      <linearGradient
        id="paint0_linear_508_97"
        x1={6.32185}
        y1={6.83563}
        x2={36.7808}
        y2={31.0938}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3434" />
        <stop offset={0.931238} stopColor="#F66" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_508_97"
        x1={-1.52189}
        y1={1.36427}
        x2={48.2021}
        y2={29.5126}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3434" />
        <stop offset={1} stopColor="#F66" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_508_97"
        x1={22.1048}
        y1={20.0177}
        x2={4.09983}
        y2={5.72443}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3434" />
        <stop offset={1} stopColor="#E51717" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_508_97"
        x1={5.87793}
        y1={21.9715}
        x2={19.8621}
        y2={3.82824}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.25} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_508_97"
        x1={5.66932}
        y1={7.3499}
        x2={20.1667}
        y2={18.8587}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F66" />
        <stop offset={0.691655} stopColor="#FF3434" stopOpacity={0.5} />
      </linearGradient>
      <radialGradient
        id="paint5_radial_508_97"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(5.73413 -7.2232 7.24817 5.75396 13 13)"
      >
        <stop stopColor="#E51717" />
        <stop offset={1} stopColor="#F66" stopOpacity={0.02} />
      </radialGradient>
      <linearGradient
        id="paint6_linear_508_97"
        x1={6.2539}
        y1={7.80037}
        x2={19.5953}
        y2={18.3915}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.196863} stopColor="#FF3434" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_508_97"
        x1={7.27293}
        y1={2.33602}
        x2={18.4651}
        y2={23.8006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E51717" />
        <stop offset={1} stopColor="#F66" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_508_97"
        x1={8.86725}
        y1={13.0002}
        x2={25.3999}
        y2={13.0002}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
);
