import { Badge } from "@/components/badge";
import { FundsTxDetailStatus, FundsTxStatus } from "@/services/openapi";

export const TransactionStatusBadge = ({
  status: detailedStatus,
  size = "middle",
}: {
  status: FundsTxDetailStatus;
  size?: "small" | "middle";
}) => {
  const { status } = detailedStatus;
  switch (status) {
    case FundsTxStatus.Rejected:
      return (
        <Badge color="red" size={size}>
          {status}
        </Badge>
      );
    case FundsTxStatus.Error:
      return (
        <Badge color="red" size={size}>
          {status}
        </Badge>
      );
    case FundsTxStatus.Refunded:
      return (
        <Badge color="gray" size={size}>
          {status}
        </Badge>
      );
    case FundsTxStatus.Finished:
      return (
        <Badge color="green" size={size}>
          {status}
        </Badge>
      );
    case FundsTxStatus.Processing:
      return (
        <Badge color="blue" size={size}>
          {status}
        </Badge>
      );
    default:
      return null;
  }
};
