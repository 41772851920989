import { type FC, type ReactNode } from "react";

import { Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: ReactNode;
  title: string;
  text: ReactNode;
};

const IncrementOrderHintPopover: FC<Props> = ({ children, text, title }) => {
  const { isIncrementOrderStepActive, interruptTour, totalSteps, currentStep, isMobile } =
    useTerminalFeatureTourContext();

  if (!isIncrementOrderStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      side={isMobile ? "top" : "bottom"}
      sideOffset={16}
      align="end"
      alignOffset={-50}
      content={
        <Hint title={title} text={text} closeButton={<Hint.CloseButton onClick={interruptTour} />}>
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { IncrementOrderHintPopover };
