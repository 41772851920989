import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { DesktopPlaceOrderBlock } from "./desktop/block";
import { MobilePlaceOrderBlock } from "./mobile/block";

const PlaceOrderBlock = () => {
  const { isMobile } = useTerminalLayout();
  return isMobile ? <MobilePlaceOrderBlock /> : <DesktopPlaceOrderBlock />;
};

export { PlaceOrderBlock };
