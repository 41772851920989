import { FC } from "react";
import { useTranslation } from "react-i18next";

import tickIcon from "@/assets/images/tick-icon.svg";
import { LocaleInfo, LocaleInfoItemsContainer } from "@/services/openapi";
import { styled } from "@/styles";

const Li = styled("li", {
  display: "flex",
  width: "100%",
  gap: "12px",
  alignItems: "center",
  elementTransition: "background",
  p: "16px 24px",
  borderRadius: "8px",
  cursor: "pointer",
  color: "$black",
  fontFamily: "$roboto",

  "@bp3": {
    "&:hover": {
      backgroundColor: "$gray",
    },
  },

  variants: {
    selected: {
      true: {
        backgroundColor: "$gray",
      },
    },
  },
});

const Icon = styled("img", {
  ml: "auto",
});

type Props = {
  languagesInfo: LocaleInfoItemsContainer;
  onChange: (locale: LocaleInfo) => void;
};

export const LanguagesList: FC<Props> = ({ languagesInfo, onChange }) => {
  const { i18n } = useTranslation();

  return (
    <ul data-test="language-switcher-list">
      {languagesInfo?.items?.map((item, i) => (
        <Li key={i} onClick={() => onChange(item)} selected={i18n.resolvedLanguage === item.code}>
          <img src={item.icon!} alt={item.name!} width={24} height={24} />
          {item.name}
          {i18n.resolvedLanguage === item.code && <Icon src={tickIcon} />}
        </Li>
      ))}
    </ul>
  );
};
