/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { NotificationItemsContainer } from '../models';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (mask?: string, from?: string, to?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mask !== undefined) {
                localVarQueryParameter['Mask'] = mask;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/notifications/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(mask?: string, from?: string, to?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(mask, from, to, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readNotification(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readNotification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {string} [mask] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(mask?: string, from?: string, to?: string, skip?: number, take?: number, options?: any): AxiosPromise<NotificationItemsContainer> {
            return localVarFp.getNotifications(mask, from, to, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read notification(s)
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readNotification(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.readNotification(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationsRequest
 */
export interface NotificationsApiGetNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly mask?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof NotificationsApiGetNotifications
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiGetNotifications
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof NotificationsApiGetNotifications
     */
    readonly take?: number
}

/**
 * Request parameters for readNotification operation in NotificationsApi.
 * @export
 * @interface NotificationsApiReadNotificationRequest
 */
export interface NotificationsApiReadNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiReadNotification
     */
    readonly id?: string
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get notifications
     * @param {NotificationsApiGetNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(requestParameters: NotificationsApiGetNotificationsRequest = {}, options?: any) {
        return NotificationsApiFp(this.configuration).getNotifications(requestParameters.mask, requestParameters.from, requestParameters.to, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read notification(s)
     * @param {NotificationsApiReadNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public readNotification(requestParameters: NotificationsApiReadNotificationRequest = {}, options?: any) {
        return NotificationsApiFp(this.configuration).readNotification(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
