import { cva } from "class-variance-authority";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MarginFreeFormat, NumberFormat, PnlFormat } from "@/app/components";
import { DialogTrigger } from "@/components/dialog";
import { TooltipQuestionIcon } from "@/components/icons";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { BonusUserPlatform } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Tooltip } from "@/shared/ui";

import { useTerminalAccountSummary } from "../account-summary/context";
import { getNumberColorClassname } from "../terminal.styles-helpers";

const listItemStyles = cva("flex items-center justify-between gap-2 border-b py-2 last:border-b-0")();

const textStyles = cva("font-roboto text-[14px] leading-[1.5] text-text lg:text-[16px]", {
  variants: {
    dimmed: {
      true: "text-text-secondary",
    },
  },
});

type Props = {
  onClose: () => void;
  showBonus: boolean;
  bonus: BonusUserPlatform | null;
};

const AccountsSelectInfo: FC<Props> = ({ onClose, showBonus, bonus }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close: closeTooltip }] = useDisclosure();

  const { currency, equity, marginLevel, leverage, pnl, currencyDecimalScale, marginFree, credit } =
    useTerminalAccountSummary();

  const handleClose = useCallback(() => {
    closeTooltip();
    onClose();
  }, [closeTooltip, onClose]);

  return (
    <ul role="list" className="bg-bg-back px-6 py-2 lg:py-3">
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.free-margin")}</div>
        <div className={textStyles()}>
          <MarginFreeFormat value={marginFree} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.pnl")}</div>
        <div className={cn(textStyles(), getNumberColorClassname(pnl))}>
          <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.equity")}</div>
        <div className={textStyles()}>
          <NumberFormat value={equity} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </li>
      {bonus && showBonus && (
        <li className={listItemStyles}>
          <div className="flex items-center gap-2">
            <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.bonus.name")}</div>
            <Tooltip
              open={opened}
              onOpenChange={onOpenChange}
              action={
                <DialogTrigger asChild>
                  <Tooltip.Action onClick={handleClose}>{t("terminal.account-select.bonus.button")}</Tooltip.Action>
                </DialogTrigger>
              }
              content={t("terminal.account-select.bonus.description", { percent: getBonusMainPercent(bonus) })}
            >
              <TooltipQuestionIcon />
            </Tooltip>
          </div>
          <div className={textStyles()}>
            <NumberFormat value={credit} decimalScale={currencyDecimalScale} currency={currency} />
          </div>
        </li>
      )}
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.margin-level")}</div>
        <div className={textStyles()}>
          <NumberFormat value={marginLevel} decimalScale={2} suffix="%" />
        </div>
      </li>
      <li className={listItemStyles}>
        <div className={textStyles({ dimmed: true })}>{t("terminal.account-select.leverage")}</div>
        <div className={textStyles()}>1:{leverage}</div>
      </li>
    </ul>
  );
};

export { AccountsSelectInfo };
