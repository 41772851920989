/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { FundsPreTransferRequest } from '../models';
// @ts-ignore
import { FundsPreTransferResult } from '../models';
// @ts-ignore
import { FundsTransferRequest } from '../models';
// @ts-ignore
import { FundsTxItemsContainer } from '../models';
// @ts-ignore
import { FundsTxType } from '../models';
/**
 * FundsApi - axios parameter creator
 * @export
 */
export const FundsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction: async (txId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('cancelTransaction', 'txId', txId)
            const localVarPath = `/v1.0/funds/transactions/{txId}/cancel`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['DateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['DateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (currency !== undefined) {
                localVarQueryParameter['Currency'] = currency;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (assetId !== undefined) {
                localVarQueryParameter['AssetId'] = assetId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preTransfer: async (fundsPreTransferRequest?: FundsPreTransferRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transfer/evaluate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundsPreTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTransactionConfirmation: async (txId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('resendTransactionConfirmation', 'txId', txId)
            const localVarPath = `/v1.0/funds/transactions/{txId}/resend`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer: async (fundsTransferRequest?: FundsTransferRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/funds/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundsTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundsApi - functional programming interface
 * @export
 */
export const FundsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTransaction(txId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTransaction(txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundsTxItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactions(dateFrom, dateTo, currency, type, assetId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async preTransfer(fundsPreTransferRequest?: FundsPreTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundsPreTransferResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.preTransfer(fundsPreTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendTransactionConfirmation(txId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendTransactionConfirmation(txId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transfer(fundsTransferRequest?: FundsTransferRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transfer(fundsTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FundsApi - factory interface
 * @export
 */
export const FundsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel transaction
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(txId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelTransaction(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get transfer transactions
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {string} [currency] 
         * @param {FundsTxType} [type] 
         * @param {string} [assetId] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(dateFrom?: string, dateTo?: string, currency?: string, type?: FundsTxType, assetId?: string, skip?: number, take?: number, options?: any): AxiosPromise<FundsTxItemsContainer> {
            return localVarFp.getTransactions(dateFrom, dateTo, currency, type, assetId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get converted amount for transferring funds
         * @param {FundsPreTransferRequest} [fundsPreTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        preTransfer(fundsPreTransferRequest?: FundsPreTransferRequest, options?: any): AxiosPromise<FundsPreTransferResult> {
            return localVarFp.preTransfer(fundsPreTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend transaction confirmation
         * @param {string} txId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendTransactionConfirmation(txId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendTransactionConfirmation(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer funds
         * @param {FundsTransferRequest} [fundsTransferRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer(fundsTransferRequest?: FundsTransferRequest, options?: any): AxiosPromise<void> {
            return localVarFp.transfer(fundsTransferRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelTransaction operation in FundsApi.
 * @export
 * @interface FundsApiCancelTransactionRequest
 */
export interface FundsApiCancelTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiCancelTransaction
     */
    readonly txId: string
}

/**
 * Request parameters for getTransactions operation in FundsApi.
 * @export
 * @interface FundsApiGetTransactionsRequest
 */
export interface FundsApiGetTransactionsRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly dateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly dateTo?: string

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly currency?: string

    /**
     * 
     * @type {FundsTxType}
     * @memberof FundsApiGetTransactions
     */
    readonly type?: FundsTxType

    /**
     * 
     * @type {string}
     * @memberof FundsApiGetTransactions
     */
    readonly assetId?: string

    /**
     * 
     * @type {number}
     * @memberof FundsApiGetTransactions
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof FundsApiGetTransactions
     */
    readonly take?: number
}

/**
 * Request parameters for preTransfer operation in FundsApi.
 * @export
 * @interface FundsApiPreTransferRequest
 */
export interface FundsApiPreTransferRequest {
    /**
     * 
     * @type {FundsPreTransferRequest}
     * @memberof FundsApiPreTransfer
     */
    readonly fundsPreTransferRequest?: FundsPreTransferRequest
}

/**
 * Request parameters for resendTransactionConfirmation operation in FundsApi.
 * @export
 * @interface FundsApiResendTransactionConfirmationRequest
 */
export interface FundsApiResendTransactionConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof FundsApiResendTransactionConfirmation
     */
    readonly txId: string
}

/**
 * Request parameters for transfer operation in FundsApi.
 * @export
 * @interface FundsApiTransferRequest
 */
export interface FundsApiTransferRequest {
    /**
     * 
     * @type {FundsTransferRequest}
     * @memberof FundsApiTransfer
     */
    readonly fundsTransferRequest?: FundsTransferRequest
}

/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
export class FundsApi extends BaseAPI {
    /**
     * 
     * @summary Cancel transaction
     * @param {FundsApiCancelTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public cancelTransaction(requestParameters: FundsApiCancelTransactionRequest, options?: any) {
        return FundsApiFp(this.configuration).cancelTransaction(requestParameters.txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get transfer transactions
     * @param {FundsApiGetTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public getTransactions(requestParameters: FundsApiGetTransactionsRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).getTransactions(requestParameters.dateFrom, requestParameters.dateTo, requestParameters.currency, requestParameters.type, requestParameters.assetId, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get converted amount for transferring funds
     * @param {FundsApiPreTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public preTransfer(requestParameters: FundsApiPreTransferRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).preTransfer(requestParameters.fundsPreTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend transaction confirmation
     * @param {FundsApiResendTransactionConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public resendTransactionConfirmation(requestParameters: FundsApiResendTransactionConfirmationRequest, options?: any) {
        return FundsApiFp(this.configuration).resendTransactionConfirmation(requestParameters.txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer funds
     * @param {FundsApiTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    public transfer(requestParameters: FundsApiTransferRequest = {}, options?: any) {
        return FundsApiFp(this.configuration).transfer(requestParameters.fundsTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
