import { cva } from "class-variance-authority";
import { type FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";
import { SymbolIcon } from "@/features/terminal/components/symbol/symbol-icon";
import { getInstrumentType } from "@/features/terminal/helpers/symbols";
import { TradingServerSymbolType } from "@/services/openapi";

const textStyles = cva("font-roboto text-[14px] leading-[1.5] text-text")();

type Props = {
  instrumentType: TradingServerSymbolType;
  symbol: string;
  baseCurrency: string;
  baseCurrencyExt: string;
  group: string;
  contractSize: number;
};

const PlaceOrderDetailsSymbolBlock: FC<Props> = ({
  symbol,
  baseCurrency,
  contractSize,
  baseCurrencyExt,
  group,
  instrumentType,
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  return (
    <Table.Card className="flex items-center justify-between gap-2 py-4">
      <div className="flex items-center gap-2">
        <SymbolIcon symbol={symbol} />
        <div className={textStyles}>{symbol}</div>
      </div>
      <div className={textStyles}>
        {isForex ? (
          <Trans
            i18nKey="terminal.place-order.details.one-lot-currency"
            values={{ currency: baseCurrency }}
            components={{
              value: <NumberFormat value={contractSize} />,
            }}
          />
        ) : group === "Crypto" ? (
          <Trans
            i18nKey="terminal.place-order.details.one-lot-currency"
            values={{ currency: baseCurrencyExt }}
            components={{
              value: <NumberFormat value={contractSize} />,
            }}
          />
        ) : (
          <Trans
            i18nKey="terminal.place-order.details.one-lot-units"
            components={{
              value: <NumberFormat value={contractSize} />,
            }}
          />
        )}
      </div>
    </Table.Card>
  );
};

export { PlaceOrderDetailsSymbolBlock };
