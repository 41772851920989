import { type FC, type ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { Popover, PopoverAnchor } from "@/components/popover";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountTradeMode } from "@/services/openapi";
import { Button, Hint } from "@/shared/ui";

import { DemoAddFundsDialog } from "../../demo/add-funds-dialog";
import { usePlaceOrderContext, VolumeLotsError } from "../context";

const DepositButton = ({
  currency,
  accountId,
  isDemoAccount,
  shouldCloseHint,
  onClose,
}: {
  currency: string;
  accountId: string;
  isDemoAccount: boolean;
  shouldCloseHint: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Hint.Footer grow>
      {isDemoAccount ? (
        <DemoAddFundsDialog
          accountCurrency={currency}
          accountId={accountId}
          onClose={shouldCloseHint ? onClose : undefined}
        >
          <Button size="sm">{t("accounts.demo.deposit.button")}</Button>
        </DemoAddFundsDialog>
      ) : (
        <Button size="sm" asChild>
          <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
        </Button>
      )}
    </Hint.Footer>
  );
};

type Props = {
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  children: ReactNode;
  currency: string;
  open: boolean;
  accountId: string;
  isDemoAccount: boolean;
  onClose: () => void;
};

const VolumeHintError: FC<Props> = ({
  children,
  volumeMode,
  changeLots,
  hasNoFreeMargin,
  volumeLotsError,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  maxBalanceVolumeMargin,
  maxBalanceVolumeLots,
  maxSystemVolumeMargin,
  maxSystemVolumeLots,
  volumeMarginDecimalScale,
  volumeLotsDecimalScale,
  currency,
  onClose,
  open,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  const isMarginMode = volumeMode === TradingAccountTradeMode.Margin;

  const shouldCloseHint = !hasNoFreeMargin && volumeLotsError === VolumeLotsError.NONE;

  return (
    <Popover open={open} onOpenChange={onClose}>
      <PopoverAnchor asChild>{children}</PopoverAnchor>
      {hasNoFreeMargin ? (
        <Hint.PopoverContent
          title={t("terminal.place-order.hint.no-free-margin-title")}
          text={
            <Trans
              i18nKey={
                isMarginMode
                  ? "terminal.place-order.margin.input-descriptor.no-free-margin"
                  : "terminal.place-order.lots.input-descriptor.no-free-margin"
              }
              values={{ currency }}
              components={{
                value: isMarginMode ? (
                  <NumberFormat value={minSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />
                ) : (
                  <NumberFormat value={minSystemVolumeLots} decimalScale={volumeLotsDecimalScale} />
                ),
                valueButton: <Hint.TextButton onClick={() => changeLots(maxBalanceVolumeLots)} />,
              }}
            />
          }
        >
          <DepositButton
            accountId={accountId}
            currency={currency}
            isDemoAccount={isDemoAccount}
            onClose={onClose}
            shouldCloseHint={shouldCloseHint}
          />
        </Hint.PopoverContent>
      ) : (
        <>
          {volumeLotsError === VolumeLotsError.MORE_THAN_FREE_MARGIN && (
            <Hint.PopoverContent
              title={t("terminal.place-order.hint.more-than-free-margin-title")}
              text={
                <Trans
                  i18nKey={
                    isMarginMode
                      ? "terminal.place-order.margin.input-descriptor.more-than-free-margin"
                      : "terminal.place-order.lots.input-descriptor.more-than-free-margin"
                  }
                  values={{ currency }}
                  components={{
                    value: isMarginMode ? (
                      <NumberFormat value={maxBalanceVolumeMargin} decimalScale={volumeMarginDecimalScale} />
                    ) : (
                      <NumberFormat value={maxBalanceVolumeLots} decimalScale={volumeLotsDecimalScale} />
                    ),
                    valueButton: <Hint.TextButton onClick={() => changeLots(maxBalanceVolumeLots)} />,
                  }}
                />
              }
            >
              <DepositButton
                accountId={accountId}
                currency={currency}
                isDemoAccount={isDemoAccount}
                onClose={onClose}
                shouldCloseHint={shouldCloseHint}
              />
            </Hint.PopoverContent>
          )}
          {volumeLotsError === VolumeLotsError.MORE_THAN_SYSTEM && (
            <Hint.PopoverContent
              title={t("terminal.place-order.hint.max-system-title")}
              text={
                <Trans
                  i18nKey={
                    isMarginMode
                      ? "terminal.place-order.margin.input-descriptor.max-system"
                      : "terminal.place-order.lots.input-descriptor.max-system"
                  }
                  values={{ currency }}
                  components={{
                    value: isMarginMode ? (
                      <NumberFormat value={maxSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />
                    ) : (
                      <NumberFormat value={maxSystemVolumeLots} decimalScale={volumeLotsDecimalScale} />
                    ),
                    valueButton: <Hint.TextButton onClick={() => changeLots(maxSystemVolumeLots)} />,
                  }}
                />
              }
            />
          )}
        </>
      )}
    </Popover>
  );
};

export { VolumeHintError };
