import { FC } from "react";

import { Flex } from "@/components/flex";
import { useTranslation } from "@/hooks/translator.hook";
import { Tabs } from "@/shared/ui";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useOrdersContext } from "../contexts/orders.context";
import { TerminalTableState, useTerminalLayout } from "../contexts/terminal-layout.context";
import { ClosedPositionsContainer } from "./closed-positions/closed-positions.container";
import { OpenOrdersContainer } from "./open-orders/open-orders.container";
import { PendingOrdersContainer } from "./pending-orders/pending-orders.container";

type Props = {
  onClose: () => void;
};

export const MobileTradingTablesContainer: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { table, changeTable } = useTerminalLayout();
  const { openOrders, pendingOrders } = useOrdersContext();
  const { account } = useTerminalAccountContext();

  const { setSymbol } = useCurrentSymbolContext();

  return (
    <Flex css={{ flexDirection: "column", overflow: "auto", hideScroll: true }}>
      <Tabs value={table} onValueChange={changeTable}>
        <Tabs.List grow className="mb-4">
          <Tabs.Trigger value={TerminalTableState.OPEN}>
            {t("terminal.open")}: {openOrders.length}
          </Tabs.Trigger>
          <Tabs.Trigger value={TerminalTableState.PENDING}>
            {t("terminal.pending")}: {pendingOrders.length}
          </Tabs.Trigger>
          <Tabs.Trigger value={TerminalTableState.CLOSED_POSITIONS}>{t("terminal.closed-positions")}</Tabs.Trigger>
        </Tabs.List>
      </Tabs>
      {table === TerminalTableState.OPEN && (
        <OpenOrdersContainer
          accountId={account.id!}
          accountCurrency={account.currency!}
          onClose={onClose}
          setSymbol={setSymbol}
          currencyDecimalScale={account.digits!}
        />
      )}
      {table === TerminalTableState.PENDING && (
        <PendingOrdersContainer
          accountId={account.id!}
          onClose={onClose}
          setSymbol={setSymbol}
          accountCurrency={account.currency!}
          currencyDecimalScale={account.digits!}
        />
      )}
      {table === TerminalTableState.CLOSED_POSITIONS && (
        <ClosedPositionsContainer
          accountId={account.id!}
          onClose={onClose}
          setSymbol={setSymbol}
          accountCurrency={account.currency!}
          currencyDecimalScale={account.digits!}
        />
      )}
    </Flex>
  );
};
