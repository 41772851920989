import * as PopoverPrimitive from "@radix-ui/react-popover";
import { type FC, ReactNode } from "react";

import { cn } from "@/shared/styles";
import { popoverContentStyles } from "@/shared/ui";

import { WatchlistContainer } from "./watchlist.container";

type Props = {
  children: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: () => void;
};

const WatchlistPopover: FC<Props> = ({ onClose, onOpenChange, open, children }) => {
  return (
    <PopoverPrimitive.Root open={open} onOpenChange={onOpenChange} modal>
      <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          align="start"
          sideOffset={8}
          className={cn(popoverContentStyles(), "h-[80vh] w-[386px] rounded-[24px] bg-bg p-6 shadow outline-none")}
        >
          <WatchlistContainer onClose={onClose} />
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};

export { WatchlistPopover };
