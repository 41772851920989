import { FC } from "react";

import { CountryField } from "@/components/form/fields";
import { SurveyField } from "@/services/openapi";

import { OnboardingFieldWrapper } from "../../styles";
import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyCountryListField: FC<Props> = ({ field: { isRequired, title, id, type, value } }) => {
  return (
    <OnboardingFieldWrapper>
      <CountryField
        name={generateSurveyFieldId(id!, type!)}
        label={generateSurveyFieldTitle(title!, isRequired!)}
        rules={isRequired ? { required: true } : undefined}
        defaultValue={value ? value : undefined}
        resetFieldOnOptionsChanged={false}
      />
    </OnboardingFieldWrapper>
  );
};
