import { FC, useEffect, useRef } from "react";

import { Box } from "@/components/box";
import { CheckboxField } from "@/components/form/fields";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField } from "@/services/openapi";

import { generateSurveyFieldId } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyDeclarationField: FC<Props> = ({ field: { isRequired, title, id, type, description, value } }) => {
  const { t } = useTranslation();
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value) {
      const field = fieldRef?.current?.querySelector("input");
      setTimeout(() => {
        field?.click();
      }, 40);
    }
  }, []);

  return (
    <>
      <Text family="roboto" lineHeight="3" css={{ whiteSpace: "pre-line" }}>
        {title}
      </Text>
      <Box ref={fieldRef}>
        <CheckboxField
          name={generateSurveyFieldId(id!, type!)}
          label={description}
          registerOptions={{
            required: isRequired ? t("form-errors.required-error")! : false,
          }}
          defaultChecked={value === "true" ? true : value === "false" ? false : undefined}
        />
      </Box>
    </>
  );
};
