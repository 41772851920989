import { Box } from "../box";
import { TableBodyContainer, TableBodyExternalProps } from "./table-body-container";
import { TableFooter, TableFooterExternalProps } from "./table-footer";

export type TableExternalProps<T> = TableBodyExternalProps<T> &
  TableFooterExternalProps & {
    emptyMessage: JSX.Element;
  };

export function Table<T>({
  renderBodyRow,
  emptyMessage,
  changePage,
  currentPage,
  items,
  pageSize,
  total,
  footerLeft,
  renderHeader,
}: TableExternalProps<T>) {
  return (
    <Box css={{ mt: "16px" }}>
      {items.length === 0 ? (
        emptyMessage
      ) : (
        <>
          <TableBodyContainer<T> renderHeader={renderHeader} renderBodyRow={renderBodyRow} items={items} />
          <TableFooter
            changePage={changePage}
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            footerLeft={footerLeft}
          />
        </>
      )}
    </Box>
  );
}
