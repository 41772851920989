import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDepositContext } from "@/contexts/deposit.context";
import { DepositContainer } from "@/features/deposit/components/form/deposit.container";
import { cabinetRoutes } from "@/routes/cabinet.routes";

export const DepositDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { paymentOptions, paymentMethod } = useDepositContext();

  useEffect(() => {
    if (!paymentOptions || !paymentMethod) {
      navigate(cabinetRoutes.deposit);
    }
  }, [paymentMethod, paymentOptions]);

  if (!paymentOptions || paymentMethod == null) return <></>;

  return <DepositContainer />;
};
