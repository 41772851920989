import { FC, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { TournamentContainer } from "./tournament.container";
import { TournamentTitle } from "./tournament-title";

export const TournamentPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { i18n } = useTranslation();

  // TODO: remove
  useLayoutEffect(() => {
    i18n.changeLanguage("vi");
  }, []);

  return (
    <div className="mx-auto max-w-[1660px] px-[24px] pb-[60px] pt-[40px] md:pt-[55px]">
      <TournamentTitle />
      <TournamentContainer tournamentId={id!} />
      <div className="fixed bottom-0 left-0 right-0 h-[20px] bg-[#19C651]" />
    </div>
  );
};
