import Big from "big.js";
import { useTranslation } from "react-i18next";

import { TradingServerSymbolType } from "@/services/openapi";

import {
  calculateCurrency,
  calculateCurrencySpread,
  calculateLeverage,
  calculatePipsSpread,
  calculatePipValue,
  calculateSwap,
  pipsSpreadDecimalScale,
} from "../../helpers/formulas";
import { getInstrumentType, MergedTerminalSymbol } from "../../helpers/symbols";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { usePlaceOrderContext, VolumeLotsError } from "../context";

type Params = {
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  volumeBuyMargin: ReturnType<typeof usePlaceOrderContext>["volumeBuyMargin"];
  volumeSellMargin: ReturnType<typeof usePlaceOrderContext>["volumeSellMargin"];
  swapLong: ReturnType<typeof usePlaceOrderContext>["swapLong"];
  swapShort: ReturnType<typeof usePlaceOrderContext>["swapShort"];
  openPrice?: ReturnType<typeof usePlaceOrderContext>["openPrice"];
  leverage: number;
  marginRateInitialMarketBuy: number;
  contractSize: number;
  priceDecimalScale: number;
  baseCurrency: string;
  accountCurrency: string;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  instrumentType: TradingServerSymbolType;
  isPendingOrder?: boolean;
};

const usePlaceOrderDetails = ({
  marginRateInitialMarketBuy,
  leverage,
  volumeLots,
  volumeLotsError,
  volumeLotsDecimalScale,
  volumeMarginDecimalScale,
  contractSize,
  priceDecimalScale,
  bid,
  ask,
  baseCurrency,
  accountCurrency,
  quoteCurrency,
  symbols,
  volumeBuyMargin,
  volumeSellMargin,
  swapLong,
  swapShort,
  instrumentType,
  isPendingOrder,
  openPrice,
}: Params) => {
  const { t } = useTranslation();

  const showValues = volumeLotsError === VolumeLotsError.NONE;

  const pendingAsk = isPendingOrder && openPrice ? openPrice : ask;
  const pendingBid = isPendingOrder && openPrice ? openPrice : bid;

  return {
    volume: showValues
      ? {
          value: volumeLots,
          decimalScale: volumeLotsDecimalScale,
        }
      : null,
    unitsLabel:
      getInstrumentType(instrumentType) === "cfd"
        ? t("terminal.place-order.details.units-cfd")
        : t("terminal.place-order.details.units-forex", { currency: baseCurrency }),
    units: showValues
      ? {
          value: new Big(volumeLots || 0).mul(contractSize).toNumber(),
        }
      : null,
    leverage: `1:${calculateLeverage({ leverage, instrumentType, marginRateInitialMarketBuy })}`,
    sellCurrentPrice: {
      value: bid,
      decimalScale: priceDecimalScale,
    },
    buyCurrentPrice: {
      value: ask,
      decimalScale: priceDecimalScale,
    },
    sellTradeAmount: showValues
      ? {
          value: calculateCurrency({
            accountCurrency,
            quoteCurrency,
            baseCurrency,
            contractSize,
            volumeLots: volumeLots || 0,
            ask: pendingAsk,
            bid: pendingBid,
            type: "sell",
            symbols,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    buyTradeAmount: showValues
      ? {
          value: calculateCurrency({
            accountCurrency,
            quoteCurrency,
            baseCurrency,
            contractSize,
            volumeLots: volumeLots || 0,
            ask: pendingAsk,
            bid: pendingBid,
            type: "buy",
            symbols,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    sellMargin: showValues
      ? {
          value: volumeSellMargin,
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    buyMargin: showValues
      ? {
          value: volumeBuyMargin,
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    swapSell: showValues
      ? {
          value: calculateSwap({
            swapLong,
            swapShort,
            type: "sell",
            accountCurrency,
            baseCurrency,
            quoteCurrency,
            symbols,
            contractSize,
            ask: pendingAsk,
            bid: pendingBid,
            priceDecimalScale,
            volumeLots: volumeLots || 0,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    swapBuy: showValues
      ? {
          value: calculateSwap({
            swapLong,
            swapShort,
            type: "buy",
            accountCurrency,
            baseCurrency,
            quoteCurrency,
            symbols,
            contractSize,
            ask: pendingAsk,
            bid: pendingBid,
            priceDecimalScale,
            volumeLots: volumeLots || 0,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    pipsSpread: !isPendingOrder
      ? {
          value: calculatePipsSpread({
            ask,
            bid,
            priceDecimalScale,
          }),
          decimalScale: pipsSpreadDecimalScale,
        }
      : null,
    pipValue: showValues
      ? {
          value: calculatePipValue({
            priceDecimalScale,
            contractSize,
            volumeLots: volumeLots || 0,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    currencySpread: !isPendingOrder
      ? {
          className: getNumberColorClassname(-1),
          value: new Big(
            calculateCurrencySpread({
              ask,
              bid,
              accountCurrency,
              baseCurrency,
              contractSize,
              quoteCurrency,
              symbols,
            }),
          )
            .neg()
            .toNumber(),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
  };
};

export { usePlaceOrderDetails };
