import { useEffect } from "react";
import { renderToString } from "react-dom/server";

import { IconCross, IconZoomOut } from "@/domains/icons";

import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { useChartContext } from "../context";

// https://www.tradingview.com/charting-library-docs/latest/ui_elements/Toolbars/
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartingLibraryWidget/#createbutton
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.CreateHTMLButtonOptions

export const useChartToggleButton = ({
  isMobile,
  isChartExpanded,
  setChartInfo,
  setIsChartExpanded,
}: {
  isMobile: boolean;
  isChartExpanded: boolean;
  setIsChartExpanded: ReturnType<typeof useTerminalLayout>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
}) => {
  useEffect(() => {
    if (isMobile) {
      window.tvWidget.headerReady().then(() => {
        const button = window.tvWidget.createButton({ align: "right", useTradingViewStyle: false });
        button.addEventListener("click", () => {
          setIsChartExpanded(prev => !prev);

          window.tvWidget.save(state =>
            setChartInfo({ data: state, range: window.tvWidget.activeChart().getVisibleRange() }),
          );
        });

        button.innerHTML = isChartExpanded
          ? renderToString(
              <button className="doto-toggle-mode-button doto-toggle-mode-button-small">
                <IconCross />
              </button>,
            )
          : renderToString(
              <button className="doto-toggle-mode-button doto-toggle-mode-button-middle">
                <IconZoomOut />
              </button>,
            );
      });
    }
  }, []);
};
