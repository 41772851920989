import Big from "big.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { NumberFormat } from "@/app/components";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { BalanceFormat } from "@/components/balance-format";
import { Box } from "@/components/box";
import { SelectOptionType } from "@/components/form";
import { NumberField, SelectField } from "@/components/form/fields";
import { AccountSelect, getAccountOptions } from "@/components/form/fields/account-select/account-select";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { Tab, Tabs } from "@/components/tabs";
import { Text } from "@/components/text";
import { useDepositContext } from "@/contexts/deposit.context";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { calculateBonus } from "@/features/deposit/helpers/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { BonusUserPlatform, PaymentMethod, TradingAccount } from "@/services/openapi";
import { useDepositFeeQuery } from "@/state/server/payment";

import { VerificationContainer } from "../verification";

enum DepositFormFields {
  PAYMENT_METHOD_ID = "paymentMethodId",
  AMOUNT = "amount",
  ACCOUNT_ID = "accountId",
}

export interface DepositFormValues {
  [DepositFormFields.PAYMENT_METHOD_ID]: string;
  [DepositFormFields.AMOUNT]: string;
  [DepositFormFields.ACCOUNT_ID]: string;
}

enum AmountTabsOptions {
  None = "",
  Small = "50",
  Middle = "100",
  Large = "500",
}

type Props = {
  accounts: TradingAccount[];
  onSubmit: (values: DepositFormValues) => void;
  paymentMethods: PaymentMethod[];
  defaultAccount: TradingAccount;
  bonus: BonusUserPlatform[];
  isKycCompleted: boolean;
};

export const DepositForm: FC<Props> = ({
  accounts,
  onSubmit: onDeposit,
  paymentMethods,
  defaultAccount,
  isKycCompleted,
  bonus,
}) => {
  const { t } = useTranslation();
  const context = useDepositContext();
  const { paymentMethod, setPaymentMethod, paymentOptions } = context;
  const [amountTab, setAmountTab] = useState<AmountTabsOptions>(AmountTabsOptions.None);
  const [isVerificationDialogVisible, { onOpenChange: setIsVerificationDialogVisible }] = useDisclosure();

  const selectedOption = useMemo(
    () =>
      paymentOptions?.find(item => {
        return item.value === `${paymentMethod!.id}${paymentMethod!.reccuringId}`;
      }),
    [paymentMethod, paymentOptions],
  );

  const form = useHookForm<DepositFormValues>({
    defaultValues: {
      [DepositFormFields.PAYMENT_METHOD_ID]: `${selectedOption?.value}` || "",
      [DepositFormFields.ACCOUNT_ID]: `${defaultAccount?.id}` || "",
      [DepositFormFields.AMOUNT]: "",
    },
  });
  const { setValue, watch, trigger } = form;

  const { accountId: accountIdValue, paymentMethodId: paymentMethodIdValue, amount: amountValue } = watch();
  const amountNumber = new Big(Number(amountValue));
  const currentAccount = useMemo(
    () => accounts.find(account => account.id === accountIdValue),
    [accountIdValue, accounts],
  );

  const { data: fee } = useDepositFeeQuery(
    {
      paymentMethodId: selectedOption?.data,
      accountId: accountIdValue,
      amount: amountValue,
    },
    {
      enabled: !!paymentMethodIdValue && !!accountIdValue && !!amountValue,
    },
  );
  const accountsOptions: SelectOptionType[] = useMemo(() => getAccountOptions(accounts), [accounts]);

  const onChangeTab = (value: AmountTabsOptions) => {
    setAmountTab(value);

    track(amplitudeEvents.deposit.amount);
    setValue(DepositFormFields.AMOUNT, value, { shouldValidate: true, shouldTouch: true });
  };

  const onSubmit = useCallback(
    (values: DepositFormValues) => {
      if (!isKycCompleted) return setIsVerificationDialogVisible(true);

      return onDeposit(values);
    },
    [isKycCompleted, onDeposit],
  );
  useEffect(() => {
    const changedMethod = paymentMethods.find(item => `${item.id}${item.reccuringId}` === paymentMethodIdValue);
    setPaymentMethod(changedMethod!);
  }, [paymentMethodIdValue, paymentMethods]);

  useEffect(() => {
    if (amountValue === amountTab || amountTab === AmountTabsOptions.None) return;
    else setAmountTab(AmountTabsOptions.None);
  }, [amountValue]);

  const bonusPercent = useMemo(() => calculateBonus(amountValue, bonus[0]), [amountValue, bonus]);

  useEffect(() => {
    trigger(DepositFormFields.AMOUNT);
  }, [paymentMethod]);

  return (
    <HookForm
      form={form}
      onSubmit={onSubmit}
      css={{
        "@bp4": {
          display: "flex",
          gap: "32px",
        },
      }}
    >
      <Stack css={{ gap: 24, "@bp2": { gap: 32 }, maxWidth: "460px", width: "100%" }}>
        <Box>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.payment.title")}
          </Text>
          <div data-test="deposit-payment-option-select">
            <SelectField
              name={DepositFormFields.PAYMENT_METHOD_ID}
              options={paymentOptions!}
              rules={{ required: t("form-errors.required-error")! }}
            />
          </div>
        </Box>
        <Box>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.account.title")}
          </Text>
          <div data-test="deposit-account-select">
            <AccountSelect
              name={DepositFormFields.ACCOUNT_ID}
              options={accountsOptions}
              resetFieldOnOptionsChanged={false}
              rules={{ required: t("form-errors.required-error")! }}
            />
          </div>
        </Box>
        <Box>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.amount.title")}
          </Text>
          <Tabs value={amountTab} onChange={onChangeTab} variant="tile" stretch css={{ mb: 16, gap: "16px" }}>
            <Tab
              numeric
              rounded
              value={AmountTabsOptions.Small}
              label={`$${AmountTabsOptions.Small}`}
              data-test={`deposit-tab-${AmountTabsOptions.Small}`}
            />
            <Tab
              numeric
              rounded
              value={AmountTabsOptions.Middle}
              label={`$${AmountTabsOptions.Middle}`}
              data-test={`deposit-tab-${AmountTabsOptions.Middle}`}
            />
            <Tab
              numeric
              rounded
              value={AmountTabsOptions.Large}
              label={`$${AmountTabsOptions.Large}`}
              data-test={`deposit-tab-${AmountTabsOptions.Large}`}
            />
          </Tabs>
          <Box>
            <NumberField
              name={DepositFormFields.AMOUNT}
              thousandSeparator=","
              placeholder={`${t("deposit.form.amount.minimumPayment")!}: ${paymentMethod?.details?.limit
                ?.from} ${paymentMethod?.currency}`}
              rules={{
                required: `${t(
                  "form-errors.min-payment",
                )} ${paymentMethod?.details?.limit?.from?.toString()} ${paymentMethod?.currency}`,
                min: {
                  message: `${t(
                    "form-errors.min-payment",
                  )} ${paymentMethod?.details?.limit?.from?.toString()} ${paymentMethod?.currency}`,
                  value: paymentMethod?.details?.limit?.from?.toString()!,
                },
                max: {
                  message: `${t(
                    "form-errors.max-payment",
                  )} ${paymentMethod?.details?.limit?.to?.toString()} ${paymentMethod?.currency}`,
                  value: paymentMethod?.details?.limit?.to?.toString()!,
                },
              }}
              data-test="deposit-amount-input"
            />
          </Box>
          {!!amountValue && (
            <Box css={{ mt: 8 }}>
              <Text as="p" color="gray" css={{ mb: 8 }} size={{ "@initial": "1", "@bp3": "2" }}>
                <Text family="roboto" lineHeight="3" css={{ display: "inline-block", color: "inherit" }}>
                  {`${t("deposit.form.amount.toBeDeposited")}:`}&nbsp;
                </Text>
                <Text
                  css={{
                    display: "inline-block",
                    fontSize: "inherit",
                    "& span": {
                      fontSize: "inherit",
                      color: "$textSecondary",
                      fontWeight: "$normal",
                      fontFamily: "$roboto",
                    },
                  }}
                >
                  <BalanceFormat value={+amountValue || 0} currency={paymentMethod!.currency!} />
                </Text>
                .{" "}
                <Text
                  as="p"
                  color="gray"
                  family="roboto"
                  lineHeight="3"
                  data-test="deposit-fee"
                  css={{ display: "inline-block" }}
                  size={{ "@initial": "1", "@bp3": "2" }}
                >
                  {`${t("deposit.form.amount.fee")}: ${fee?.value ? fee?.value : 0}%`}
                </Text>
              </Text>
            </Box>
          )}
        </Box>
        <SubmitButton size="small" data-test="deposit-btn" checkValid={false}>
          {t("button.go-to-payment")}
        </SubmitButton>
        <VerificationContainer open={isVerificationDialogVisible} onOpenChange={setIsVerificationDialogVisible} />
      </Stack>

      {bonus && !!bonusPercent && (
        <>
          <div className="my-6 h-px w-full bg-gray xl:my-0 xl:h-auto xl:w-px xl:grow" />

          <div className="w-full">
            <div className="mb-3 flex items-center justify-between font-roboto">
              <div className="flex items-center gap-2">
                {t("deposit.bonus.text")}

                <BonusTooltip
                  bonus={bonus[0]}
                  actionText={t("deposit.bonus.button")}
                  content={t("deposit.bonus.description", {
                    percent: getBonusMainPercent(bonus[0]!),
                  })}
                />
              </div>
              <div>+{Math.floor(bonusPercent)}%</div>
            </div>

            <div className="flex items-center justify-between font-gilroy font-semibold">
              <div>{t("deposit.bonus.total")}</div>
              <div>
                {Number(amountValue) !== 0 && (
                  <div className="mb-1 block text-end text-text-placeholder line-through">
                    <NumberFormat
                      value={amountValue}
                      currency={bonus[0].currency!}
                      decimalScale={currentAccount?.digits}
                    />
                  </div>
                )}
                <NumberFormat
                  value={amountNumber.plus(amountNumber.mul(bonusPercent).div(100)).toNumber()}
                  currency={bonus[0].currency!}
                  decimalScale={currentAccount?.digits}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </HookForm>
  );
};
