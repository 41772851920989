import React, { useEffect, useState } from "react";

const SENDER_ID = "WuTL44enr";

export const IframeDepositPage = () => {
  const [iframeSrc, setIframeSrc] = useState<string>("");

  useEffect(() => {
    const regex = /^[^?#]+\?([^#]+)/;
    const url = window.location.href;
    const result = url.match(regex);

    if (result && result[1]) {
      setIframeSrc(result[1]);
    }
  }, [window.location.href]);

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e?.data?.senderId !== SENDER_ID) return;

      switch (e?.data?.action) {
        case "@payIn/getLayoutParameters":
          (document.querySelector("#paymentFrame") as unknown as HTMLIFrameElement)?.contentWindow?.postMessage(
            {
              messageId: e.data.messageId,
              status: "done",
              result: {
                themeName: "d-light",
                isLightTheme: true,
                isRTL: false,
              },
            },
            "*",
          );
          break;
      }
    };
    // eslint-disable-next-line no-restricted-globals
    addEventListener("message", listener);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener("message", listener);
  }, []);

  return (
    <>
      {iframeSrc ? (
        <iframe
          id="paymentFrame"
          style={{ width: "100%", height: "100%", border: "none", minHeight: "700px", minWidth: "100%" }}
          src={iframeSrc}
        />
      ) : (
        <p>Something went wrong</p>
      )}
    </>
  );
};
