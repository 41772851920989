export const UnreadNotificationsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="#212121"
      fillRule="evenodd"
      d="M12 4C9.303 4 6.993 6.086 6.673 8.907l-.345 3.044a4.817 4.817 0 01-1.11 2.576C4.698 15.14 5.17 16 5.83 16h12.34c.66 0 1.132-.861.612-1.473a4.817 4.817 0 01-1.11-2.576l-.345-3.044c-.02-.179 1.922-.701 1.886-.873-.323-1.559-2.533-4.405-3.794-5.172-.113-.069-.93 1.77-1.049 1.71A5.199 5.199 0 0012 4zM4.686 8.681C5.115 4.896 8.24 2 12 2a7.183 7.183 0 013.42.862 7.543 7.543 0 013.793 5.172c.042.212.076.428.101.647l.345 3.045c.064.562.291 1.087.648 1.507C21.854 15.054 20.649 18 18.17 18h-3.147a.999.999 0 01.914 1.35C15.347 20.923 13.786 22 12 22s-3.348-1.078-3.936-2.65A1 1 0 018.977 18H5.83c-2.48 0-3.684-2.946-2.137-4.767.357-.42.585-.945.648-1.507l.345-3.045zM9.023 18a1 1 0 01.913.65c.285.759 1.08 1.35 2.064 1.35.985 0 1.779-.591 2.064-1.35a1 1 0 01.913-.65H9.023z"
      clipRule="evenodd"
    ></path>
    <circle cx="18.044" cy="4.953" r="3.953" fill="#FF3434" stroke="#212121" strokeWidth="2"></circle>
  </svg>
);

export const NotificationsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.481 4C9.784 4 7.474 6.086 7.154 8.907L6.81 11.95a4.817 4.817 0 01-1.11 2.576C5.179 15.14 5.652 16 6.31 16h12.34c.66 0 1.132-.861.612-1.473a4.817 4.817 0 01-1.11-2.576l-.345-3.044C17.488 6.086 15.178 4 12.48 4zM5.167 8.681C5.596 4.896 8.72 2 12.481 2c3.76 0 6.885 2.896 7.314 6.681l.345 3.045c.063.562.29 1.087.648 1.507C22.335 15.054 21.13 18 18.65 18h-3.147a.999.999 0 01.913 1.35c-.588 1.572-2.15 2.65-3.936 2.65s-3.348-1.078-3.936-2.65A1 1 0 019.458 18H6.31c-2.48 0-3.684-2.946-2.137-4.767.357-.42.585-.945.648-1.507l.345-3.045zM9.504 18a1 1 0 01.913.65c.285.759 1.08 1.35 2.064 1.35.985 0 1.779-.591 2.064-1.35a1 1 0 01.913-.65H9.504z"
      clipRule="evenodd"
    ></path>
  </svg>
);
