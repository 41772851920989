import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingAccountTradeMode } from "@/services/openapi";
import { Select } from "@/shared/ui";

import { usePlaceOrderContext } from "../../context";

type Props = {
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  currency: string;
};

const MobileVolumeModeSelect: FC<Props> = ({ volumeMode, changeVolumeMode, currency }) => {
  const { t } = useTranslation();

  return (
    <Select value={volumeMode} onValueChange={changeVolumeMode} size="sm">
      <Select.Item value={TradingAccountTradeMode.Margin}>
        {t("terminal.place-order.margin.title", { currency })}
      </Select.Item>
      <Select.Item value={TradingAccountTradeMode.Lots}>{t("terminal.place-order.lots.title")}</Select.Item>
    </Select>
  );
};

export { MobileVolumeModeSelect };
