import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
};

const CloseOrderHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { isCloseOrderStepActive, interruptTour, closeOrder, totalSteps, currentStep, isMobile } =
    useTerminalFeatureTourContext();

  if (!isCloseOrderStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      sideOffset={isMobile ? 16 : 8}
      usePortal={!isMobile}
      content={
        <Hint
          title={t("terminal.onboarding.close-order.title")}
          text={t("terminal.onboarding.close-order.description")}
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
            <Button size="sm" onClick={() => closeOrder()}>
              {isMobile ? t("terminal.onboarding.buttons.back-to-chart") : t("terminal.onboarding.buttons.next")}
            </Button>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { CloseOrderHintPopover };
