import { FC } from "react";

import { useDisclosure } from "@/hooks/disclosure.hook";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { MoreInWatchList } from "../components/trading-unavailable/components/more-in-watch-list/more-in-watch-list";
import { TerminalPlaceOrderProvider } from "../context";
import { MobilePlaceOrderContainer } from "./container";
import { OrderSettingsDialog } from "./order-settings/dialog";
import { VolumeSettingsDialog } from "./volume-settings/dialog";

const MobilePlaceOrderBlock: FC = () => {
  const [openedOrderSettings, { open: openOrderSettings, close: closeOrderSettings }] = useDisclosure();
  const [openedVolumeSettings, { open: openVolumeSettings, close: closeVolumeSettings }] = useDisclosure();

  const { isTradingAvailable, symbolInfo } = useCurrentSymbolContext();

  return isTradingAvailable.available ? (
    <TerminalPlaceOrderProvider>
      <MobilePlaceOrderContainer openOrderSettings={openOrderSettings} openVolumeSettings={openVolumeSettings} />
      <VolumeSettingsDialog open={openedVolumeSettings} onClose={closeVolumeSettings} />
      <OrderSettingsDialog open={openedOrderSettings} onClose={closeOrderSettings} symbol={symbolInfo!.symbol!} />
    </TerminalPlaceOrderProvider>
  ) : (
    <div className="flex items-center justify-center bg-bg-back px-4 py-2">
      <MoreInWatchList />
    </div>
  );
};

export { MobilePlaceOrderBlock };
