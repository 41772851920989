import {
  getDepositFee,
  getDepositMethods,
  getDepositWidgets,
  getMaximumWithdrawAmount,
  getWithdrawFee,
  getWithdrawMethods,
  getWithdrawWidgets,
} from "@/services/payment";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { useBaseQuery } from "@/state/server";
import { paymentQueryKeys } from "@/state/server/payment/payment.keys";
import { QueryOptionsType } from "@/state/server/types";

export const useDepositMethodQuery = (options?: QueryOptionsType<ApiResponseType<typeof getDepositMethods>>) => {
  return useBaseQuery<ApiResponseType<typeof getDepositMethods>>({
    queryKey: paymentQueryKeys.depositMethods(),
    queryFn: getDepositMethods,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawMethodQuery = (options?: QueryOptionsType<ApiResponseType<typeof getWithdrawMethods>>) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawMethods>>({
    queryKey: paymentQueryKeys.withdrawMethods(),
    queryFn: getWithdrawMethods,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useDepositFeeQuery = (
  filters: ApiRequestParamsType<typeof getDepositFee>,
  options?: QueryOptionsType<ApiResponseType<typeof getDepositFee>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getDepositFee>>({
    queryKey: paymentQueryKeys.depositFee(filters),
    queryFn: () => getDepositFee(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawFeeQuery = (
  filters: ApiRequestParamsType<typeof getWithdrawFee>,
  options?: QueryOptionsType<ApiResponseType<typeof getWithdrawFee>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawFee>>({
    queryKey: paymentQueryKeys.withdrawFee(filters),
    queryFn: () => getWithdrawFee(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useMaximumWithdrawAmountQuery = (
  filters: ApiRequestParamsType<typeof getMaximumWithdrawAmount>,
  options?: QueryOptionsType<ApiResponseType<typeof getMaximumWithdrawAmount>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getMaximumWithdrawAmount>>({
    queryKey: paymentQueryKeys.maximumWithdrawAmount(filters),
    queryFn: () => getMaximumWithdrawAmount(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useDepositWidgetsQuery = (options?: QueryOptionsType<ApiResponseType<typeof getDepositWidgets>>) => {
  return useBaseQuery<ApiResponseType<typeof getDepositWidgets>>({
    queryKey: paymentQueryKeys.widgets(),
    queryFn: getDepositWidgets,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawWidgetsQuery = (options?: QueryOptionsType<ApiResponseType<typeof getWithdrawWidgets>>) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawWidgets>>({
    queryKey: paymentQueryKeys.widgets(),
    queryFn: getWithdrawWidgets,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};
