import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const Row: FC<{ children: ReactNode }> = ({ children }) => (
  <li className="grid grid-cols-2 items-center gap-3 border-b py-2 last:border-none">{children}</li>
);

const Label: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="text-start font-roboto text-[16px] leading-[1.5] text-text-secondary">{children}</div>
);

const Value: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <div className={cn("text-end font-roboto text-[16px] leading-[1.5] text-text", className)}>{children}</div>
);

const List: FC<{ children: ReactNode }> = ({ children }) => <ul>{children}</ul>;

const Component = Object.assign(List, {
  Row,
  Label,
  Value,
});

export { Component as DesktopPlaceOrderDetailsList };
