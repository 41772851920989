import { FC, Fragment, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useInfiniteTerminalPositionsQuery } from "@/state/server/terminal";

import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { TradingCardsContainer } from "../components/trading-table/trading-cards.container";
import { TradingTable } from "../components/trading-table/trading-table";
import { TradingTableEmptyState } from "../components/trading-table/trading-table-empty-state";
import { ClosedPositionCard } from "./closed-position-card";
import { ClosedPositionTableRow } from "./closed-position-table-row";
import { ClosedPositionsHeaderRow } from "./closed-positions-header-row";

type Props = {
  accountId: string;
  setSymbol: (symbol: string) => void;
  accountCurrency: string;
  currencyDecimalScale: number;
  onClose?: () => void;
};

const _ClosedPositionsTable: FC<Props> = ({ accountId, setSymbol, onClose, accountCurrency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const { ref, inView } = useInView();
  const { isMobile } = useTerminalLayout();

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage } = useInfiniteTerminalPositionsQuery({
    pageSize: 40,
    tradingAccountId: accountId,
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  const emptyState = (
    <TradingTableEmptyState
      title={t("terminal.empty-state.closed-title")}
      description={t("terminal.empty-state.closed-description")}
    />
  );

  return isMobile ? (
    <TradingCardsContainer>
      {data && data.pages[0].items!.length === 0 && emptyState}
      {data &&
        data.pages.map((page, i) => (
          <Fragment key={i}>
            {page.items!.map(position => (
              <ClosedPositionCard
                key={position.position}
                accountId={accountId}
                position={position}
                accountCurrency={accountCurrency}
                onCardClose={onClose}
                setSymbol={setSymbol}
                currencyDecimalScale={currencyDecimalScale}
              />
            ))}
          </Fragment>
        ))}
      {hasNextPage && <div ref={ref} style={{ height: "20px" }} />}
    </TradingCardsContainer>
  ) : (
    <>
      {data && (
        <>
          {data.pages[0].items!.length > 0 ? (
            <TradingTable.Scroll>
              <TradingTable.StyledTable>
                <thead>
                  <ClosedPositionsHeaderRow accountCurrency={accountCurrency} />
                </thead>
                <tbody>
                  {data.pages.map((page, i) => (
                    <Fragment key={i}>
                      {page.items!.map(position => (
                        <ClosedPositionTableRow
                          key={position.position}
                          accountId={accountId}
                          position={position}
                          setSymbol={setSymbol}
                          currencyDecimalScale={currencyDecimalScale}
                        />
                      ))}
                    </Fragment>
                  ))}
                  {hasNextPage && <tr ref={ref}></tr>}
                </tbody>
              </TradingTable.StyledTable>
            </TradingTable.Scroll>
          ) : (
            emptyState
          )}
        </>
      )}
    </>
  );
};

export const ClosedPositionsTable = memo(_ClosedPositionsTable);
