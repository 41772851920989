import { FC } from "react";

import { Flex } from "@/components/flex";
import { TextNumberField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Link } from "@/components/link";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { twoFARecoveryRules } from "@/utils/validators";

import { AuthBox } from "../styles";

enum TwoFactorRecoveryFields {
  RECOVERY_CODE = "recoveryCode",
}

export interface ITwoFARecoveryFormValues {
  [TwoFactorRecoveryFields.RECOVERY_CODE]: string;
}

interface Props {
  onSubmit: (values: ITwoFARecoveryFormValues) => void;
}

export const TwoFactorRecoveryForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useHookForm<ITwoFARecoveryFormValues>();

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <AuthBox css={{ mb: "40px", mt: "16px" }}>
        <Text weight="bold" family="roboto" align="center" as="h2" css={{ mb: "16px" }} size="3">
          {t("auth.two-factor-recovery.title")}
        </Text>
        <Text family="roboto" align="center" css={{ mb: "16px" }}>
          {t("auth.two-factor-recovery.description")}
        </Text>
        <TextNumberField
          name={TwoFactorRecoveryFields.RECOVERY_CODE}
          placeholder={t("two-factor.recovery-code")!}
          rules={twoFARecoveryRules(t)}
        />
        <Flex css={{ justifyContent: "center", my: "15px" }}>
          <SubmitButton checkValid={false}>{t("button.continue")}</SubmitButton>
        </Flex>
        <Text weight="bold" align="center">
          {t("auth.sign-up.account")}{" "}
          <Link to={authRoutes.signUp} color="primary">
            {t("auth.sign-up.sign-up")}
          </Link>
        </Text>
      </AuthBox>
    </HookForm>
  );
};
