import { styled } from "@/styles";

export const ErrorMessage = styled("div", {
  marginTop: "8px",
  opacity: "0",

  fontFamily: "$roboto",
  fontSize: "16px",
  fontWeight: "$normal",
  lineHeight: "$3" /* 24px */,

  color: "$negativeDefault",

  variants: {
    hasError: {
      true: {
        opacity: "1",
      },
    },
  },
});
