import { styled } from "@/styles";

import { mixinsCommon } from "../../mixins/common";
import { iconEnd, iconStart } from "../../mixins/input";

export const Wrapper = styled("div", {
  position: "relative",
  width: "100%",
});
export const InputWrapper = styled("div", {
  position: "relative",
  // width: "100%",
});

export const Input = styled("input", {
  width: "100%",
  gap: "12px",
  px: "14px",

  border: "1px solid $bgBorder",
  borderRadius: "16px",
  boxSizing: "border-box",
  background: "$white",

  color: "$dark",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontWeight: "$normal",
  lineHeight: "$3" /* 24px */,

  "&::placeholder": {
    color: "$textPlaceholder",
  },

  "&:hover": {
    borderColor: "$textPlaceholder",
  },

  "&:focus": {
    borderColor: "$textSecondary",
  },

  "&:focus-visible": {
    outline: "none",
  },

  variants: {
    inputSize: {
      s: {
        height: "46px",
      },
      m: {
        height: "54px",
      },
      l: {
        height: "62px",
      },
    },
    indentStart: {
      true: {
        paddingLeft: "50px",
      },
    },
    indentEnd: {
      true: {
        paddingRight: "50px",
      },
    },
    disabled: {
      true: mixinsCommon.disabled,
    },
    error: {
      true: {
        "&:hover": {
          borderColor: "$negativeDefault",
        },

        "&:focus": {
          borderColor: "$negativeDefault",
          boxShadow: "0px 0px 0px 2px rgba(255, 52, 52, 0.12)",
        },
      },
    },
  },

  defaultVariants: {
    inputSize: "s",
  },
});

export const IconStart = styled(iconStart, {});
export const IconEnd = styled(iconEnd, {});
