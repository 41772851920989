export const GlassSuccessIcon = () => (
  <svg width={29} height={29} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_701_31857)">
      <path
        d="M22.411 12.977c0 5.139-4.228 9.305-9.443 9.305s-9.443-4.166-9.443-9.305c0-5.139 4.228-9.305 9.443-9.305s9.443 4.166 9.443 9.305z"
        fill="url(#paint0_linear_701_31857)"
      />
    </g>
    <g filter="url(#filter1_bii_701_31857)">
      <path
        d="M22.411 12.981c0 5.141-4.228 9.309-9.443 9.309s-9.443-4.168-9.443-9.309c0-5.141 4.228-9.309 9.443-9.309s9.443 4.168 9.443 9.309z"
        fill="url(#paint1_linear_701_31857)"
        fillOpacity={0.1}
      />
    </g>
    <g filter="url(#filter2_bii_701_31857)">
      <path
        d="M24.59 12.947c0-6.29-5.175-11.39-11.56-11.39-6.383 0-11.559 5.1-11.559 11.39 0 6.29 5.176 11.39 11.56 11.39s11.56-5.1 11.56-11.39z"
        fill="url(#paint2_linear_701_31857)"
        fillOpacity={0.1}
      />
      <path
        d="M24.59 12.947c0-6.29-5.175-11.39-11.56-11.39-6.383 0-11.559 5.1-11.559 11.39 0 6.29 5.176 11.39 11.56 11.39s11.56-5.1 11.56-11.39z"
        stroke="url(#paint3_linear_701_31857)"
        strokeWidth={1.70075}
      />
    </g>
    <g filter="url(#filter3_biii_701_31857)">
      <path
        d="M12.997 3.855c-5.14 0-9.307 4.106-9.307 9.17 0 5.065 4.167 9.17 9.307 9.17 5.14 0 9.307-4.105 9.307-9.17 0-5.064-4.167-9.17-9.307-9.17z"
        fill="url(#paint4_linear_701_31857)"
        fillOpacity={0.01}
      />
      <path
        d="M12.997 3.175c-5.506 0-9.987 4.4-9.987 9.85 0 5.45 4.48 9.851 9.987 9.851 5.506 0 9.987-4.4 9.987-9.85 0-5.45-4.48-9.851-9.987-9.851z"
        stroke="url(#paint5_radial_701_31857)"
        strokeWidth={1.3606}
      />
    </g>
    <g filter="url(#filter4_biii_701_31857)">
      <path
        d="M13.054 4.577c-4.73 0-8.565 3.779-8.565 8.44 0 4.66 3.835 8.439 8.565 8.439 4.73 0 8.565-3.779 8.565-8.44 0-4.66-3.835-8.439-8.565-8.439z"
        fill="url(#paint6_linear_701_31857)"
        fillOpacity={0.01}
      />
    </g>
    <g filter="url(#filter5_i_701_31857)">
      <path
        d="M18.09 9.308a1.166 1.166 0 00-1.642.034l-4.365 4.464-1.515-1.573a1.166 1.166 0 00-1.641-.034 1.147 1.147 0 00-.034 1.63l2.291 2.357a1.305 1.305 0 001.872-.002l5.068-5.246a1.146 1.146 0 00-.035-1.629z"
        fill="url(#paint7_linear_701_31857)"
      />
    </g>
    <g filter="url(#filter6_b_701_31857)">
      <path
        d="M2.458 18.497c3.163 5.89 10.447 8.203 16.27 5.167 5.822-3.036 7.978-10.272 4.815-16.161C20.38 1.613 13.096-.7 7.273 2.336 1.451 5.372-.705 12.608 2.458 18.497z"
        fill="url(#paint8_linear_701_31857)"
        fillOpacity={0.01}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_701_31857"
        x={1.16189}
        y={1.30915}
        width={26.9886}
        height={26.7119}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx={1.68803} dy={1.68803} />
        <feGaussianBlur stdDeviation={2.02564} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.458586 0 0 0 0 0.157293 0 0 0 0.5 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_701_31857" result="shape" />
      </filter>
      <filter
        id="filter1_bii_701_31857"
        x={-3.27786}
        y={-3.13061}
        width={32.492}
        height={32.2233}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={3.4015} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_701_31857" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
        <feBlend in2="shape" result="effect2_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={-3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0313726 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 0.15 0" />
        <feBlend in2="effect2_innerShadow_701_31857" result="effect3_innerShadow_701_31857" />
      </filter>
      <filter
        id="filter2_bii_701_31857"
        x={-6.182}
        y={-6.09679}
        width={38.4257}
        height={38.087}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={3.4015} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_701_31857" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0" />
        <feBlend in2="shape" result="effect2_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={-3.4015} />
        <feGaussianBlur stdDeviation={1.70075} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0313726 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 0.15 0" />
        <feBlend in2="effect2_innerShadow_701_31857" result="effect3_innerShadow_701_31857" />
      </filter>
      <filter
        id="filter3_biii_701_31857"
        x={-31.6855}
        y={-31.5207}
        width={89.3649}
        height={89.0923}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={17.0075} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_701_31857" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={6.803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect2_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={3.06135} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0313726 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 1 0" />
        <feBlend in2="effect2_innerShadow_701_31857" result="effect3_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={0.6803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend in2="effect3_innerShadow_701_31857" result="effect4_innerShadow_701_31857" />
      </filter>
      <filter
        id="filter4_biii_701_31857"
        x={-12.5181}
        y={-12.4303}
        width={51.1445}
        height={50.8936}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={8.50375} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_701_31857" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={6.803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect2_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={3.06135} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0313726 0 0 0 0 0.843137 0 0 0 0 0.309804 0 0 0 0.2 0" />
        <feBlend in2="effect2_innerShadow_701_31857" result="effect3_innerShadow_701_31857" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy={1.3606} />
        <feGaussianBlur stdDeviation={0.6803} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
        <feBlend in2="effect3_innerShadow_701_31857" result="effect4_innerShadow_701_31857" />
      </filter>
      <filter
        id="filter5_i_701_31857"
        x={6.52852}
        y={8.9874}
        width={11.9182}
        height={10.9944}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx={-2.0409} dy={3.4015} />
        <feGaussianBlur stdDeviation={2.21097} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.643137 0 0 0 0 0.854902 0 0 0 0 0.952941 0 0 0 0.6 0" />
        <feBlend in2="shape" result="effect1_innerShadow_701_31857" />
      </filter>
      <filter
        id="filter6_b_701_31857"
        x={0.660094}
        y={0.65985}
        width={24.6803}
        height={24.6803}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={0.170075} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_701_31857" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_701_31857" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_701_31857"
        x1={6.2901}
        y1={6.81271}
        x2={36.7491}
        y2={31.0709}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.14627} stopColor="#54D860" />
        <stop offset={0.931238} stopColor="#61E36C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_701_31857"
        x1={-1.55364}
        y1={1.34526}
        x2={48.1703}
        y2={29.4936}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#08BD46" />
        <stop offset={1} stopColor="#30F072" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_701_31857"
        x1={13.0309}
        y1={24.3369}
        x2={13.0309}
        y2={1.55658}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#08D74F" />
        <stop offset={1} stopColor="#08BD46" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_701_31857"
        x1={1.47137}
        y1={12.9467}
        x2={24.5903}
        y2={12.9467}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.25} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_701_31857"
        x1={3.69007}
        y1={13.0254}
        x2={22.3052}
        y2={13.0254}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#08BD46" />
        <stop offset={0.691655} stopColor="#08D74F" stopOpacity={0.5} />
      </linearGradient>
      <radialGradient
        id="paint5_radial_701_31857"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -9.17053 9.30686 0 12.997 13.025)"
      >
        <stop stopColor="#E51717" />
        <stop offset={1} stopColor="#F66" stopOpacity={0.02} />
      </radialGradient>
      <linearGradient
        id="paint6_linear_701_31857"
        x1={4.48942}
        y1={13.0165}
        x2={21.6202}
        y2={13.0165}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.196863} stopColor="#08BD46" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_701_31857"
        x1={8.56978}
        y1={12.7839}
        x2={28.3249}
        y2={12.7839}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_701_31857"
        x1={7.27318}
        y1={2.33603}
        x2={18.4654}
        y2={23.8006}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#08BD46" />
        <stop offset={1} stopColor="#30F072" />
      </linearGradient>
    </defs>
  </svg>
);
