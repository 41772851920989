import { Flex } from "@/components/flex";
import { SymbolIcon } from "@/features/terminal/components/symbol/symbol-icon";
import { CSS, styled } from "@/styles";

const Container = styled(Flex, {
  alignItems: "center",
  gap: "8px",
});

export const OrderSymbol = ({ symbol, css }: { symbol: string; css?: CSS }) => {
  return (
    <Container css={css}>
      <SymbolIcon symbol={symbol} />
      {symbol}
    </Container>
  );
};
