import IconArrowRight24 from "@/features/terminal/place-order/components/trading-unavailable/assets/arrow-right24.svg";

import * as Styled from "./instrument-button.styled";

export const InstrumentButton = ({ symbol, setSymbol }: { symbol: string; setSymbol: (symbol: string) => void }) => {
  return (
    <Styled.InstrumentButton onClick={() => setSymbol(symbol)}>
      <Styled.InstrumentCurrency>
        <img
          src={`${import.meta.env.VITE_SYMBOL_IMAGE_URL}/${symbol}.png`}
          alt={symbol}
          loading="lazy"
          width="24"
          height="24"
        />
        <p>{symbol}</p>
      </Styled.InstrumentCurrency>
      <img src={IconArrowRight24} alt="arrow-right" loading="lazy" />
    </Styled.InstrumentButton>
  );
};
