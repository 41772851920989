import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { appLinks } from "@/app/config/links";
import { BonusUserPlatform } from "@/services/openapi";
import { ButtonGroup, ExternalLink } from "@/shared/ui";

import { DepositInfo } from "./deposit-info";
import { WithdrawalInfo } from "./withdrawal-info";

enum Terms {
  WITHDRAWAL = "withdrawal",
  DEPOSIT = "deposit",
}

type Props = {
  bonus: BonusUserPlatform;
};

const TermsBlock: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();

  const [term, setTerm] = useState<Terms>(Terms.DEPOSIT);

  return (
    <>
      <h3 className="mb-4 text-center font-gilroy text-[18px] font-semibold leading-[1.2] text-text md:mb-6 md:text-[22px]">
        {t("bonus.dialog.terms.title")}
      </h3>
      <ButtonGroup value={term} onValueChange={setTerm} size="sm" className="mb-6">
        <ButtonGroup.Item value={Terms.DEPOSIT}>{t("cabinet.navigation.deposit")}</ButtonGroup.Item>
        <ButtonGroup.Item value={Terms.WITHDRAWAL}>{t("cabinet.navigation.withdrawal")}</ButtonGroup.Item>
      </ButtonGroup>
      {term === Terms.DEPOSIT && <DepositInfo bonus={bonus} />}
      {term === Terms.WITHDRAWAL && <WithdrawalInfo />}
      <ExternalLink
        className="mt-4 block text-center font-gilroy text-[14px] font-bold leading-[1.2] text-text underline"
        href={appLinks.bonusesTerms}
      >
        {t("bonus.dialog.terms.link")}
      </ExternalLink>
    </>
  );
};

export { TermsBlock };
