import { type FC, type ReactElement, useCallback } from "react";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { SetBalanceDialogContent } from "@/features/dashboard/components/set-balance-button/dialog-content";
import { useDisclosure } from "@/hooks/disclosure.hook";

type Props = {
  accountId: string;
  accountCurrency: string;
  children: ReactElement;
  onClose?: () => void;
};

const DemoAddFundsDialog: FC<Props> = ({ children, onClose, accountCurrency, accountId }) => {
  const [opened, { close: closeDialog, open: openDialog }] = useDisclosure();

  const handleClose = useCallback(() => {
    closeDialog();
    onClose?.();
  }, [closeDialog, onClose]);

  return (
    <Dialog
      open={opened}
      onOpenChange={flag => {
        if (flag) {
          openDialog();
        } else {
          handleClose();
        }
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <SetBalanceDialogContent accountId={accountId} accountCurrency={accountCurrency} onClose={handleClose} />
    </Dialog>
  );
};

export { DemoAddFundsDialog };
