import { type ComponentProps, type FC, type ReactNode } from "react";

import { TradingAccountTradeMode } from "@/services/openapi";

import { DesktopVolumeLotsField } from "../fields/volume-lots/field";
import { DesktopVolumeMarginField } from "../fields/volume-margin/field";
import { DesktopVolumeModeSelect } from "../volume-mode/select";

type Props = ComponentProps<typeof DesktopVolumeModeSelect> &
  ComponentProps<typeof DesktopVolumeLotsField> &
  ComponentProps<typeof DesktopVolumeMarginField> & {
    children?: ReactNode;
  };

const DesktopValueTypeBlock: FC<Props> = ({
  changeLots,
  changeMargin,
  currency,
  decrementOrder,
  decrementVolumeDisabled,
  hasNoFreeMargin,
  incrementOrder,
  changeVolumeMode,
  incrementVolumeDisabled,
  isSubmitting,
  marginMultiplier,
  maxAvailableVolumeLots,
  maxAvailableVolumeMargin,
  maxBalanceVolumeLots,
  maxBalanceVolumeMargin,
  maxSystemVolumeLots,
  maxSystemVolumeMargin,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  volumeLots,
  volumeLotsDecimalScale,
  volumeLotsError,
  volumeLotsStep,
  volumeMargin,
  volumeMarginDecimalScale,
  volumeMode,
  children,
  incrementOrderStyles,
  volumeMarginFormValue,
  volumeLotsFormValue,
  changeVolumeModeStyles,
  accountId,
  isDemoAccount,
}) => {
  return (
    <div className="flex flex-col gap-1">
      {children}
      <DesktopVolumeModeSelect
        volumeMode={volumeMode}
        changeVolumeMode={changeVolumeMode}
        currency={currency}
        changeVolumeModeStyles={changeVolumeModeStyles}
      />
      {volumeMode === TradingAccountTradeMode.Lots && (
        <DesktopVolumeLotsField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          volumeLotsFormValue={volumeLotsFormValue}
          changeLots={changeLots}
          currency={currency}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
          incrementOrderStyles={incrementOrderStyles}
          volumeLots={volumeLots}
          volumeLotsStep={volumeLotsStep}
        />
      )}
      {volumeMode === TradingAccountTradeMode.Margin && (
        <DesktopVolumeMarginField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeMargin={minSystemVolumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeMargin={changeMargin}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={currency}
          volumeMargin={volumeMargin}
          marginMultiplier={marginMultiplier}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          volumeLotsStep={volumeLotsStep}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeMarginFormValue={volumeMarginFormValue}
          incrementOrderStyles={incrementOrderStyles}
        />
      )}
    </div>
  );
};

export { DesktopValueTypeBlock };
