import { FC, memo } from "react";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalAccountSummary } from "./context";
import { DesktopAccountSummary } from "./desktop-account-summary";
import { MobileAccountSummary } from "./mobile-account-summary";

const _Container: FC = () => {
  const { isMobile } = useTerminalLayout();

  const { bonus, showBonus } = useTerminalAccountContext();

  const { currency, equity, leverage, marginLevel, pnl, currencyDecimalScale, marginFree, credit } =
    useTerminalAccountSummary();

  return isMobile ? (
    <MobileAccountSummary currencyDecimalScale={currencyDecimalScale} pnl={pnl} currency={currency} equity={equity} />
  ) : (
    <DesktopAccountSummary
      currencyDecimalScale={currencyDecimalScale}
      marginLevel={marginLevel}
      pnl={pnl}
      currency={currency}
      equity={equity}
      leverage={leverage}
      marginFree={marginFree}
      bonus={bonus}
      showBonus={showBonus}
      credit={credit}
    />
  );
};

const Container = memo(_Container);

Container.displayName = "AccountSummaryContainer";

export { Container as AccountSummaryContainer };
