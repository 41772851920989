import { FC } from "react";

import image from "@/assets/images/DialogImage3.svg";
import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { InfoDialog } from "@/components/info-dialog";
import { useTranslation } from "@/hooks/translator.hook";

type Props = {
  onDeposit: () => void;
};

export const CongratulationsDialogContent: FC<Props> = ({ onDeposit }) => {
  const { t } = useTranslation();
  return (
    <InfoDialog
      imageSrc={image}
      title={t("congratulations.dialog-title")}
      description={t("congratulations.dialog-description")}
    >
      <Flex css={{ justifyContent: "center" }}>
        <Button onClick={onDeposit}>{t("button.deposit")}</Button>
      </Flex>
    </InfoDialog>
  );
};
