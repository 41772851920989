import { FC, memo, SyntheticEvent } from "react";
import { useQueryClient } from "react-query";

import { FavoriteIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { TerminalAccountSymbols } from "@/services/openapi";
import {
  terminalQueryKeys,
  useAddSymbolToFavoritesMutation,
  useRemoveSymbolFromFavoritesMutation,
} from "@/state/server/terminal";

type Props = {
  isFavorite: boolean;
  symbol: string;
  accountId: string;
};

const _WatchlistFavoriteButton: FC<Props> = ({ isFavorite, symbol, accountId }) => {
  const queryClient = useQueryClient();

  const { mutate: addFavorite, isLoading: addLoading } = useAddSymbolToFavoritesMutation();
  const { mutate: removeFavorite, isLoading: removeLoading } = useRemoveSymbolFromFavoritesMutation();

  const disabled = addLoading || removeLoading;

  const toggleFavorite = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isFavorite) {
      removeFavorite(
        { symbol, tradingAccountId: accountId },
        {
          onSuccess: () => {
            queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
              const newFavorites: string[] = oldData!.favorites!.filter(item => item !== symbol);
              const newData: TerminalAccountSymbols = {
                ...oldData,
                favorites: newFavorites,
              };
              return newData;
            });
          },
        },
      );
    } else {
      addFavorite(
        { symbol, tradingAccountId: accountId },
        {
          onSuccess: () => {
            queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
              const newFavorites: string[] = [...oldData!.favorites!, symbol];
              const newData: TerminalAccountSymbols = {
                ...oldData,
                favorites: newFavorites,
              };
              return newData;
            });
          },
        },
      );
    }
  };

  return (
    <UnstyledButton onClick={toggleFavorite} disabled={disabled} css={{ p: 5 }}>
      <FavoriteIcon filled={isFavorite} />
    </UnstyledButton>
  );
};

export const WatchlistFavoriteButton = memo(_WatchlistFavoriteButton);
