import { FC, memo, useState } from "react";

import { PasswordHideIcon, PasswordShowIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { useTranslation } from "@/hooks/translator.hook";

import { TextField, TextFieldProps } from "../text";

export const _PasswordField: FC<TextFieldProps> = props => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      registerOptions={{
        required: t("form-errors.required-error")!,
        ...props.registerOptions,
      }}
      placeholder={props.placeholder || t("fields.password.password")!}
      rightAdornment={
        <UnstyledButton onClick={() => setShowPassword(!showPassword)} css={{ color: "$textSecondary" }}>
          {showPassword ? <PasswordHideIcon /> : <PasswordShowIcon />}
        </UnstyledButton>
      }
    />
  );
};

export const PasswordField = memo(_PasswordField);
