import { Slot } from "@radix-ui/react-slot";
import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { useTerminalFeatureTourContext } from "@/features/terminal/feature-tour/context";
import { LotsIncrementOrderHintPopover } from "@/features/terminal/feature-tour/steps/increment-order/lots-popover";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../../context";
import { VolumeLotsFieldDescriptor } from "../../../volume-lots/descriptor";

type Props = Omit<ComponentProps<typeof VolumeLotsFieldDescriptor>, "isMobile"> & {
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  volumeLotsFormValue: ReturnType<typeof usePlaceOrderContext>["volumeLotsFormValue"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  isSubmitting: boolean;
  incrementOrderStyles?: ReturnType<typeof useTerminalFeatureTourContext>["incrementOrderStyles"];
};

const emptyObject = {} as ReturnType<typeof useTerminalFeatureTourContext>["incrementOrderStyles"];

const DesktopVolumeLotsField: FC<Props> = ({
  changeLots,
  currency,
  decrementOrder,
  decrementVolumeDisabled,
  hasNoFreeMargin,
  incrementOrder,
  incrementVolumeDisabled,
  isSubmitting,
  maxAvailableVolumeLots,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  minSystemVolumeLots,
  volumeLotsFormValue,
  volumeLotsDecimalScale,
  volumeLotsError,
  volumeLotsStep,
  volumeLots,
  incrementOrderStyles = emptyObject,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  return (
    <NumberInput
      label={t("common.volume")!}
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeLotsFormValue}
      suffix={` ${t("terminal.lots")}`}
      renderPlusButton={button => (
        <LotsIncrementOrderHintPopover
          volumeLotsStep={volumeLotsStep}
          currentVolume={volumeLots || 0}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
        >
          <Slot style={incrementOrderStyles.target}>{button}</Slot>
        </LotsIncrementOrderHintPopover>
      )}
      renderWrapper={element => <Slot style={incrementOrderStyles.highlight}>{element}</Slot>}
      onIncrement={incrementOrder}
      onDecrement={decrementOrder}
      onChange={value => changeLots(value, { format: false })}
      invalid={volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin}
      decimalScale={volumeLotsDecimalScale}
      plusDisabled={incrementVolumeDisabled}
      minusDisabled={decrementVolumeDisabled}
      descriptor={
        <VolumeLotsFieldDescriptor
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          changeLots={changeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          currency={currency}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
        />
      }
    />
  );
};

export { DesktopVolumeLotsField };
