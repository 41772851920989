import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Hint } from "@/shared/ui";

import { IncrementOrderHintPopover } from "./hint-popover";

type Props = {
  children: React.ReactNode;
};

const MarginIncrementOrderHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <IncrementOrderHintPopover
      title={t("terminal.onboarding.increment-order.margin.title")}
      text={
        <Trans
          i18nKey="terminal.onboarding.increment-order.margin.description"
          components={{ strong: <Hint.Strong /> }}
        />
      }
    >
      {children}
    </IncrementOrderHintPopover>
  );
};

export { MarginIncrementOrderHintPopover };
