import Big from "big.js";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { Hint } from "@/shared/ui";

import { IncrementOrderHintPopover } from "./hint-popover";

type Props = {
  children: React.ReactNode;
  volumeLotsStep: number;
  volumeLotsDecimalScale: number;
  currentVolume: number;
};

const LotsIncrementOrderHintPopover: React.FC<Props> = ({
  children,
  volumeLotsStep,
  currentVolume,
  volumeLotsDecimalScale,
}) => {
  const { t } = useTranslation();

  return (
    <IncrementOrderHintPopover
      title={t("terminal.onboarding.increment-order.lots.title")}
      text={
        <Trans
          i18nKey="terminal.onboarding.increment-order.lots.description"
          components={{
            strong: <Hint.Strong />,
            value: (
              <NumberFormat
                value={new Big(currentVolume).plus(volumeLotsStep).toNumber()}
                decimalScale={volumeLotsDecimalScale}
              />
            ),
          }}
        />
      }
    >
      {children}
    </IncrementOrderHintPopover>
  );
};

export { LotsIncrementOrderHintPopover };
