import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";

import { breakpoints } from "./breakpoints";

export const { globalCss, styled, css, keyframes, config, theme, createTheme } = createStitches({
  theme: {
    colors: {
      primary: "#19C651",
      white: "#FFFFFF",
      black: "#212121",
      gray: "#E8E8E8",
      extraLightGray: "#BABABA",
      lightGray: "#F8F8F8",
      darkGray: "#7C7C7C",
      blue: "#0070FF",
      lite: "#FFFFFF",
      dark: "#212121",
      textMain: "#212121",
      textSecondary: "#7C7C7C",
      textInverse: "#FFFFFF",
      textPlaceholder: "#BABABA",
      bgPrimary: "#FFFFFF",
      bgDeep: "#E8E8E8",
      bgSelected: "#E8E8E8",
      bgBack: "#F8F8F8",
      bgAdditional: "#212121",
      bgBorder: "#E8E8E8",
      warningDark: "#E85500",
      warningDefault: "#FF7223",
      warningLight: "#FF9357",
      positiveDark: "#08BD46",
      positiveDefault: "#19C651",
      positiveLight: "#00B53E",
      negativeDark: "#E61717",
      negativeDefault: "#FF3434",
      negativeLight: "#FF6666",
    },
    fonts: {
      gilroy: "Gilroy, NotoSans",
      roboto: "'Roboto', sans-serif !important",
    },
    fontWeights: {
      normal: "400",
      bold: "600",
      bolder: "700",
    },
    space: {
      containerXPadding: "16px",
    },
    sizes: {
      appContainer: "1300px",
    },
    radii: {
      round: "50%",
      common: "8px",
    },
    lineHeights: {
      1: "19px",
      2: "22px",
      3: "24px",
      4: "32px",
      5: "56px",
      6: "64px",
    },
  },
  media: {
    bp0: `(min-width: ${breakpoints.xs}px)`,
    bp1: `(min-width: ${breakpoints.sm}px)`,
    bp2: `(min-width: ${breakpoints.md}px)`,
    bp3: `(min-width: ${breakpoints.lg}px)`,
    bp4: `(min-width: ${breakpoints.xl}px)`,
  },
  utils: {
    p: (value: Stitches.PropertyValue<"padding">) => ({
      padding: value,
    }),
    pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value: Stitches.PropertyValue<"margin">) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<"marginRight">) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    elementTransition: (values: string) => ({
      transitionProperty: values,
      transitionDuration: "300ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      transitionDelay: "0s",
      willChange: values,
    }),
    hideScroll: () => ({
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
    }),
  },
  prefix: "doto",
});

export const darkTheme = createTheme("dark-theme", {
  colors: {
    white: "#151515",
    black: "#EEEEEE",
    gray: "#034D11",
    extraLightGray: "#BABABA",
    lightGray: "#212121",
    darkGray: "#95AB99",
    lite: "#FFFFFF",
    dark: "#212121",
    textMain: "#EEEEEE",
    textSecondary: "#95AB99",
    textInverse: "#212121",
    bgPrimary: "#212121",
    bgDeep: "#181514",
    bgSelected: "#034D11",
    bgBack: "#151515",
    bgAdditional: "#EEEEEE",
    bgBorder: "hsla(133, 17%, 58%, 0.2)",
    warningDark: "#E85500",
    warningDefault: "#FF7223",
    warningLight: "#FF9357",
    positiveDark: "#08BD46",
    positiveDefault: "#19C651",
    positiveLight: "#00B53E",
    negativeDark: "#E61717",
    negativeDefault: "#FF3434",
    negativeLight: "#FF6666",
  },
});

export const darkThemeSelector = `.${darkTheme} &`;
