export const PaymentsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none" viewBox="0 0 24 25">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23 6.5a1 1 0 00-1-1H6a5 5 0 00-5 5v8a5 5 0 005 5h12a5 5 0 005-5v-12zm-2 1v11a3 3 0 01-3 3H6a3 3 0 01-3-3v-8a3 3 0 013-3h15z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23 6.5a5 5 0 00-5-5h-6a5 5 0 00-5 5 1 1 0 001 1h14a1 1 0 001-1zm-2.17-1H9.17a3.001 3.001 0 012.83-2h6c1.306 0 2.418.835 2.83 2zM2 11.5a1 1 0 00-1 1v4a1 1 0 001 1h4a3 3 0 100-6H2zm1 2h3a1 1 0 110 2H3v-2z"
      clipRule="evenodd"
    ></path>
  </svg>
);
