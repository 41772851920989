import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Flex } from "@/components/flex";
import { Loader } from "@/components/loader";
import { Text } from "@/components/text";
import { DialogName, getDialogSearchParams } from "@/hooks/dialog.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useConfirmEmailMutation } from "@/state/server/auth";

import { AuthBox } from "../styles";

type Props = {
  userId: string;
  code: string;
};

export const EmailConfirmContainer: FC<Props> = ({ userId, code }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, error, isLoading } = useConfirmEmailMutation({ showErrorMessage: false });

  useEffect(() => {
    mutate(
      { code, userId },
      {
        onSuccess: () => {
          navigate(
            { pathname: cabinetRoutes.dashboard, search: getDialogSearchParams(DialogName.EMAIL_CONFIRM) },
            { replace: true },
          );
        },
      },
    );
  }, []);

  return (
    <AuthBox>
      {isLoading && (
        <Flex css={{ justifyContent: "center", mt: "60px" }}>
          <Loader />
        </Flex>
      )}
      {error && (
        <>
          <Text family="roboto" align="center" css={{ my: "16px" }}>
            {t("auth.email-confirm.error")}
          </Text>
          <Text family="roboto" align="center" color="negative" css={{ my: "16px" }}>
            {error.errorMessage}
          </Text>
        </>
      )}
    </AuthBox>
  );
};
