import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TradingAccountTradeMode } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Button } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../context";
import { PlaceOrderButtonType } from "../../place-order.helpers";

const buttonStyles = cva("select-none flex-col gap-0 px-0");

const textStyles = cva("font-gilroy text-[18px] font-semibold leading-[1.2] text-text-inverse")();

const valueStyles = cva("font-roboto text-[14px] font-normal leading-[1.5] text-text-inverse")();

type Props = {
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  volumeBuyMargin: ReturnType<typeof usePlaceOrderContext>["volumeBuyMargin"];
  volumeSellMargin: ReturnType<typeof usePlaceOrderContext>["volumeSellMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  onSubmit: (values: any, side: PlaceOrderButtonType) => Promise<unknown>;
  accountCurrency: string;
  className?: string;
  persistActive?: boolean;
};

const MobilePlaceOrderSubmitButtons: FC<Props> = ({
  hasNoFreeMargin,
  volumeLotsError,
  volumeMode,
  accountCurrency,
  volumeBuyMargin,
  volumeSellMargin,
  volumeMarginDecimalScale,
  onSubmit,
  persistActive,
  className,
}) => {
  const { t } = useTranslation();

  const { formState, handleSubmit } = useFormContext();
  const { isSubmitting } = formState;

  const disabled = (volumeLotsError !== VolumeLotsError.NONE || hasNoFreeMargin) && !persistActive;

  const showMargin = !(disabled || volumeMode === TradingAccountTradeMode.Lots);

  return (
    <>
      <Button
        onClick={handleSubmit(values => onSubmit(values, "Sell"))}
        className={cn(buttonStyles(), className)}
        pending={isSubmitting}
        disabled={disabled}
        size="lg"
        variant="danger"
      >
        <div className={textStyles}>{t("common.sell")}</div>
        {showMargin && (
          <div className={valueStyles}>
            <NumberFormat value={volumeSellMargin} currency={accountCurrency} decimalScale={volumeMarginDecimalScale} />
          </div>
        )}
      </Button>
      <Button
        onClick={handleSubmit(values => onSubmit(values, "Buy"))}
        className={cn(buttonStyles(), className)}
        pending={isSubmitting}
        disabled={disabled}
        size="lg"
      >
        <div className={textStyles}>{t("common.buy")}</div>
        {showMargin && (
          <div className={valueStyles}>
            <NumberFormat value={volumeBuyMargin} currency={accountCurrency} decimalScale={volumeMarginDecimalScale} />
          </div>
        )}
      </Button>
    </>
  );
};

export { MobilePlaceOrderSubmitButtons };
