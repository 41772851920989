import { useTranslation } from "@/hooks/translator.hook";

import { useTerminalAccountSummary } from "../../account-summary/context";
import { useTerminalAccountContext } from "../../contexts/account.context";
import { CloseAllOpenOrdersButton } from "../close/close-all-open-orders-button";
import { TradingTable } from "../components/trading-table/trading-table";

export const OpenOrdersHeaderRow = ({ ordersCount }: { ordersCount: number }) => {
  const { t } = useTranslation();
  const {
    account: { currency, id, digits },
  } = useTerminalAccountContext();

  const { pnl, swaps } = useTerminalAccountSummary();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.margin-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.current-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-time")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {t("terminal.swap")}, {currency}
      </TradingTable.Head>
      <TradingTable.Head align="end">{t("terminal.pnl-currency", { currency })}</TradingTable.Head>
      <TradingTable.Head align="end">
        {ordersCount > 1 && (
          <CloseAllOpenOrdersButton
            accountId={id!}
            pnl={pnl}
            ordersCount={ordersCount}
            swaps={swaps}
            accountCurrency={currency!}
            currencyDecimalScale={digits!}
          />
        )}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};
