import React, { createContext, memo, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { TerminalEventType } from "@/services/openapi";
import {
  useSymbolsLastPricesQuery,
  useTerminalSymbolsFavoritesQuery,
  useTerminalSymbolsQuery,
} from "@/state/server/terminal";

import { filterTerminalSocket, terminalCommands } from "../helpers/socket.commands";
import {
  MergedTerminalSymbol,
  normalizeMarketWatch,
  normalizeSymbolsList,
  StaticSymbolGroup,
  SymbolGroupType,
  SymbolsListType,
} from "../helpers/symbols";
import { useTerminalSocket } from "../hooks/socket.hook";

type SymbolsContextState = {
  symbols: MergedTerminalSymbol[];
  symbolsList: SymbolsListType;
  symbolGroups: SymbolGroupType[];
  symbolsIsLoaded: boolean;
  group: SymbolGroupType;
  isChartLoaded: boolean;
  setGroup: (group: SymbolGroupType) => void;
  setChartLoaded: (isLoaded: boolean) => void;
};

const SymbolsContext = createContext<SymbolsContextState>({} as SymbolsContextState);

export const TerminalSymbolsContextProvider = memo(
  ({ children, serverId, accountId }: { children: React.ReactNode; serverId: string; accountId: string }) => {
    const [isChartLoaded, setChartLoaded] = useState(false);
    const [list, setList] = useState<SymbolsListType>({});
    const [symbolGroups, setSymbolGroups] = useState<SymbolGroupType[]>([
      StaticSymbolGroup.ALL,
      StaticSymbolGroup.POPULAR,
      StaticSymbolGroup.RECENTS,
      StaticSymbolGroup.FAVORITES,
    ]);

    const [group, setGroup] = useState<SymbolGroupType>(symbolGroups[1]); // popular

    const { data: symbolsData, isSuccess: symbolsLoaded } = useTerminalSymbolsQuery(serverId);
    const { data: lastPrices, isSuccess: lastPricesLoaded } = useSymbolsLastPricesQuery(
      { tradingServerId: serverId },
      { cacheTime: 0, enabled: symbolsLoaded },
    );
    const { data: favoriteData, isSuccess: favoritesLoaded } = useTerminalSymbolsFavoritesQuery(accountId, {
      enabled: lastPricesLoaded,
      cacheTime: 0,
      onSuccess: ({ group }) => {
        if (group) {
          setGroup(group);
        }
      },
    });

    const requestsLoaded = symbolsLoaded && favoritesLoaded && lastPricesLoaded;

    const { sendJsonMessage, lastJsonMessage: socketMessage } = useTerminalSocket({
      filter: e => filterTerminalSocket(e, [TerminalEventType.MarketWatch]),
    });

    useEffect(() => {
      if (requestsLoaded) {
        sendJsonMessage(terminalCommands.marketWatch);
      }
    }, [requestsLoaded]);

    useEffect(() => {
      try {
        if (favoriteData) {
          const { favorites = [], charts = [], excluded } = favoriteData;
          setList(previousList => {
            const updatedList = { ...previousList };
            Object.keys(updatedList).forEach(symbol => {
              updatedList[symbol] = {
                ...updatedList[symbol],
                isFavorite: favorites!.includes(symbol),
                isChartFavorite: charts!.includes(symbol),
                isExcluded: excluded!.includes(symbol),
              };
            });
            return updatedList;
          });
        }
      } catch (error) {
        logError(error);
      }
    }, [favoriteData]);

    useEffect(() => {
      try {
        if (symbolsData) {
          const normalizedSymbols = normalizeSymbolsList(symbolsData.symbols || []);
          setList(normalizedSymbols);
          if (Array.isArray(symbolsData.groups)) {
            setSymbolGroups(prevGroups => [...prevGroups, ...symbolsData.groups!]);
          }
        }
      } catch (error) {
        logError(error);
      }
    }, [symbolsData]);

    useEffect(() => {
      try {
        if (!socketMessage) return;
        const normalizedMarketWatch = normalizeMarketWatch(socketMessage.dm!.t!, socketMessage.dm!.s!);
        setList(previousList => {
          const updatedList = { ...previousList };
          Object.keys(normalizedMarketWatch).forEach(name => {
            (Object.keys(normalizedMarketWatch[name]) as Array<keyof MergedTerminalSymbol>).forEach(field => {
              if (name && field && updatedList[name]) {
                // @ts-ignore
                updatedList[name][field] = normalizedMarketWatch[name][field];
              }
            });
          });
          return updatedList;
        });
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!lastPrices) return;
        const normalizedLastPrices = normalizeSymbolsList(lastPrices.items!);
        setList(previousList => {
          const updatedList = { ...previousList };
          Object.keys(normalizedLastPrices).forEach(name => {
            updatedList[name] = { ...updatedList[name], ...normalizedLastPrices[name] };
          });
          return updatedList;
        });
      } catch (error) {
        logError(error);
      }
    }, [lastPrices]);

    const symbols = useMemo(() => Object.values(list), [list]);

    useEffect(() => {
      if (requestsLoaded) {
        const event = new CustomEvent("terminal-update", {
          detail: {
            terminalLoaded: requestsLoaded,
            openPositions: () => document.dispatchEvent(window.openPositionsEvent),
            closePositions: () => document.dispatchEvent(window.openPositionsEvent),
          },
        });
        document.dispatchEvent(event);
      }
    }, [requestsLoaded]);

    const value: SymbolsContextState = useMemo(
      () => ({
        symbols,
        symbolGroups,
        symbolsIsLoaded: requestsLoaded,
        group,
        isChartLoaded,
        setGroup,
        setChartLoaded,
        symbolsList: list,
      }),
      [symbols, symbolGroups, requestsLoaded, group, isChartLoaded, setGroup, setChartLoaded, list],
    );

    return <SymbolsContext.Provider value={value}>{children}</SymbolsContext.Provider>;
  },
);
TerminalSymbolsContextProvider.displayName = "TerminalSymbolsContextProvider";

export const useSymbolsContext = () => {
  return useContext(SymbolsContext);
};
