import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext } from "../../../context";

type Props = {
  changeOpenPrice: ReturnType<typeof usePlaceOrderContext>["changeOpenPrice"];
  resetOpenPrice: ReturnType<typeof usePlaceOrderContext>["resetOpenPrice"];
  priceDecimalScale: number;
  openPriceFormValue: ReturnType<typeof usePlaceOrderContext>["openPriceFormValue"];
  isSubmitting: boolean;
};

const DesktopOpenPriceField: FC<Props> = ({
  changeOpenPrice,
  isSubmitting,
  openPriceFormValue,
  priceDecimalScale,
  resetOpenPrice,
}) => {
  const { t } = useTranslation();

  return (
    <NumberInput
      label={t("terminal.place-order.open-price.title")!}
      pending={isSubmitting}
      value={openPriceFormValue}
      onChange={value => changeOpenPrice(value, { format: false })}
      onBlur={resetOpenPrice}
      decimalScale={priceDecimalScale}
      tooltipContent={t("terminal.place-order.open-price.description")}
      placeholder={t("terminal.place-order.open-price.input-placeholder")!}
    />
  );
};

export { DesktopOpenPriceField };
