import { FC } from "react";
import { useController } from "react-hook-form";

import { SubmitButton } from "@/app/form";
import { Box } from "@/components/box";
import { DialogButtons } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { HookForm } from "@/components/form/hook-form";
import { InfoIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType, TradingServerPlatform } from "@/services/openapi";
import { ButtonGroup } from "@/shared/ui";

enum CreateAccountFields {
  TYPE = "type",
  PLATFORM = "platform",
}

export interface ICreateAccountFormValues {
  [CreateAccountFields.TYPE]: TradingAccountType;
  [CreateAccountFields.PLATFORM]: TradingServerPlatform;
}

type Props = {
  type: TradingAccountType;
  onSubmit: (values: ICreateAccountFormValues) => void;
};

export const CreateAccountForm: FC<Props> = ({ onSubmit, type }) => {
  const { t } = useTranslation();

  const form = useHookForm<ICreateAccountFormValues>({
    defaultValues: {
      [CreateAccountFields.TYPE]: type,
      [CreateAccountFields.PLATFORM]: TradingServerPlatform.MetaTrader5,
    },
  });

  const { control, watch } = form;

  const { platform } = watch();

  const { field: typeField } = useController({ name: CreateAccountFields.TYPE, control });
  const { field: platformField } = useController({ name: CreateAccountFields.PLATFORM, control });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <ButtonGroup value={typeField.value} onValueChange={typeField.onChange} data-test="create-account-type-switcher">
        <ButtonGroup.Item value={TradingAccountType.Real}>{t("common.real")}</ButtonGroup.Item>
        <ButtonGroup.Item value={TradingAccountType.Demo}>{t("common.demo")}</ButtonGroup.Item>
      </ButtonGroup>
      <Text css={{ fontSize: "22px", lineHeight: 1.2, mt: 32, mb: 16 }} weight="bold">
        {t("accounts.trading-platform")}
      </Text>
      <ButtonGroup
        value={platformField.value}
        onValueChange={platformField.onChange}
        data-test="create-account-platform-switcher"
      >
        <ButtonGroup.Item value={TradingServerPlatform.MetaTrader5}>Doto</ButtonGroup.Item>
        <ButtonGroup.Item value={TradingServerPlatform.MetaTrader4}>MetaTrader 4</ButtonGroup.Item>
      </ButtonGroup>
      {/* TODO: create separate component */}
      <Flex css={{ gap: 12, mt: 16 }}>
        <Box css={{ color: "$textSecondary" }}>
          <InfoIcon />
        </Box>
        <Text color="dimmed" family="roboto" lineHeight="3">
          {platform === TradingServerPlatform.MetaTrader5
            ? t("accounts.servers.doto-mt5-description")
            : t("accounts.servers.mt-description", { version: 4 })}
        </Text>
      </Flex>
      <DialogButtons>
        <SubmitButton data-test="create-account-btn-action">{t("button.create")}</SubmitButton>
      </DialogButtons>
    </HookForm>
  );
};
