import { JsonValue } from "react-use-websocket/dist/lib/types";

import { TerminalCommand, TerminalCommandType, TerminalEvent, TerminalEventType } from "@/services/openapi";

const marketWatchSubsribeCommand: TerminalCommand = {
  type: TerminalCommandType.MarketWatchSubscribe,
};

const pingCommand: TerminalCommand = {
  type: TerminalCommandType.Ping,
};

const tradingSubscribeCommand: TerminalCommand = {
  type: TerminalCommandType.TradingSubscribe,
};

const tradingInitDataCommand: TerminalCommand = {
  type: TerminalCommandType.TradingInitData,
};

const tickSubscribe = (symbol: string) =>
  ({
    type: TerminalCommandType.TickSubscribe,
    symbols: [symbol],
  }) as any as JsonValue;

export const terminalCommands = {
  ping: pingCommand as JsonValue,
  marketWatch: marketWatchSubsribeCommand as JsonValue,
  tradingSubscribe: tradingSubscribeCommand as JsonValue,
  tradingInitData: tradingInitDataCommand as JsonValue,
  tickSubscribe,
};

export const filterTerminalSocket = (event: MessageEvent, filters: TerminalEventType[]) => {
  const data: TerminalEvent = JSON.parse(event.data);
  return filters.includes(data.t!);
};
