import { FC, memo } from "react";

import { useExtendedOrdersContext } from "../../contexts/extended-orders.context";
import { useTerminalFeatureTourContext } from "../../feature-tour/context";
import { OpenOrdersTable } from "./open-orders-table";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

const _OpenOrdersContainer: FC<Props> = ({ accountId, accountCurrency, onClose, setSymbol, currencyDecimalScale }) => {
  const { extendedOpenOrders } = useExtendedOrdersContext();
  const { closeOrderStyles } = useTerminalFeatureTourContext();

  return (
    <OpenOrdersTable
      orders={extendedOpenOrders}
      accountId={accountId}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
      onClose={onClose}
      setSymbol={setSymbol}
      closeOrderStyles={closeOrderStyles}
    />
  );
};

export const OpenOrdersContainer = memo(_OpenOrdersContainer);
