import { getDeviceId } from "@amplitude/analytics-browser";
import { useCallback, useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { amplitudeInit, amplitudeParameterName } from "@/app/libs/amplitude";
import { LiveChatWidget } from "@/app/libs/liveChat";
import { GoogleTagManager } from "@/components/analytics/google-tag-manager";
import { CookiePopup } from "@/components/cookie-popup";
import { ServerConnection } from "@/components/server-connection";
import { CookieType } from "@/constants/enums";
import { useAuth } from "@/contexts/auth.context";
import { useTheme } from "@/contexts/theme.context";
import { useCookie } from "@/hooks/cookie.hook";
import { useLocalStorage } from "@/hooks/localstorage.hook";
import { useMarketingCookies } from "@/hooks/marketing-cookies.hook";
import { useMobileAppMode } from "@/hooks/mobile-app-mode.hook";
import { authRoutes } from "@/routes/auth.routes";
import { billingRoutes } from "@/routes/billing.routes";
import { gaSessionId } from "@/services/api.helpers";
import { darkTheme } from "@/styles";

import { cabinetRoutes } from "./cabinet.routes";
import { terminalRoutes } from "./terminal.routes";

export const RootRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [acceptedCookieType, setCookieAcceptedType] = useLocalStorage<CookieType>(
    "acceptedCookieType",
    CookieType.NOT_DEFINED,
  );
  const { isDarkTheme } = useTheme();
  const [_, setCookie] = useCookie();

  const isMobileAppMode = useMobileAppMode();

  useMarketingCookies();

  // FIXME: we are not going to need this in the future
  const isDepositPage = useMemo(() => {
    const depositRoutes = [cabinetRoutes.deposit, cabinetRoutes.depositDetails, billingRoutes.depositIframe];

    return depositRoutes.includes(location.pathname);
  }, [location]);

  const { isAuthenticated, setIsCookieAccepted } = useAuth();

  const onCookieAccept = useCallback(
    (cookieType: CookieType) => {
      setCookieAcceptedType(cookieType);
      setIsCookieAccepted(true);
    },
    [setCookieAcceptedType, setIsCookieAccepted],
  );

  const setAcceptedCookieTypeFromSearchParams = () => {
    const cookieType = searchParams.get("acceptedCookieType") as CookieType;
    if (cookieType) {
      setCookieAcceptedType(cookieType);
    }
  };

  const updateBodyClass = (isDarkTheme: boolean, location: { pathname: string }) => {
    if (isDarkTheme) {
      if (Object.values({ ...cabinetRoutes, ...terminalRoutes }).includes(location.pathname)) {
        document.body.classList.add(darkTheme.className);
      } else {
        document.body.classList.remove(darkTheme.className);
      }
    }
  };

  const navigateUserBasedOnState = () => {
    if (location.pathname === "/" && isAuthenticated) {
      navigate(cabinetRoutes.dashboard, { replace: true });
    } else if (location.pathname === "/" && !isAuthenticated) {
      navigate(authRoutes.signIn, { replace: true });
    }
  };

  useEffect(() => {
    navigateUserBasedOnState();
    updateBodyClass(isDarkTheme, location);
    setAcceptedCookieTypeFromSearchParams();
  }, [location]);

  const isWebView = searchParams.get("isWebView") === "true";

  useEffect(() => {
    amplitudeInit().promise.then(() => setCookie(amplitudeParameterName, getDeviceId()!));
  }, []);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "session_start",
      session_id: gaSessionId,
    });
  }, []);

  return (
    <>
      <ServerConnection />
      {acceptedCookieType === "acceptAllCookies" && <GoogleTagManager />}
      {!acceptedCookieType && !isMobileAppMode && !isWebView && !isDepositPage && (
        <CookiePopup onCookieAccept={onCookieAccept} />
      )}
      <LiveChatWidget />

      <Outlet />
    </>
  );
};
