import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import { TradingTournamentItem } from "@/services/openapi";
import { cn } from "@/shared/styles";

type Props = {
  participant: TradingTournamentItem;
};

export const TournamentParticipantCard: FC<Props> = ({ participant }) => {
  const { t } = useTranslation();

  const { isColored, rank, floatingPnl, userName, login } = participant;

  return (
    <div
      className={cn("border-b-[0.5px] border-[#D6D7DB] px-[20px] pb-[20px] pt-[12px]", {
        "rounded-[20px] border-none": isColored,
      })}
      style={
        isColored
          ? {
              // FIXME:  add to tailwind
              background: "linear-gradient(277deg, #67E15D -22.88%, #F4F85A 129.61%)",
            }
          : undefined
      }
    >
      <div className="flex gap-[15px]">
        <div>
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px] text-text-secondary">
            {t("tournament.table.rank")}
          </div>
          <div className="mt-[8px] font-gilroy text-[40px] font-semibold leading-[1.2]">{rank}</div>
        </div>
        <div>
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px] text-text-secondary">
            {t("tournament.table.name")}
          </div>
          <div className="mt-[20px] font-roboto text-[18px] font-semibold leading-[24px]">{userName}</div>
        </div>
      </div>
      <div className="mt-[20px] flex items-center justify-between">
        <div className="flex flex-col gap-[12px]">
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px] text-text-secondary">
            {t("tournament.table.account")}
          </div>
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px]">{login}</div>
        </div>
        <div className="flex flex-col gap-[12px]">
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px] text-text-secondary">
            {t("tournament.table.result")}
          </div>
          <div className="font-roboto text-[18px] leading-[24px] tracking-[-0.18px]">
            <NumericFormat
              displayType="text"
              value={floatingPnl}
              thousandSeparator=","
              prefix={`${floatingPnl! > 0 ? "+" : ""}$`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
