import { FC, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { UnstyledText } from "@/components/text";
import { styled } from "@/styles";

const Button = styled("button", {
  all: "unset",
  cursor: "pointer",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexGrow: 1,
});

type Props = {
  value: ReactNode;
  children: (onClose: () => void) => ReactNode;
};

export const MobileModifyOrderTextButton: FC<Props> = ({ value, children }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <UnstyledText color={value ? "black" : "dimmed"} css={{ textDecoration: value ? "underline" : "none" }}>
            {value ? value : "—"}
          </UnstyledText>
          <UnstyledText color="dimmed" css={{ textDecoration: "underline", "&:hover": { color: "$textMain" } }}>
            {value ? t("button.edit") : t("button.add")}
          </UnstyledText>
        </Button>
      </DialogTrigger>
      {open && children(onClose)}
    </Dialog>
  );
};
