import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { ClockIcon, GlassBellIcon, GlassErrorIcon } from "@/components/icons";
import { GlassSuccessIcon } from "@/components/icons/glass-success-icon";
import { IconCross } from "@/domains/icons";

import { cn } from "../../styles";

const Viewport = forwardRef<
  ElementRef<typeof ToastPrimitives.Viewport>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, id = "radix-toast-vp", ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    id={id}
    className={cn(
      "fixed end-1/2 top-0 z-100 w-full translate-x-1/2 outline-none md:end-0 md:max-w-[390px] md:translate-x-0",
      className,
    )}
    {...props}
  />
));

const toastStyles = cva(
  "p-4 transition-all data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out",
  {
    variants: {
      swipeDirection: {
        up: "data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:slide-out-to-top-full data-[state=open]:slide-in-from-top-full",
        down: "data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-full",
        left: "data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:slide-out-to-left-full data-[state=open]:slide-in-from-left-full",
        right:
          "data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-right-full",
      },
    },
  },
);

const Root = forwardRef<
  ElementRef<typeof ToastPrimitives.Root>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & VariantProps<typeof toastStyles>
>(({ className, swipeDirection, ...props }, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(toastStyles({ swipeDirection }), className)} {...props} />;
});

const actionStyles = cva(
  "-mb-1 min-h-8 w-full text-start font-roboto text-[14px] font-semibold leading-[1.5] text-action md:text-[16px] md:font-normal",
  {
    variants: {
      variant: {
        success: {
          true: "text-action",
        },
        warning: {
          true: "text-warning",
        },
        inverted: {
          true: "text-gray",
        },
        default: {
          true: "text-dark",
        },
      },
    },
  },
);

const Action = forwardRef<
  ElementRef<typeof ToastPrimitives.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Action> & VariantProps<typeof actionStyles>
>(({ className, variant = "default", ...props }, ref) => (
  <ToastPrimitives.Action ref={ref} className={cn(actionStyles({ variant }), className)} {...props} />
));

const Close = forwardRef<
  ElementRef<typeof ToastPrimitives.Close>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "relative grid size-6 shrink-0 place-items-center text-text before:absolute before:size-12",
      className,
    )}
    {...props}
  >
    <IconCross />
  </ToastPrimitives.Close>
));

const Title = forwardRef<
  ElementRef<typeof ToastPrimitives.Title>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("font-gilroy text-[16px] font-semibold leading-[1.2] md:text-[18px]", className)}
    {...props}
  />
));

const Description = forwardRef<
  ElementRef<typeof ToastPrimitives.Description>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("font-roboto text-[14px] font-normal leading-[1.5] text-text-secondary md:text-[16px]", className)}
    {...props}
  />
));

const Text = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("font-roboto text-[14px] font-normal leading-[1.5] text-text md:text-[16px]", className)}
    {...props}
  />
));

const TextButton = forwardRef<
  ElementRef<typeof ToastPrimitives.Action>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, type = "button", ...props }, ref) => (
  <ToastPrimitives.Action ref={ref} type={type} className={cn("underline", className)} {...props} />
));

enum Icons {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  WAITING = "waiting",
}

const getIcon = (icon: Icons | ReactNode) => {
  if (icon === Icons.SUCCESS) {
    return <GlassSuccessIcon />;
  }

  if (icon === Icons.ERROR) {
    return <GlassErrorIcon />;
  }

  if (icon === Icons.WARNING) {
    return <GlassBellIcon />;
  }

  if (icon === Icons.WAITING) {
    return <ClockIcon />;
  }
  return icon;
};

const Icon = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div"> & { icon: Icons | ReactNode }>(
  ({ className, icon, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("grid size-6 shrink-0 place-items-center", className)} {...props}>
        {getIcon(icon)}
      </div>
    );
  },
);

type ToastProps = ComponentPropsWithoutRef<typeof Root> & { icon: Icons | ReactNode };

const Component = Object.assign(Root, {
  Viewport,
  Provider: ToastPrimitives.Provider,
  Close,
  Action,
  Title,
  Description,
  Text,
  TextButton,
  Icon,
});

export { Component as Toast, Icons as ToastIcons, type ToastProps };
