import React, { Fragment } from "react";

import { TerminalEvent } from "@/services/openapi";

import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { MergedTerminalSymbol } from "../helpers/symbols";
import { ChartAskLine } from "./ask-line";
import { ChartColors } from "./config/colors";
import { useChartContext } from "./context";
import { useChartAutoSave } from "./hooks/auto-save.hook";
import { useChartToggleButton } from "./hooks/chart-toggle-button.hook";
import { useDefaultChart } from "./hooks/default-chart.hook";
import { useHandleChartTheme } from "./hooks/handle-theme.hook";
import { useRefreshChartLines } from "./hooks/refresh-lines.hook";
import { useUpdateChartSymbol } from "./hooks/update-symbol.hook";
import { ChartOrders } from "./orders";

type Props = {
  tickMessage: TerminalEvent;
  symbolInfo: MergedTerminalSymbol;
  chartColors: ChartColors;
  accountId: string;
  accountCurrency: string;
  isDarkTheme: boolean;
  isMobile: boolean;
  isChartExpanded: boolean;
  currencyDecimalScale: number;
  setIsChartExpanded: ReturnType<typeof useTerminalLayout>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
};

const Widget: React.FC<Props> = ({
  tickMessage,
  symbolInfo,
  chartColors,
  accountCurrency,
  accountId,
  isDarkTheme,
  isMobile,
  isChartExpanded,
  setIsChartExpanded,
  setChartInfo,
  currencyDecimalScale,
}) => {
  useDefaultChart();
  useChartAutoSave({ accountId });
  useHandleChartTheme({ chartColors, isChartExpanded, isDarkTheme });
  useChartToggleButton({ isChartExpanded, isMobile, setChartInfo, setIsChartExpanded });
  useUpdateChartSymbol(symbolInfo.symbol!);
  const refreshKey = useRefreshChartLines();

  // needed to prevent situations when chart symbol !== selected symbol
  // it happens because app symbol changing acts immediately, while tv symbol changing triggers after loading chart data
  if (symbolInfo.symbol !== window.tvWidget.activeChart().symbol()) {
    return null;
  }

  return (
    <Fragment key={refreshKey}>
      <ChartAskLine symbolInfo={symbolInfo} chartColors={chartColors} tickMessage={tickMessage} />
      <ChartOrders
        symbolInfo={symbolInfo}
        accountCurrency={accountCurrency}
        accountId={accountId}
        currencyDecimalScale={currencyDecimalScale}
        chartColors={chartColors}
      />
    </Fragment>
  );
};

Widget.displayName = "ChartWidget";

export { Widget as ChartWidget };
