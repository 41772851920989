import { IOrderLineAdapter } from "public/charting_library/charting_library";
import React, { useEffect, useRef } from "react";

import { TerminalEvent } from "@/services/openapi";

import { MergedTerminalSymbol } from "../helpers/symbols";
import { ChartColors } from "./config/colors";

type Props = {
  symbolInfo: MergedTerminalSymbol;
  tickMessage: TerminalEvent;
  chartColors: ChartColors;
};

const ChartAskLine: React.FC<Props> = ({ tickMessage, chartColors, symbolInfo }) => {
  const ref = useRef<IOrderLineAdapter | null>(null);

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createorderline
      ref.current = window.tvWidget
        .activeChart()
        .createOrderLine({ disableUndo: true })
        .setText("")
        .setQuantity("")
        .setLineStyle(1)
        .setLineColor(chartColors.positive)
        .setPrice(symbolInfo.ask!);
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current && tickMessage && symbolInfo.symbol === tickMessage.dt!.s) {
      window.tvWidget.activeChart().dataReady(() => {
        ref.current!.setPrice(tickMessage.dt!.a!);
      });
    }
  }, [tickMessage]);

  return null;
};

ChartAskLine.displayName = "ChartAskLine";

export { ChartAskLine };
