import React from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/components/dialog";
import { Button, Hint } from "@/shared/ui";

import { FeatureTourButton } from "../../components/button/button";
import { HintDialogContent } from "../../components/hint/hint-dialog";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  onRepeat: ReturnType<typeof useTerminalFeatureTourContext>["repeatTour"];
  onFinish: ReturnType<typeof useTerminalFeatureTourContext>["finishTour"];
  onTrade: ReturnType<typeof useTerminalFeatureTourContext>["tradeReal"];
  isLoading: ReturnType<typeof useTerminalFeatureTourContext>["isLoading"];
};

const LotsFinalHintDialog: React.FC<Props> = ({ onRepeat, onFinish, onTrade, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <HintDialogContent>
        <Hint
          title={t("terminal.onboarding.final.lots.title")}
          text={t("terminal.onboarding.final.lots.description")}
          closeButton={<Hint.CloseButton onClick={onFinish} />}
        >
          <Hint.Footer grow>
            <FeatureTourButton size="sm" onClick={onRepeat} pending={isLoading}>
              {t("terminal.onboarding.buttons.again")}
            </FeatureTourButton>
            <Button size="sm" onClick={onTrade} pending={isLoading}>
              {t("terminal.onboarding.buttons.trade-real")}
            </Button>
          </Hint.Footer>
        </Hint>
      </HintDialogContent>
    </Dialog>
  );
};

export { LotsFinalHintDialog };
