import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { TerminalTableState, useTerminalLayout } from "@/features/terminal/contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "@/features/terminal/feature-tour/context";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalDealType, TradingAccountType } from "@/services/openapi";
import { useOpenTerminalOrderMutation } from "@/state/server/terminal";

import { usePlaceOrderContext } from "../../context";
import {
  getMaximumOrderVolume,
  getOriginalVolume,
  handleMarketTakeProfitStopLossErrors,
} from "../../place-order.helpers";
import { DesktopMarketOrderForm } from "./form";

const DesktopMarketOrderContainer: FC = () => {
  const { t } = useTranslation();

  const { symbolInfo } = useCurrentSymbolContext();

  const { changeTable } = useTerminalLayout();

  const {
    volumeMode,
    volumeLotsFormValue,
    volumeMarginFormValue,
    volumeLots,
    volumeMargin,
    changeLots,
    changeMargin,
    maxBalanceVolumeLots,
    maxBalanceVolumeMargin,
    maxSystemVolumeLots,
    minSystemVolumeLots,
    maxSystemVolumeMargin,
    minSystemVolumeMargin,
    marginMultiplier,
    maxAvailableVolumeLots,
    volumeLotsStep,
    decrementVolumeDisabled,
    incrementVolumeDisabled,
    decrementOrder,
    incrementOrder,
    maxAvailableVolumeMargin,
    volumeLotsDecimalScale,
    volumeMarginDecimalScale,
    hasNoFreeMargin,
    volumeLotsError,
    volumeSellMargin,
    volumeBuyMargin,
    ask,
    bid,
    swapLong,
    swapShort,
    currency,
    marginFree,
    changeVolumeMode,
  } = usePlaceOrderContext();

  const {
    account: { id: accountId, leverage, type },
  } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();

  const {
    incrementOrderStyles,
    placeOrder,
    incrementOrder: featureTourIncrementOrder,
    placeOrderStyles,
    isPlaceOrderStepActive,
    changeVolumeModeStyles,
  } = useTerminalFeatureTourContext();

  const {
    digits,
    symbol,
    contractSize,
    baseCurrency,
    type: instrumentType,
    quoteCurrency,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
  } = symbolInfo!;

  const { mutateAsync: openMarketOrder } = useOpenTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof DesktopMarketOrderForm>["onSubmit"] = (
    { stopLoss, takeProfit },
    side,
    takeProfitStopLossUtils,
  ) => {
    const sendRequest = handleMarketTakeProfitStopLossErrors({
      ask,
      bid,
      stopLoss,
      takeProfit,
      side,
      priceDecimalScale: digits!,
      t,
      ...takeProfitStopLossUtils,
    });

    if (!sendRequest) {
      return Promise.resolve();
    }

    return openMarketOrder(
      {
        tradingAccountId: accountId!,
        terminalOpenOrderRequest: {
          volume: getMaximumOrderVolume({
            maxBalanceVolumeLots,
            volumeLots,
            marginFree,
            baseCurrency: baseCurrency!,
            bid,
            contractSize: contractSize!,
            currency,
            instrumentType: instrumentType!,
            leverage: leverage!,
            marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
            marginRateInitialMarketSell: marginRateInitialMarketSell!,
            marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
            marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            quoteCurrency: quoteCurrency!,
            symbols,
            volumeLotsDecimalScale,
            volumeLotsStep,
          }),
          symbol,
          type: side === "Buy" ? TerminalDealType.Buy : TerminalDealType.Sell,
          takeProfit: getInputNumberValue(takeProfit),
          stopLoss: getInputNumberValue(stopLoss),
          volumeOriginal: getOriginalVolume({ volumeMode, volumeLots, volumeMargin }),
        },
      },
      {
        onSuccess: () => {
          changeTable(TerminalTableState.OPEN);
          placeOrder();
        },
      },
    );
  };

  const handleIncrement = () => {
    featureTourIncrementOrder();
    incrementOrder();
  };

  return (
    <DesktopMarketOrderForm
      marginRateInitialMarketBuy={marginRateInitialMarketBuy!}
      accountId={accountId!}
      isDemoAccount={type === TradingAccountType.Demo}
      changeVolumeModeStyles={changeVolumeModeStyles}
      changeVolumeMode={changeVolumeMode}
      leverage={leverage!}
      hasNoFreeMargin={hasNoFreeMargin}
      volumeLotsStep={volumeLotsStep}
      volumeMode={volumeMode}
      changeLots={changeLots}
      changeMargin={changeMargin}
      onSubmit={handleSubmit}
      priceDecimalScale={digits!}
      ask={ask}
      bid={bid}
      contractSize={contractSize!}
      baseCurrency={baseCurrency!}
      accountCurrency={currency}
      volumeLots={volumeLots}
      volumeMargin={volumeMargin}
      maxSystemVolumeLots={maxSystemVolumeLots}
      maxBalanceVolumeLots={maxBalanceVolumeLots}
      minSystemVolumeLots={minSystemVolumeLots}
      maxBalanceVolumeMargin={maxBalanceVolumeMargin}
      maxSystemVolumeMargin={maxSystemVolumeMargin}
      minSystemVolumeMargin={minSystemVolumeMargin}
      marginMultiplier={marginMultiplier}
      maxAvailableVolumeLots={maxAvailableVolumeLots}
      incrementVolumeDisabled={incrementVolumeDisabled}
      decrementVolumeDisabled={decrementVolumeDisabled}
      decrementOrder={decrementOrder}
      incrementOrder={handleIncrement}
      volumeLotsError={volumeLotsError}
      maxAvailableVolumeMargin={maxAvailableVolumeMargin}
      volumeMarginDecimalScale={volumeMarginDecimalScale}
      volumeLotsDecimalScale={volumeLotsDecimalScale}
      volumeSellMargin={volumeSellMargin}
      volumeBuyMargin={volumeBuyMargin}
      instrumentType={instrumentType!}
      symbols={symbols}
      quoteCurrency={quoteCurrency!}
      swapLong={swapLong}
      swapShort={swapShort}
      incrementOrderStyles={incrementOrderStyles}
      isPlaceOrderStepActive={isPlaceOrderStepActive}
      placeOrderStyles={placeOrderStyles}
      volumeLotsFormValue={volumeLotsFormValue}
      volumeMarginFormValue={volumeMarginFormValue}
    />
  );
};

export { DesktopMarketOrderContainer };
