import { FC } from "react";

import { Flex } from "@/components/flex";
import { EmailField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Text } from "@/components/text";
import { withCaptcha } from "@/decorators";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";

import { AuthBox } from "../styles";

enum ForgotPasswordFields {
  EMAIL = "email",
}

export interface IForgotPasswordFormValues {
  [ForgotPasswordFields.EMAIL]: string;
}

interface Props {
  onSubmit: (values: IForgotPasswordFormValues) => void;
  disabledSubmit: boolean;
}

const _ForgotPasswordForm: FC<Props> = ({ onSubmit, disabledSubmit }) => {
  const { t } = useTranslation();
  const form = useHookForm<IForgotPasswordFormValues>();

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <AuthBox css={{ mt: "16px", mb: "54px" }}>
        <Text family="roboto" align="center" css={{ mb: "16px" }}>
          {t("auth.forgot-password.description")}
        </Text>
        <EmailField name={ForgotPasswordFields.EMAIL} bottomOffset="6" />
        <Flex css={{ justifyContent: "center" }}>
          <SubmitButton disabled={disabledSubmit}>{t("auth.forgot-password.restore-password")}</SubmitButton>
        </Flex>
      </AuthBox>
    </HookForm>
  );
};

export const ForgotPasswordForm = withCaptcha(_ForgotPasswordForm);
