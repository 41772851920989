import { FC, memo } from "react";

import { useTerminalLayout } from "./contexts/terminal-layout.context";
import { TerminalDesktop } from "./terminal-desktop";
import { TerminalMobile } from "./terminal-mobile";

const _Terminal: FC = () => {
  const { isMobile } = useTerminalLayout();

  return isMobile ? <TerminalMobile /> : <TerminalDesktop />;
};

const Terminal = memo(_Terminal);

Terminal.displayName = "Terminal";

export { Terminal };
