import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";

import { AuthFormContainer } from "../auth-form.container";
import { TwoFactorMode, twoFactorParamsName } from "./two-factor.helpers";
import { TwoFactorResetContainer } from "./two-factor-reset.container";
import { TwoFactorSigninContainer } from "./two-factor-signin.container";

export const TwoFactorContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(twoFactorParamsName) as TwoFactorMode | null;

  const { t } = useTranslation();
  return (
    <AuthFormContainer
      title={mode === TwoFactorMode.SIGN_IN ? t("auth.sign-in.login") : t("auth.reset-password.title")}
      backLink
    >
      {mode === TwoFactorMode.SIGN_IN && <TwoFactorSigninContainer />}
      {mode === TwoFactorMode.RESET_PASSWORD && <TwoFactorResetContainer />}
    </AuthFormContainer>
  );
};
