import Big from "big.js";
import React, { createContext, memo, useContext, useMemo } from "react";

import { TerminalDealType, TradingServerSymbolTradeMode, TradingServerSymbolType } from "@/services/openapi";

import { countMargin, countProfitAndLoss, LiveOrder } from "../helpers/orders";
import { getBidAsk, getOrderCurrentPrice } from "../trading-tables/trading-tables.helpers";
import { useCurrentSymbolContext } from "./current-symbol-context";
import { useOrdersContext } from "./orders.context";
import { useSymbolsContext } from "./symbols.context";

export interface OpenOrder extends LiveOrder {
  ask: number;
  bid: number;
  pnl: number;
  currentPrice: number | undefined;
  contractSize: number | undefined;
  digits: number | undefined;
  volumeStep: number | undefined;
  volumeMin: number | undefined;
  volumeDecimalScale: number | undefined;
  tradeMode: TradingServerSymbolTradeMode | undefined;
  baseCurrency: string | undefined;
  quoteCurrency: string | undefined;
  margin: number;
  marginRateMaintenanceMarketBuy: number | undefined;
  marginRateMaintenanceMarketSell: number | undefined;
  instrumentType: TradingServerSymbolType | undefined;
}

export interface PendingOrder extends LiveOrder {
  ask: number;
  bid: number;
  currentPrice: number | undefined;
  digits: number | undefined;
  volumeDecimalScale: number | undefined;
  margin: number;
  baseCurrency: string | undefined;
  quoteCurrency: string | undefined;
  contractSize: number | undefined;
}

type ExtendedOrdersContextState = {
  extendedOpenOrders: OpenOrder[];
  extendedPendingOrders: PendingOrder[];
};

const ExtendedOrdersContext = createContext<ExtendedOrdersContextState>({} as ExtendedOrdersContextState);

export const TerminalExtendedOrdersContextProvider = memo(
  ({
    children,
    accountCurrency,
    leverage,
    currencyDecimalScale,
  }: {
    children: React.ReactNode;
    accountCurrency: string;
    currencyDecimalScale: number;
    leverage: number;
  }) => {
    const { openOrders, pendingOrders } = useOrdersContext();

    const { symbolInfo } = useCurrentSymbolContext();

    const { symbolsList, symbols } = useSymbolsContext();

    const extendedOpenOrders: OpenOrder[] = useMemo(
      () =>
        openOrders.map(order => {
          const { symbol, type, volume, price } = order;

          const {
            ask: a,
            bid: b,
            contractSize,
            digits,
            volumeStep,
            volumeMin,
            volumeDecimalScale,
            tradeMode,
            baseCurrency,
            quoteCurrency,
            marginRateMaintenanceMarketBuy,
            marginRateMaintenanceMarketSell,
            marginRateInitialMarketBuy,
            marginRateInitialMarketSell,
            type: instrumentType,
            isChartFavorite,
          } = symbolsList[symbol] || {};

          const { ask, bid } = getBidAsk({ ask: a, bid: b, symbol, symbolInfo });

          const currentPrice = symbolsList[symbol] ? getOrderCurrentPrice(type, ask, bid) : undefined;

          const pnl = countProfitAndLoss({
            type,
            volume,
            openPrice: price,
            accountCurrency,
            currentPrice,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            contractSize,
            symbols,
          });

          return {
            ...order,
            ask,
            bid,
            currentPrice,
            pnl: new Big(pnl).round(currencyDecimalScale, 2).toNumber(),
            contractSize,
            digits,
            volumeStep,
            volumeMin,
            volumeDecimalScale,
            tradeMode,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            marginRateMaintenanceMarketBuy,
            marginRateMaintenanceMarketSell,
            instrumentType,
            margin: countMargin({
              accountCurrency,
              baseCurrency: baseCurrency!,
              contractSize: contractSize!,
              leverage,
              quoteCurrency: quoteCurrency!,
              volume,
              type,
              symbols,
              openPrice: price,
              instrumentType: instrumentType!,
              marginRate: "maintenance",
              marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
              marginRateInitialMarketSell: marginRateInitialMarketSell!,
              marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
              marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            }),
          };
        }),
      [openOrders, symbols, symbolInfo, accountCurrency, symbolsList],
    );

    const extendedPendingOrders: PendingOrder[] = useMemo(
      () =>
        pendingOrders.map(order => {
          const { symbol, type, volume, price } = order;

          const {
            ask: a,
            bid: b,
            digits,
            volumeDecimalScale,
            baseCurrency,
            quoteCurrency,
            contractSize,
            marginRateInitialMarketBuy,
            marginRateInitialMarketSell,
            marginRateMaintenanceMarketBuy,
            marginRateMaintenanceMarketSell,
            type: instrumentType,
          } = symbolsList[symbol] || {};

          const { ask, bid } = getBidAsk({ ask: a, bid: b, symbol, symbolInfo });

          const currentPrice = symbolsList[symbol] ? getOrderCurrentPrice(type, ask, bid) : undefined;

          return {
            ...order,
            ask,
            bid,
            currentPrice,
            digits,
            volumeDecimalScale,
            baseCurrency: baseCurrency!,
            quoteCurrency: quoteCurrency!,
            contractSize,
            margin: countMargin({
              accountCurrency,
              baseCurrency: baseCurrency!,
              contractSize: contractSize!,
              leverage,
              quoteCurrency: quoteCurrency!,
              volume,
              type:
                type === TerminalDealType.BuyLimit || type === TerminalDealType.BuyStop
                  ? TerminalDealType.Buy
                  : TerminalDealType.Sell,
              symbols,
              openPrice: price,
              instrumentType: instrumentType!,
              marginRate: "maintenance",
              marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
              marginRateInitialMarketSell: marginRateInitialMarketSell!,
              marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
              marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            }),
          };
        }),
      [pendingOrders, symbolInfo, symbolsList],
    );

    const value: ExtendedOrdersContextState = useMemo(
      () => ({ extendedOpenOrders, extendedPendingOrders }),
      [extendedOpenOrders, extendedPendingOrders],
    );

    return <ExtendedOrdersContext.Provider value={value}>{children}</ExtendedOrdersContext.Provider>;
  },
);
TerminalExtendedOrdersContextProvider.displayName = "TerminalExtendedOrdersContextProvider";

export const useExtendedOrdersContext = () => {
  return useContext(ExtendedOrdersContext);
};
