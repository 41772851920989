import { VariantProps } from "@stitches/react";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { CSS, styled } from "@/styles";

import { Description, Error, Label } from "../styles";

const StyledWrapper = styled("div");

const TileRadioWrapper = styled("div", {
  flexWrap: "wrap",

  variants: {
    type: {
      radio: {
        display: "flex",
        borderRadius: "8px",
        overflow: "hidden",
        border: "0.5px solid $gray",

        "@bp3": {
          borderRadius: "16px",
        },
      },
      checkbox: {
        display: "inline-flex",
      },
    },
  },
});

type TileWrapperInternalProps = VariantProps<typeof TileRadioWrapper> & {
  children: React.ReactNode;
};

export type TileWrapperRefType = React.ElementRef<typeof TileRadioWrapper>;

export type TileWrapperExternalProps = Omit<React.ComponentProps<typeof TileRadioWrapper>, "onChange" | "value"> & {
  css?: CSS;
  label?: React.ReactNode;
  error?: FieldError;
  showError?: boolean;
  showDescription?: boolean;
  description?: string;
};

type Props = TileWrapperExternalProps & TileWrapperInternalProps;

export const TileWrapper = forwardRef<TileWrapperRefType, Props>(
  ({ label, error, showError, showDescription = true, children, css, description, ...restProps }, ref) => {
    return (
      <StyledWrapper css={css}>
        {label && <Label label={label} />}
        {description && showDescription && <Description description={description} />}
        <TileRadioWrapper ref={ref} className="checkbox-wrapper" tabIndex={0} {...restProps}>
          {children}
        </TileRadioWrapper>
        {showError && <Error errorMessage={error!.message} />}
      </StyledWrapper>
    );
  },
);
TileWrapper.displayName = "TileWrapper";
