import { cva } from "class-variance-authority";
import { type FC } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { useTranslation } from "@/hooks/translator.hook";
import { cn } from "@/shared/styles";

import { getNumberColorClassname } from "../terminal.styles-helpers";

const blockStyles = cva(
  "relative flex h-6 items-center justify-center gap-1 bg-bg-back after:absolute after:end-0 after:h-4 after:w-px after:bg-bg-border first:rounded-s-[16px] last:rounded-e-[16px] last:after:hidden",
)();

const textStyles = cva("font-roboto text-[12px] leading-[1.5] text-text")();

type Props = {
  equity: number;
  pnl: number;
  currency: string;
  currencyDecimalScale: number;
};

const MobileAccountSummary: FC<Props> = ({ currency, equity, pnl, currencyDecimalScale }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center px-4 *:flex-1">
      <div className={blockStyles}>
        <div className={textStyles}>{t("terminal.account-summary.equity")}</div>
        <div className={textStyles}>
          <NumberFormat value={equity} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </div>
      <div className={blockStyles}>
        <div className={textStyles}>{t("terminal.account-summary.pnl")}</div>
        <div className={cn(textStyles, getNumberColorClassname(pnl) || "text-text-secondary")}>
          <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
        </div>
      </div>
    </div>
  );
};

export { MobileAccountSummary };
