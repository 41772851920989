import { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { AlertDialog, Button } from "@/shared/ui";

import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  onContinue: ReturnType<typeof useTerminalFeatureTourContext>["continueTour"];
  onTerminate: ReturnType<typeof useTerminalFeatureTourContext>["terminateTour"];
  isLoading: ReturnType<typeof useTerminalFeatureTourContext>["isLoading"];
};

const InterruptTourDialog: FC<Props> = ({ onContinue, onTerminate, isLoading }) => {
  const { t } = useTranslation();

  return (
    <AlertDialog open onOpenChange={onTerminate}>
      <AlertDialog.Content
        title={t("terminal.onboarding.interrupt.title")}
        description={t("terminal.onboarding.interrupt.description")}
      >
        <AlertDialog.Buttons>
          <Button onClick={onContinue} pending={isLoading}>
            {t("terminal.onboarding.buttons.continue-tutorial")}
          </Button>
          <AlertDialog.Cancel asChild>
            <Button variant="tertiary" pending={isLoading}>
              {t("terminal.onboarding.buttons.close-tutorial")}
            </Button>
          </AlertDialog.Cancel>
        </AlertDialog.Buttons>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export { InterruptTourDialog };
