import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";

import { SelectOptionType } from "@/components/form";
import { NumberField } from "@/components/form/fields";
import { AccountSelectDataType } from "@/components/form/fields/account-select";
import { ErrorMessage } from "@/domains/ui-kit";
import { WithdrawalFormFields } from "@/features/withdrawal/components/form/withdrawal.page.form.constants";
import * as Styled from "@/features/withdrawal/components/form/withdrawal.page.form.styled";
import { useTranslation } from "@/hooks/translator.hook";
import { MaximumLimitDescription, PaymentMethod } from "@/services/openapi";
import { Tooltip } from "@/shared/ui";
import { useWithdrawFeeQuery } from "@/state/server/payment";

type Props = {
  paymentMethod: PaymentMethod;
  withdrawLimit?: MaximumLimitDescription;
  accountsOptions: Array<SelectOptionType<AccountSelectDataType>>;
  accountId: string;
  paymentId: any;
};

export const FieldAmount: FC<Props> = ({ paymentId, accountId, accountsOptions, paymentMethod, withdrawLimit }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const amount = watch(WithdrawalFormFields.AMOUNT);
  const { data: fee } = useWithdrawFeeQuery(
    {
      paymentMethodId: paymentId,
      accountId,
      amount: amount?.toString(),
    },
    {
      enabled: !!paymentId && !!accountId && !!amount,
    },
  );

  const renderFee = fee?.value ? fee?.value : 0;
  const renderTotal = amount - renderFee;
  const isErrorAmountMax = errors[WithdrawalFormFields.AMOUNT]?.type === "max";
  const isErrorAmountRequired = errors[WithdrawalFormFields.AMOUNT]?.type === "required";
  const min = paymentMethod?.details?.limit?.from;
  const max = useMemo(() => withdrawLimit?.maxPaymentMethodAmount, [withdrawLimit?.maxPaymentMethodAmount]);
  const amountNotSave = !!(withdrawLimit?.safeToWithdrawAmount && renderTotal >= withdrawLimit?.safeToWithdrawAmount);
  const maxSaveAmount =
    max && withdrawLimit?.safeToWithdrawAmount && max > withdrawLimit?.safeToWithdrawAmount
      ? withdrawLimit?.safeToWithdrawAmount
      : max;

  const handleSetAmount = (value?: number | null) =>
    value && setValue(WithdrawalFormFields.AMOUNT, value, { shouldValidate: true });

  const MinMax = () => (
    <Trans
      t={t}
      i18nKey="withdrawal.form.amount.minMax"
      values={{
        amount_from: min,
        currency_from: paymentMethod.currency,
        amount_to: max,
        currency_to: paymentMethod.currency,
      }}
      components={{
        minSpan: <Styled.ClickSpan onClick={() => handleSetAmount(min)} />,
        maxSpan: <Styled.ClickSpan onClick={() => handleSetAmount(max)} />,
      }}
    />
  );

  return (
    <>
      <Tooltip
        open={amountNotSave}
        defaultOpen={amountNotSave}
        side="right"
        content={
          <Styled.TooltipSaveAmount>
            <Trans
              t={t}
              i18nKey="withdrawal.form.amount.tooltip"
              values={{
                amount: maxSaveAmount,
                currency: paymentMethod.currency,
              }}
              components={{
                clickSpan: <Styled.ClickSpan onClick={() => handleSetAmount(maxSaveAmount)} />,
              }}
            />
          </Styled.TooltipSaveAmount>
        }
      >
        <div>
          <NumberField
            name={WithdrawalFormFields.AMOUNT}
            thousandSeparator=","
            placeholder={`0 ${paymentMethod.currency}`}
            rules={{
              required: t("form-errors.required-error")!,
              min: min?.toString() || undefined,
              max: max,
            }}
          />
        </div>
      </Tooltip>

      {errors[WithdrawalFormFields.AMOUNT] ? (
        !isErrorAmountRequired ? (
          <Styled.ErrorMessage as={ErrorMessage} hasError>
            {isErrorAmountMax ? (
              max ? (
                <MinMax />
              ) : (
                <Trans
                  t={t}
                  i18nKey="withdrawal.form.amount.errorMax"
                  values={{ amount: max, currency: paymentMethod.currency }}
                  components={{
                    clickSpan: <Styled.ClickSpan onClick={() => handleSetAmount(max)} />,
                  }}
                />
              )
            ) : (
              <MinMax />
            )}
          </Styled.ErrorMessage>
        ) : null
      ) : max ? (
        <Styled.MinMax css={{ mt: "8px" }}>
          {amount && amount > 0 ? (
            paymentMethod.fields && paymentMethod.fields.length > 0 ? (
              t("withdrawal.form.amount.amountPayoutFee", {
                fee_amount: renderFee,
                fee_currency: paymentMethod.currency,
                amount_amount: renderTotal.toFixed(2),
                amount_currency: paymentMethod.currency,
              })
            ) : (
              t("withdrawal.form.amount.amountPayoutNoFee", {
                amount_amount: renderTotal.toFixed(2),
                amount_currency: paymentMethod.currency,
              })
            )
          ) : (
            <MinMax />
          )}
        </Styled.MinMax>
      ) : null}
    </>
  );
};
