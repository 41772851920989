import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";
import { Link } from "react-router-dom";

import { MarginFreeFormat } from "@/app/components";
import { SidebarButton } from "@/components/sidebar";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";
import { useAccountsTotalBalanceQuery } from "@/state/server/accounts";

const TradeButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>(
  ({ onClick, ...props }, ref) => {
    const { t } = useTranslation();
    const { isLoading, open } = useTerminalAutoRoute();

    return (
      <Button
        pending={isLoading}
        onClick={e => {
          onClick?.(e);
          open();
        }}
        size="sm"
        data-test="trade-btn"
        ref={ref}
        {...props}
      >
        {t("button.trade")}
      </Button>
    );
  },
);

const DepositButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>((props, ref) => {
  const { t } = useTranslation();

  return (
    <Button asChild variant="secondary" size="sm" data-test="deposit-btn" ref={ref} {...props}>
      <Link to={cabinetRoutes.deposit}>{t("cabinet.navigation.deposit")}</Link>
    </Button>
  );
});

type Props = {
  title: string;
  children?: ReactNode;
  showBalance?: boolean;
};

const CabinetTitle: FC<Props> = ({ title, children, showBalance = true }) => {
  const { data: balance } = useAccountsTotalBalanceQuery();

  const shouldRenderBalance = showBalance && !!balance;

  return (
    <div className="flex flex-wrap items-center justify-between gap-4 p-4 lg:px-0 lg:py-5">
      <div className="flex items-center gap-4">
        <SidebarButton />
        <h1 className="font-gilroy text-[24px] font-semibold leading-[1.2] text-text lg:text-[32px]">{title}</h1>
      </div>
      {shouldRenderBalance && (
        <div className="font-roboto text-[14px] leading-[1.5] text-text lg:hidden">
          <MarginFreeFormat value={balance.amount} currency={balance.currency!} />
        </div>
      )}
      <div className="hidden items-center gap-4 lg:flex">
        {shouldRenderBalance && (
          <div className="font-gilroy text-[22px] font-semibold leading-[1.2] text-text">
            <MarginFreeFormat value={balance.amount} currency={balance.currency!} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

const Component = Object.assign(CabinetTitle, { TradeButton, DepositButton });

export { Component as CabinetTitle };
