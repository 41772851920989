import { CSSProperties, FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";

import { TerminalPopover } from "../../components/popover";
import { AddChartSymbolButton } from "../../components/symbol/add-chart-symbol-button";
import { OpenOrder } from "../../contexts/extended-orders.context";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalLocators } from "../../locators";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { CloseOpenOrderButton } from "../close/close-open-order-button";
import { ModifyOrderDesktopTextButton } from "../components/trading-table/modify-order-desktop-text-button";
import { OrderSymbol } from "../components/trading-table/order-symbol";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTable } from "../components/trading-table/trading-table";
import { PartialCloseContainer } from "../modify-order/partial-close/partial-close.container";
import { ModifyOpenOrderStopLossContainer } from "../modify-order/stop-loss/open-order-stop-loss.container";
import { ModifyOpenOrderTakeProfitContainer } from "../modify-order/take-profit/open-order-take-profit.container";

type Props = {
  order: OpenOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  isLastOrder?: boolean;
  style?: CSSProperties;
};

const _OpenOrdersTableRow: FC<Props> = ({
  order,
  accountId,
  accountCurrency,
  setSymbol,
  style,
  isLastOrder,
  currencyDecimalScale,
}) => {
  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    swap,
    contractSize,
    volumeStep,
    volumeMin,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    ask,
    bid,
    currentPrice,
    pnl,
    digits,
    margin,
  } = order;

  const { t } = useTranslation();

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, symbol, accountId });

  return (
    <TradingTable.Row style={style}>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.symbol}>
        <AddChartSymbolButton onClick={addChartSymbol}>
          <OrderSymbol symbol={symbol} />
        </AddChartSymbolButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.type}>
        <OrderType type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.volume}>
        <ModifyOrderDesktopTextButton value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}>
          {onClose => (
            <TerminalPopover title={t("terminal.modify-order.partial-close")}>
              <PartialCloseContainer
                onClose={onClose}
                volume={volume}
                volumeStep={volumeStep!}
                volumeMin={volumeMin!}
                volumeDecimalScale={volumeDecimalScale!}
                currentPrice={currentPrice!}
                orderType={type}
                orderId={id}
                contractSize={contractSize!}
                baseCurrency={baseCurrency!}
                quoteCurrency={quoteCurrency!}
                accountId={accountId}
                currency={accountCurrency}
                openPrice={price}
                symbol={symbol}
                priceDecimalScale={digits!}
                currencyDecimalScale={currencyDecimalScale}
                swap={swap}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.openPrice}>
        <NumberFormat value={price} decimalScale={digits} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={digits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.takeProfit}>
        <ModifyOrderDesktopTextButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={digits} /> : null}
        >
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.take-profit-desc")!} title={t("terminal.take-profit")}>
              <ModifyOpenOrderTakeProfitContainer
                onClose={onClose}
                symbol={symbol}
                volume={volume}
                orderType={type}
                orderId={id}
                priceDecimalScale={digits!}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency!}
                quoteCurrency={quoteCurrency!}
                contractSize={contractSize!}
                currency={accountCurrency}
                openPrice={price}
                currentPrice={currentPrice!}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.stopLoss}>
        <ModifyOrderDesktopTextButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={digits} /> : null}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.stop-loss-desc")!} title={t("terminal.stop-loss")}>
              <ModifyOpenOrderStopLossContainer
                onClose={onClose}
                symbol={symbol}
                volume={volume}
                orderType={type}
                orderId={id}
                priceDecimalScale={digits!}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency!}
                quoteCurrency={quoteCurrency!}
                contractSize={contractSize!}
                currency={accountCurrency}
                openPrice={price}
                currentPrice={currentPrice!}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.timeOpened}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.swap}>
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.pnl}>
        <div className={getNumberColorClassname(pnl)}>
          <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
        </div>
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.close}>
        <CloseOpenOrderButton orderId={id} accountId={accountId} isLastOrder={isLastOrder} />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

export const OpenOrdersTableRow = memo(_OpenOrdersTableRow);
