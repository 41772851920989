import { FC, useState } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";
import { Tabs } from "@/shared/ui";

import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { ChangePasswordContainer } from "./change-password/change-password.container";
import { ChangeConfirmEmailDialog } from "./personal/components/change-confirm-email/change-confirm-email-dialog";
import { ChangeEmailDialog } from "./personal/components/change-email/change-email-dialog";
import { PersonalContainer } from "./personal/personal.container";
import { TwoFactorContainer } from "./two-factor/two-factor.container";

enum ProfileSettingsTabs {
  PERSONAL = "personal",
  CHANGE_PASSWORD = "change-password",
  TWO_FACTOR = "two-factor",
}

export const ProfileSettingsPage: FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(ProfileSettingsTabs.PERSONAL);

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.profile-settings")}>
          <CabinetTitle.DepositButton onClick={() => track(amplitudeEvents.deposit.fromProfile)} />
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      <Tabs value={tab} onValueChange={setTab}>
        <Tabs.List className="mb-6">
          <Tabs.Trigger value={ProfileSettingsTabs.PERSONAL}>{t("profile-settings.tabs.personal")}</Tabs.Trigger>
          <Tabs.Trigger value={ProfileSettingsTabs.CHANGE_PASSWORD}>
            {t("profile-settings.tabs.change-password")}
          </Tabs.Trigger>
          <Tabs.Trigger value={ProfileSettingsTabs.TWO_FACTOR}>{t("profile-settings.tabs.two-factor")}</Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value={ProfileSettingsTabs.PERSONAL}>
          <PersonalContainer />
        </Tabs.Content>
        <Tabs.Content value={ProfileSettingsTabs.CHANGE_PASSWORD}>
          <ChangePasswordContainer />
        </Tabs.Content>
        <Tabs.Content value={ProfileSettingsTabs.TWO_FACTOR}>
          <TwoFactorContainer />
        </Tabs.Content>
      </Tabs>
      {/* dialogs */}
      <ChangeEmailDialog />
      <ChangeConfirmEmailDialog />
    </CabinetPage>
  );
};
