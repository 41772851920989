import { FC, useCallback, useState } from "react";

import { useGoogleCaptcha } from "@/hooks/captcha.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { useForgotPasswordMutation } from "@/state/server/auth";

import { AuthFormContainer } from "../auth-form.container";
import { ForgotPasswordForm, IForgotPasswordFormValues } from "./forgot-password.form";
import { ForgotPasswordSuccess } from "./forgot-password-success";

export const ForgotPasswordContainer: FC = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<"form" | "success">("form");
  const { captchaToken, disabledSubmit, hasCaptcha, onVerify, refreshCaptcha, refreshReCaptchaValue } =
    useGoogleCaptcha();

  const { mutateAsync: forgotPassword } = useForgotPasswordMutation();

  const submitForgot = useCallback(
    (values: IForgotPasswordFormValues) => {
      const captcha = hasCaptcha ? { captcha: { challengeGoogleRecaptchaV3: { token: captchaToken } } } : {};
      return forgotPassword(
        { passwordForgotRequest: { ...values, ...captcha } },
        {
          onSuccess: () => {
            refreshCaptcha();
            setStep("success");
          },
          onError: () => {
            if (hasCaptcha) {
              refreshCaptcha();
            }
          },
        },
      );
    },
    [forgotPassword, setStep, captchaToken, hasCaptcha, refreshCaptcha],
  );

  return (
    <AuthFormContainer title={t("auth.forgot-password.title")} backLink>
      {step === "form" && (
        <ForgotPasswordForm
          onSubmit={submitForgot}
          disabledSubmit={disabledSubmit}
          onVerify={onVerify}
          refreshReCaptchaValue={refreshReCaptchaValue}
        />
      )}
      {step === "success" && <ForgotPasswordSuccess />}
    </AuthFormContainer>
  );
};
