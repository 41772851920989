import { TFunction } from "i18next";
import { FC, memo } from "react";

import { Flex } from "@/components/flex";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { styled } from "@/styles";

const getTypeColor = (type: TerminalDealType) => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return "positive";
    default:
      return "negative";
  }
};

export const getOrderTypeText = (t: TFunction, type: TerminalDealType) => {
  switch (type) {
    case TerminalDealType.Buy:
      return t("terminal.types.buy");
    case TerminalDealType.BuyLimit:
      return t("terminal.types.buy-limit");
    case TerminalDealType.BuyStop:
      return t("terminal.types.buy-stop");
    case TerminalDealType.BuyStopLimit:
      return t("terminal.types.buy-stop-limit");
    case TerminalDealType.Sell:
      return t("terminal.types.sell");
    case TerminalDealType.SellLimit:
      return t("terminal.types.sell-limit");
    case TerminalDealType.SellStop:
      return t("terminal.types.sell-stop");
    case TerminalDealType.SellStopLimit:
      return t("terminal.types.sell-stop-limit");
  }
};

const Container = styled(Flex, { alignItems: "center", gap: "8px" });

const TypeBlock = styled("div", {
  width: "8px",
  height: "8px",
  borderRadius: "$round",

  variants: {
    color: {
      positive: {
        background: "$positiveDefault",
      },
      negative: {
        background: "$negativeDefault",
      },
    },
  },
});

type Props = {
  type: TerminalDealType;
  withCircle?: boolean;
};

const _OrderType: FC<Props> = ({ type, withCircle = true }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {withCircle && <TypeBlock color={getTypeColor(type)} />}
      {getOrderTypeText(t, type)}
    </Container>
  );
};

export const OrderType = memo(_OrderType);
