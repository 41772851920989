import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
  symbol: string;
};

const SelectSymbolHintPopover: React.FC<Props> = ({ children, symbol }) => {
  const { t } = useTranslation();

  const { isSelectSymbolStepActive, selectSymbol, interruptTour, totalSteps, currentStep, isMobile } =
    useTerminalFeatureTourContext();

  if (!isSelectSymbolStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      sideOffset={isMobile ? 8 : 15}
      align="start"
      content={
        <Hint
          title={t("terminal.onboarding.select-symbol.title")}
          text={t("terminal.onboarding.select-symbol.description", { asset: symbol })}
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
            <Button size="sm" onClick={selectSymbol}>
              {t("terminal.onboarding.buttons.next")}
            </Button>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { SelectSymbolHintPopover };
