import { VariantProps } from "@stitches/react";

import { styled } from "@/styles/stitches.config";

export const UnstyledText = styled("span", {
  display: "block",

  elementTransition: "color",

  variants: {
    family: {
      gilroy: {
        fontFamily: "$gilroy",
      },
      roboto: {
        fontFamily: "$roboto",
      },
    },
    color: {
      black: {
        color: "$textMain",
      },
      white: {
        color: "$textInverse",
      },
      negative: {
        color: "$negativeDefault",
      },
      dimmed: {
        color: "$textSecondary",
      },
      positive: {
        color: "$positiveDefault",
      },
      warning: {
        color: "$warningDefault",
      },
      gray: {
        color: "$textSecondary",
      },
      extraLightGrey: {
        color: "$extraLightGray",
      },
    },
    size: {
      "0": {
        fontSize: "12px",
      },
      "1": {
        fontSize: "14px",
      },
      "2": {
        fontSize: "16px",
      },
      "3": {
        fontSize: "18px",
      },
      "4": {
        fontSize: "22px",
      },
      "5": {
        fontSize: "24px",
      },
      "6": {
        fontSize: "32px",
      },
      "7": {
        fontSize: "40px",
      },
    },
    weight: {
      normal: {
        fontWeight: "$normal",
      },
      bold: {
        fontWeight: "$bold",
      },
      bolder: {
        fontWeight: "$bolder",
      },
    },
    lineHeight: {
      "1": {
        lineHeight: "$1",
      },
      "2": {
        lineHeight: "$2",
      },
      "3": {
        lineHeight: "$3",
      },
      "4": {
        lineHeight: "$4",
      },
      "5": {
        lineHeight: "$5",
      },
      "6": {
        lineHeight: "$6",
      },
    },
    align: {
      left: {
        textAlign: "left",
      },
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    inline: {
      true: {
        display: "inline",
      },
    },
  },
});

export type TextColorType = VariantProps<typeof UnstyledText>["color"];

export const Text = styled(UnstyledText, {
  defaultVariants: {
    family: "gilroy",
    size: "2",
    weight: "normal",
    color: "black",
    lineHeight: "1",
  },
});
