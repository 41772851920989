import { FC, memo, useState } from "react";
import { useLocation } from "react-router-dom";

import { BurgerIcon } from "@/components/icons";
import { Sheet } from "@/components/sheet";
import { SidebarContent } from "@/components/sidebar/sidebar-content";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { LanguageSwitcherContainer } from "@/features/language-switcher/language-switcher.container";
import { cabinetRoutes } from "@/routes/cabinet.routes";

type Props = {
  isMobileAppMode?: boolean;
};

const _SidebarButton: FC<Props> = ({ isMobileAppMode }) => {
  const { pathname } = useLocation();
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [sheetOpen, setSheetOpen] = useState(false);
  const isTradingPage = pathname === cabinetRoutes.mt4Terminal || pathname === cabinetRoutes.mt5Terminal;

  const onClose = () => setSheetOpen(false);

  const openBottomSheet = () => {
    setSheetOpen(false);
    setBottomSheetOpen(true);
  };

  return (
    <>
      <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
        <Sheet.Trigger asChild>
          <UnstyledButton css={{ color: isTradingPage ? "$textInverse" : "$textMain", "@bp3": { display: "none" } }}>
            <BurgerIcon />
          </UnstyledButton>
        </Sheet.Trigger>
        <Sheet.Content>
          <SidebarContent isMobileAppMode={isMobileAppMode} onLanguageDialogOpen={openBottomSheet} onClose={onClose} />
        </Sheet.Content>
      </Sheet>
      <LanguageSwitcherContainer onOpenChange={setBottomSheetOpen} open={bottomSheetOpen} />
    </>
  );
};

export const SidebarButton = memo(_SidebarButton);
