import { FC, useEffect } from "react";

import { Flex } from "@/components/flex";
import { TextNumberField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Link } from "@/components/link";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { twoFACodeRules } from "@/utils/validators";

import { AuthBox } from "../styles";
import { getTwoFactorSearchParams, TwoFactorMode } from "./two-factor.helpers";

enum TwoFactorFields {
  TWO_FA_CODE = "twoFactorCode",
}

export interface ITwoFACodeFormValues {
  [TwoFactorFields.TWO_FA_CODE]: string;
}

interface Props {
  onSubmit: (values: ITwoFACodeFormValues) => void;
  mode: TwoFactorMode;
}

export const TwoFactorForm: FC<Props> = ({ onSubmit, mode }) => {
  const { t } = useTranslation();
  const form = useHookForm<ITwoFACodeFormValues>();

  useEffect(() => {
    if (form.getValues().twoFactorCode?.length === 6) {
      form.handleSubmit(onSubmit)();
    }
  }, [form.getValues().twoFactorCode]);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <AuthBox css={{ mb: "40px", mt: "16px" }}>
        <Text weight="bold" family="roboto" align="center" as="h2" css={{ mb: "16px" }} size="3">
          {t("auth.two-factor.title")}
        </Text>
        <Text family="roboto" align="center" css={{ mb: "16px" }}>
          {t("auth.two-factor.description")}
        </Text>
        <TextNumberField
          name={TwoFactorFields.TWO_FA_CODE}
          placeholder={t("two-factor.two-fa-code")!}
          rules={twoFACodeRules(t)}
        />
        <Text weight="bold" align="center" css={{ mt: "24px" }}>
          {t("auth.two-factor.recovery-info")}
        </Text>
        <Text weight="bold" align="center" css={{ mt: "7px" }}>
          <Link to={{ pathname: authRoutes.twoFactorRecovery, search: getTwoFactorSearchParams(mode) }} color="primary">
            {t("auth.two-factor.link-to-recovery")}
          </Link>
        </Text>
        <Flex css={{ justifyContent: "center", my: "15px" }}>
          <SubmitButton checkValid={false}>{t("button.verify")}</SubmitButton>
        </Flex>
        <Text weight="bold" align="center">
          {t("auth.sign-up.account")}{" "}
          <Link to={authRoutes.signUp} color="primary">
            {t("auth.sign-up.sign-up")}
          </Link>
        </Text>
      </AuthBox>
    </HookForm>
  );
};
