import * as Collapsible from "@radix-ui/react-collapsible";
import { FC } from "react";

import { PopoverClose } from "@/components/popover";
import { IconChevronDown } from "@/domains/icons";
import { BonusUserPlatform, TradingAccount } from "@/services/openapi";

import { useTerminalAccountContext } from "../contexts/account.context";
import { AccountsSelectInfo } from "./info";
import { AccountsSelectRow } from "./row";

type Props = {
  currentAccount: TradingAccount;
  setAccount: ReturnType<typeof useTerminalAccountContext>["setAccount"];
  accounts: TradingAccount[];
  onClose: () => void;
  showBonus: boolean;
  bonus: BonusUserPlatform | null;
};

const PopoverContent: FC<Props> = ({ accounts, currentAccount, setAccount, onClose, showBonus, bonus }) => {
  return (
    <>
      <Collapsible.Root>
        <Collapsible.Trigger asChild>
          <AccountsSelectRow active account={currentAccount}>
            <div>
              <IconChevronDown />
            </div>
          </AccountsSelectRow>
        </Collapsible.Trigger>
        <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
          <AccountsSelectInfo onClose={onClose} showBonus={showBonus} bonus={bonus} />
        </Collapsible.Content>
      </Collapsible.Root>
      {accounts.map(account => (
        <PopoverClose asChild key={account.id}>
          <AccountsSelectRow account={account} onClick={() => setAccount(account.id!)} />
        </PopoverClose>
      ))}
    </>
  );
};

export { PopoverContent as AccountsSelectPopoverContent };
