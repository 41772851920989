enum FeatureTourSteps {
  NONE = "none",
  IDLE = "idle", // needed for toasts
  WELCOME = "welcome",
  SELECT_SYMBOL = "selectSymbol",
  CHART = "chart",
  INCREMENT_ORDER = "incrementOrder",
  CHECK_FREE_MARGIN = "checkFreeMargin", // only margin
  CHANGE_VOLUME_MODE = "changeVolumeMode", // only margin
  PLACE_ORDER = "placeOrder",
  CLOSE_ORDER = "closeOrder",
  FINAL = "final",
  INTERRUPT = "interrupt",
}

export { FeatureTourSteps };
