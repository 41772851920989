export const FinancialStatusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3H14C15.1046 3 16 3.89543 16 5V6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H8V5C8 3.89543 8.89543 3 10 3ZM4 8H8H16H20V13H13H11H4V8ZM4 19V15H11V16H13V15H20V19H4ZM14 5V6H10V5H14Z"
      fill="#7C7C7C"
    />
  </svg>
);
