import { ComponentProps, FC } from "react";

import { TerminalDealType, TradingAccountType } from "@/services/openapi";
import { useOpenTerminalOrderMutation } from "@/state/server/terminal";

import { useTerminalAccountContext } from "../../contexts/account.context";
import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useSymbolsContext } from "../../contexts/symbols.context";
import { useTerminalFeatureTourContext } from "../../feature-tour/context";
import { usePlaceOrderContext } from "../context";
import { getMaximumOrderVolume, getOriginalVolume } from "../place-order.helpers";
import { MobilePlaceOrderForm } from "./form";

type Props = {
  openOrderSettings: () => void;
  openVolumeSettings: () => void;
};

const MobilePlaceOrderContainer: FC<Props> = ({ openOrderSettings, openVolumeSettings }) => {
  const { symbols } = useSymbolsContext();
  const { symbolInfo } = useCurrentSymbolContext();

  const {
    volumeMode,
    volumeLots,
    volumeMargin,
    changeLots,
    maxBalanceVolumeLots,
    maxBalanceVolumeMargin,
    maxSystemVolumeLots,
    minSystemVolumeLots,
    maxSystemVolumeMargin,
    minSystemVolumeMargin,
    volumeLotsStep,
    decrementVolumeDisabled,
    incrementVolumeDisabled,
    decrementOrder,
    incrementOrder,
    volumeLotsDecimalScale,
    volumeMarginDecimalScale,
    hasNoFreeMargin,
    volumeLotsError,
    volumeSellMargin,
    volumeBuyMargin,
    currency,
    refreshForm,
    resetForm,
    changeVolumeMode,
    marginFree,
    bid,
  } = usePlaceOrderContext();

  const {
    account: { id: accountId, type, leverage },
  } = useTerminalAccountContext();

  const {
    placeOrder,
    placeOrderStyles,
    incrementOrderStyles,
    incrementOrder: featureTourIncrementOrder,
    isPlaceOrderStepActive,
    changeVolumeModeStyles,
    isIncrementOrderStepActive,
  } = useTerminalFeatureTourContext();

  const { mutateAsync: openMarketOrder } = useOpenTerminalOrderMutation();

  const {
    symbol,
    baseCurrency,
    quoteCurrency,
    contractSize,
    type: instrumentType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
  } = symbolInfo!;

  const handleSubmit: ComponentProps<typeof MobilePlaceOrderForm>["onSubmit"] = side => {
    return openMarketOrder(
      {
        tradingAccountId: accountId!,
        terminalOpenOrderRequest: {
          volume: getMaximumOrderVolume({
            maxBalanceVolumeLots,
            volumeLots,
            marginFree,
            baseCurrency: baseCurrency!,
            bid,
            contractSize: contractSize!,
            currency,
            instrumentType: instrumentType!,
            leverage: leverage!,
            marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
            marginRateInitialMarketSell: marginRateInitialMarketSell!,
            marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
            marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            quoteCurrency: quoteCurrency!,
            symbols,
            volumeLotsDecimalScale,
            volumeLotsStep,
          }),
          symbol,
          type: side === "Buy" ? TerminalDealType.Buy : TerminalDealType.Sell,
          volumeOriginal: getOriginalVolume({ volumeMode, volumeLots, volumeMargin }),
        },
      },
      {
        onSuccess: () => {
          placeOrder();
        },
      },
    );
  };

  const handleOpenOrderSettings = () => {
    refreshForm();
    openOrderSettings();
  };

  const handleOpenVolumeSettings = () => {
    resetForm();
    openVolumeSettings();
  };

  const handleIncrement = () => {
    featureTourIncrementOrder();
    incrementOrder();
  };

  return (
    <MobilePlaceOrderForm
      accountId={accountId!}
      isDemoAccount={type === TradingAccountType.Demo}
      isIncrementOrderStepActive={isIncrementOrderStepActive}
      changeVolumeModeStyles={changeVolumeModeStyles}
      incrementOrderStyles={incrementOrderStyles}
      placeOrderStyles={placeOrderStyles}
      isPlaceOrderStepActive={isPlaceOrderStepActive}
      openOrderSettings={handleOpenOrderSettings}
      openVolumeSettings={handleOpenVolumeSettings}
      hasNoFreeMargin={hasNoFreeMargin}
      volumeLotsStep={volumeLotsStep}
      volumeMode={volumeMode}
      changeLots={changeLots}
      onSubmit={handleSubmit}
      accountCurrency={currency}
      volumeLots={volumeLots}
      volumeMargin={volumeMargin}
      maxSystemVolumeLots={maxSystemVolumeLots}
      maxBalanceVolumeLots={maxBalanceVolumeLots}
      minSystemVolumeLots={minSystemVolumeLots}
      maxBalanceVolumeMargin={maxBalanceVolumeMargin}
      maxSystemVolumeMargin={maxSystemVolumeMargin}
      minSystemVolumeMargin={minSystemVolumeMargin}
      incrementVolumeDisabled={incrementVolumeDisabled}
      decrementVolumeDisabled={decrementVolumeDisabled}
      decrementOrder={decrementOrder}
      incrementOrder={handleIncrement}
      volumeLotsError={volumeLotsError}
      volumeMarginDecimalScale={volumeMarginDecimalScale}
      volumeLotsDecimalScale={volumeLotsDecimalScale}
      volumeSellMargin={volumeSellMargin}
      volumeBuyMargin={volumeBuyMargin}
      changeVolumeMode={changeVolumeMode}
    />
  );
};

export { MobilePlaceOrderContainer };
