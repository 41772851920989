import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";

import { Dialog } from "@/components/dialog";
import { useTranslation } from "@/hooks/translator.hook";

import { TerminalDialogContent } from "../components/terminal-dialog-content";
import { useOrdersContext } from "../contexts/orders.context";
import { TerminalTableState, useTerminalLayout } from "../contexts/terminal-layout.context";
import { MobileTradingTablesContainer } from "./mobile-trading-tables.container";

const itemStyles = cva(
  "text-text-main relative grow py-2 font-roboto text-[14px] leading-[1.5] after:absolute after:end-0 after:h-[21px] after:w-px after:bg-bg-border last:after:hidden",
)();

const MobileTradingTables = () => {
  const { t } = useTranslation();
  const { table, changeTable, closeTable, isMobileAppMode } = useTerminalLayout();
  const { openOrders, pendingOrders } = useOrdersContext();

  return (
    <>
      {table === TerminalTableState.NONE && !isMobileAppMode && (
        <div className="bg-bg-back pb-2">
          <ToggleGroup.Root type="single" value={table} onValueChange={changeTable} className="flex bg-bg">
            <ToggleGroup.Item value={TerminalTableState.OPEN} className={itemStyles}>
              {t("terminal.open")}: {openOrders.length}
            </ToggleGroup.Item>
            <ToggleGroup.Item className={itemStyles} value={TerminalTableState.PENDING}>
              {t("terminal.pending")}: {pendingOrders.length}
            </ToggleGroup.Item>
            <ToggleGroup.Item className={itemStyles} value={TerminalTableState.CLOSED_POSITIONS}>
              {t("terminal.closed-positions")}
            </ToggleGroup.Item>
          </ToggleGroup.Root>
        </div>
      )}
      {table !== TerminalTableState.NONE && (
        <Dialog
          open
          onOpenChange={flag => {
            if (!flag) {
              changeTable(TerminalTableState.NONE);
            }
          }}
        >
          <TerminalDialogContent title={t("terminal.orders.title")} showCloseButton={!isMobileAppMode}>
            <MobileTradingTablesContainer onClose={closeTable} />
          </TerminalDialogContent>
        </Dialog>
      )}
    </>
  );
};

export { MobileTradingTables };
