import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";
import { BonusUserPlatform } from "@/services/openapi";

type Props = {
  bonus: BonusUserPlatform;
};

const DepositInfo: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();

  return (
    <Table.Card>
      <Table>
        {bonus.amountSet!.map(({ amount, limitMaxOriginal }, i, arr) => {
          const isFirst = i === 0;
          return (
            <Table.Row key={i}>
              <Table.Value className="text-start">
                {isFirst ? (
                  <Trans
                    i18nKey="bonus.dialog.terms.deposit.first"
                    components={{
                      value: <NumberFormat prefix="$" value={limitMaxOriginal} />,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="bonus.dialog.terms.deposit.range"
                    components={{
                      fromValue: <NumberFormat prefix="$" value={arr.at(i - 1)!.limitMaxOriginal} />,
                      toValue: <NumberFormat prefix="$" value={limitMaxOriginal} />,
                    }}
                  />
                )}
              </Table.Value>
              <Table.Value>{t("bonus.dialog.terms.deposit.bonus", { value: amount })}</Table.Value>
            </Table.Row>
          );
        })}
        <Table.Row>
          <Table.Value className="text-start">
            <Trans
              i18nKey="bonus.dialog.terms.deposit.over"
              components={{
                value: <NumberFormat prefix="$" value={bonus.amountSet!.at(-1)!.limitMaxOriginal} />,
              }}
            />
          </Table.Value>
          <Table.Value>{t("bonus.dialog.terms.deposit.no-bonus")}</Table.Value>
        </Table.Row>
      </Table>
      <p className="mt-2 font-roboto text-[14px] leading-normal text-text-secondary md:text-[16px]">
        <Trans
          i18nKey="bonus.dialog.terms.deposit.caption"
          components={{
            value: <NumberFormat prefix="$" value={bonus.amountMax} />,
          }}
        />
      </p>
    </Table.Card>
  );
};

export { DepositInfo };
