import { FC, useCallback } from "react";

import { TradingAccount } from "@/services/openapi";
import { useChangeAccountMainPasswordMutation } from "@/state/server/accounts";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { ChangeAccountPasswordForm, IChangeAccountPasswordFormValues } from "./change-account-password.form";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const ChangeAccountPasswordContainer: FC<Props> = ({ account, onClose }) => {
  const { mutateAsync: changePassword } = useChangeAccountMainPasswordMutation({
    successMessage: "account-password-changed",
  });
  const { options } = useProfileData();

  const handleSubmit = useCallback(
    (values: IChangeAccountPasswordFormValues) => {
      return changePassword(
        { id: account.id!, tradingAccountPwdRequest: { ...values } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changePassword, account],
  );

  return (
    <ChangeAccountPasswordForm
      accountPlatform={account.platform!}
      onSubmit={handleSubmit}
      is2FaEnabled={options!.is2FaEnabled!}
    />
  );
};
