import { styled } from "@/styles";

import { mixinsCommon } from "../mixins/common";
import { iconEnd, iconStart } from "../mixins/input";

const textOverflow = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const Text = styled("span", { fontFamily: "$roboto", fontSize: "14px", fontWeight: "$normal", lineHeight: "20px" });

export const Trigger = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "100%",

  padding: "15px", // не по дизайну, костыль для визаульной обратной совместимости, праивльно 16px
  gap: "12px",

  borderRadius: "16px",
  border: "1px solid $bgBorder",
  background: "$bgPrimary",

  color: "$textMain",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontWeight: "$normal",
  lineHeight: "$3" /* 24px */,
  ...textOverflow,
  outline: "none",

  "&:hover": {
    border: "1px solid $textSecondary",
  },

  "&:focus": {
    border: "1px solid $textSecondary",
    boxShadow: "0px 0px 0px 2px rgba(21, 38, 149, 0.15)",
  },

  "@bp3": {
    padding: "20px",
  },

  variants: {
    iconPadding: {
      true: {
        paddingLeft: "52px",
      },
    },
    disabled: {
      true: mixinsCommon.disabled,
    },
    error: {
      true: {
        "&:hover": {
          borderColor: "$negativeDefault",
        },

        "&:focus": {
          borderColor: "$negativeDefault",
          boxShadow: "0px 0px 0px 2px rgba(255, 52, 52, 0.15)",
        },
      },
    },
  },
});

export const IconStart = styled(iconStart, {});
export const IconEnd = styled(iconEnd, {});

export const Content = styled("div", {
  position: "relative",
  maxHeight: "400px",
  padding: "8px 0",

  borderRadius: "24px",
  backgroundColor: "$bgBack",
  boxShadow: "0px 10px 40px -10px rgba(21, 38, 149, 0.15)",

  zIndex: 50,

  "&[data-[state=open]": {
    pointerEvents: "none",
  },
});

export const Item = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  outline: "none",
  padding: "16px",

  backgroundColor: "$bgPrimary",

  cursor: "default",
  userSelect: "none",

  "&[data-highlighted]": {
    backgroundColor: "$bgSelected",
  },

  "&[data-disabled]": {
    pointerEvents: "none",
    backgroundColor: "rgba(0,0,0,0.2)",
  },

  "&:hover": {
    backgroundColor: "$bgSelected",
  },

  variants: {
    disabled: {
      true: mixinsCommon.disable,
    },
  },
});

export const ItemContent = styled("div", {
  display: "flex",
  flexShrink: 0,
  alignItems: "center",
  gap: "12px",
  marginRight: "4px",
});

export const ItemText = styled(Text, {
  ...textOverflow,
  color: "$textMain",

  variants: {
    selected: {
      true: {
        paddingRight: "36px",
      },
    },
  },
});

export const Label = styled(Text, {
  padding: "12px 24px",
  backgroundColor: "$bgBack",
});

export const Icon = styled("div", {
  position: "absolute",
  right: "16px",
});

export const Separator = styled("div", {
  height: "1px",
  backgroundColor: "$textPlaceholder",
  margin: "5px",
});
