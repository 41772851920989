import { FC } from "react";

import { TextField } from "@/components/form/fields";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField } from "@/services/openapi";

import { OnboardingFieldWrapper } from "../../styles";
import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyStringField: FC<Props> = ({
  field: { isRequired, title, id, type, value, description, placeholder },
}) => {
  const { t } = useTranslation();
  return (
    <OnboardingFieldWrapper>
      <TextField
        name={generateSurveyFieldId(id!, type!)}
        label={generateSurveyFieldTitle(title!, isRequired!)}
        registerOptions={{
          required: isRequired ? t("form-errors.required-error")! : false,
        }}
        defaultValue={value ? value : undefined}
        description={description!}
        placeholder={placeholder ? placeholder : undefined}
      />
    </OnboardingFieldWrapper>
  );
};
