/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    Unknown = 'Unknown',
    String = 'String',
    Decimal = 'Decimal',
    Int = 'Int',
    Date = 'Date',
    File = 'File',
    Boolean = 'Boolean',
    PollSingle = 'PollSingle',
    PollMulti = 'PollMulti',
    CountryList = 'CountryList',
    PhoneNumber = 'PhoneNumber',
    Declaration = 'Declaration'
}



