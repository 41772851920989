import { FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { useField } from "@/components/form/field.hook";
import { CheckboxField } from "@/components/form/fields";
import { Description, Error, Label } from "@/components/form/inputs/styles";
import { Stack } from "@/components/stack";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField as SurveyFieldType } from "@/services/openapi";

import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";
import { SurveyField } from "../survey-field";

type Props = {
  field: SurveyFieldType;
};

export const SurveyPollMultiField: FC<Props> = ({
  field: { isRequired, title, id, pollFields, type, pollValues, description },
}) => {
  const { t } = useTranslation();
  const fieldId = generateSurveyFieldId(id!, type!);

  const { watch } = useFormContext();

  const { showError } = useField(fieldId);

  return (
    <>
      {/* TODO: make separate component CheckboxGroup */}
      <Stack>
        <div>
          <Label label={generateSurveyFieldTitle(title!, isRequired!)} />
          {description && <Description description={description} />}
        </div>
        {pollFields!.map(({ id: pollId, title: pollTitle }) => (
          <CheckboxField
            key={pollTitle}
            name={fieldId}
            value={pollId!}
            label={pollTitle}
            registerOptions={{ required: isRequired }}
            defaultChecked={pollValues!.includes(pollId!)}
          />
        ))}
        {showError && <Error errorMessage={t("form-errors.required-error")} />}
      </Stack>
      {watch(fieldId)?.length > 0 &&
        pollFields!.map(({ id: pollId, title: pollTitle, fields }) => {
          if (watch(fieldId).includes(pollId)) {
            return (
              <Fragment key={pollTitle}>
                {fields!.map(field => (
                  <SurveyField field={field} key={field.id} />
                ))}
              </Fragment>
            );
          }
          return null;
        })}
    </>
  );
};
