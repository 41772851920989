import { FC } from "react";

import { OrderSymbol } from "../components/trading-table/order-symbol";

type Props = {
  isMobile: boolean | undefined;
  symbol: string;
};

export const ModifyOrderSymbol: FC<Props> = ({ isMobile, symbol }) => {
  if (!isMobile) {
    return null;
  }
  return <OrderSymbol symbol={symbol} css={{ my: 16, lineHeight: 1.2, fontWeight: "$bold", fontSize: 16 }} />;
};
