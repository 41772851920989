import { FC, ReactNode } from "react";

import { Stack } from "@/components/stack";

type Props = {
  children: ReactNode;
  button?: ReactNode;
  className?: string;
};

export const TradingCardsContainer: FC<Props> = ({ children, className, button }) => {
  return (
    <Stack gap="3" className={className} css={{ overflow: "auto", hideScroll: true }}>
      {button}
      {children}
    </Stack>
  );
};
