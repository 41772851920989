import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const listStyles = cva(
  "group/tabs relative flex overflow-auto scrollbar-hide before:absolute before:inset-0 before:top-auto before:border-b-2",
  {
    variants: {
      grow: {
        true: "*:flex-1",
      },
    },
  },
);

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof listStyles>
>(({ className, grow, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} data-grow={grow} className={cn(listStyles({ grow }), className)} {...props} />
));

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={cn("outline-none", className)} {...props} />
));

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "relative inline-flex items-center justify-center whitespace-nowrap px-4 py-4 font-gilroy text-[16px] font-semibold leading-[1.2] text-text-secondary transition-colors before:absolute before:inset-0 before:top-auto before:border-b-bg-additional hover:text-text data-[state=active]:text-text data-[state=active]:before:border-b-2 group-data-[grow]/tabs:px-4 md:px-10 md:text-[18px]",
      className,
    )}
    {...props}
  />
));

type RootProps<T extends string> = Omit<
  ComponentPropsWithoutRef<typeof TabsPrimitive.Root>,
  "defaultValue" | "value" | "onValueChange"
> & {
  defaultValue?: T;
  value?: T;
  onValueChange?: (value: T) => void;
};

// @ts-ignore
const Root = <T extends string>(props: RootProps<T>) => <TabsPrimitive.Root {...props} />;

const Component = Object.assign(Root, {
  List,
  Trigger,
  Content,
});

export { Component as Tabs };
