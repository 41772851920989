import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";

type OnboardingContextState = {
  currentStepIndex: number;
  setCurrentStepIndex: Dispatch<SetStateAction<number>>;
};

export const OnboardingContext = createContext<OnboardingContextState>({} as OnboardingContextState);

export const OnboardingContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const value = useMemo(
    (): OnboardingContextState => ({
      currentStepIndex,
      setCurrentStepIndex,
    }),
    [currentStepIndex],
  );

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export const useOndoardingContext = () => {
  return useContext(OnboardingContext);
};
