import * as Collapsible from "@radix-ui/react-collapsible";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { toggleLiveChat } from "@/app/libs/liveChat";
import LogoSymbolLight from "@/assets/images/LogoSymbolLight.svg";
import LogoWordLight from "@/assets/images/LogoWordLight.svg";
import { Box } from "@/components/box";
import {
  AutoTerminalIcon,
  NotificationsIcon,
  PaymentsIcon,
  SupportIcon,
  UnreadNotificationsIcon,
} from "@/components/icons";
import { Stack } from "@/components/stack";
import { LanguageSwitcherButton } from "@/features/language-switcher";
import { useHover } from "@/hooks/hover.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { CabinetNavItem } from "@/layouts/cabinet/cabinet-nav-item";
import {
  cabinetBottomNavItems,
  cabinetTopNavItems,
  mobileAppSidebarPaymentsNavItems,
  sidebarPaymentsNavItems,
} from "@/layouts/cabinet/cabinet-navigation.helpers";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { terminalRoutes } from "@/routes/terminal.routes";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { keyframes, styled } from "@/styles";

export const sidebarWidthExpanded = 255;
export const sidebarWidthCollapsed = 88;

const Nav = styled("nav", {
  flexGrow: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  mt: "40px",
});

const ImgWrapper = styled("div", {
  ml: "16px",
  display: "flex",
  gap: "6px",
  alignItems: "center",
});

const ImgLogo = styled("img", {
  elementTransition: "opacity",
});

export const SidebarWrapper = styled("aside", {
  display: "none",

  "@bp3": {
    background: "$dark",
    position: "fixed",
    top: 0,
    bottom: 0,
    overflowY: "auto",
    p: "40px 16px",
    width: sidebarWidthExpanded,

    display: "flex",
    flexDirection: "column",
  },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-collapsible-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-collapsible-content-height)" },
  to: { height: 0 },
});

const InsideNavItems = styled(Collapsible.Content, {
  pl: "19px",
  overflow: "hidden",

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});

export const Wrapper = styled("div", {
  background: "$dark",
  height: "100%",
  overflowY: "auto",
  p: "26px 16px 24px 16px",
  display: "flex",
  flexDirection: "column",

  "@bp3": {
    background: "$dark",
    position: "fixed",
    top: 0,
    bottom: 0,
    overflowY: "auto",
    p: "40px 16px",
    width: sidebarWidthExpanded,
    display: "flex",
    flexDirection: "column",
    elementTransition: "width",
    transitionDuration: "300ms",
    hideScroll: "true",
    zIndex: "10",
  },

  variants: {
    collapsed: {
      true: {
        width: sidebarWidthCollapsed,
      },
    },
  },
});

type Props = {
  initiallyCollapsed?: boolean;
  isMobileAppMode?: boolean;
  onClose?: () => void;
  onLanguageDialogOpen?: () => void;
  analyticsEventOnDeposit?: () => void;
};

const paymentMenuPages = sidebarPaymentsNavItems.map(item => item.link);

const _SidebarContent: FC<Props> = ({
  onClose,
  initiallyCollapsed = false,
  isMobileAppMode,
  onLanguageDialogOpen,
  analyticsEventOnDeposit,
}) => {
  const profileData = useProfileData();
  const [isPaymentMenuOpened, setIsPaymentMenuOpened] = useState(false);

  const location = useLocation();
  const isCurrentPageInSubMenu = useMemo(() => paymentMenuPages.includes(location.pathname), [location.pathname]);
  const { isLoading, open } = useTerminalAutoRoute();

  const { ref, hovered } = useHover({ delay: 400 });

  const isCollapsed = initiallyCollapsed && !hovered;

  useEffect(() => {
    if (isCurrentPageInSubMenu) {
      setIsPaymentMenuOpened(true);
    }
  }, [isCurrentPageInSubMenu]);

  return (
    <Wrapper collapsed={isCollapsed} ref={ref}>
      <ImgWrapper>
        <img
          alt="doto"
          style={{ minHeight: "29px", minWidth: "27px", width: "27px", height: "29px" }}
          src={LogoSymbolLight}
        />
        <ImgLogo
          alt="doto"
          src={LogoWordLight}
          style={{ minHeight: "20px", minWidth: "65px" }}
          css={{ opacity: `${isCollapsed ? 0 : 1}` }}
        />
      </ImgWrapper>
      <Nav>
        <Stack as="ul" css={{ gap: "2px" }}>
          <CabinetNavItem
            title="terminal"
            Icon={AutoTerminalIcon}
            iconOnly={isCollapsed}
            action={open}
            isLoading={isLoading}
          />

          {cabinetTopNavItems.map(item => (
            <CabinetNavItem key={item.title} {...item} onClose={onClose} iconOnly={isCollapsed} />
          ))}

          <Collapsible.Root open={isPaymentMenuOpened && !isCollapsed}>
            <CabinetNavItem
              Icon={PaymentsIcon}
              title="payments"
              action={() => setIsPaymentMenuOpened(!isPaymentMenuOpened)}
              iconOnly={isCollapsed}
              withChevron={isPaymentMenuOpened ? "down" : "up"}
              active={!isPaymentMenuOpened && isCurrentPageInSubMenu}
            />

            <InsideNavItems>
              <Stack as="ul" css={{ gap: "2px", marginTop: "2px" }}>
                {isMobileAppMode
                  ? mobileAppSidebarPaymentsNavItems.map(item => (
                      <CabinetNavItem key={item.title} {...item} onClose={onClose} iconOnly={isCollapsed} />
                    ))
                  : sidebarPaymentsNavItems.map(item => {
                      if (item.link === cabinetRoutes.deposit)
                        return (
                          <CabinetNavItem
                            key={item.title}
                            {...item}
                            iconOnly={isCollapsed}
                            analyticsEvent={analyticsEventOnDeposit}
                            onClose={onClose}
                          />
                        );

                      return <CabinetNavItem key={item.title} {...item} onClose={onClose} iconOnly={isCollapsed} />;
                    })}
              </Stack>
            </InsideNavItems>
          </Collapsible.Root>
        </Stack>
        <Box>
          <Stack as="ul" css={{ gap: "2px" }}>
            <CabinetNavItem iconOnly={isCollapsed} Icon={SupportIcon} title="support" action={toggleLiveChat} />

            {cabinetBottomNavItems.map(item => (
              <CabinetNavItem key={item.title} {...item} onClose={onClose} iconOnly={isCollapsed} />
            ))}

            <CabinetNavItem
              onClose={onClose}
              Icon={profileData?.notificationsCount! > 0 ? UnreadNotificationsIcon : NotificationsIcon}
              title="notifications"
              link={cabinetRoutes.notifications}
              iconOnly={isCollapsed}
            />
            <LanguageSwitcherButton onOpen={onLanguageDialogOpen!} collapsed={isCollapsed} />
          </Stack>
        </Box>
      </Nav>
    </Wrapper>
  );
};

export const SidebarContent = memo(_SidebarContent);
