import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { getShowBonusCondition } from "@/entities/bonuses/helpers";
import { terminalAccountParamsName } from "@/routes/terminal.routes";
import {
  BonusUserPlatform,
  TerminalEventType,
  TradingAccount,
  TradingAccountTradeMode,
  TradingAccountType,
} from "@/services/openapi";
import {
  accountsQueryKeys,
  useChangeAccountTradeModeMutation,
  useUpdateLastAccountMutation,
} from "@/state/server/accounts";

import { filterTerminalSocket, terminalCommands } from "../helpers/socket.commands";
import { useTerminalSocket } from "../hooks/socket.hook";

type ContextProps = {
  account: TradingAccount;
  terminalType: TradingAccountType;
  bonus: BonusUserPlatform | null;
  showBonus: boolean;
  setAccount: (accountId: string) => void;
  changeAccountTradeMode: (tradeMode: TradingAccountTradeMode) => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode; account: TradingAccount; bonuses: BonusUserPlatform[] }> = ({
  children,
  account,
  bonuses,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const [eventId, setEventId] = useState(0);
  const { mutateAsync: updateLastAccount } = useUpdateLastAccountMutation();

  const queryClient = useQueryClient();

  const setAccount: ContextProps["setAccount"] = useCallback(
    accountId => setSearchParams({ [terminalAccountParamsName]: accountId }),
    [setSearchParams],
  );

  const { mutate } = useChangeAccountTradeModeMutation();

  const changeAccountTradeMode: ContextProps["changeAccountTradeMode"] = useCallback(
    tradeMode => {
      mutate({ id: account.id!, tradeMode });
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(account.id!), oldData => {
        return { ...oldData, tradeMode };
      });
    },
    [account.id, mutate, queryClient],
  );

  const { sendJsonMessage, lastJsonMessage } = useTerminalSocket({
    filter: e =>
      filterTerminalSocket(e, [
        TerminalEventType.Init,
        TerminalEventType.Heartbeat,
        TerminalEventType.Account,
        TerminalEventType.Deal,
        TerminalEventType.Subscription,
      ]),
  });

  // useEffect(() => {
  //   if (lastJsonMessage) {
  //     if (eventId + 1 !== lastJsonMessage.e) {
  //       sendJsonMessage(terminalCommands.tradingInitData);
  //     }
  //     setEventId(lastJsonMessage.e!);
  //   }
  // }, [lastJsonMessage]);

  useEffect(() => {
    sendJsonMessage(terminalCommands.tradingSubscribe);
  }, []);

  useEffect(() => {
    try {
      if (lastJsonMessage?.da) {
        const { b, c, l } = lastJsonMessage.da;
        queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(account.id!), oldData => {
          return { ...oldData, balance: b, credit: c, leverage: l };
        });
      }
    } catch (error) {
      logError(error);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    updateLastAccount({ id: account.id! });
  }, [account.id]);

  const bonus = useMemo(() => (bonuses.length > 0 ? bonuses[0] : null), [bonuses]);

  const showBonus = useMemo(
    () =>
      getShowBonusCondition({
        allowedTradingServerAccounts: bonus?.allowedTradingServerAccounts || [],
        serverAccountId: account.serverAccountId!,
      }),
    [bonus?.allowedTradingServerAccounts, account.serverAccountId],
  );

  const value: ContextProps = useMemo(
    () => ({
      account,
      terminalType: account.type!,
      setAccount,
      changeAccountTradeMode,
      bonus,
      showBonus,
    }),
    [account, setAccount, changeAccountTradeMode, showBonus, bonus],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.displayName = "TerminalAccountContextProvider";

const useTerminalAccountContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalAccountContext must be used within a TerminalAccountContextProvider");
  }

  return context;
};

export { Provider as TerminalAccountContextProvider, useTerminalAccountContext };
