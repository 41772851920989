import { FC, memo } from "react";

import { useExtendedOrdersContext } from "../../contexts/extended-orders.context";
import { PendingOrdersTable } from "./pending-orders-table";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

const _PendingOrdersContainer: FC<Props> = ({
  accountId,
  onClose,
  setSymbol,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const { extendedPendingOrders } = useExtendedOrdersContext();
  return (
    <PendingOrdersTable
      orders={extendedPendingOrders}
      accountId={accountId}
      onClose={onClose}
      setSymbol={setSymbol}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
    />
  );
};

export const PendingOrdersContainer = memo(_PendingOrdersContainer);
