import { FC } from "react";

import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { TradingUnavailable } from "../components/trading-unavailable/trading-unavailable";
import { TerminalPlaceOrderProvider } from "../context";
import { DesktopPlaceOrderContainer } from "./container";

const DesktopPlaceOrderBlock: FC = () => {
  const { isTradingAvailable } = useCurrentSymbolContext();

  return isTradingAvailable.available ? (
    <TerminalPlaceOrderProvider>
      <DesktopPlaceOrderContainer />
    </TerminalPlaceOrderProvider>
  ) : (
    <TradingUnavailable />
  );
};

export { DesktopPlaceOrderBlock };
