import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useTranslation } from "@/hooks/translator.hook";

import { InstrumentButton } from "./components/instrument-button/instrument-button";
import { MoreInWatchList } from "./components/more-in-watch-list/more-in-watch-list";
import { TradingReopenMessage } from "./components/trading-reopen-message/trading-reopen-message";
import * as Styled from "./trading-unavailable.styled";

export const TradingUnavailable = () => {
  const { topActiveInstruments, setSymbol } = useCurrentSymbolContext();
  const { t } = useTranslation();

  return (
    <Styled.Container>
      <TradingReopenMessage />

      {topActiveInstruments.length > 0 && (
        <Styled.Block>
          <p>{t("terminal.trading-unavailable.top-active-instruments")}</p>

          {topActiveInstruments.map((activeInstrument, index) => (
            <InstrumentButton key={activeInstrument} symbol={activeInstrument} setSymbol={setSymbol} />
          ))}

          <MoreInWatchList />
        </Styled.Block>
      )}
    </Styled.Container>
  );
};
