import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@/components/box";
import { useAuth } from "@/contexts/auth.context";
import { LocaleInfo } from "@/services/openapi";
import { usePlatformLocalesQuery } from "@/state/server/platform";
import { useProfileSettingsQuery } from "@/state/server/profile";
import { styled } from "@/styles";

const Button = styled("button", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  p: "14px 18px",
  cursor: "pointer",
  borderRadius: "40px",
  elementTransition: "background",
  background: "none",
  border: "none",
  width: "100%",
  color: "$lite",
  whiteSpace: "nowrap",
  fontFamily: "$gilroy",
  fontWeight: "$bold",

  fontSize: "16px",

  "&:hover": {
    background: "#034D11",
  },

  variants: {
    light: {
      true: {
        background: "none",
        color: "$black",
        elementTransition: "color",

        "&:hover": {
          background: "none",
          color: "$darkGray",
        },
      },
    },
  },
});

const Icon = styled("img", {
  minWidth: "25px",
  height: "25px",
  borderRadius: "$round",
});

type Props = {
  collapsed?: boolean;
  onOpen: () => void;
  light?: boolean;
};

export const LanguageSwitcherButton: FC<Props> = ({ onOpen, collapsed, light = false }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<LocaleInfo>();
  const { isAuthenticated } = useAuth();
  const { data: platformLocales } = usePlatformLocalesQuery();
  const { data: settings } = useProfileSettingsQuery({ enabled: isAuthenticated });

  useEffect(() => {
    if (settings) {
      setCurrentLanguage(settings.locale);
    } else {
      platformLocales?.items?.find(item => {
        if (item.code === i18n.resolvedLanguage) setCurrentLanguage(item);
      });
    }
  }, [settings, platformLocales, i18n.resolvedLanguage]);

  if (!platformLocales || !currentLanguage) return null;

  return (
    <Button type="button" light={light} onClick={onOpen} data-test="language-switcher-button">
      <Icon src={currentLanguage?.icon!} alt={currentLanguage?.name!} />
      <Box css={{ opacity: `${collapsed ? 0 : 1}` }}>{currentLanguage?.name}</Box>
    </Button>
  );
};
