import { FC } from "react";

import { NumberFormat } from "@/app/components";
import { TerminalDealType } from "@/services/openapi";

type Props = {
  volumeSell: number | undefined;
  volumeBuy: number | undefined;
  decimalScale: number;
  type: TerminalDealType;
};

export const ClosedPositionVolume: FC<Props> = ({ volumeSell, volumeBuy, type, decimalScale }) => {
  return (
    <>
      {volumeSell === volumeBuy ? (
        <NumberFormat value={volumeSell} decimalScale={decimalScale} />
      ) : (
        <>
          <NumberFormat value={type === TerminalDealType.Buy ? volumeSell : volumeBuy} decimalScale={decimalScale} /> /{" "}
          <NumberFormat value={type === TerminalDealType.Buy ? volumeBuy : volumeSell} decimalScale={decimalScale} />
        </>
      )}
    </>
  );
};
