import { effect, type WriteSignal } from "@maverick-js/signals";
import dayjs from "dayjs";
import {
  type Bar,
  type DatafeedConfiguration,
  type IBasicDataFeed,
  type ResolutionString,
} from "public/charting_library/charting_library";
import { type LibrarySymbolInfo } from "public/charting_library/datafeed-api";
import { type QueryClient } from "react-query";

import { type TerminalBarItemsContainer, type TerminalEvent } from "@/services/openapi";
import { getSymbolChartFirstDate, getSymbolCharts } from "@/services/terminal";
import { terminalQueryKeys } from "@/state/server/terminal";

import { getTickSizeFromDecimalScale } from "../../helpers/formatting";
import { type MergedTerminalSymbol } from "../../helpers/symbols";
import { formatTimeResolution } from "./resolution";
import { getSymbolSessions } from "./sessions";
import { getSymbolTimezone } from "./timezone";

// https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API

const configurationData: DatafeedConfiguration = {
  supported_resolutions: ["1", "5", "15", "30", "60", "240", "1D", "1W", "1M"] as ResolutionString[],
};

type Params = {
  $tick: WriteSignal<TerminalEvent>;
  symbols: MergedTerminalSymbol[];
  tradingServerId: string;
  queryClient: QueryClient;
};

const getDatafeed = ({ symbols, tradingServerId, queryClient, $tick }: Params): IBasicDataFeed => ({
  onReady: callback => {
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (_, __, ___, onResult) => {
    onResult([]);
  },
  resolveSymbol: async (symbolName, onResolve, onError) => {
    const symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
    if (!symbolItem) {
      // TODO: add translations
      onError("cannot resolve symbol");
      return;
    }

    const symbolInfo: LibrarySymbolInfo = {
      name: symbolItem.symbol!,
      full_name: symbolItem.symbol!,
      ticker: symbolItem.symbol!,
      description: symbolItem.description!,
      type: symbolItem.group!,
      session: getSymbolSessions(symbolItem.sessions!),
      timezone: getSymbolTimezone(),
      format: "price",
      minmov: 1,
      minmove2: 0,
      fractional: false,
      pricescale: Math.pow(10, Math.abs(Math.log10(getTickSizeFromDecimalScale(symbolItem.digits!)))),
      has_intraday: true,
      supported_resolutions: configurationData.supported_resolutions!,
      has_daily: true,
      has_weekly_and_monthly: true,
      visible_plots_set: "ohlc",
      data_status: "streaming",
      exchange: "",
      listed_exchange: "",
    };

    setTimeout(() => {
      onResolve(symbolInfo);
    }, 0);
  },
  getBars: async ({ name }, resolution, periodParams, onResult, onError) => {
    const { from, to, firstDataRequest } = periodParams;
    const timeframe = formatTimeResolution(resolution);
    const startTime = from * 1000;
    const endTime = to * 1000;

    try {
      const queryKey = terminalQueryKeys.chartFirstDate({ symbol: name, tradingServerId, timeframe });
      let firstDateData = queryClient.getQueryData(queryKey);

      if (!firstDateData) {
        firstDateData = await queryClient.fetchQuery(
          queryKey,
          () => getSymbolChartFirstDate({ symbol: name, tradingServerId, timeframe }),
          {
            staleTime: Infinity,
            cacheTime: Infinity,
          },
        );
      }

      // @ts-ignore
      const noData = dayjs(firstDateData).isAfter(startTime);

      const response: TerminalBarItemsContainer = await getSymbolCharts({
        symbol: name,
        tradingServerId,
        from: new Date(startTime).toISOString(),
        to: firstDataRequest ? undefined : new Date(endTime).toISOString(),
        timeframe,
      });

      // https://www.tradingview.com/charting-library-docs/latest/tutorials/implement_datafeed_tutorial/Datafeed-Implementation#:~:text=Implement%20getBars%20using%20parseFullSymbol%20and%20CryptoCompare%27s%20Daily%20Pair%20OHLCV%20to%20retrieve%20historic%20OHLCV%20data.
      let bars: Bar[] = [];
      response.items!.forEach(({ close, date, high, low, open }) => {
        const time = dayjs(date).unix() * 1000;
        if (time >= startTime && time < endTime) {
          bars = [
            ...bars,
            {
              time,
              low: low!,
              high: high!,
              open: open!,
              close: close!,
            },
          ];
        }
      });

      onResult(bars, { noData });
    } catch (error: any) {
      onError(error.errorMessage);
    }
  },
  subscribeBars: ({ name }, _, onTick) => {
    effect(() => {
      const data = $tick();
      if (data?.dt?.s === name) {
        const { t: timestamp, b: bid } = data.dt;
        // https://www.tradingview.com/charting-library-docs/latest/getting_started/Frequently-Asked-Questions#:~:text=9.%20What%20if%20I%20have%20a%20single%20price%20for%20each%20timestamp%3F
        onTick({ close: bid!, high: bid!, low: bid!, open: bid!, time: timestamp! });
      }
    });
  },
  unsubscribeBars: () => {},
});

export { getDatafeed };
