import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Box } from "@/components/box";
import { Button as GlobalButton } from "@/components/button";
import { ArrowLeftIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { NavigationItem } from "@/features/onboarding/components/navigation/navigation.helpers";
import { styled } from "@/styles";

const Wrapper = styled(Box, {
  m: "25px 0",
});

const LineWrapper = styled("div", {
  display: "flex",
  gap: "4px",
});

const Line = styled("div", {
  borderRadius: "50px",
  width: "100%",
  height: "4px",
  backgroundColor: "$bgDeep",

  variants: {
    filled: {
      true: {
        backgroundColor: "$primary",
      },
    },
  },
});

const Button = styled(GlobalButton, {
  fontSize: "12px",
  fontWeight: "$bolder",
  color: "$black",
  p: "8px 0 !important",

  "& svg": {
    width: "16px",
    height: "16px",
  },
});

type Props = {
  activeStep: string;
  setStep: (step: string) => void;
  navigation: NavigationItem[];
};

export const ProgressBar: FC<Props> = ({ activeStep, setStep, navigation }) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState<string>(activeStep);
  const currentStepIndex = useMemo<number>(
    () => navigation.findIndex(step => step.id === currentStep),
    [navigation, currentStep],
  );

  const isFirstStep = useMemo<boolean>(() => currentStepIndex === 0, [currentStepIndex]);
  const prevStep = useMemo<NavigationItem>(() => navigation[currentStepIndex - 1], [currentStepIndex, navigation]);

  const goPrev = () => {
    if (prevStep?.id) {
      track(amplitudeEvents.kyc.back(currentStepIndex + 1));
      setStep(prevStep.id);
    }
  };

  useEffect(() => {
    if (currentStep > activeStep) {
      const lastFilledItemIndex = navigation
        .slice()
        .reverse()
        .findIndex(({ filled }) => filled);
      const reversedIndex = navigation.length - 1 - lastFilledItemIndex;
      const redirectItem = lastFilledItemIndex !== -1 ? navigation[reversedIndex + 1] : null;

      if (redirectItem) {
        setCurrentStep(redirectItem.id);
        setStep(redirectItem.id);
      }
    } else {
      setCurrentStep(activeStep);
    }
  }, [activeStep, navigation, currentStep, setStep]);

  return (
    <>
      <Wrapper>
        <Box css={{ display: "flex", mb: "8px", alignItems: "center" }}>
          {!isFirstStep && (
            <Button variant="text" onClick={goPrev}>
              <ArrowLeftIcon />
              <Text family="roboto" size="0" css={{ ml: "auto", fontWeight: 700 }}>
                {t("onboarding.navigation.button-back")}: {navigation[currentStepIndex - 1].title}
              </Text>
            </Button>
          )}

          <Text family="roboto" size="0" css={{ ml: "auto", fontWeight: 700 }}>
            {currentStepIndex + 1}/{navigation.length}
          </Text>
        </Box>
        <LineWrapper>
          {navigation.map(item => (
            <Line key={item.id} filled={item.filled} />
          ))}
        </LineWrapper>
      </Wrapper>
    </>
  );
};
