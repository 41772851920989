import { kycService } from "../api";
// import { AddressUpdate, ProfileUpdate, SurveyUpdate } from "../openapi";
import {
  KycApiGetSumSubAccessTokenTokenRequest,
  KycApiUpdateProfileRequest,
  KycApiUpdateSurveyRequest,
} from "../openapi";

export const getKycInfo = async () => await (await kycService().getKycInfo()).data;

// export const updateAddress = async (values: AddressUpdate): Promise<any> =>
//   await kycService().updateAddress({ addressUpdate: values });

export const updateProfile = async (values: KycApiUpdateProfileRequest) =>
  await (
    await kycService().updateProfile(values)
  ).data;

export const getSumSubAccessTokenToken = async (values: KycApiGetSumSubAccessTokenTokenRequest) =>
  await (
    await kycService().getSumSubAccessTokenToken(values)
  ).data;

export const updateSurvey = async (values: KycApiUpdateSurveyRequest) =>
  await (
    await kycService().updateSurvey(values)
  ).data;
