import React from "react";

export const ChevronDownIcon = () => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2929 0.292969L13.7071 1.70718L7.00001 8.41429L0.292908 1.70718L1.70712 0.292969L7.00001 5.58586L12.2929 0.292969Z"
      fill="currentColor"
    />
  </svg>
);
