import { TFunction } from "i18next";

export const confirmPasswordRules = (t: TFunction, password: string) => ({
  validate: {
    matchesPreviousPassword: (value: string) => password === value || t("form-errors.confirm-password-error")!,
  },
});

export const twoFACodeRules = (t: TFunction) => ({
  pattern: {
    value: /^\d{6}$/,
    message: t("form-errors.contain-digits", { number: 6 }),
  },
  required: t("form-errors.required-error"),
});

export const twoFARecoveryRules = (t: TFunction) => ({
  required: t("form-errors.required-error"),
});
