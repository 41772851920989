import { FC, Fragment, useMemo } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { GlobalLoader } from "@/components/loader";
import { DemoToRealContainer } from "@/features/terminal/demo-to-real/demo-to-real.container";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { terminalAccountParamsName, terminalRoutes } from "@/routes/terminal.routes";
import { useAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useTerminalTokenQuery } from "@/state/server/terminal";

import { TerminalProvider } from "./contexts/terminal.provider";
import { TerminalLayoutContextProvider } from "./contexts/terminal-layout.context";
import { Terminal } from "./terminal";

const _TerminalPage: FC = () => {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get(terminalAccountParamsName);

  const location = useLocation();

  const isMobileAppMode = useMemo(() => location.pathname === terminalRoutes.terminalMobile, [location]);

  const {
    data,
    isSuccess,
    isError,
    isLoading: accountLoading,
  } = useAccountQuery(accountId!, { enabled: !!accountId, cacheTime: 0, refetchOnWindowFocus: false });

  const { data: bonusData, isLoading: bonusesLoading } = useBonusesQuery();

  const { data: socketToken, isLoading: tokenLoading } = useTerminalTokenQuery(accountId!, {
    enabled: data?.actions?.canUseTerminal,
    cacheTime: 0,
  });

  if (accountLoading || tokenLoading || bonusesLoading) {
    return <GlobalLoader />;
  }

  if (!accountId || isError || (isSuccess && !data.actions?.canUseTerminal)) {
    return <Navigate to={cabinetRoutes.dashboard} replace />;
  }

  return (
    <Fragment key={accountId + socketToken}>
      <TerminalProvider
        account={data!}
        bonuses={bonusData!.items!}
        token={socketToken!}
        isMobileAppMode={isMobileAppMode}
      >
        <DemoToRealContainer />
        <Terminal />
      </TerminalProvider>
    </Fragment>
  );
};

const TerminalPage: FC = () => {
  return (
    // we need to provide terminal layout as high as possible for correct subscriptions work
    <TerminalLayoutContextProvider>
      <_TerminalPage />
    </TerminalLayoutContextProvider>
  );
};

TerminalPage.displayName = "TerminalPage";

export default TerminalPage;
