import { ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { MobilePlaceOrderSection } from "../../order-section/order-section";
import { MobileOpenPriceField } from "./field";

type Props = ComponentProps<typeof MobileOpenPriceField>;

const MobileOpenPriceBlock: FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <MobilePlaceOrderSection
      title={t("terminal.place-order.open-price.title")}
      description={t("terminal.place-order.open-price.description")!}
    >
      <MobileOpenPriceField {...props} />
    </MobilePlaceOrderSection>
  );
};

export { MobileOpenPriceBlock };
