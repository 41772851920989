import { darkTheme, theme } from "@/styles";

type ChartColors = {
  area: string;
  hlcAreaLowColor: string;
  hlcAreaHighColor: string;
  text: string;
  background: string;
  positive: string;
  negative: string;
  iconClose: string;
  orderLineBackground: string;
  orderLineText: string;
  loadingBackground: string;
  loadingForeground: string;
};

// TODO:
const lightChartColors: ChartColors = {
  area: "rgba(8, 215, 79, 0.3)",
  hlcAreaLowColor: "rgba(255, 52, 52, 0.3)",
  hlcAreaHighColor: "rgba(8, 215, 79, 0.3)",
  text: theme.colors.textMain.value,
  background: theme.colors.bgPrimary.value,
  positive: theme.colors.positiveDefault.value,
  negative: theme.colors.negativeDefault.value,
  iconClose: theme.colors.textSecondary.value,
  orderLineBackground: theme.colors.bgAdditional.value,
  orderLineText: theme.colors.textInverse.value,
  loadingBackground: theme.colors.bgBack.value,
  loadingForeground: theme.colors.primary.value,
};

const darkChartColors: ChartColors = {
  area: "rgba(8, 215, 79, 0.3)",
  hlcAreaLowColor: "rgba(255, 52, 52, 0.3)",
  hlcAreaHighColor: "rgba(8, 215, 79, 0.3)",
  text: darkTheme.colors.textMain.value,
  background: darkTheme.colors.bgPrimary.value,
  positive: darkTheme.colors.positiveDefault.value,
  negative: darkTheme.colors.negativeDefault.value,
  iconClose: darkTheme.colors.textSecondary.value,
  orderLineBackground: darkTheme.colors.bgAdditional.value,
  orderLineText: darkTheme.colors.textInverse.value,
  loadingBackground: darkTheme.colors.bgBack.value,
  loadingForeground: theme.colors.primary.value,
};

const getChartColors = (isDarkTheme: boolean) => {
  return isDarkTheme ? darkChartColors : lightChartColors;
};

export { type ChartColors, getChartColors };
