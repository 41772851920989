import { FC, useCallback } from "react";

import { AddressInfo, FeatureTours } from "@/services/openapi";
import { ProfileInfo } from "@/services/openapi/models/profile-info";
import { useUpdateProfileMutation } from "@/state/server/kyc";
import { usePlatformCountriesQuery } from "@/state/server/platform";

import { NavigationTypes } from "../navigation/navigation.helpers";
import { IProfileFormValues, ProfileForm } from "./profile.form";

interface Props {
  profile: ProfileInfo;
  address: AddressInfo;
  setNextStep: () => void;
  navigationParams: NavigationTypes;
  featureTourInfo: FeatureTours;
}

export const ProfileContainer: FC<Props> = ({ setNextStep, profile, address, navigationParams, featureTourInfo }) => {
  const { mutateAsync: updateProfile } = useUpdateProfileMutation();
  const { data: countries } = usePlatformCountriesQuery();

  const submitProfile = useCallback(
    (values: IProfileFormValues) => {
      const newValues = { ...values };
      return updateProfile(
        { profileUpdate: newValues },
        {
          onSuccess: () => {
            setNextStep();
          },
        },
      );
    },
    [updateProfile, setNextStep],
  );

  return (
    <>
      {countries && (
        <ProfileForm
          profile={profile}
          address={address}
          onSubmit={submitProfile}
          unsupportedCountries={countries.unsupportedCountries!}
          navigationParams={navigationParams}
          featureTourInfo={featureTourInfo}
        />
      )}
    </>
  );
};
