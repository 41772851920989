import { FC, memo } from "react";

import { CloseIcon, SearchIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { useTranslation } from "@/hooks/translator.hook";

import { TextInput, TextInputExternalProps } from "../text";

type Props = Omit<TextInputExternalProps, "onChange"> & {
  onChange: (value: string) => void;
};

const _SearchInput: FC<Props> = props => {
  const { t } = useTranslation();
  const { value, onChange, ...restProps } = props;

  return (
    <TextInput
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={t("common.search")!}
      inputMode="search"
      leftAdornment={<SearchIcon />}
      rightAdornment={
        value ? (
          <UnstyledButton onClick={() => onChange("")} css={{ color: "$textMain" }}>
            <CloseIcon />
          </UnstyledButton>
        ) : undefined
      }
      {...restProps}
    />
  );
};

export const SearchInput = memo(_SearchInput);
