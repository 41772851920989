import { type FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { NumberInput, StepperInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../context";
import { PlaceOrderDepositButton } from "../deposit/button";

type Props = {
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  maxAvailableVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeMargin"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  currency: string;
  accountId: string;
  isDemoAccount: boolean;
  isMobile?: boolean;
};

const VolumeMarginFieldDescriptor: FC<Props> = ({
  changeLots,
  hasNoFreeMargin,
  volumeLotsError,
  minSystemVolumeLots,
  maxAvailableVolumeLots,
  minSystemVolumeMargin,
  maxAvailableVolumeMargin,
  maxBalanceVolumeMargin,
  maxBalanceVolumeLots,
  maxSystemVolumeMargin,
  maxSystemVolumeLots,
  currency,
  volumeMarginDecimalScale,
  isMobile,
  accountId,
  isDemoAccount,
}) => {
  // it isn't error
  if (hasNoFreeMargin) {
    return (
      <>
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.no-free-margin"
          values={{ currency }}
          components={{
            value: <NumberFormat value={minSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
          }}
        />{" "}
        <PlaceOrderDepositButton
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          currency={currency}
          isMobile={isMobile!}
        />
      </>
    );
  }

  return (
    <>
      {volumeLotsError === VolumeLotsError.NONE && maxAvailableVolumeLots !== minSystemVolumeLots && (
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.range"
          values={{ currency }}
          components={{
            fromButton: isMobile ? (
              <StepperInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />
            ) : (
              <NumberInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />
            ),
            fromValue: <NumberFormat value={minSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
            toButton: isMobile ? (
              <StepperInput.DescriptorButton onClick={() => changeLots(maxAvailableVolumeLots)} />
            ) : (
              <NumberInput.DescriptorButton onClick={() => changeLots(maxAvailableVolumeLots)} />
            ),
            toValue: <NumberFormat value={maxAvailableVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
          }}
        />
      )}
      {volumeLotsError === VolumeLotsError.LESS_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.min-system"
          values={{ currency }}
          components={{
            value: <NumberFormat value={minSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
            valueButton: isMobile ? (
              <StepperInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />
            ) : (
              <NumberInput.DescriptorButton onClick={() => changeLots(minSystemVolumeLots)} />
            ),
          }}
        />
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_FREE_MARGIN && (
        <>
          <Trans
            i18nKey="terminal.place-order.margin.input-descriptor.more-than-free-margin"
            values={{ currency }}
            components={{
              value: <NumberFormat value={maxBalanceVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
              valueButton: isMobile ? (
                <StepperInput.DescriptorButton onClick={() => changeLots(maxBalanceVolumeLots)} />
              ) : (
                <NumberInput.DescriptorButton onClick={() => changeLots(maxBalanceVolumeLots)} />
              ),
            }}
          />{" "}
          <PlaceOrderDepositButton
            accountId={accountId}
            isDemoAccount={isDemoAccount}
            currency={currency}
            isMobile={isMobile!}
          />
        </>
      )}
      {volumeLotsError === VolumeLotsError.MORE_THAN_SYSTEM && (
        <Trans
          i18nKey="terminal.place-order.margin.input-descriptor.max-system"
          values={{ currency }}
          components={{
            value: <NumberFormat value={maxSystemVolumeMargin} decimalScale={volumeMarginDecimalScale} />,
            valueButton: isMobile ? (
              <StepperInput.DescriptorButton onClick={() => changeLots(maxSystemVolumeLots)} />
            ) : (
              <NumberInput.DescriptorButton onClick={() => changeLots(maxSystemVolumeLots)} />
            ),
          }}
        />
      )}
    </>
  );
};

export { VolumeMarginFieldDescriptor };
