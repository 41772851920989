import { FC } from "react";
import { NavLink } from "react-router-dom";

import { ChevronDownIcon } from "@/components/icons";
import { Loader } from "@/components/loader";
import { useTranslation } from "@/hooks/translator.hook";
import { useIsReadOnly } from "@/state/server/profile/profile.hooks";
import { darkTheme, styled } from "@/styles/stitches.config";

import { CabinetNavItemType } from "./cabinet-navigation.helpers";

const Li = styled("li", {
  display: "flex",
  alignItems: "center",
  gap: "12px",
  p: "16px",
  cursor: "pointer",
  borderRadius: "40px",
  elementTransition: "background",
  whiteSpace: "nowrap",
  fontWeight: "$bolder",
  fontSize: "16px",
  color: "$lite",
  fontFamily: "$gilroy",
  minHeight: "57px",

  "@bp3": {
    "&:hover": {
      background: darkTheme.colors.bgSelected.value,
    },
  },

  variants: {
    active: {
      true: {
        background: darkTheme.colors.bgSelected.value,
      },
    },
  },
});

const IconContainer = styled("div", {
  width: "24px",
  display: "grid",
  placeItems: "center",
  "svg path": {
    fill: "$lite",
  },
});

const ChevronWrapper = styled("div", {
  ml: "auto",
  elementTransition: "opacity",
});

const ChevronIconWrapper = styled("div", {
  elementTransition: "transform",
});

const ItemName = styled("span", {
  elementTransition: "opacity",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const CabinetNavItem: FC<
  CabinetNavItemType & { onClose?: () => void; active?: boolean; analyticsEvent?: () => void; isLoading?: boolean }
> = ({
  Icon,
  title,
  action,
  link,
  blank,
  checkReadOnly,
  iconOnly,
  withChevron,
  active,
  onClose,
  analyticsEvent,
  isLoading,
}) => {
  const { t } = useTranslation();
  const isReadOnly = useIsReadOnly();

  if (checkReadOnly && isReadOnly) {
    return null;
  }

  if (action) {
    return (
      <Li onClick={action} active={active} data-test={`navlink-${title}`}>
        {isLoading ? (
          <div className="grid size-full place-items-center">
            <Loader css={{ fill: "$white" }} />
          </div>
        ) : (
          <>
            <IconContainer>
              <Icon />
            </IconContainer>
            <ItemName css={{ opacity: `${iconOnly ? 0 : 1}` }}>{t(`cabinet.navigation.${title}`)}</ItemName>

            {withChevron && (
              <ChevronWrapper css={{ opacity: `${iconOnly ? 0 : 1}` }}>
                <ChevronIconWrapper css={{ transform: `rotate(${withChevron === "up" ? "0" : " 180deg"})` }}>
                  <ChevronDownIcon />
                </ChevronIconWrapper>
              </ChevronWrapper>
            )}
          </>
        )}
      </Li>
    );
  }

  return (
    <NavLink
      to={link!}
      target={blank ? "_blank" : undefined}
      onClick={() => {
        onClose && onClose();
        analyticsEvent && analyticsEvent();
      }}
      data-test={`navlink-${title}`}
    >
      {({ isActive }) => (
        <Li active={isActive}>
          <IconContainer>
            <Icon />
          </IconContainer>
          <ItemName css={{ opacity: `${iconOnly ? 0 : 1}` }}>{t(`cabinet.navigation.${title}`)}</ItemName>
        </Li>
      )}
    </NavLink>
  );
};
