import { FC, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { amplitudeEvents, amplitudeSetUserId, track } from "@/app/libs/amplitude";
import { Footer } from "@/components/footer";
import { Grid } from "@/components/grid";
import { NoAccountsDialog } from "@/components/no-accounts-dialog";
import { SidebarContent, sidebarWidthExpanded } from "@/components/sidebar";
import { LanguageSwitcherContainer } from "@/features/language-switcher/language-switcher.container";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { styled } from "@/styles/stitches.config";

const Wrapper = styled(Grid, {
  height: "100%",
});

const CabinetContent = styled(Grid, {
  background: "$bgBack",
  boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",

  "@bp3": {
    gridTemplateRows: "1fr auto",
    ml: sidebarWidthExpanded,
  },
});

const Main = styled("main", {
  maxWidth: "100vw",

  pb: "30px",
  px: "$containerXPadding",

  "@bp3": {
    p: "40px 64px",
    maxWidth: `calc(100vw - ${sidebarWidthExpanded}px)`,
  },
});

export const CabinetLayout: FC = () => {
  const { id: userId } = useProfileData();
  const { isDesktop } = useScreenSize();
  const { pathname } = useLocation();
  const [languageSwitcherOpen, setLanguageSwitcherOpen] = useState(false);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: userId,
    });

    if (userId) {
      amplitudeSetUserId(userId);
    }
  }, [userId]);

  const analyticsEventOnDeposit = () => {
    if (pathname === cabinetRoutes.dashboard) {
      track(amplitudeEvents.deposit.fromMyAccountsMenu);
    }
  };

  return (
    <>
      <Wrapper>
        {isDesktop && (
          <SidebarContent
            analyticsEventOnDeposit={analyticsEventOnDeposit}
            onLanguageDialogOpen={() => setLanguageSwitcherOpen(true)}
          />
        )}
        <LanguageSwitcherContainer onOpenChange={setLanguageSwitcherOpen} open={languageSwitcherOpen} />

        <CabinetContent>
          <Main>
            <Outlet />
          </Main>
          <Footer />
        </CabinetContent>
      </Wrapper>
      <NoAccountsDialog />
    </>
  );
};
