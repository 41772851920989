import {
  AccountsIcon,
  AutoTerminalIcon,
  DepositIcon,
  ProfileIcon,
  TransactionHistoryIcon,
  TransferIcon,
  WithdrawalIcon,
} from "@/components/icons";
import { IconDeposit, IconWithdraw } from "@/domains/icons";
import { cabinetRoutes } from "@/routes/cabinet.routes";

export type CabinetNavItemType = {
  Icon: React.ComponentType<any>;
  title: string;
  link?: string;
  action?: () => void;
  blank?: boolean;
  checkReadOnly?: boolean;
  iconOnly?: boolean;
  withChevron?: "up" | "down";
};

export type TradingNavItemType = {
  title: string;
  link?: string;
  action?: () => void;
  blank?: boolean;
  checkReadOnly?: boolean;
};

export const cabinetTopNavItems: CabinetNavItemType[] = [
  { Icon: AccountsIcon, link: cabinetRoutes.dashboard, title: "accounts" },
];

export const sidebarPaymentsNavItems: CabinetNavItemType[] = [
  { Icon: IconDeposit, link: cabinetRoutes.deposit, title: "deposit", checkReadOnly: true },
  { Icon: IconWithdraw, link: cabinetRoutes.withdrawal, title: "withdrawal", checkReadOnly: true },
  { Icon: TransferIcon, link: cabinetRoutes.transfer, title: "transfer", checkReadOnly: true },
  { Icon: TransactionHistoryIcon, link: cabinetRoutes.transactionHistory, title: "transaction-history" },
];

export const mobileAppSidebarPaymentsNavItems: CabinetNavItemType[] = [
  { Icon: DepositIcon, link: cabinetRoutes.deposit, title: "deposit", checkReadOnly: true },
  { Icon: WithdrawalIcon, link: cabinetRoutes.withdrawal, title: "withdrawal", checkReadOnly: true },
  { Icon: TransactionHistoryIcon, link: cabinetRoutes.transactionHistory, title: "transaction-history" },
];

export const tradingTopNavItems: TradingNavItemType[] = [
  { link: cabinetRoutes.dashboard, title: "accounts" },
  { link: cabinetRoutes.deposit, title: "deposit", checkReadOnly: true },
  { link: cabinetRoutes.withdrawal, title: "withdrawal", checkReadOnly: true },
  { link: cabinetRoutes.transfer, title: "transfer", checkReadOnly: true },
];

export const cabinetBottomNavItems: CabinetNavItemType[] = [
  { Icon: ProfileIcon, link: cabinetRoutes.profileSettings, title: "profile-settings" },
];
