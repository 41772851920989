import { FC } from "react";

import { Box } from "@/components/box";
import { PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";

enum NewRecoveryCodesFields {
  PASSWORD = "password",
}

export interface INewRecoveryCodesFormValues {
  [NewRecoveryCodesFields.PASSWORD]: string;
}

interface Props {
  onSubmit: (values: INewRecoveryCodesFormValues) => void;
}

export const NewRecoveryCodesForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useHookForm<INewRecoveryCodesFormValues>();

  return (
    <HookForm onSubmit={onSubmit} form={form}>
      <Stack>
        <PasswordField name={NewRecoveryCodesFields.PASSWORD} />
        <Box>
          <SubmitButton css={{ whiteSpace: "pre-wrap" }}>{t("two-factor.settings.create-new-codes")}</SubmitButton>
        </Box>
      </Stack>
    </HookForm>
  );
};
