import { FC } from "react";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { useTranslation } from "@/hooks/translator.hook";

import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";
import { TransactionsTableContainer } from "./components/transactions-table/transactions-table.container";

export const TransactionsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.transaction-history")}>
          <CabinetTitle.DepositButton onClick={() => track(amplitudeEvents.transcationHistory.depositClick)} />
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      <TransactionsTableContainer />
    </CabinetPage>
  );
};
