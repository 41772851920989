import { cva } from "class-variance-authority";
import { FC } from "react";

import { MarginFreeFormat, NumberFormat, PnlFormat } from "@/app/components";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { useTranslation } from "@/hooks/translator.hook";
import { BonusUserPlatform } from "@/services/openapi";
import { cn } from "@/shared/styles";

import { InternetConnectionInfo } from "../components/internet-connection/internet-connection-info";
import { getNumberColorClassname } from "../terminal.styles-helpers";

const textStyles = cva("font-roboto text-[12px] leading-[1.5] text-text", {
  variants: {
    dimmed: {
      true: "text-text-secondary",
    },
  },
});

const LabeledValue: FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => {
  return (
    <div className="flex items-center gap-1">
      <div className={textStyles({ dimmed: true })}>{label}</div>
      {value}
    </div>
  );
};

type Props = {
  equity: number;
  marginLevel: number;
  leverage: number;
  pnl: number;
  currency: string;
  currencyDecimalScale: number;
  marginFree: number;
  credit: number;
  bonus: BonusUserPlatform | null;
  showBonus: boolean;
};

const DesktopAccountSummary: FC<Props> = ({
  currency,
  equity,
  leverage,
  marginLevel,
  pnl,
  currencyDecimalScale,
  marginFree,
  bonus,
  showBonus,
  credit,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-end gap-4 rounded-[8px] bg-bg-back px-4 py-1">
      <LabeledValue
        label={t("terminal.account-summary.free-margin")}
        value={
          <div className={textStyles()}>
            <MarginFreeFormat value={marginFree} currency={currency} decimalScale={currencyDecimalScale} />
          </div>
        }
      />
      <LabeledValue
        label={t("terminal.account-summary.equity")}
        value={
          <div className={textStyles()}>
            <NumberFormat value={equity} currency={currency} decimalScale={currencyDecimalScale} />
          </div>
        }
      />
      {bonus && showBonus && (
        <LabeledValue
          label={t("terminal.account-summary.bonus.name")}
          value={
            <div className="flex items-center gap-2">
              <div className={textStyles()}>
                <NumberFormat value={credit} currency={currency} decimalScale={currencyDecimalScale} />
              </div>
              <BonusTooltip
                bonus={bonus}
                actionText={t("terminal.account-summary.bonus.button")}
                content={t("terminal.account-summary.bonus.description", { percent: getBonusMainPercent(bonus) })}
              />
            </div>
          }
        />
      )}
      <LabeledValue
        label={t("terminal.account-summary.margin-level")}
        value={
          <div className={textStyles()}>
            <NumberFormat value={marginLevel} decimalScale={2} suffix="%" />
          </div>
        }
      />
      <LabeledValue
        label={t("terminal.account-summary.leverage")}
        value={<div className={textStyles()}>1:{leverage}</div>}
      />
      <LabeledValue
        label={t("terminal.account-summary.pnl")}
        value={
          <div className={cn(textStyles(), getNumberColorClassname(pnl))}>
            <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
          </div>
        }
      />
      <InternetConnectionInfo />
    </div>
  );
};

export { DesktopAccountSummary };
