import useWebSocket, { Options } from "react-use-websocket";

import { TerminalEvent } from "@/services/openapi";

import { useSocketContext } from "../contexts/socket.context";

export const useTerminalSocket = (options?: Options) => {
  const { socketUrl } = useSocketContext();
  // @ts-ignore
  return useWebSocket<TerminalEvent>(socketUrl, {
    ...options,
    share: true,
  });
};
