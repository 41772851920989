import { createContext, FC, memo, ReactNode, useCallback, useContext, useMemo } from "react";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { ActivityFront, TradingAccountTradeMode, TradingAccountType } from "@/services/openapi";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { useUpdateActivityMutation } from "@/state/server/profile/profile.mutations";

import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalNavigate } from "../helpers";
import { FeatureTour } from "./feature-tour";
import {
  getFeatureTourCurrentStep,
  getTotalFeatureTourSteps,
  trackChangeVolumeMode,
  trackChartExamine,
  trackCheckFreeMargin,
  trackCloseOrder,
  trackIncrementOrder,
  trackPlaceOrder,
  trackRepeatTour,
  trackSelectSymbol,
  trackStartTour,
  trackTerminateTour,
} from "./helpers";
import { useFeatureTour } from "./reducer";
import {
  getChangeVolumeModeStyles,
  getChartStyles,
  getCheckFreeMarginStyles,
  getCloseOrderStyles,
  getIncrementOrderStyles,
  getPlaceOrderStyles,
  getSelectSymbolStyles,
} from "./styles";
import { FeatureTourSteps } from "./types";

type ContextProps = {
  // states
  isFeatureTourDisabled: boolean;
  isFeatureTourIdle: boolean;
  /* isForkStepActive: boolean; */
  isWelcomeStepActive: boolean;
  isTourInterrupted: boolean;
  isSelectSymbolStepActive: boolean;
  isChartStepActive: boolean;
  isIncrementOrderStepActive: boolean;
  isPlaceOrderStepActive: boolean;
  isCheckFreeMarginStepActive: boolean;
  isCloseOrderStepActive: boolean;
  isChangeVolumeModeStepActive: boolean;
  isFinalStepActive: boolean;
  // actions
  /* forkReal: () => void;
  forkDemo: () => void; */
  startTour: () => void;
  interruptTour: () => void;
  continueTour: () => void;
  terminateTour: () => void;
  selectSymbol: () => void;
  chartExamine: () => void;
  incrementOrder: () => void;
  placeOrder: () => void;
  checkFreeMargin: () => void;
  closeOrder: (closeOrder?: boolean) => void;
  changeVolumeMode: () => void;
  repeatTour: () => void;
  finishTour: () => void;
  tradeReal: () => void;
  leaveIdleState: () => void;
  // styles
  chartStyles: ReturnType<typeof getChartStyles>;
  selectSymbolStyles: ReturnType<typeof getSelectSymbolStyles>;
  incrementOrderStyles: ReturnType<typeof getIncrementOrderStyles>;
  placeOrderStyles: ReturnType<typeof getPlaceOrderStyles>;
  checkFreeMarginStyles: ReturnType<typeof getCheckFreeMarginStyles>;
  closeOrderStyles: ReturnType<typeof getCloseOrderStyles>;
  changeVolumeModeStyles: ReturnType<typeof getChangeVolumeModeStyles>;
  // other
  isLoading: boolean;
  isMobile: boolean;
  currentStep: number;
  totalSteps: number;
  tradeMode: TradingAccountTradeMode;
};

const Context = createContext<ContextProps | undefined>(undefined);

const _Provider: FC<{
  children: ReactNode;
  accountType: TradingAccountType;
  isMobileAppMode: boolean;
  tradeMode: TradingAccountTradeMode;
}> = ({ children, accountType, isMobileAppMode, tradeMode }) => {
  const navigate = useTerminalNavigate(isMobileAppMode);

  const { isMobile, closeTable } = useTerminalLayout();

  const profile = useProfileData();

  const [state, dispatch] = useFeatureTour({ accountType, isMobileAppMode, profile });
  const { mutate: updateActivity, isLoading } = useUpdateActivityMutation();

  // other
  const isMarginMode = tradeMode === TradingAccountTradeMode.Margin;

  const currentStep = useMemo(
    () => getFeatureTourCurrentStep({ step: state.currentStep, isMobile, tradeMode }),
    [state.currentStep, isMobile, tradeMode],
  );
  const previousStep = useMemo(
    () => getFeatureTourCurrentStep({ step: state.previousStep, isMobile, tradeMode }),
    [state.previousStep, isMobile, tradeMode],
  );
  const totalSteps = useMemo(() => getTotalFeatureTourSteps({ isMobile, tradeMode }), [isMobile, tradeMode]);

  // states
  const isFeatureTourDisabled = state.currentStep === FeatureTourSteps.NONE;
  const isFeatureTourIdle = state.currentStep === FeatureTourSteps.IDLE;
  const isWelcomeStepActive = state.currentStep === FeatureTourSteps.WELCOME;
  const isTourInterrupted = state.currentStep === FeatureTourSteps.INTERRUPT;
  const isSelectSymbolStepActive = state.currentStep === FeatureTourSteps.SELECT_SYMBOL;
  const isChartStepActive = state.currentStep === FeatureTourSteps.CHART;
  const isIncrementOrderStepActive = state.currentStep === FeatureTourSteps.INCREMENT_ORDER;
  const isPlaceOrderStepActive = state.currentStep === FeatureTourSteps.PLACE_ORDER;
  const isCheckFreeMarginStepActive = state.currentStep === FeatureTourSteps.CHECK_FREE_MARGIN;
  const isCloseOrderStepActive = state.currentStep === FeatureTourSteps.CLOSE_ORDER;
  const isChangeVolumeModeStepActive = state.currentStep === FeatureTourSteps.CHANGE_VOLUME_MODE;
  const isFinalStepActive = state.currentStep === FeatureTourSteps.FINAL;

  // actions
  const startTour: ContextProps["startTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "START_TOUR" });
    trackStartTour({ isMobile, isMarginMode });
  }, [isMobile, isMarginMode, dispatch, isFeatureTourDisabled]);

  const interruptTour: ContextProps["interruptTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "INTERRUPT_TOUR" });
  }, [dispatch, isFeatureTourDisabled]);

  const continueTour: ContextProps["continueTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "CONTINUE_TOUR" });
  }, [dispatch, isFeatureTourDisabled]);

  const selectSymbol: ContextProps["selectSymbol"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "SELECT_SYMBOL" });
    trackSelectSymbol({ isMobile, isMarginMode, currentStep });
  }, [dispatch, isFeatureTourDisabled, isMarginMode, isMobile, currentStep]);

  const chartExamine: ContextProps["chartExamine"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "CHART_EXAMINE" });
    trackChartExamine({ isMobile, isMarginMode, currentStep });
  }, [dispatch, isFeatureTourDisabled, isMobile, isMarginMode, currentStep]);

  const incrementOrder: ContextProps["incrementOrder"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "INCREMENT_ORDER" });
    trackIncrementOrder({ isMobile, isMarginMode, currentStep });
  }, [isMobile, isMarginMode, dispatch, isFeatureTourDisabled, currentStep]);

  const placeOrder: ContextProps["placeOrder"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "PLACE_ORDER", payload: { isMobile, tradeMode } });
    trackPlaceOrder({ isMobile, isMarginMode, currentStep });
  }, [isMobile, isMarginMode, dispatch, tradeMode, isFeatureTourDisabled, currentStep]);

  const checkFreeMargin: ContextProps["checkFreeMargin"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "CHECK_FREE_MARGIN", payload: { isMobile } });
    trackCheckFreeMargin({ isMobile, isMarginMode, currentStep });
  }, [dispatch, isMobile, isMarginMode, isFeatureTourDisabled, currentStep]);

  const closeOrder: ContextProps["closeOrder"] = useCallback(
    (closeOrder = false) => {
      if (isFeatureTourDisabled) return;

      if (isMobile) {
        closeTable();
      }
      dispatch({ type: "CLOSE_ORDER", payload: { tradeMode, closeOrder } });
      trackCloseOrder({ isMobile, isMarginMode, currentStep });
    },
    [isMobile, isMarginMode, dispatch, closeTable, tradeMode, isFeatureTourDisabled, currentStep],
  );

  const changeVolumeMode: ContextProps["changeVolumeMode"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "CHANGE_VOLUME_MODE" });
    trackChangeVolumeMode({ isMobile, isMarginMode, currentStep });
  }, [isMobile, isMarginMode, dispatch, isFeatureTourDisabled, currentStep]);

  const repeatTour: ContextProps["repeatTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "REPEAT_TOUR" });
    trackRepeatTour({ isMobile, isMarginMode });
  }, [dispatch, isFeatureTourDisabled, isMobile, isMarginMode]);

  const terminateTour: ContextProps["terminateTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    updateActivity(
      { activityRequest: { activity: ActivityFront.TourInterrupted } },
      {
        onSuccess: () => {
          dispatch({ type: "TERMINATE_TOUR" });
          trackTerminateTour({ isMobile, isMarginMode, previousStep });
        },
      },
    );
  }, [dispatch, previousStep, updateActivity, isFeatureTourDisabled, isMarginMode, isMobile]);

  const finishTour: ContextProps["finishTour"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    updateActivity(
      { activityRequest: { activity: ActivityFront.TourCompleted } },
      {
        onSuccess: () => {
          dispatch({ type: "FINISH_TOUR" });
        },
      },
    );
  }, [dispatch, updateActivity, isFeatureTourDisabled]);

  const tradeReal: ContextProps["tradeReal"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    updateActivity(
      { activityRequest: { activity: ActivityFront.TourCompleted } },
      {
        onSuccess: () => {
          dispatch({ type: "REAL_TRADE" });
          navigate(cabinetRoutes.deposit);
        },
      },
    );
  }, [dispatch, updateActivity, navigate, isFeatureTourDisabled]);

  const leaveIdleState: ContextProps["leaveIdleState"] = useCallback(() => {
    if (isFeatureTourDisabled) return;

    dispatch({ type: "LEAVE_IDLE" });
  }, [dispatch, isFeatureTourDisabled]);

  // styles
  const selectSymbolStyles = useMemo(() => getSelectSymbolStyles(isSelectSymbolStepActive), [isSelectSymbolStepActive]);

  const chartStyles = useMemo(() => getChartStyles(isChartStepActive), [isChartStepActive]);

  const incrementOrderStyles = useMemo(
    () => getIncrementOrderStyles(isIncrementOrderStepActive),
    [isIncrementOrderStepActive],
  );

  const placeOrderStyles = useMemo(() => getPlaceOrderStyles(isPlaceOrderStepActive), [isPlaceOrderStepActive]);

  const checkFreeMarginStyles = useMemo(
    () => getCheckFreeMarginStyles(isCheckFreeMarginStepActive),
    [isCheckFreeMarginStepActive],
  );

  const closeOrderStyles = useMemo(() => getCloseOrderStyles(isCloseOrderStepActive), [isCloseOrderStepActive]);

  const changeVolumeModeStyles = useMemo(
    () => getChangeVolumeModeStyles(isChangeVolumeModeStepActive),
    [isChangeVolumeModeStepActive],
  );

  const value: ContextProps = useMemo(
    () => ({
      isFeatureTourDisabled,
      isFeatureTourIdle,
      /* isForkStepActive, */
      isWelcomeStepActive,
      isTourInterrupted,
      isSelectSymbolStepActive,
      isChartStepActive,
      isIncrementOrderStepActive,
      isPlaceOrderStepActive,
      isCheckFreeMarginStepActive,
      isCloseOrderStepActive,
      isChangeVolumeModeStepActive,
      isFinalStepActive,
      /* forkReal,
      forkDemo, */
      startTour,
      interruptTour,
      continueTour,
      terminateTour,
      selectSymbol,
      chartExamine,
      incrementOrder,
      placeOrder,
      checkFreeMargin,
      closeOrder,
      changeVolumeMode,
      repeatTour,
      finishTour,
      tradeReal,
      leaveIdleState,
      selectSymbolStyles,
      chartStyles,
      incrementOrderStyles,
      placeOrderStyles,
      checkFreeMarginStyles,
      closeOrderStyles,
      changeVolumeModeStyles,
      isLoading,
      isMobile,
      currentStep,
      totalSteps,
      tradeMode,
    }),
    [
      isFeatureTourDisabled,
      isFeatureTourIdle,
      /* isForkStepActive, */
      isWelcomeStepActive,
      isTourInterrupted,
      isSelectSymbolStepActive,
      isChartStepActive,
      isIncrementOrderStepActive,
      isPlaceOrderStepActive,
      isCheckFreeMarginStepActive,
      isCloseOrderStepActive,
      isChangeVolumeModeStepActive,
      isFinalStepActive,
      /* forkReal,
      forkDemo, */
      startTour,
      interruptTour,
      continueTour,
      terminateTour,
      selectSymbol,
      chartExamine,
      incrementOrder,
      placeOrder,
      checkFreeMargin,
      closeOrder,
      changeVolumeMode,
      repeatTour,
      finishTour,
      tradeReal,
      leaveIdleState,
      selectSymbolStyles,
      chartStyles,
      incrementOrderStyles,
      placeOrderStyles,
      checkFreeMarginStyles,
      closeOrderStyles,
      changeVolumeModeStyles,
      isLoading,
      isMobile,
      currentStep,
      totalSteps,
      tradeMode,
    ],
  );

  return (
    <Context.Provider value={value}>
      <FeatureTour tour={value} />
      {children}
    </Context.Provider>
  );
};

const Provider = memo(_Provider);

Provider.displayName = "TerminalFeatureTourContextProvider";

const useTerminalFeatureTourContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalFeatureTourContext must be used within a TerminalFeatureTourContextProvider");
  }

  return context;
};

export { Provider as TerminalFeatureTourContextProvider, useTerminalFeatureTourContext };
