import { Anchor, Arrow, Content, PopoverProps, Portal as PopoverPortal, Root } from "@radix-ui/react-popover";
import { Portal } from "@radix-ui/react-portal";
import React, { ComponentPropsWithoutRef } from "react";

import { cn } from "@/shared/styles";
import { overlayStyles, popoverContentStyles } from "@/shared/ui";

type Props = ComponentPropsWithoutRef<typeof Content> &
  Pick<PopoverProps, "onOpenChange"> & {
    content: React.ReactNode;
    usePortal?: boolean;
  };

const HintPopover: React.FC<Props> = ({
  children,
  content,
  onOpenChange,
  className,
  collisionPadding = 10,
  usePortal = true,
  ...props
}) => {
  const overlayElement = <div className={overlayStyles()} />;

  const contentElement = (
    <Content
      collisionPadding={collisionPadding}
      // z-index must be higher than default popper components and highlight elements
      className={cn(popoverContentStyles(), "!pointer-events-auto z-70 w-full max-w-[300px]", className)}
      {...props}
    >
      {content}
      <Arrow width={16} height={8} className="fill-bg-additional" />
    </Content>
  );

  return (
    <Root modal open onOpenChange={onOpenChange}>
      <Anchor asChild>{children}</Anchor>
      {usePortal ? (
        <>
          <Portal asChild>{overlayElement}</Portal>
          <PopoverPortal>{contentElement}</PopoverPortal>
        </>
      ) : (
        <>
          {overlayElement}
          {contentElement}
        </>
      )}
    </Root>
  );
};

export { HintPopover };
