import { styled } from "@/styles";

export const Badge = styled("div", {
  fontFamily: "$roboto",
  fontWeight: "$normal",
  display: "inline-flex",
  alignItems: "center",
  border: "1px solid",
  p: "4px 8px",
  borderRadius: "8px",

  variants: {
    size: {
      small: {
        lineHeight: "$1",
        fontSize: "14px",
      },
      middle: {
        lineHeight: "$3",
        fontSize: "16px",
      },
    },
    color: {
      black: {
        color: "$textMain",
        borderColor: "$bgBorder",
      },
      orange: {
        color: "$warningDefault",
        borderColor: "$warningDefault",
        background: "$bgPrimary",
      },
      blue: {
        color: "$blue",
        borderColor: "$blue",
        background: "$bgPrimary",
      },
      green: {
        color: "$positiveDefault",
        borderColor: "$positiveDefault",
        background: "$bgPrimary",
      },
      gray: {
        color: "$textSecondary",
        borderColor: "$textSecondary",
        background: "$bgPrimary",
      },
      red: {
        color: "$negativeDefault",
        borderColor: "$negativeDefault",
        background: "$bgPrimary",
      },
    },
  },

  defaultVariants: {
    size: "middle",
    color: "black",
  },
});
