import React, { useMemo } from "react";

import { useExtendedOrdersContext } from "../../contexts/extended-orders.context";
import { useOrdersContext } from "../../contexts/orders.context";
import { MergedTerminalSymbol } from "../../helpers/symbols";
import { ChartColors } from "../config/colors";
import { ChartOpenOrder } from "./open";
import { ChartPendingOrder } from "./pending";

type Props = {
  symbolInfo: MergedTerminalSymbol;
  accountCurrency: string;
  accountId: string;
  currencyDecimalScale: number;
  chartColors: ChartColors;
};

const ChartOrders: React.FC<Props> = ({
  symbolInfo,
  accountCurrency,
  accountId,
  chartColors,
  currencyDecimalScale,
}) => {
  const { pendingOrders } = useOrdersContext();
  const { extendedOpenOrders } = useExtendedOrdersContext();

  const pendingSymbolOrders = useMemo(
    () => pendingOrders.filter(({ symbol }) => symbol === symbolInfo.symbol),
    [pendingOrders, symbolInfo.symbol],
  );

  const openSymbolOrders = useMemo(
    () => extendedOpenOrders.filter(({ symbol }) => symbol === symbolInfo.symbol),
    [extendedOpenOrders, symbolInfo.symbol],
  );

  return (
    <>
      {pendingSymbolOrders.map(order => (
        <ChartPendingOrder
          key={order.id}
          order={order}
          accountId={accountId}
          volumeDecimalScale={symbolInfo.volumeDecimalScale!}
          chartColors={chartColors}
        />
      ))}
      {openSymbolOrders.map(order => (
        <ChartOpenOrder
          key={order.id}
          order={order}
          accountId={accountId}
          accountCurrency={accountCurrency}
          chartColors={chartColors}
          currencyDecimalScale={currencyDecimalScale}
        />
      ))}
    </>
  );
};

ChartOrders.displayName = "ChartOrders";

export { ChartOrders };
