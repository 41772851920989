import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "@/hooks/localstorage.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useResetPasswordMutation } from "@/state/server/auth";

import { TwoFactorMode } from "../two-factor/two-factor.helpers";
import { ITwoFARecoveryFormValues, TwoFactorRecoveryForm } from "./two-factor-recovery.form";

export const TwoFactorRecoveryResetContainer: FC = () => {
  const [resetValues, setResetValues] = useLocalStorage(TwoFactorMode.RESET_PASSWORD, {});

  const navigate = useNavigate();
  const { mutateAsync: resetPassword } = useResetPasswordMutation();

  const submitReset = useCallback(
    ({ recoveryCode }: ITwoFARecoveryFormValues) => {
      return resetPassword(
        { passwordResetRequest: { ...resetValues, recoveryCode } },
        {
          onSuccess: () => {
            setResetValues({});
            navigate(cabinetRoutes.dashboard);
          },
        },
      );
    },
    [resetPassword, navigate, resetValues],
  );

  return <TwoFactorRecoveryForm onSubmit={submitReset} />;
};
