import { FC } from "react";

import image from "@/assets/images/DialogImage1.svg";
import { ClockIcon, MailIcon } from "@/components/icons";
import { InfoDialog, InfoDialogContent } from "@/components/info-dialog";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { styled } from "@/styles";

const Li = styled("li", {
  display: "flex",
  gap: "16px",
  alignItems: "center",
  color: "$textMain",
});

type Props = {
  onDepositFunds: () => void;
};

export const VerificationDialogContent: FC<Props> = ({ onDepositFunds }) => {
  const { t } = useTranslation();
  return (
    <InfoDialog
      imageSrc={image}
      title={t("verification.dialog-title")}
      description={t("verification.dialog-description")}
    >
      <InfoDialogContent>
        <Stack as="ul">
          <Li>
            <ClockIcon />
            <Text family="roboto">{t("verification.dialog-text-1")}</Text>
          </Li>
          <Li>
            <MailIcon />
            <Text family="roboto">{t("verification.dialog-text-2")}</Text>
          </Li>
        </Stack>
      </InfoDialogContent>
    </InfoDialog>
  );
};
