import dayjs from "dayjs";

import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import IconClockCircle from "@/features/terminal/place-order/components/trading-unavailable/assets/clock-circle.svg";
import { useTranslation } from "@/hooks/translator.hook";

import * as Styled from "./trading-reopen-message.styled";

export const TradingReopenMessage = () => {
  const { t, Trans } = useTranslation();
  const { isTradingAvailable, currentSymbol } = useCurrentSymbolContext();

  const currentDate = dayjs();
  const openDate = dayjs(isTradingAvailable.remainingTime);
  const timeLeftHours = openDate.diff(currentDate, "hour");
  const timeLeftMinutes = dayjs(openDate.diff(currentDate)).format("mm");
  const date = openDate.format("MMM D");
  const time = openDate.format("HH:mm");
  const timezone = openDate.format("Z");

  const isDate = timeLeftHours > 24;
  const isToday = timeLeftHours >= 1 && timeLeftHours <= 24;
  const isTime = timeLeftHours === 0;

  return (
    <Styled.Block>
      <img src={IconClockCircle} alt="clock-circle" loading="lazy" width="24" height="24" />
      <p>
        <Trans>
          {isDate && t("terminal.trading-unavailable.reopen-message.date", { currentSymbol, date, time, timezone })}
          {isToday && t("terminal.trading-unavailable.reopen-message.today-at", { currentSymbol, time, timezone })}
          {isTime &&
            t("terminal.trading-unavailable.reopen-message.time", {
              currentSymbol,
              time: timeLeftMinutes,
            })}
        </Trans>
      </p>
    </Styled.Block>
  );
};
