import { Flex } from "@/components/flex";
import { styled } from "@/styles";

export const InstrumentButton = styled("button", {
  display: "flex",
  justifyContent: "space-between",
  padding: "8px",
  gap: "8px",

  background: "$white",
  borderRadius: "16px",

  "&:active": {
    transition: "all 0.1 linear",
    transform: "scale(0.98)",
  },
});

export const InstrumentCurrency = styled(Flex, {
  alignItems: "center",
  gap: "8px",
});
