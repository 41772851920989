import * as Collapsible from "@radix-ui/react-collapsible";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { Box } from "@/components/box";
import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { IconChevronDown } from "@/domains/icons";
import { AddChartSymbolButton } from "@/features/terminal/components/symbol/add-chart-symbol-button";
import { isBuyOrder } from "@/features/terminal/helpers/orders";
import { TerminalDealType } from "@/services/openapi";
import { styled } from "@/styles";

import { OrderSymbol } from "./order-symbol";
import { getOrderTypeText } from "./order-type";

const StyledList = styled("div", {
  display: "grid",
  gridTemplateColumns: "2fr 3fr",
  gap: 4,
});

const Dot = styled("div", {
  width: 8,
  height: 8,
  borderRadius: "50%",
  variants: {
    color: {
      negative: {
        background: "$negativeDefault",
      },
      positive: {
        background: "$positiveDefault",
      },
    },
  },
});

type Props = ComponentPropsWithoutRef<typeof Collapsible.Root> & {
  children: React.ReactNode;
  symbol: string;
  rightBottomBlock: React.ReactNode;
  type: TerminalDealType;
  volume: number | React.ReactNode;
  volumeDecimalScale?: number;
  button?: React.ReactNode;
  pnl?: React.ReactNode;
  text?: string;
  onSymbolClick?: () => void;
};

const Card = forwardRef<ElementRef<typeof Collapsible.Root>, Props>(
  (
    {
      children,
      button,
      onSymbolClick,
      symbol,
      pnl,
      text,
      rightBottomBlock,
      type,
      volume,
      volumeDecimalScale,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <Collapsible.Root ref={ref} className="flex flex-col rounded-[24px] border bg-bg p-4" {...restProps}>
        <Collapsible.Trigger className="flex items-center gap-4">
          <Box css={{ flexGrow: 1 }}>
            <Flex css={{ justifyContent: "space-between", alignItems: "center", mb: 4 }}>
              <Text weight="bold">
                {onSymbolClick ? (
                  <AddChartSymbolButton onClick={onSymbolClick}>
                    <OrderSymbol symbol={symbol} />
                  </AddChartSymbolButton>
                ) : (
                  <OrderSymbol symbol={symbol} />
                )}
              </Text>
              {pnl && <Text weight="bold">{pnl}</Text>}
              {text && <Text family="roboto">{text}</Text>}
            </Flex>
            <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
              <Text size="1" family="roboto" css={{ display: "flex", alignItems: "center", gap: 8 }}>
                <Dot color={isBuyOrder(type) ? "positive" : "negative"} />
                {getOrderTypeText(t, type)}
                {" ∙ "}
                {typeof volume === "number" ? (
                  <>{volumeDecimalScale ? <NumberFormat value={volume} decimalScale={volumeDecimalScale} /> : volume}</>
                ) : (
                  <>{volume}</>
                )}{" "}
                {t("terminal.lots")}
              </Text>
              <Text size="1" family="roboto">
                {rightBottomBlock}
              </Text>
            </Flex>
          </Box>
          <IconChevronDown className="size-4" />
        </Collapsible.Trigger>
        <Collapsible.Content className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
          <div className="mt-3">{children}</div>
        </Collapsible.Content>
        {button && <div className="mt-3">{button}</div>}
      </Collapsible.Root>
    );
  },
);

const ItemLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text color="dimmed" family="roboto" size="1" css={{ py: 6 }}>
      {children}
    </Text>
  );
};

const StyledText = styled(Text, {
  py: "6px",

  variants: {
    hasBackground: {
      true: {
        px: "8px",
        background: "$bgBack",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
  },
});

const ItemValue = ({ children, hasBackground }: { hasBackground?: boolean; children: ReactNode }) => {
  return (
    <StyledText family="roboto" size="1" hasBackground={hasBackground}>
      {children}
    </StyledText>
  );
};

const Component = Object.assign(Card, {
  List: StyledList,
  ItemLabel,
  ItemValue,
});

export { Component as TradingTableCard };
