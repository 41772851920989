import React, { createContext, memo, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { useInterval } from "@/hooks/interval.hook";
import { TerminalEventType } from "@/services/openapi";
import { useTerminalSymbolsFavoritesQuery } from "@/state/server/terminal";

import { filterTerminalSocket, terminalCommands } from "../helpers/socket.commands";
import { isSymbolAvailable, MergedTerminalSymbol } from "../helpers/symbols";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useSymbolsContext } from "./symbols.context";

type CurrentSymbolContextState = {
  symbolInfo: MergedTerminalSymbol | undefined;
  currentSymbol: string | undefined;
  setSymbol: (symbol: string) => void;
  isTradingAvailable: { available: boolean; remainingTime?: Date };
  topActiveInstruments: Array<string>;
};

const CurrentSymbolContext = createContext<CurrentSymbolContextState>({} as CurrentSymbolContextState);

export const TerminalCurrentSymbolContextProvider = memo(
  ({ children, accountId }: { children: React.ReactNode; accountId: string }) => {
    const [symbol, setSymbol] = useState<string>();
    const [symbolInfo, setSymbolInfo] = useState<MergedTerminalSymbol>();
    const [isTradingAvailable, setIsTradingAvailable] = useState<{ available: boolean; remainingTime?: Date }>({
      available: false,
    });
    const { symbols } = useSymbolsContext();

    const { data: favoriteData } = useTerminalSymbolsFavoritesQuery(accountId, {
      enabled: false,
    });

    useEffect(() => {
      try {
        if (!symbol && symbols.length > 0) {
          if (favoriteData) {
            setSymbol(favoriteData.chartsSelected!);
          }
        }
      } catch (error) {
        logError(error);
      }
    }, [symbols, symbol, favoriteData]);

    useEffect(() => {
      try {
        if (symbol) {
          const symbolInfo = symbols.find(item => item.symbol === symbol);
          setSymbolInfo(symbolInfo);
          setIsTradingAvailable(isSymbolAvailable(symbolInfo!.sessions!));
        }
      } catch (error) {
        logError(error);
      }
    }, [symbol]);

    const { sendJsonMessage, lastJsonMessage: socketMessage } = useTerminalSocket({
      filter: e => filterTerminalSocket(e, [TerminalEventType.Tick]),
    });

    useEffect(() => {
      if (symbol) {
        sendJsonMessage(terminalCommands.tickSubscribe(symbol));
      }
    }, [symbol]);

    useInterval(
      () => {
        if (symbolInfo) {
          setIsTradingAvailable(isSymbolAvailable(symbolInfo.sessions!));
        }
      },
      symbol ? 1000 : null,
    );

    useEffect(() => {
      try {
        const { dt } = socketMessage || {};
        if (!dt) return;
        if (symbol === dt.s) {
          setSymbolInfo(prev => ({
            ...prev,
            ask: dt.a,
            bid: dt.b,
            eventTime: dt.t,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const value: CurrentSymbolContextState = useMemo(
      () => ({
        currentSymbol: symbol,
        symbolInfo,
        setSymbol,
        isTradingAvailable,
        topActiveInstruments: favoriteData?.topActive ?? [],
      }),
      [symbolInfo, symbol, isTradingAvailable, favoriteData],
    );

    return <CurrentSymbolContext.Provider value={value}>{children}</CurrentSymbolContext.Provider>;
  },
);
TerminalCurrentSymbolContextProvider.displayName = "TerminalCurrentSymbolContextProvider";

export const useCurrentSymbolContext = () => {
  return useContext(CurrentSymbolContext);
};
