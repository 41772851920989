import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactElement, type ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { cn } from "@/shared/styles";

import { Tooltip } from "../../tooltip";
import { _Descriptor } from "./_descriptor";

const inputStyles = cva(
  "has-[input:focus]:border-text-secondary has-[label:hover]:border-text-secondary has-[input:focus]:shadow-focus",
  {
    variants: {
      invalid: {
        true: "has-[input:focus]:border-warning has-[label:hover]:border-warning has-[input:focus]:shadow-error",
      },
    },
  },
);
const selectStyles = cva(
  "outline-none hover:border-text-secondary focus:border-text-secondary focus:shadow-focus data-[state=open]:border-text-secondary",
  {
    variants: {
      invalid: {
        true: "hover:border-warning focus:border-warning focus:shadow-error data-[state=open]:border-warning",
      },
    },
  },
);

const wrapperStyles = cva(
  "inline-flex w-full items-center justify-between gap-3 rounded-[16px] border bg-bg px-4 text-start transition",
  {
    variants: {
      size: {
        sm: "h-[52px]",
        md: "h-14",
        lg: "h-16",
      },
      invalid: {
        true: "border-warning",
      },
      disabled: {
        true: "pointer-events-none",
      },
      pending: {
        true: "animate-pulse",
      },
    },
  },
);

type _ExternalWrapperProps = VariantProps<typeof wrapperStyles> & {
  className?: string;
  label?: string;
  startSection?: ReactNode;
  endSection?: ReactNode;
  tooltipContent?: ReactNode;
  descriptor?: ReactNode;
  renderWrapper?: (element: ReactElement) => ReactNode; // needed for place order suggester and feature-tour
};

type Props = ComponentPropsWithoutRef<"div"> &
  _ExternalWrapperProps & {
    type: "input" | "select";
  };

const _Wrapper = forwardRef<ElementRef<"div">, Props>(
  (
    {
      size = "md",
      children,
      className,
      startSection,
      endSection,
      disabled,
      pending,
      label,
      invalid,
      descriptor,
      renderWrapper,
      tooltipContent,
      type,
      ...props
    },
    ref,
  ) => {
    const WrapperTag: keyof JSX.IntrinsicElements = type === "select" ? "button" : "div";
    const LabelTag: keyof JSX.IntrinsicElements = type === "select" ? "div" : "label";

    const element = (
      <WrapperTag
        className={cn(
          wrapperStyles({ size, disabled, pending, invalid }),
          type === "select" && selectStyles({ invalid }),
          type === "input" && inputStyles({ invalid }),
        )}
        {...props}
        // @ts-ignore
        ref={ref}
      >
        {startSection}
        <LabelTag className="flex h-full grow flex-col justify-center gap-0.5">
          {label && <div className="font-roboto text-[12px] leading-[1.5] text-text-secondary">{label}</div>}
          {children}
        </LabelTag>
        {(endSection || tooltipContent) && (
          <div className="flex items-center gap-3">
            {tooltipContent && (
              <Tooltip content={tooltipContent}>
                <div className="relative grid size-6 place-items-center before:absolute before:size-6">
                  <TooltipQuestionIcon />
                </div>
              </Tooltip>
            )}
            {endSection}
          </div>
        )}
      </WrapperTag>
    );

    return (
      <div
        className={cn("group/input-wrapper", className)}
        data-size={size}
        data-invalid={invalid}
        data-disabled={disabled}
      >
        {renderWrapper ? renderWrapper(element) : element}
        {descriptor && <_Descriptor>{descriptor}</_Descriptor>}
      </div>
    );
  },
);

export { _Wrapper };
export type { _ExternalWrapperProps };
