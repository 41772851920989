import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@/components/box";
import { useScript } from "@/hooks/script.hook";
import { TradingServer } from "@/services/openapi";

// https://www.mql5.com/en/trading/widget

type Props = {
  servers: TradingServer[];
};

const containerId = "webterminal";

export const MT4TerminalContainer: FC<Props> = ({ servers }) => {
  const { i18n } = useTranslation();
  const status = useScript("https://metatraderweb.app/trade/widget.js", { removeOnUnmount: true });

  useEffect(() => {
    if (status === "ready") {
      window!.MetaTraderWebTerminal("webterminal", {
        startMode: "login",
        language: i18n.resolvedLanguage,
        colorScheme: "black_on_white",
        utmSource: "www.doto.com",
        version: 4,
        server: servers[0].marketName!,
        servers: servers.map(({ marketName }) => marketName!),
      });
    }
  }, [status]);

  return (
    <>
      {status === "error" && (
        <p>
          {"Ad blocking software is preventing the Trading Terminal from loading."}
          <br />
          {
            "Please disable your ad blocker, or add site to the exception list, and reload the Trading Terminal to continue."
          }
        </p>
      )}
      <Box id={containerId} css={{ width: "100%", height: "100%" }} />
    </>
  );
};
