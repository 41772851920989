import { FC } from "react";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TerminalDealType } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { formatInputNumberValue } from "../../helpers/formatting";
import { isBuyOrder } from "../../helpers/orders";

const getShowMoreCondition = ({
  orderType,
  type,
}: {
  orderType: TerminalDealType;
  type: "stopLoss" | "takeProfit" | "openingPrice";
}) => {
  if (type === "takeProfit") {
    return isBuyOrder(orderType);
  }
  if (type === "stopLoss") {
    return !isBuyOrder(orderType);
  }
  return orderType === TerminalDealType.BuyStop || orderType === TerminalDealType.SellLimit;
};

const ModifyMoreLessDescription: FC<{
  thresholdValue: number;
  orderType: TerminalDealType;
  decimalScale: number;
  onChange: (value: string) => void;
  type: "stopLoss" | "takeProfit" | "openingPrice";
}> = ({ thresholdValue, type, decimalScale, onChange, orderType }) => {
  const handleClick = () => {
    onChange(formatInputNumberValue(thresholdValue, decimalScale)!);
  };

  const showMoreThan = getShowMoreCondition({ orderType, type });

  return showMoreThan ? (
    <Trans
      i18nKey="terminal.order-settings.more-than"
      components={{
        valueButton: <NumberInput.DescriptorButton onClick={handleClick} />,
        value: <NumberFormat value={thresholdValue} decimalScale={decimalScale} />,
      }}
    />
  ) : (
    <Trans
      i18nKey="terminal.order-settings.less-than"
      components={{
        valueButton: <NumberInput.DescriptorButton onClick={handleClick} />,
        value: <NumberFormat value={thresholdValue} decimalScale={decimalScale} />,
      }}
    />
  );
};

export { ModifyMoreLessDescription };
