export const AccountsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.27 5.054a3 3 0 013-3h8a3 3 0 013 3v1.17c1.166.412 2 1.523 2 2.83v1.17c1.166.412 2 1.524 2 2.83v4a5 5 0 01-5 5h-12a5 5 0 01-5-5v-4c0-1.306.835-2.418 2-2.83v-1.17c0-1.307.835-2.418 2-2.83v-1.17zm2 1h10v-1a1 1 0 00-1-1h-8a1 1 0 00-1 1v1zm-2 4h14v-1a1 1 0 00-1-1h-12a1 1 0 00-1 1v1zm-1 2a1 1 0 00-1 1v4a3 3 0 003 3h12a3 3 0 003-3v-4a1 1 0 00-1-1h-16zm5 2a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);
