import { getDeviceId, init, setUserId, track } from "@amplitude/analytics-browser";
import { BaseEvent, EventOptions } from "@amplitude/analytics-types";

import { getUserPlatformAndOS } from "@/utils/helpers";

const amplitudeEvents = {
  myAccounts: {
    accountOptionsClick: "myaccounts_real_meatball_button_depos_nameit",
  },
  verify: {
    popupSeen: "popup_verified_view",
    completeProfile: "popup_verified_completemyprofile",
    toDemo: "popup_verified_todemoaccount",
    bannerClick: "myaccounts_banner_button_verify",
  },
  fork: {
    toReal: "fork_button_real",
    toDemo: "fork_button_demo",
  },
  featureTour: {
    view: "feature_tour_view",
    start: "feature_tour_button_start",
    repeat: "feature_tour_repeat",
    close: (index: number) => `feature_tour_step${index}_button_cros_names`,
    next: (index: number) => `feature_tour_step${index}_button_next`,
  },
  transcationHistory: {
    depositClick: "transactionhistory_button_depos_nameit",
    depositClickWithNoTransactions: "transactionhistory_button_depos_nameit_now",
  },
  deposit: {
    amount: "depos_nameit_details_amount",
    completeProfileClick: "depos_nameit_button_completeprofile",
    fromTerminal: "terminal_to_depos_nameit",
    fromProfile: "profile_button_depos_nameit",
    fromNotifications: "notifications_button_depos_nameit",
    fromMyAccounts: "myaccounts_real_button_depos_nameit",
    fromMyAccountsMenu: "myaccounts_button_depos_nameit",
  },
  terminal: {
    fromDeposit: "depos_nameit_button_trade",
  },
  kyc: {
    close: (index: number) => `kyc_step${index}_button_clos_namee`,
    next: (index: number) => `kyc_step${index}_button_next`,
    back: (index: number) => `kyc_step${index}_button_back`,
  },
  fromDemoToReal: {
    toReal: "popup_switch_to_real_account_click_button",
    show: "popup_switch_to_real_account_view",
  },
};

const amplitudeSetUserId = (userId: string) => setUserId(userId);
const amplitudeInit = () =>
  init(import.meta.env.VITE_AMPLITUDE_KEY, {
    /* serverZone: "EU", */
    deviceId: getDeviceId(),
    defaultTracking: {
      pageViews: true,
      sessions: true,
    },
  });

const amplitudeTrack = (
  eventName: string | BaseEvent,
  eventProperties?: Record<string, any>,
  eventOptions?: EventOptions,
) => {
  const { os } = getUserPlatformAndOS();

  const customProperties = () => {
    if (os === "Android" || os === "IOS") return { event_platform: os, ...eventProperties };
    else return eventProperties;
  };

  track(eventName, customProperties(), eventOptions);
};
const amplitudeParameterName = "Analytics-Device-Id";

export { amplitudeParameterName, amplitudeInit, amplitudeSetUserId, amplitudeEvents, amplitudeTrack as track };
