import { useCallback } from "react";
import { useQueryClient } from "react-query";

import { TerminalAccountSymbols } from "@/services/openapi";
import { terminalQueryKeys, useAddSymbolToChartsMutation } from "@/state/server/terminal";

type Props = {
  accountId: string;
  symbol: string;
  setSymbol: (symbol: string) => void;
  callback?: () => void;
};

export const useAddChartSymbol = ({ accountId, symbol, setSymbol, callback }: Props) => {
  const queryClient = useQueryClient();

  const { mutate: addFavorite, isLoading } = useAddSymbolToChartsMutation();

  const addChartSymbol = useCallback(() => {
    if (isLoading) {
      return;
    }
    const isSymbolChartFavorite = queryClient
      .getQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId))
      ?.charts?.includes(symbol);

    if (isSymbolChartFavorite) {
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        const newRecent = [symbol, ...oldData!.recent!.filter(s => s !== symbol)].slice(0, 10);
        return { ...oldData, chartsSelected: symbol, recent: newRecent };
      });
    } else {
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        const newChartFavorites = [symbol, ...oldData!.charts!.filter(s => s !== symbol)].slice(0, 10);
        const newRecent = [symbol, ...oldData!.recent!.filter(s => s !== symbol)].slice(0, 10);

        return { ...oldData, charts: newChartFavorites, chartsSelected: symbol, recent: newRecent };
      });
    }
    addFavorite({ symbol, tradingAccountId: accountId });
    setSymbol(symbol);
    callback && callback();
  }, [accountId, addFavorite, isLoading, queryClient, symbol, setSymbol, callback]);

  return { addChartSymbol };
};
