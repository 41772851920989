import { FC } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";

import { Props } from "./types";
import { DepositVerificationBottomSheet } from "./verification-bottomsheet";
import { DepositVerificationDialog } from "./verification-dialog";

export const fromDepositState = "fromDeposit";

export const VerificationContainer: FC<Props> = ({ open, onOpenChange }) => {
  const { isMobile } = useScreenSize();

  if (isMobile) return <DepositVerificationBottomSheet open={open} onOpenChange={onOpenChange} />;

  return <DepositVerificationDialog open={open} onOpenChange={onOpenChange} />;
};
