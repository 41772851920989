import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/shared/ui";

import { OpenOrder } from "../../contexts/extended-orders.context";
import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../../feature-tour/context";
import { TradingCardsContainer } from "../components/trading-table/trading-cards.container";
import { TradingTable } from "../components/trading-table/trading-table";
import { TradingTableEmptyState } from "../components/trading-table/trading-table-empty-state";
import { OpenOrdersInfoCard } from "./info-card";
import { OpenOrderCard } from "./open-order-card";
import { OpenOrdersHeaderRow } from "./open-orders-header-row";
import { OpenOrdersTableRow } from "./open-orders-table-row";

type Props = {
  orders: OpenOrder[];
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  closeOrderStyles: ReturnType<typeof useTerminalFeatureTourContext>["closeOrderStyles"];
  onClose?: () => void;
};

export const OpenOrdersTable: FC<Props> = ({
  orders,
  accountId,
  accountCurrency,
  onClose,
  setSymbol,
  closeOrderStyles,
  currencyDecimalScale,
}) => {
  const { isMobile, openWatchlist } = useTerminalLayout();
  const { t } = useTranslation();

  const emptyState = (
    <TradingTableEmptyState
      title={t("terminal.empty-state.open-title")}
      description={t("terminal.empty-state.open-description")}
      action={
        <Button
          size="sm"
          onClick={() => {
            onClose && onClose();
            openWatchlist();
          }}
        >
          {t("terminal.go-to-watchlist")}
        </Button>
      }
    />
  );

  return isMobile ? (
    <TradingCardsContainer button={<OpenOrdersInfoCard accountId={accountId} ordersCount={orders.length} />}>
      {orders.length === 0 && emptyState}
      {orders.map(order => (
        <OpenOrderCard
          key={order.id}
          order={order}
          accountId={accountId}
          accountCurrency={accountCurrency}
          onCardClose={onClose}
          setSymbol={setSymbol}
          currencyDecimalScale={currencyDecimalScale}
        />
      ))}
    </TradingCardsContainer>
  ) : (
    <TradingTable
      items={orders}
      emptyState={emptyState}
      renderHeader={() => <OpenOrdersHeaderRow ordersCount={orders.length} />}
      renderRow={(order, i) => {
        const isLastOrder = i === 0;

        if (isLastOrder) {
          return (
            <OpenOrdersTableRow
              key={order.id}
              order={order}
              accountId={accountId}
              accountCurrency={accountCurrency}
              setSymbol={setSymbol}
              style={closeOrderStyles.highlight}
              currencyDecimalScale={currencyDecimalScale}
              isLastOrder
            />
          );
        }

        return (
          <OpenOrdersTableRow
            key={order.id}
            order={order}
            accountId={accountId}
            accountCurrency={accountCurrency}
            setSymbol={setSymbol}
            currencyDecimalScale={currencyDecimalScale}
          />
        );
      }}
    />
  );
};
