import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";
import { popoverContentStyles } from "@/shared/ui";

const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <HoverCardPrimitive.Portal>
    <HoverCardPrimitive.Content
      align="start"
      side="left"
      sideOffset={16}
      collisionPadding={38}
      className={cn(popoverContentStyles(), "w-[300px] rounded-[24px] bg-bg p-6 shadow outline-none")}
    >
      {children}
    </HoverCardPrimitive.Content>
  </HoverCardPrimitive.Portal>
);

type Props = {
  children: ReactNode;
  content: ReactNode;
  disableHoverCard?: boolean;
};

const PlaceOrderHoverCard: FC<Props> = ({ children, content, disableHoverCard }) => (
  <HoverCardPrimitive.Root open={disableHoverCard ? false : undefined} openDelay={300} closeDelay={0}>
    <HoverCardPrimitive.Trigger asChild>{children}</HoverCardPrimitive.Trigger>
    <Content>{content}</Content>
  </HoverCardPrimitive.Root>
);

export { PlaceOrderHoverCard };
