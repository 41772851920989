import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { Loader } from "../loader";

const buttonStyles = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-[32px] font-gilroy font-bold transition-colors focus-visible:outline-none disabled:pointer-events-none",
  {
    variants: {
      variant: {
        primary: "bg-action text-text-inverse hover:bg-action-hover disabled:bg-text-placeholder",
        secondary: "bg-dark text-text-inverse hover:bg-dark disabled:bg-text-placeholder",
        tertiary: "border border-bg-border text-text hover:border-text-placeholder disabled:text-text-placeholder",
        flat: "bg-white text-text hover:text-text-secondary disabled:text-text-placeholder",
        danger: "bg-warning text-text-inverse hover:bg-warning-press disabled:bg-text-placeholder",
      },
      size: {
        sm: "h-10 px-6 py-2 text-[14px]",
        md: "h-14 px-6 py-4 text-[16px]",
        lg: "h-16 px-10 py-5 text-[18px]",
      },
      pending: {
        true: "",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        pending: true,
        className: "!text-white disabled:bg-action-press",
      },
      {
        variant: "secondary",
        pending: true,
        className: "!text-white disabled:bg-dark",
      },
      {
        variant: "tertiary",
        pending: true,
        className: "!text-dark disabled:border-text-placeholder",
      },
      {
        variant: "flat",
        pending: true,
        className: "disabled:text-text-main !text-dark",
      },
      {
        variant: "danger",
        pending: true,
        className: "!text-white disabled:bg-warning",
      },
    ],
  },
);

type ButtonProps = ComponentPropsWithoutRef<"button"> &
  VariantProps<typeof buttonStyles> & {
    asChild?: boolean;
    startSection?: ReactNode;
    pending?: boolean;
  };

const Button = forwardRef<ElementRef<"button">, ButtonProps>(
  (
    {
      className,
      variant = "primary",
      size = "md",
      asChild = false,
      pending,
      disabled: disabledProp,
      type = "button",
      startSection,
      children,
      ...props
    },
    ref,
  ) => {
    const disabled = disabledProp || pending;
    const Comp = asChild && !pending ? Slot : "button";
    return (
      <Comp
        className={cn(buttonStyles({ variant, size, className, pending }))}
        disabled={disabled}
        type={type}
        ref={ref}
        {...props}
      >
        {!pending && startSection}
        {!pending && <Slottable>{children}</Slottable>}
        {pending && <Loader className="px-4" />}
      </Comp>
    );
  },
);

export { Button, buttonStyles };
