import { ComponentProps, FC } from "react";

import { UnstyledButton } from "@/components/unstyled-button.tsx";

type Props = ComponentProps<typeof UnstyledButton>;

export const AddChartSymbolButton: FC<Props> = props => {
  return (
    <UnstyledButton
      css={{
        elementTransition: "color",
        "&:hover": {
          color: "$textSecondary",
        },
      }}
      {...props}
    />
  );
};
