import { FC, useCallback } from "react";

import { TradingAccount } from "@/services/openapi";
import { useChangeAccountLeverageMutation } from "@/state/server/accounts";

import { ILeverageFormValues, LeverageForm } from "./leverage.form";

type Props = {
  account: TradingAccount;
  onClose: () => void;
};

export const LeverageContainer: FC<Props> = ({ account, onClose }) => {
  const { mutateAsync: changeLeverage } = useChangeAccountLeverageMutation({ successMessage: "leverage-changed" });

  const handleSubmit = useCallback(
    ({ value }: ILeverageFormValues) => {
      return changeLeverage(
        { id: account.id!, integerContainer: { value } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changeLeverage, account],
  );

  return (
    <LeverageForm
      onSubmit={handleSubmit}
      leverage={account.leverage!}
      availableLeverages={account.leveragesAvailable!}
    />
  );
};
