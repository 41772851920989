import Big from "big.js";
import { TFunction } from "i18next";
import { InfiniteData } from "react-query";

import { NumberFormat } from "@/app/components";
import {
  TerminalDealEventType,
  TerminalDealType,
  TerminalDealUpdateType,
  TerminalEventDeal,
  TerminalOrder,
  TerminalOrderItemsContainer,
  TradingServerSymbolType,
} from "@/services/openapi";
import { Toast, toast, ToastIcons } from "@/shared/ui";

import { ToastSymbolIcon } from "../components/symbol/symbol-icon";
import { TerminalTableState, useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../feature-tour/context";
import { getBaseSymbol, getQuoteSymbol } from "../helpers";
import { getNumberColorClassname } from "../terminal.styles-helpers";
import { getOrderTypeText } from "../trading-tables/components/trading-table/order-type";
import { getOrderCurrentPrice } from "../trading-tables/trading-tables.helpers";
import { getInstrumentType, MergedTerminalSymbol } from "./symbols";

export type LiveOrder = {
  id: number;
  symbol: string;
  type: TerminalDealType;
  swap: number;
  volume: number;
  price: number;
  event: TerminalDealEventType;
  date: string;
  isUpdate: boolean;
  stopLoss?: number;
  takeProfit?: number;
  updateVolume?: number;
  updatePrice?: number;
  updateProfit?: number | null;
  updateType?: TerminalDealType;
  updateAction?: TerminalDealUpdateType;
  updatePosition?: number;
};

export const getClosedOrderById = ({
  closedOrdersWrapper,
  orderId,
}: {
  orderId: number;
  closedOrdersWrapper: InfiniteData<TerminalOrderItemsContainer>;
}): TerminalOrder | undefined => {
  let neededOrder: TerminalOrder | undefined;

  const closedOrders = closedOrdersWrapper.pages[0].items;

  if (closedOrders) {
    closedOrders.forEach(order => {
      if (order.position === orderId) {
        neededOrder = order;
        return;
      }
    });

    return neededOrder;
  }
};

export const transformTerminalOrder = ({ d, e, p, s, sl, sw, t, tp, v, dt, u }: TerminalEventDeal): LiveOrder => ({
  id: d!,
  symbol: s!,
  date: dt!,
  type: t!,
  swap: sw!,
  volume: v!,
  price: p!,
  stopLoss: sl!,
  takeProfit: tp!,
  event: e!,
  updatePosition: u?.i,
  updateProfit: u?.r,
  updateAction: u?.u,
  updatePrice: u?.p,
  updateType: u?.t,
  updateVolume: u?.v,
  isUpdate: !!u,
});

export const normalizeOrdersList = (list: TerminalEventDeal[]) => {
  const initObject: { [key: string]: LiveOrder } = {};
  list.forEach(item => (initObject[item.d!] = transformTerminalOrder(item)));
  return initObject;
};

export const isPendingOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.BuyStop:
    case TerminalDealType.SellStop:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.SellLimit:
      return true;
    default:
      return false;
  }
};

export const isMarketOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.Sell:
      return true;
    default:
      return false;
  }
};

export const isBuyOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return true;
    default:
      return false;
  }
};

export const isSellOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
    case TerminalDealType.SellStopLimit:
      return true;
    default:
      return false;
  }
};

export const countProfitAndLoss = ({
  contractSize,
  currentPrice,
  openPrice,
  type,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  accountCurrency: string;
  volume: number | undefined;
  currentPrice: number | undefined;
  contractSize: number | undefined;
  baseCurrency: string | undefined;
  quoteCurrency: string | undefined;
}) => {
  if (!volume || !currentPrice || !contractSize || !baseCurrency || !quoteCurrency) {
    return 0;
  }

  const direction = isBuyOrder(type) ? 1 : -1;
  const baseResult = new Big(currentPrice).minus(openPrice).mul(volume).mul(contractSize).mul(direction);

  if (quoteCurrency === accountCurrency) {
    return baseResult.toNumber();
  }

  if (baseCurrency === accountCurrency) {
    if (!currentPrice) {
      return 0;
    }
    return baseResult.div(currentPrice).toNumber();
  }

  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: accountCurrency,
    quoteCurrencyPredicate: quoteCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 0;
    }
    const baseCurrentPrice = type === TerminalDealType.Buy ? baseSymbol.bid : baseSymbol.ask;
    return baseResult.div(baseCurrentPrice).toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    quoteCurrencyPredicate: accountCurrency,
    baseCurrencyPredicate: quoteCurrency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 0;
    }
    const quoteCurrentPrice = type === TerminalDealType.Buy ? quoteSymbol.bid : quoteSymbol.ask;
    return baseResult.mul(quoteCurrentPrice).toNumber();
  }

  return 0;
};

export const countMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  instrumentType,
  marginRate,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  instrumentType: TradingServerSymbolType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  return new Big(
    countBaseMargin({
      type,
      symbols,
      contractSize,
      leverage: isForex ? leverage : 1,
      openPrice,
      volume,
      accountCurrency,
      baseCurrency,
      quoteCurrency,
    }),
  )
    .mul(
      getMarginRate({
        marginRate,
        marginRateInitialMarketBuy,
        marginRateInitialMarketSell,
        marginRateMaintenanceMarketBuy,
        marginRateMaintenanceMarketSell,
        type,
      }),
    )
    .mul(isForex ? 1 : openPrice)
    .toNumber();
};

const getMarginRate = ({
  marginRate,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
  type,
}: {
  type: TerminalDealType;
  marginRate: "initial" | "maintenance";
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
}) => {
  if (type === TerminalDealType.Buy) {
    if (marginRate === "initial") {
      return marginRateInitialMarketBuy;
    } else {
      return marginRateMaintenanceMarketBuy;
    }
  } else {
    if (marginRate === "initial") {
      return marginRateInitialMarketSell;
    } else {
      return marginRateMaintenanceMarketSell;
    }
  }
};

export const countBaseMargin = ({
  type,
  symbols,
  contractSize,
  leverage,
  openPrice,
  volume,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
}: {
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
  openPrice: number;
  volume: number;
  contractSize: number;
  leverage: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
}): number => {
  if (baseCurrency === accountCurrency) {
    return new Big(volume).mul(contractSize).div(leverage).toNumber();
  }

  if (quoteCurrency === accountCurrency) {
    return new Big(volume).mul(contractSize).mul(openPrice).div(leverage).toNumber();
  }

  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: accountCurrency,
    quoteCurrencyPredicate: baseCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 0;
    }
    const baseCurrentPrice = getOrderCurrentPrice(type, baseSymbol.ask, baseSymbol.bid)!;
    return new Big(volume).mul(contractSize).div(baseCurrentPrice).div(leverage).toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    quoteCurrencyPredicate: accountCurrency,
    baseCurrencyPredicate: baseCurrency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 0;
    }
    const quoteCurrentPrice = getOrderCurrentPrice(type, quoteSymbol.ask, quoteSymbol.bid)!;
    return new Big(volume).mul(contractSize).mul(quoteCurrentPrice).div(leverage).toNumber();
  }

  return 0;
};

export const updateOrdersList = ({
  t,
  newOrder,
  currency,
  list,
  symbols,
  currencyDecimalScale,
  isFeatureTourDisabled,
  leaveIdleState,
  changeTable,
}: {
  t: TFunction;
  newOrder: LiveOrder;
  currency: string;
  currencyDecimalScale: number;
  isFeatureTourDisabled: boolean;
  list: {
    [key: string]: LiveOrder;
  };
  symbols: MergedTerminalSymbol[];
  leaveIdleState: ReturnType<typeof useTerminalFeatureTourContext>["leaveIdleState"];
  changeTable: ReturnType<typeof useTerminalLayout>["changeTable"];
}): {
  [key: string]: LiveOrder;
} => {
  const updatedList = JSON.parse(JSON.stringify(list)) as typeof list;
  const oldOrder: LiveOrder = updatedList[newOrder.id];

  const isMarket = isMarketOrder(newOrder.type);

  const { volumeDecimalScale } = symbols.find(({ symbol }) => symbol === newOrder.symbol) || {};

  const marketMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.updateType!),
    volume: newOrder.updateVolume?.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
    position: newOrder.updatePosition,
  };

  const pendingMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.type),
    volume: newOrder.volume.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
  };

  if (isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      delete updatedList[newOrder.id];
      if (newOrder.updateAction === TerminalDealUpdateType.Close) {
        toast({
          icon: <ToastSymbolIcon symbol={marketMessageInfo.symbol} />,
          title: t("terminal.messages.market-order-closed", { symbol: marketMessageInfo.symbol })!,
          onClose: () => leaveIdleState(),
          description: (
            <span className={getNumberColorClassname(newOrder.updateProfit!)}>
              <NumberFormat value={newOrder.updateProfit} decimalScale={currencyDecimalScale} currency={currency} />
            </span>
          ),
          action: isFeatureTourDisabled ? (
            <Toast.Action
              altText={t("terminal.messages.market-order-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-closed-button")}
            </Toast.Action>
          ) : null,
        });
      }
      return updatedList;
    }

    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };

    if (newOrder.isUpdate) {
      if (oldOrder && oldOrder.type !== newOrder.updateType && newOrder.updateType !== newOrder.type) {
        toast({
          text: t("terminal.messages.market-order-partially-closed", marketMessageInfo),
          icon: <ToastSymbolIcon symbol={marketMessageInfo.symbol} />,
          action: isFeatureTourDisabled ? (
            <Toast.Action
              altText={t("terminal.messages.market-order-partially-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-partially-closed-button")}
            </Toast.Action>
          ) : null,
        });
      } else {
        toast({
          title: t("terminal.messages.market-order-opened", marketMessageInfo)!,
          description: <>#{marketMessageInfo.id}</>,
          icon: <ToastSymbolIcon symbol={marketMessageInfo.symbol} />,
          onClose: () => leaveIdleState(),
          action: isFeatureTourDisabled ? (
            <Toast.Action
              altText={t("terminal.messages.market-order-opened-button")}
              onClick={() => {
                changeTable(TerminalTableState.OPEN);
              }}
            >
              {t("terminal.messages.market-order-opened-button")}
            </Toast.Action>
          ) : null,
        });
      }
    }
    return updatedList;
  }

  if (!isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      const oldPending = isPendingOrder(oldOrder?.type);
      const newPending = isPendingOrder(newOrder.type);
      if (oldPending && newPending) {
        delete updatedList[newOrder.id];
        return updatedList;
      } else {
        return updatedList;
      }
    }
    if (oldOrder) {
      toast({ text: t("terminal.messages.pending-order-modified", pendingMessageInfo), icon: ToastIcons.SUCCESS });
    } else {
      toast({
        text: t("terminal.messages.pending-order-placed", pendingMessageInfo),
        icon: ToastIcons.SUCCESS,
        action: isFeatureTourDisabled ? (
          <Toast.Action
            altText={t("terminal.messages.pending-order-placed-button")}
            onClick={() => {
              changeTable(TerminalTableState.PENDING);
            }}
          >
            {t("terminal.messages.pending-order-placed-button")}
          </Toast.Action>
        ) : null,
      });
    }
    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };
  }

  return updatedList;
};
