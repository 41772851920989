import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { addBreadcrumb } from "@/app/libs/sentry";
import { TradingAccountTradeMode } from "@/services/openapi";

import { FeatureTourSteps } from "./types";

const desktopLotsSteps: Record<string, number> = {
  [FeatureTourSteps.SELECT_SYMBOL]: 1,
  [FeatureTourSteps.CHART]: 2,
  [FeatureTourSteps.INCREMENT_ORDER]: 3,
  [FeatureTourSteps.PLACE_ORDER]: 4,
  [FeatureTourSteps.CLOSE_ORDER]: 5,
};

const desktopMarginSteps: Record<string, number> = {
  [FeatureTourSteps.SELECT_SYMBOL]: 1,
  [FeatureTourSteps.CHART]: 2,
  [FeatureTourSteps.INCREMENT_ORDER]: 3,
  [FeatureTourSteps.PLACE_ORDER]: 4,
  [FeatureTourSteps.CHECK_FREE_MARGIN]: 5,
  [FeatureTourSteps.CLOSE_ORDER]: 6,
  [FeatureTourSteps.CHANGE_VOLUME_MODE]: 7,
};

const mobileLotsSteps: Record<string, number> = {
  [FeatureTourSteps.SELECT_SYMBOL]: 1,
  [FeatureTourSteps.CHART]: 2,
  [FeatureTourSteps.INCREMENT_ORDER]: 3,
  [FeatureTourSteps.PLACE_ORDER]: 4,
};

const mobileMarginSteps: Record<string, number> = {
  [FeatureTourSteps.SELECT_SYMBOL]: 1,
  [FeatureTourSteps.CHART]: 2,
  [FeatureTourSteps.INCREMENT_ORDER]: 3,
  [FeatureTourSteps.PLACE_ORDER]: 4,
  [FeatureTourSteps.CHECK_FREE_MARGIN]: 5,
  [FeatureTourSteps.CHANGE_VOLUME_MODE]: 6,
};

const getDesktopSteps = (tradeMode: TradingAccountTradeMode) => {
  return tradeMode === TradingAccountTradeMode.Lots ? desktopLotsSteps : desktopMarginSteps;
};

const getMobileSteps = (tradeMode: TradingAccountTradeMode) => {
  return tradeMode === TradingAccountTradeMode.Lots ? mobileLotsSteps : mobileMarginSteps;
};

const getCurrentStep = ({
  step,
  isMobile,
  tradeMode,
}: {
  step: FeatureTourSteps;
  isMobile: boolean;
  tradeMode: TradingAccountTradeMode;
}) => {
  const steps = isMobile ? getMobileSteps(tradeMode) : getDesktopSteps(tradeMode);
  return steps[step] || 0;
};

const getTotalSteps = ({ isMobile, tradeMode }: { isMobile: boolean; tradeMode: TradingAccountTradeMode }) => {
  const steps = isMobile ? getMobileSteps(tradeMode) : getDesktopSteps(tradeMode);
  return Object.keys(steps).length;
};

// amplitude

type TrackParams = {
  isMarginMode: boolean;
  isMobile: boolean;
};

type TrackNextParams = TrackParams & {
  currentStep: number;
};

const addSentryBreadcrumb = (message: string) =>
  addBreadcrumb({
    category: "feature-tour",
    level: "info",
    message,
  });

const trackForkReal = ({ isMarginMode, isMobile }: TrackParams) => {
  track(amplitudeEvents.fork.toReal);
  addSentryBreadcrumb("Click fork real");
};

const trackForkDemo = ({ isMarginMode, isMobile }: TrackParams) => {
  track(amplitudeEvents.fork.toDemo);
  addSentryBreadcrumb("Click fork demo");
};

const trackStartTour = ({ isMarginMode, isMobile }: TrackParams) => {
  track(amplitudeEvents.featureTour.start);
  addSentryBreadcrumb("Click start tour");
};

const trackSelectSymbol = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click select symbol");
};

const trackChartExamine = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click chart examine");
};

const trackIncrementOrder = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click increment order");
};

const trackPlaceOrder = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click place order");
};

const trackCheckFreeMargin = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click check free margin");
};

const trackCloseOrder = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click close order");
};

const trackChangeVolumeMode = ({ isMarginMode, isMobile, currentStep }: TrackNextParams) => {
  track(amplitudeEvents.featureTour.next(currentStep));
  addSentryBreadcrumb("Click change volume mode");
};

const trackRepeatTour = ({ isMarginMode, isMobile }: TrackParams) => {
  track(amplitudeEvents.featureTour.repeat);
  addSentryBreadcrumb("Click repeat tour");
};

const trackTerminateTour = ({
  isMarginMode,
  isMobile,
  previousStep,
}: TrackParams & {
  previousStep: number;
}) => {
  track(amplitudeEvents.featureTour.close(previousStep));
  addSentryBreadcrumb("Click terminate tour");
};

export {
  getCurrentStep as getFeatureTourCurrentStep,
  getTotalSteps as getTotalFeatureTourSteps,
  trackStartTour,
  trackSelectSymbol,
  trackChartExamine,
  trackIncrementOrder,
  trackPlaceOrder,
  trackCheckFreeMargin,
  trackCloseOrder,
  trackChangeVolumeMode,
  trackRepeatTour,
  trackTerminateTour,
};
