import { FC, ReactNode } from "react";

import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { styled } from "@/styles";

const Container = styled("div", {
  display: "grid",
  placeItems: "center",
  height: 56,
  width: 56,
  borderRadius: "$round",
  color: "$textSecondary",
  background: "$bgBack",
  margin: "0 auto",
});

export const EmptyStateIcon = ({ isClosedOrder }: { isClosedOrder?: boolean }) => {
  return (
    <Container>
      {isClosedOrder ? (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.5 12.5C20.5 8.08172 16.9183 4.5 12.5 4.5C10.2129 4.5 8.14918 5.45944 6.69032 7H9.125C9.67729 7 10.125 7.44772 10.125 8C10.125 8.55228 9.67729 9 9.125 9H4.70404C4.15175 9 3.70404 8.55228 3.70404 8V3.5C3.70404 2.94772 4.15175 2.5 4.70404 2.5C5.25632 2.5 5.70404 2.94772 5.70404 3.5V5.16427C7.48731 3.5115 9.87523 2.5 12.5 2.5C18.0228 2.5 22.5 6.97715 22.5 12.5C22.5 18.0228 18.0228 22.5 12.5 22.5C6.97715 22.5 2.5 18.0228 2.5 12.5C2.5 11.9477 2.94772 11.5 3.5 11.5C4.05228 11.5 4.5 11.9477 4.5 12.5C4.5 16.9183 8.08172 20.5 12.5 20.5C16.9183 20.5 20.5 16.9183 20.5 12.5Z"
            fill="currentColor"
          />
          <path
            d="M12.7261 9.50849C12.7261 9.03646 12.2784 8.65381 11.7261 8.65381C11.1738 8.65381 10.7261 9.03646 10.7261 9.50849V13.7819C10.7261 14.0677 10.8932 14.3345 11.1714 14.4931L14.1714 16.2024C14.6309 16.4643 15.2518 16.3581 15.5581 15.9654C15.8645 15.5726 15.7403 15.042 15.2808 14.7801L12.7261 13.3245V9.50849Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.5 5C9.5 3.89543 10.3954 3 11.5 3H13.5C14.6046 3 15.5 3.89543 15.5 5H9.5ZM7.5 5C7.5 2.79086 9.29086 1 11.5 1H13.5C15.7091 1 17.5 2.79086 17.5 5H18.5C21.2614 5 23.5 7.23858 23.5 10V13V18C23.5 20.7614 21.2614 23 18.5 23H6.5C3.73858 23 1.5 20.7614 1.5 18V13V10C1.5 7.23858 3.73858 5 6.5 5H7.5ZM18.5 7C20.1569 7 21.5 8.34315 21.5 10V12H14.2324C13.8866 11.4022 13.2403 11 12.5 11C11.7597 11 11.1134 11.4022 10.7676 12H3.5V10C3.5 8.34315 4.84315 7 6.5 7H8.5H16.5H18.5ZM10.7676 14H3.5V18C3.5 19.6569 4.84315 21 6.5 21H18.5C20.1569 21 21.5 19.6569 21.5 18V14H14.2324C13.8866 14.5978 13.2403 15 12.5 15C11.7597 15 11.1134 14.5978 10.7676 14Z"
            fill="currentColor"
          />
        </svg>
      )}
    </Container>
  );
};

type Props = {
  title: string;
  description: string;
  isClosedOrder?: boolean;
  action?: ReactNode;
};

export const TradingTableEmptyState: FC<Props> = ({ title, description, action, isClosedOrder }) => (
  <Flex
    css={{
      px: 40,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
      mt: 50,
      "@bp3": { mt: 0 },
    }}
  >
    <EmptyStateIcon isClosedOrder={isClosedOrder} />
    <Text size="3" align="center" weight="bold" css={{ mt: 12 }}>
      {title}
    </Text>
    <Text family="roboto" align="center" color="dimmed" css={{ mb: 24, mt: 6 }}>
      {description}
    </Text>
    {action}
  </Flex>
);
