import { useTerminalLayout } from "@/features/terminal/contexts/terminal-layout.context";
import { useTranslation } from "@/hooks/translator.hook";

import * as Styled from "./more-in-watch-list.styled";

export const MoreInWatchList = () => {
  const { t } = useTranslation();
  const { openWatchlist } = useTerminalLayout();
  return <Styled.WatchMoreButton onClick={openWatchlist}>{t("button.more-in-watchlist")}</Styled.WatchMoreButton>;
};
