import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { NumberInput } from "@/shared/ui";

type Props = {
  name: string;
  priceDecimalScale: number;
};

const DesktopTakeProfitField: FC<Props> = ({ name, priceDecimalScale }) => {
  const { t } = useTranslation();

  const [field] = useControlledField({ name, defaultValue: "" });

  return (
    <NumberInput
      clearable
      label={t("terminal.place-order.take-profit.label")!}
      placeholder={t("terminal.place-order.take-profit.placeholder")!}
      decimalScale={priceDecimalScale}
      {...field}
    />
  );
};

export { DesktopTakeProfitField };
