import { cva } from "class-variance-authority";
import { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { IconPlus } from "@/domains/icons";
import { TerminalDialogContent } from "@/features/terminal/components/terminal-dialog-content";

import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../feature-tour/context";
import { SelectSymbolHintPopover } from "../feature-tour/steps/select-symbol/hint-popover";
import { shineAnimationClassName } from "../feature-tour/styles";
import { WatchlistContainer } from "../watchlist/watchlist.container";
import { WatchlistPopover } from "../watchlist/watchlist-popover";
import { ChartSymbolButton } from "./chart-symbol-button";

const addButtonStyles = cva(
  "grid h-12 place-items-center rounded-[16px] bg-bg-back px-3 text-text transition-colors hover:bg-bg-selected lg:h-14 lg:rounded-[8px] lg:px-2",
  {
    variants: {
      active: {
        true: "!bg-bg-selected",
      },
    },
  },
);

type Props = {
  favoriteSymbols: string[];
  accountId: string;
  currentSymbol: string | undefined;
  setSymbol: (symbol: string) => void;
  symbolsIsLoaded: boolean;
};

const _ChartSymbols: FC<Props> = ({ favoriteSymbols, accountId, currentSymbol, setSymbol, symbolsIsLoaded }) => {
  const { watchlistOpen, isMobile, watchlistOnChange, closeWatchlist } = useTerminalLayout();
  const { t } = useTranslation();
  const { selectSymbolStyles, isSelectSymbolStepActive } = useTerminalFeatureTourContext();

  const onSymbolsScroll = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      const scrollWidth = node.scrollWidth;
      node.scrollTo({ left: -scrollWidth });
    }
  }, []);

  return (
    <div className="flex gap-2 overflow-auto" style={selectSymbolStyles.highlight}>
      {symbolsIsLoaded && (
        <div className="flex flex-row-reverse gap-3 overflow-auto scrollbar-hide lg:gap-0" ref={onSymbolsScroll}>
          {favoriteSymbols.map(symbol => {
            const activeSymbol = symbol === currentSymbol;
            if (activeSymbol) {
              return (
                <SelectSymbolHintPopover key={symbol} symbol={symbol}>
                  <ChartSymbolButton
                    className={isSelectSymbolStepActive ? shineAnimationClassName : undefined}
                    symbol={symbol}
                    chartFavoritesCount={favoriteSymbols.length}
                    accountId={accountId}
                    currentSymbol={currentSymbol}
                    setSymbol={setSymbol}
                    style={selectSymbolStyles.target}
                    showRemoveButton={!isSelectSymbolStepActive}
                  />
                </SelectSymbolHintPopover>
              );
            }

            return (
              <ChartSymbolButton
                key={symbol}
                symbol={symbol}
                chartFavoritesCount={favoriteSymbols.length}
                accountId={accountId}
                currentSymbol={currentSymbol}
                setSymbol={setSymbol}
              />
            );
          })}
        </div>
      )}

      {isMobile ? (
        <>
          <Dialog open={watchlistOpen} onOpenChange={watchlistOnChange}>
            <DialogTrigger asChild>
              <button className={addButtonStyles({ active: watchlistOpen })}>
                <IconPlus />
              </button>
            </DialogTrigger>
            <TerminalDialogContent title={t("terminal.navigation.watchlist")}>
              <WatchlistContainer onClose={closeWatchlist} />
            </TerminalDialogContent>
          </Dialog>
        </>
      ) : (
        <WatchlistPopover open={watchlistOpen} onOpenChange={watchlistOnChange} onClose={closeWatchlist}>
          <button className={addButtonStyles({ active: watchlistOpen })}>
            <IconPlus />
          </button>
        </WatchlistPopover>
      )}
    </div>
  );
};

export const ChartSymbols = memo(_ChartSymbols);
