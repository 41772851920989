import { FC } from "react";

import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { TwoFATabsContainer } from "@/features/two-factor/two-factor-tabs.container";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";

import { TwoFASteps } from "../two-factor.container";

enum DisableTwoFAFields {
  PASSWORD = "password",
  TWO_FA_CODE = "twoFactorCode",
  RECOVERY_CODE = "recoveryCode",
}

export interface IDisableTwoFAFormValues {
  [DisableTwoFAFields.PASSWORD]: string;
  [DisableTwoFAFields.RECOVERY_CODE]?: string;
  [DisableTwoFAFields.TWO_FA_CODE]?: string;
}

interface Props {
  setStep: (step: TwoFASteps) => void;
  onSubmit: (values: IDisableTwoFAFormValues) => void;
}

export const DisableTwoFAForm: FC<Props> = ({ onSubmit, setStep }) => {
  const { t } = useTranslation();
  const form = useHookForm<IDisableTwoFAFormValues>();

  return (
    <HookForm onSubmit={onSubmit} form={form}>
      <Stack>
        <TwoFATabsContainer
          recoveryCodeName={DisableTwoFAFields.RECOVERY_CODE}
          twoFACodeName={DisableTwoFAFields.TWO_FA_CODE}
          withLabels={false}
        />
        <PasswordField name={DisableTwoFAFields.PASSWORD} />
        <Flex css={{ alignItems: "center", gap: "16px", flexWrap: "wrap" }}>
          <Button onClick={() => setStep(TwoFASteps.ENABLED)}>{t("button.cancel")}</Button>
          <SubmitButton variant="secondary" css={{ whiteSpace: "pre-wrap" }}>
            {t("two-factor.settings.remove-two-fa")}
          </SubmitButton>
        </Flex>
      </Stack>
    </HookForm>
  );
};
