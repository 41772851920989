import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogClose, DialogTrigger } from "@/components/dialog";
import { IconTick } from "@/domains/icons";
import { TerminalDialogContent } from "@/features/terminal/components/terminal-dialog-content";
import { ChangeVolumeModeHintPopover } from "@/features/terminal/feature-tour/steps/change-volume-mode/hint-popover";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { TradingAccountTradeMode } from "@/services/openapi";
import { cn } from "@/shared/styles";

import { usePlaceOrderContext } from "../../context";
import { mobilePlaceOrderButtonStyles } from "../styles";

const Button: FC<{
  active: boolean;
  title: string;
  description: string;
  onClick: ComponentProps<"button">["onClick"];
}> = ({ active, onClick, description, title }) => {
  return (
    <DialogClose className={cn("-mx-4 bg-bg p-4 text-text", active && "bg-bg-selected")} onClick={onClick}>
      <div className="mb-1 flex items-center justify-between gap-3">
        <div className="font-gilroy text-[16px] font-semibold leading-[1.2]">{title}</div>
        {active && <IconTick />}
      </div>
      <div className="text-start font-roboto text-[14px] leading-[1.5] text-text-secondary">{description}</div>
    </DialogClose>
  );
};

type Props = {
  currency: string;
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
};

const SelectVolumeModeDialog: FC<Props> = ({ currency, volumeMode, changeVolumeMode }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <ChangeVolumeModeHintPopover>
        <DialogTrigger asChild>
          <button className={cn(mobilePlaceOrderButtonStyles(), "font-roboto text-[12px] leading-[1.5]")}>
            {volumeMode === TradingAccountTradeMode.Margin ? t("common.margin") : t("common.volume")}
          </button>
        </DialogTrigger>
      </ChangeVolumeModeHintPopover>
      <TerminalDialogContent title={t("terminal.place-order.volume-mode.select-title")}>
        <div>
          <Button
            onClick={() => changeVolumeMode(TradingAccountTradeMode.Margin)}
            active={volumeMode === TradingAccountTradeMode.Margin}
            title={t("terminal.place-order.margin.title", { currency })}
            description={t("terminal.place-order.margin.description")}
          />
          <Button
            onClick={() => changeVolumeMode(TradingAccountTradeMode.Lots)}
            active={volumeMode === TradingAccountTradeMode.Lots}
            title={t("terminal.place-order.lots.title")}
            description={t("terminal.place-order.lots.description")}
          />
        </div>
      </TerminalDialogContent>
    </Dialog>
  );
};

export { SelectVolumeModeDialog };
