import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";

import { TerminalPopover } from "../../components/popover";
import { AddChartSymbolButton } from "../../components/symbol/add-chart-symbol-button";
import { PendingOrder } from "../../contexts/extended-orders.context";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalLocators } from "../../locators";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { ClosePendingOrderButton } from "../close/close-pending-order-button";
import { ModifyOrderDesktopTextButton } from "../components/trading-table/modify-order-desktop-text-button";
import { OrderSymbol } from "../components/trading-table/order-symbol";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTable } from "../components/trading-table/trading-table";
import { OpeningPriceContainer } from "../modify-order/opening-price/opening-price.container";
import { ModifyPendingOrderStopLossContainer } from "../modify-order/stop-loss/pending-order-stop-loss.container";
import { ModifyPendingOrderTakeProfitContainer } from "../modify-order/take-profit/pending-order-take-profit.container";

type Props = {
  order: PendingOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
};

const _PendingOrdersTableRow: FC<Props> = ({ order, accountId, setSymbol, accountCurrency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    currentPrice,
    ask,
    bid,
    digits,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    contractSize,
    margin,
  } = order;

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, symbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.symbol}>
        <AddChartSymbolButton onClick={addChartSymbol}>
          <OrderSymbol symbol={symbol} />
        </AddChartSymbolButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.type}>
        <OrderType type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.volume}>
        <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.openPrice}>
        <ModifyOrderDesktopTextButton value={<NumberFormat value={price} decimalScale={digits} />}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.opening-price-desc")!} title={t("terminal.open-price")}>
              <OpeningPriceContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={digits!}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                currentPrice={currentPrice!}
                symbol={symbol}
                volume={volume}
                volumeDecimalScale={volumeDecimalScale!}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={digits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.takeProfit}>
        <ModifyOrderDesktopTextButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={digits} /> : null}
        >
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.take-profit-desc")!} title={t("terminal.take-profit")}>
              <ModifyPendingOrderTakeProfitContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={digits!}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency!}
                quoteCurrency={quoteCurrency!}
                contractSize={contractSize!}
                volume={volume}
                currency={accountCurrency}
                currentPrice={currentPrice!}
                symbol={symbol}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.stopLoss}>
        <ModifyOrderDesktopTextButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={digits} /> : null}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.stop-loss-desc")!} title={t("terminal.stop-loss")}>
              <ModifyPendingOrderStopLossContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={digits!}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency!}
                quoteCurrency={quoteCurrency!}
                contractSize={contractSize!}
                volume={volume}
                currency={accountCurrency}
                currentPrice={currentPrice!}
                symbol={symbol}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderDesktopTextButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.placementTime}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.pending.delete}>
        <ClosePendingOrderButton
          accountId={accountId}
          orderId={id}
          symbol={symbol}
          orderType={type}
          volume={volume}
          volumeDecimalScale={volumeDecimalScale!}
        />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

export const PendingOrdersTableRow = memo(_PendingOrdersTableRow);
