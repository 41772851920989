import React, { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useField } from "@/components/form/field.hook";
import { SelectWrapper } from "@/components/form/inputs/select/styles";
import { Description, Label } from "@/components/form/inputs/styles";
import { Select } from "@/domains/ui-kit";
import { useTranslation } from "@/hooks/translator.hook";

import { ControllerRules } from "../../types";

export type SelectNewFieldExternalProps = {
  name: string;
  options?: Array<any>;
  defaultValue?: any; // TODO:
  rules?: ControllerRules;
  resetFieldOnOptionsChanged?: boolean;
  label?: ReactNode;
  description?: ReactNode;
  selectProps?: Omit<ComponentPropsWithoutRef<typeof Select>, "optionList">;
};

const defaultOptions = [{}];

export const _SelectNewField: React.FC<SelectNewFieldExternalProps> = ({
  name,
  rules,
  defaultValue,
  resetFieldOnOptionsChanged = true,
  options = defaultOptions, // memoize options everytime to prevent infinite loop!!!
  label,
  description,
  selectProps,
}) => {
  const { t } = useTranslation();
  const { control, setValue, resetField } = useFormContext();
  const { field } = useController({ name, control, rules, defaultValue });

  const { error, showError } = useField(name);

  useEffect(() => {
    if (options.length === 1) {
      setValue(name, options[0].value, { shouldValidate: true });
    } else if (resetFieldOnOptionsChanged) {
      resetField(name);
    }
  }, [options]);

  // TODO: в дизайне нет disabled состояния
  // const isDisabledInner = isDisabled || options.length === 1 || isLoading;
  const placeholder = selectProps?.placeholder || t("fields.select.placeholder");
  const correctErrorMessage = showError ? error?.message : undefined;

  // TODO: это дефолтная обертка для каждого филда, вынести в отдельный врапер
  return (
    <SelectWrapper error={showError} disabled={false}>
      {label && <Label label={label} name={name} />}
      {description && <Description description={description} />}

      <Select
        optionList={options}
        placeholder={placeholder}
        setValue={field.onChange}
        value={field.value}
        errorMessage={correctErrorMessage}
        {...selectProps}
      />
    </SelectWrapper>
  );
};

export const SelectNewField = React.memo(_SelectNewField);
