/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AppleAuthInfo } from '../models';
// @ts-ignore
import { AuthResult } from '../models';
// @ts-ignore
import { ConfirmSignUpWithResetPwdRequest } from '../models';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { LogInRequest } from '../models';
// @ts-ignore
import { PasswordChangeRequest } from '../models';
// @ts-ignore
import { PasswordForgotRequest } from '../models';
// @ts-ignore
import { PasswordRequest } from '../models';
// @ts-ignore
import { PasswordResetRequest } from '../models';
// @ts-ignore
import { SignUpRequest } from '../models';
// @ts-ignore
import { StringContainer } from '../models';
// @ts-ignore
import { TwoFactor } from '../models';
// @ts-ignore
import { TwoFactorConfirm } from '../models';
// @ts-ignore
import { TwoFactorDisableRequest } from '../models';
// @ts-ignore
import { TwoFactorRecoveryCodeItemsContainer } from '../models';
// @ts-ignore
import { TwoFactorResult } from '../models';
// @ts-ignore
import { TwoFactorStatus } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize: async (email?: string, logInRequest?: LogInRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingApple: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingFacebook: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/facebook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleCode: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleJwt: async (stringContainer?: StringContainer, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/google/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringContainer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (passwordChangeRequest?: PasswordChangeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail: async (userId?: string, code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailWithResetPwd: async (confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/confirm/pwd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmSignUpWithResetPwdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTwoStepAuth: async (twoFactorConfirm?: TwoFactorConfirm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorConfirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuthRecoveryCodes: async (passwordRequest?: PasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/recoverycodes/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTwoStepAuth: async (twoFactorDisableRequest?: TwoFactorDisableRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorDisableRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeAppleInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signin/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthRecoveryCodes: async (passwordRequest?: PasswordRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa/recoverycodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFromAnotherDevices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (email?: string, signUpRequest?: SignUpRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationLink: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/signup/link/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByLink: async (passwordResetRequest?: PasswordResetRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecoveryPasswordLink: async (passwordForgotRequest?: PasswordForgotRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/auth/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordForgotRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorize(email?: string, logInRequest?: LogInRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorize(email, logInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingApple(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingApple(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingFacebook(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingFacebook(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingGoogleCode(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingGoogleCode(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeUsingGoogleJwt(stringContainer?: StringContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeUsingGoogleJwt(stringContainer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(passwordChangeRequest?: PasswordChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(passwordChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmail(userId?: string, code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(userId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmTwoStepAuth(twoFactorConfirm?: TwoFactorConfirm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmTwoStepAuth(twoFactorConfirm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTwoStepAuth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTwoStepAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorRecoveryCodeItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTwoStepAuthRecoveryCodes(passwordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableTwoStepAuth(twoFactorDisableRequest?: TwoFactorDisableRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableTwoStepAuth(twoFactorDisableRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizeAppleInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppleAuthInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizeAppleInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorRecoveryCodeItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoStepAuthRecoveryCodes(passwordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwoStepAuthStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFactorStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwoStepAuthStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutFromAnotherDevices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutFromAnotherDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(email?: string, signUpRequest?: SignUpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(email, signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendConfirmationLink(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendConfirmationLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordByLink(passwordResetRequest?: PasswordResetRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordByLink(passwordResetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRecoveryPasswordLink(passwordForgotRequest?: PasswordForgotRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRecoveryPasswordLink(passwordForgotRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Authorize
         * @param {string} [email] 
         * @param {LogInRequest} [logInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(email?: string, logInRequest?: LogInRequest, options?: any): AxiosPromise<string> {
            return localVarFp.authorize(email, logInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Apple
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingApple(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingApple(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Facebook
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingFacebook(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingFacebook(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Google
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleCode(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingGoogleCode(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize using Google JWT
         * @param {StringContainer} [stringContainer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeUsingGoogleJwt(stringContainer?: StringContainer, options?: any): AxiosPromise<AuthResult> {
            return localVarFp.authorizeUsingGoogleJwt(stringContainer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change password
         * @param {PasswordChangeRequest} [passwordChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(passwordChangeRequest?: PasswordChangeRequest, options?: any): AxiosPromise<string> {
            return localVarFp.changePassword(passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm email after registration
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmail(userId?: string, code?: string, options?: any): AxiosPromise<string> {
            return localVarFp.confirmEmail(userId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm email after registration with reset password
         * @param {ConfirmSignUpWithResetPwdRequest} [confirmSignUpWithResetPwdRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest, options?: any): AxiosPromise<string> {
            return localVarFp.confirmEmailWithResetPwd(confirmSignUpWithResetPwdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA confirm
         * @param {TwoFactorConfirm} [twoFactorConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTwoStepAuth(twoFactorConfirm?: TwoFactorConfirm, options?: any): AxiosPromise<TwoFactorResult> {
            return localVarFp.confirmTwoStepAuth(twoFactorConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuth(options?: any): AxiosPromise<TwoFactor> {
            return localVarFp.createTwoStepAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA generate new recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): AxiosPromise<TwoFactorRecoveryCodeItemsContainer> {
            return localVarFp.createTwoStepAuthRecoveryCodes(passwordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA disable
         * @param {TwoFactorDisableRequest} [twoFactorDisableRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableTwoStepAuth(twoFactorDisableRequest?: TwoFactorDisableRequest, options?: any): AxiosPromise<void> {
            return localVarFp.disableTwoStepAuth(twoFactorDisableRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize Apple info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeAppleInfo(options?: any): AxiosPromise<AppleAuthInfo> {
            return localVarFp.getAuthorizeAppleInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA recovery codes
         * @param {PasswordRequest} [passwordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthRecoveryCodes(passwordRequest?: PasswordRequest, options?: any): AxiosPromise<TwoFactorRecoveryCodeItemsContainer> {
            return localVarFp.getTwoStepAuthRecoveryCodes(passwordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 2FA status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwoStepAuthStatus(options?: any): AxiosPromise<TwoFactorStatus> {
            return localVarFp.getTwoStepAuthStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout from another devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFromAnotherDevices(options?: any): AxiosPromise<string> {
            return localVarFp.logoutFromAnotherDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary New registration
         * @param {string} [email] 
         * @param {SignUpRequest} [signUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(email?: string, signUpRequest?: SignUpRequest, options?: any): AxiosPromise<string> {
            return localVarFp.register(email, signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend confirmation link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationLink(options?: any): AxiosPromise<void> {
            return localVarFp.resendConfirmationLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset password by link
         * @param {PasswordResetRequest} [passwordResetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordByLink(passwordResetRequest?: PasswordResetRequest, options?: any): AxiosPromise<string> {
            return localVarFp.resetPasswordByLink(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send reset password link
         * @param {PasswordForgotRequest} [passwordForgotRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRecoveryPasswordLink(passwordForgotRequest?: PasswordForgotRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendRecoveryPasswordLink(passwordForgotRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorize operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeRequest
 */
export interface AuthApiAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiAuthorize
     */
    readonly email?: string

    /**
     * 
     * @type {LogInRequest}
     * @memberof AuthApiAuthorize
     */
    readonly logInRequest?: LogInRequest
}

/**
 * Request parameters for authorizeUsingApple operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingAppleRequest
 */
export interface AuthApiAuthorizeUsingAppleRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingApple
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for authorizeUsingFacebook operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingFacebookRequest
 */
export interface AuthApiAuthorizeUsingFacebookRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingFacebook
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for authorizeUsingGoogleCode operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingGoogleCodeRequest
 */
export interface AuthApiAuthorizeUsingGoogleCodeRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingGoogleCode
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for authorizeUsingGoogleJwt operation in AuthApi.
 * @export
 * @interface AuthApiAuthorizeUsingGoogleJwtRequest
 */
export interface AuthApiAuthorizeUsingGoogleJwtRequest {
    /**
     * 
     * @type {StringContainer}
     * @memberof AuthApiAuthorizeUsingGoogleJwt
     */
    readonly stringContainer?: StringContainer
}

/**
 * Request parameters for changePassword operation in AuthApi.
 * @export
 * @interface AuthApiChangePasswordRequest
 */
export interface AuthApiChangePasswordRequest {
    /**
     * 
     * @type {PasswordChangeRequest}
     * @memberof AuthApiChangePassword
     */
    readonly passwordChangeRequest?: PasswordChangeRequest
}

/**
 * Request parameters for confirmEmail operation in AuthApi.
 * @export
 * @interface AuthApiConfirmEmailRequest
 */
export interface AuthApiConfirmEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiConfirmEmail
     */
    readonly userId?: string

    /**
     * 
     * @type {string}
     * @memberof AuthApiConfirmEmail
     */
    readonly code?: string
}

/**
 * Request parameters for confirmEmailWithResetPwd operation in AuthApi.
 * @export
 * @interface AuthApiConfirmEmailWithResetPwdRequest
 */
export interface AuthApiConfirmEmailWithResetPwdRequest {
    /**
     * 
     * @type {ConfirmSignUpWithResetPwdRequest}
     * @memberof AuthApiConfirmEmailWithResetPwd
     */
    readonly confirmSignUpWithResetPwdRequest?: ConfirmSignUpWithResetPwdRequest
}

/**
 * Request parameters for confirmTwoStepAuth operation in AuthApi.
 * @export
 * @interface AuthApiConfirmTwoStepAuthRequest
 */
export interface AuthApiConfirmTwoStepAuthRequest {
    /**
     * 
     * @type {TwoFactorConfirm}
     * @memberof AuthApiConfirmTwoStepAuth
     */
    readonly twoFactorConfirm?: TwoFactorConfirm
}

/**
 * Request parameters for createTwoStepAuthRecoveryCodes operation in AuthApi.
 * @export
 * @interface AuthApiCreateTwoStepAuthRecoveryCodesRequest
 */
export interface AuthApiCreateTwoStepAuthRecoveryCodesRequest {
    /**
     * 
     * @type {PasswordRequest}
     * @memberof AuthApiCreateTwoStepAuthRecoveryCodes
     */
    readonly passwordRequest?: PasswordRequest
}

/**
 * Request parameters for disableTwoStepAuth operation in AuthApi.
 * @export
 * @interface AuthApiDisableTwoStepAuthRequest
 */
export interface AuthApiDisableTwoStepAuthRequest {
    /**
     * 
     * @type {TwoFactorDisableRequest}
     * @memberof AuthApiDisableTwoStepAuth
     */
    readonly twoFactorDisableRequest?: TwoFactorDisableRequest
}

/**
 * Request parameters for getTwoStepAuthRecoveryCodes operation in AuthApi.
 * @export
 * @interface AuthApiGetTwoStepAuthRecoveryCodesRequest
 */
export interface AuthApiGetTwoStepAuthRecoveryCodesRequest {
    /**
     * 
     * @type {PasswordRequest}
     * @memberof AuthApiGetTwoStepAuthRecoveryCodes
     */
    readonly passwordRequest?: PasswordRequest
}

/**
 * Request parameters for register operation in AuthApi.
 * @export
 * @interface AuthApiRegisterRequest
 */
export interface AuthApiRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthApiRegister
     */
    readonly email?: string

    /**
     * 
     * @type {SignUpRequest}
     * @memberof AuthApiRegister
     */
    readonly signUpRequest?: SignUpRequest
}

/**
 * Request parameters for resetPasswordByLink operation in AuthApi.
 * @export
 * @interface AuthApiResetPasswordByLinkRequest
 */
export interface AuthApiResetPasswordByLinkRequest {
    /**
     * 
     * @type {PasswordResetRequest}
     * @memberof AuthApiResetPasswordByLink
     */
    readonly passwordResetRequest?: PasswordResetRequest
}

/**
 * Request parameters for sendRecoveryPasswordLink operation in AuthApi.
 * @export
 * @interface AuthApiSendRecoveryPasswordLinkRequest
 */
export interface AuthApiSendRecoveryPasswordLinkRequest {
    /**
     * 
     * @type {PasswordForgotRequest}
     * @memberof AuthApiSendRecoveryPasswordLink
     */
    readonly passwordForgotRequest?: PasswordForgotRequest
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Authorize
     * @param {AuthApiAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorize(requestParameters: AuthApiAuthorizeRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorize(requestParameters.email, requestParameters.logInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Apple
     * @param {AuthApiAuthorizeUsingAppleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingApple(requestParameters: AuthApiAuthorizeUsingAppleRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingApple(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Facebook
     * @param {AuthApiAuthorizeUsingFacebookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingFacebook(requestParameters: AuthApiAuthorizeUsingFacebookRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingFacebook(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Google
     * @param {AuthApiAuthorizeUsingGoogleCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingGoogleCode(requestParameters: AuthApiAuthorizeUsingGoogleCodeRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingGoogleCode(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize using Google JWT
     * @param {AuthApiAuthorizeUsingGoogleJwtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorizeUsingGoogleJwt(requestParameters: AuthApiAuthorizeUsingGoogleJwtRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).authorizeUsingGoogleJwt(requestParameters.stringContainer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change password
     * @param {AuthApiChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(requestParameters: AuthApiChangePasswordRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).changePassword(requestParameters.passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm email after registration
     * @param {AuthApiConfirmEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmEmail(requestParameters: AuthApiConfirmEmailRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmEmail(requestParameters.userId, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm email after registration with reset password
     * @param {AuthApiConfirmEmailWithResetPwdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmEmailWithResetPwd(requestParameters: AuthApiConfirmEmailWithResetPwdRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmEmailWithResetPwd(requestParameters.confirmSignUpWithResetPwdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA confirm
     * @param {AuthApiConfirmTwoStepAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmTwoStepAuth(requestParameters: AuthApiConfirmTwoStepAuthRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).confirmTwoStepAuth(requestParameters.twoFactorConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createTwoStepAuth(options?: any) {
        return AuthApiFp(this.configuration).createTwoStepAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA generate new recovery codes
     * @param {AuthApiCreateTwoStepAuthRecoveryCodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public createTwoStepAuthRecoveryCodes(requestParameters: AuthApiCreateTwoStepAuthRecoveryCodesRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).createTwoStepAuthRecoveryCodes(requestParameters.passwordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA disable
     * @param {AuthApiDisableTwoStepAuthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public disableTwoStepAuth(requestParameters: AuthApiDisableTwoStepAuthRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).disableTwoStepAuth(requestParameters.twoFactorDisableRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize Apple info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getAuthorizeAppleInfo(options?: any) {
        return AuthApiFp(this.configuration).getAuthorizeAppleInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA recovery codes
     * @param {AuthApiGetTwoStepAuthRecoveryCodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTwoStepAuthRecoveryCodes(requestParameters: AuthApiGetTwoStepAuthRecoveryCodesRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).getTwoStepAuthRecoveryCodes(requestParameters.passwordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 2FA status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getTwoStepAuthStatus(options?: any) {
        return AuthApiFp(this.configuration).getTwoStepAuthStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout from another devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logoutFromAnotherDevices(options?: any) {
        return AuthApiFp(this.configuration).logoutFromAnotherDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary New registration
     * @param {AuthApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(requestParameters: AuthApiRegisterRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).register(requestParameters.email, requestParameters.signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend confirmation link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendConfirmationLink(options?: any) {
        return AuthApiFp(this.configuration).resendConfirmationLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset password by link
     * @param {AuthApiResetPasswordByLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPasswordByLink(requestParameters: AuthApiResetPasswordByLinkRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).resetPasswordByLink(requestParameters.passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send reset password link
     * @param {AuthApiSendRecoveryPasswordLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public sendRecoveryPasswordLink(requestParameters: AuthApiSendRecoveryPasswordLinkRequest = {}, options?: any) {
        return AuthApiFp(this.configuration).sendRecoveryPasswordLink(requestParameters.passwordForgotRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
