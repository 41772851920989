import { FC, memo, useMemo } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { PlatformCountry } from "@/services/openapi";
import { usePlatformCountriesQuery } from "@/state/server/platform";

import { SelectOptionType } from "../../inputs";
import { SelectField, SelectFieldExternalProps } from "../select";

const getCountriesOptions = (countries: PlatformCountry[]): SelectOptionType[] => {
  return countries.map(({ country }) => ({ value: country!, label: country! }));
};

type Props = Omit<SelectFieldExternalProps, "options">;

export const _CountryField: FC<Props> = props => {
  const { t } = useTranslation();

  const { data, isLoading } = usePlatformCountriesQuery();

  const options = useMemo(() => {
    const allCountries = [...(data?.countries || []), ...(data?.unsupportedCountries || [])];
    return getCountriesOptions(allCountries);
  }, [data]);

  return (
    <SelectField
      {...props}
      options={options}
      isLoading={isLoading}
      placeholder={props.placeholder || t("fields.country.country")!}
      label={props.label || t("fields.country.country")!}
    />
  );
};

export const CountryField = memo(_CountryField);
