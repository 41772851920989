import Big from "big.js";
import React, { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { logError } from "@/app/libs/sentry";
import { TerminalDealType, TradingAccount, TradingServerSymbolType } from "@/services/openapi";
import { accountsQueryKeys } from "@/state/server/accounts";

import { useExtendedOrdersContext } from "../contexts/extended-orders.context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { countMargin } from "../helpers/orders";
import { MergedTerminalSymbol } from "../helpers/symbols";

const calculateSymbolMargin = ({
  contractSize,
  marginBuyRate,
  marginSellRate,
  orders,
  leverage,
  accountCurrency,
  baseCurrency,
  instrumentType,
  quoteCurrency,
  symbols,
}: MarginInfoType) => {
  if (!baseCurrency || !quoteCurrency || !instrumentType || !contractSize || !marginBuyRate || !marginSellRate) {
    return 0;
  }

  // https://support.metaquotes.net/en/docs/mt5/client/trading_advanced/margin_forex#:~:text=final%20margin%20value.-,Example,-The%20following%20positions
  let buyVolume = new Big(0);
  let sellVolume = new Big(0);
  let averagePriceInfo = { overall: new Big(0), buy: new Big(0), sell: new Big(0) };

  orders.forEach(({ type, volume, price }) => {
    let buyPriceInfo = averagePriceInfo.buy;
    let sellPriceInfo = averagePriceInfo.sell;

    if (type === TerminalDealType.Buy) {
      buyVolume = buyVolume.plus(volume);
      buyPriceInfo = buyPriceInfo.plus(new Big(volume).mul(price));
    } else {
      sellVolume = sellVolume.plus(volume);
      sellPriceInfo = sellPriceInfo.plus(new Big(volume).mul(price));
    }
    averagePriceInfo = {
      overall: averagePriceInfo.overall.plus(new Big(volume).mul(price)),
      buy: buyPriceInfo,
      sell: sellPriceInfo,
    };
  });

  const largerLeg: TerminalDealType = buyVolume.gt(sellVolume) ? TerminalDealType.Buy : TerminalDealType.Sell;

  const hedgedVolume = largerLeg === TerminalDealType.Buy ? sellVolume : buyVolume;
  const nonHedgedVolume = buyVolume.minus(sellVolume).abs();

  const hedgedWeightedAverageOpenPrice = averagePriceInfo.overall.div(buyVolume.plus(sellVolume));
  const nonHedgedWeightedAverageOpenPrice =
    largerLeg === TerminalDealType.Buy ? averagePriceInfo.buy.div(buyVolume) : averagePriceInfo.sell.div(sellVolume);

  const marginRatioHedged = new Big(marginBuyRate).plus(marginSellRate).div(2);
  const marginRatioNonHedged = largerLeg === TerminalDealType.Buy ? marginBuyRate : marginSellRate;

  const hedgedVolumeMargin = countMargin({
    accountCurrency,
    baseCurrency,
    contractSize,
    instrumentType,
    leverage,
    marginRate: "maintenance",
    quoteCurrency,
    symbols,
    volume: hedgedVolume.toNumber(),
    openPrice: hedgedWeightedAverageOpenPrice.toNumber(),
    marginRateInitialMarketBuy: marginRatioHedged.toNumber(),
    marginRateInitialMarketSell: marginRatioHedged.toNumber(),
    marginRateMaintenanceMarketBuy: marginRatioHedged.toNumber(),
    marginRateMaintenanceMarketSell: marginRatioHedged.toNumber(),
    type: TerminalDealType.Sell, // FIXME: do not pass the type
  });
  const nonHedgedVolumeMargin = countMargin({
    accountCurrency,
    baseCurrency,
    contractSize,
    instrumentType,
    leverage,
    marginRate: "maintenance",
    quoteCurrency,
    symbols,
    volume: nonHedgedVolume.toNumber(),
    openPrice: nonHedgedWeightedAverageOpenPrice.toNumber(),
    marginRateInitialMarketBuy: marginRatioNonHedged,
    marginRateInitialMarketSell: marginRatioNonHedged,
    marginRateMaintenanceMarketBuy: marginRatioNonHedged,
    marginRateMaintenanceMarketSell: marginRatioNonHedged,
    type: TerminalDealType.Sell,
  });

  return new Big(hedgedVolumeMargin).plus(nonHedgedVolumeMargin).toNumber();
};

type MarginInfoType = {
  marginBuyRate: number | undefined;
  marginSellRate: number | undefined;
  contractSize: number | undefined;
  leverage: number;
  symbols: MergedTerminalSymbol[];
  accountCurrency: string;
  baseCurrency: string | undefined;
  quoteCurrency: string | undefined;
  instrumentType: TradingServerSymbolType | undefined;
  orders: { volume: number; type: TerminalDealType; price: number }[];
};
type MarginListType = {
  [keys: string]: MarginInfoType;
};

type SummaryDataType = {
  pnl: number;
  swaps: number;
  margin: number;
  marginLevel: number;
};

type ContextProps = {
  equity: number;
  marginFree: number;
  balance: number;
  margin: number;
  credit: number;
  marginLevel: number;
  leverage: number;
  pnl: number;
  swaps: number;
  currency: string;
  currencyDecimalScale: number;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode; account: TradingAccount }> = ({ children, account }) => {
  const queryClient = useQueryClient();

  const { equity, currency, marginFree, balance, leverage, credit, id, digits } = account;

  const { extendedOpenOrders } = useExtendedOrdersContext();
  const { symbols } = useSymbolsContext();

  const [summaryData, setSummaryData] = useState<SummaryDataType>({
    pnl: 0,
    margin: 0,
    marginLevel: 0,
    swaps: 0,
  });

  useEffect(() => {
    try {
      let profitAndLossBig = new Big(0);
      let swapBig = new Big(0);
      let marginBig = new Big(0);

      const list: MarginListType = {};

      extendedOpenOrders.forEach(order => {
        const {
          contractSize,
          baseCurrency,
          quoteCurrency,
          marginRateMaintenanceMarketSell,
          marginRateMaintenanceMarketBuy,
          instrumentType,
          pnl,
          swap,
          type,
          volume,
          symbol,
          price,
        } = order;

        profitAndLossBig = profitAndLossBig.plus(pnl);

        swapBig = swapBig.plus(swap);

        list[symbol] = {
          contractSize: contractSize,
          marginBuyRate: marginRateMaintenanceMarketBuy,
          marginSellRate: marginRateMaintenanceMarketSell,
          leverage: leverage!,
          accountCurrency: currency!,
          baseCurrency: baseCurrency,
          quoteCurrency: quoteCurrency,
          symbols,
          instrumentType: instrumentType,
          orders: list[symbol] ? [...list[symbol].orders, { type, volume, price }] : [{ type, volume, price }],
        };
      });

      for (const symbol in list) {
        marginBig = marginBig.plus(calculateSymbolMargin(list[symbol]));
      }

      const pnl = profitAndLossBig.plus(swapBig).toNumber();
      const equityBig = new Big(balance!).plus(credit!).plus(pnl);

      const marginFree = equityBig.minus(marginBig).toNumber();

      setSummaryData({
        pnl,
        margin: marginBig.toNumber(),
        marginLevel: marginBig.toNumber() ? new Big(equityBig).div(marginBig).mul(100).toNumber() : 0,
        swaps: swapBig.toNumber(),
      });
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(id!), oldData => {
        return { ...oldData, equity: equityBig.toNumber(), marginFree };
      });
    } catch (error) {
      logError(error);
    }
  }, [extendedOpenOrders, symbols, balance, leverage, credit]);

  const value: ContextProps = useMemo(
    () => ({
      balance: balance!,
      margin: summaryData.margin,
      marginLevel: summaryData.marginLevel,
      pnl: summaryData.pnl,
      swaps: summaryData.swaps,
      currency: currency!,
      credit: credit!,
      equity: equity!,
      leverage: leverage!,
      marginFree: marginFree!,
      currencyDecimalScale: digits!,
    }),
    [balance, summaryData, currency, credit, equity, leverage, marginFree, digits],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useTerminalAccountSummary = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalAccountSummary must be used within a TerminalAccountSummaryContextProvider");
  }

  return context;
};

export { Provider as TerminalAccountSummaryContextProvider, useTerminalAccountSummary };
