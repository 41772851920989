import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
};

const ChangeVolumeModeHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { isChangeVolumeModeStepActive, interruptTour, changeVolumeMode, totalSteps, currentStep, isMobile } =
    useTerminalFeatureTourContext();

  if (!isChangeVolumeModeStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      sideOffset={isMobile ? 0 : 8}
      side={isMobile ? "top" : "left"}
      content={
        <Hint
          title={t("terminal.onboarding.change-volume-mode.title")}
          text={t("terminal.onboarding.change-volume-mode.description")}
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
            <Button size="sm" onClick={changeVolumeMode}>
              {t("terminal.onboarding.buttons.next")}
            </Button>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { ChangeVolumeModeHintPopover };
