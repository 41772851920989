import { ComponentProps, FC } from "react";

import { CSS, styled } from "@/styles";

import { linkStyles } from "./link";

export const StyledLink = styled("a", linkStyles);

type Props = ComponentProps<typeof StyledLink> & { css?: CSS };

export const ExternalLink: FC<Props> = ({ children, ...props }) => {
  return (
    <StyledLink target="_blank" rel="noreferrer" {...props}>
      {children}
    </StyledLink>
  );
};
