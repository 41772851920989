import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const getDepositRedirectSearchParam = (res: string) => `?${redirectPageQueryParam}=${res}`;
const redirectPageQueryParam = "depositLink";

/*
  Страница используется для обхода блокировки всплывающих окон в Safari и Firefox
  нужна только в депозитах, тк некоторые платёжки требуют открытия ссылки биллинга в новом окне
*/

export const DepositRedirectPage = () => {
  const [searchParams] = useSearchParams();
  const redirectLink = searchParams.get(redirectPageQueryParam);

  useEffect(() => {
    if (!redirectLink) return;

    const form = document.createElement("form");
    form.action = redirectLink;
    form.method = "get";
    form.style.display = "none";

    document.body.appendChild(form);

    form.submit();
  }, []);

  return null;
};
