import { FC, useMemo } from "react";

import { Box } from "@/components/box";
import { Header } from "@/components/header";
import { TradingServerPlatform } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";

export const MT5TerminalPage: FC = () => {
  const { data: servers } = useServersQuery();

  const mt5Server = useMemo(
    () => servers?.find(({ platformType }) => platformType === TradingServerPlatform.MetaTrader5),
    [servers],
  );

  if (!servers) return null;

  return (
    <>
      <Header />
      <Box
        as="iframe"
        css={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        src={`${mt5Server!.terminalWebUrl}?mode=connect`}
      />
    </>
  );
};
