import React, { ComponentProps, ElementRef, forwardRef } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { useTranslation } from "@/hooks/translator.hook";

import { TerminalBackDialogContent } from "../../components/terminal-back-dialog-content";
import { OpenOrder } from "../../contexts/extended-orders.context";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { CloseOpenOrderButton } from "../close/close-open-order-button";
import { MobileModifyOrderTextButton } from "../components/trading-table/mobile-modify-order-text-button";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTableCard } from "../components/trading-table/trading-table-card";
import { PartialCloseContainer } from "../modify-order/partial-close/partial-close.container";
import { ModifyOpenOrderStopLossContainer } from "../modify-order/stop-loss/open-order-stop-loss.container";
import { ModifyOpenOrderTakeProfitContainer } from "../modify-order/take-profit/open-order-take-profit.container";

type Props = {
  order: OpenOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onCardClose?: () => void;
};

const _OpenOrderCard = forwardRef<ElementRef<typeof TradingTableCard>, Props>(
  ({ order, accountId, accountCurrency, onCardClose, setSymbol, currencyDecimalScale }, ref) => {
    const { t } = useTranslation();

    const {
      symbol,
      type,
      volume,
      price,
      stopLoss,
      takeProfit,
      id,
      date,
      swap,
      contractSize,
      digits,
      volumeStep,
      volumeMin,
      volumeDecimalScale,
      baseCurrency,
      quoteCurrency,
      ask,
      bid,
      currentPrice,
      pnl,
      margin,
    } = order;

    const { addChartSymbol } = useAddChartSymbol({
      setSymbol,
      symbol,
      accountId,
      callback: onCardClose,
    });

    return (
      <TradingTableCard
        ref={ref}
        type={type}
        volume={volume}
        volumeDecimalScale={volumeDecimalScale}
        rightBottomBlock={<>{currentPrice ? <NumberFormat value={currentPrice} decimalScale={digits} /> : "—"}</>}
        onSymbolClick={addChartSymbol}
        symbol={symbol}
        pnl={
          <div className={getNumberColorClassname(pnl)}>
            <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
          </div>
        }
        button={<CloseOpenOrderButton orderId={id} accountId={accountId} />}
      >
        <TradingTableCard.List>
          <TradingTableCard.ItemLabel>{t("common.type")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            <OrderType type={type} withCircle={false} />
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.volume-lot")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue hasBackground>
            <MobileModifyOrderTextButton value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}>
              {onClose => (
                <TerminalBackDialogContent title={t("terminal.modify-order.partial-close")!}>
                  <PartialCloseContainer
                    onClose={onClose}
                    volume={volume}
                    volumeStep={volumeStep!}
                    volumeMin={volumeMin!}
                    volumeDecimalScale={volumeDecimalScale!}
                    currentPrice={currentPrice!}
                    orderType={type}
                    orderId={id}
                    contractSize={contractSize!}
                    baseCurrency={baseCurrency!}
                    quoteCurrency={quoteCurrency!}
                    accountId={accountId}
                    currency={accountCurrency}
                    openPrice={price}
                    symbol={symbol}
                    priceDecimalScale={digits!}
                    swap={swap}
                    currencyDecimalScale={currencyDecimalScale}
                    isMobile
                  />
                </TerminalBackDialogContent>
              )}
            </MobileModifyOrderTextButton>
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>
            {t("terminal.margin-currency", { currency: accountCurrency })}
          </TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.open-price")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            <NumberFormat value={price} decimalScale={digits} />
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.current-price")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            {currentPrice ? <NumberFormat value={currentPrice} decimalScale={digits} /> : "—"}
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.take-profit")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue hasBackground>
            <MobileModifyOrderTextButton
              value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={digits} /> : null}
            >
              {onClose => (
                <TerminalBackDialogContent title={t("terminal.take-profit")!}>
                  <ModifyOpenOrderTakeProfitContainer
                    onClose={onClose}
                    symbol={symbol}
                    volume={volume}
                    orderType={type}
                    orderId={id}
                    priceDecimalScale={digits!}
                    stopLoss={stopLoss}
                    takeProfit={takeProfit}
                    ask={ask}
                    bid={bid}
                    accountId={accountId}
                    baseCurrency={baseCurrency!}
                    quoteCurrency={quoteCurrency!}
                    contractSize={contractSize!}
                    currency={accountCurrency}
                    openPrice={price}
                    currentPrice={currentPrice!}
                    currencyDecimalScale={currencyDecimalScale}
                    isMobile
                  />
                </TerminalBackDialogContent>
              )}
            </MobileModifyOrderTextButton>
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.stop-loss")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue hasBackground>
            <MobileModifyOrderTextButton
              value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={digits} /> : null}
            >
              {onClose => (
                <TerminalBackDialogContent title={t("terminal.stop-loss")!}>
                  <ModifyOpenOrderStopLossContainer
                    onClose={onClose}
                    symbol={symbol}
                    volume={volume}
                    orderType={type}
                    orderId={id}
                    priceDecimalScale={digits!}
                    stopLoss={stopLoss}
                    takeProfit={takeProfit}
                    ask={ask}
                    bid={bid}
                    accountId={accountId}
                    baseCurrency={baseCurrency!}
                    quoteCurrency={quoteCurrency!}
                    contractSize={contractSize!}
                    currency={accountCurrency}
                    openPrice={price}
                    currentPrice={currentPrice!}
                    currencyDecimalScale={currencyDecimalScale}
                    isMobile
                  />
                </TerminalBackDialogContent>
              )}
            </MobileModifyOrderTextButton>
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.position")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>{id}</TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>{t("terminal.open-time")}</TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>{terminalFormatDate(date)}</TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>
            {t("terminal.swap")}, {accountCurrency}
          </TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
          </TradingTableCard.ItemValue>
          <TradingTableCard.ItemLabel>
            {t("terminal.pnl-currency", { currency: accountCurrency })}
          </TradingTableCard.ItemLabel>
          <TradingTableCard.ItemValue>
            <div className={getNumberColorClassname(pnl)}>
              <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
            </div>
          </TradingTableCard.ItemValue>
        </TradingTableCard.List>
      </TradingTableCard>
    );
  },
);

const OpenOrderCard = React.memo(_OpenOrderCard);

export { OpenOrderCard };
