import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { StepperInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../../context";
import { VolumeLotsFieldDescriptor } from "../../../volume-lots/descriptor";

type Props = Omit<ComponentProps<typeof VolumeLotsFieldDescriptor>, "isMobile"> & {
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  volumeLotsFormValue: ReturnType<typeof usePlaceOrderContext>["volumeLotsFormValue"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  isSubmitting: boolean;
};

const MobileVolumeLotsField: FC<Props> = ({
  changeLots,
  currency,
  decrementOrder,
  decrementVolumeDisabled,
  hasNoFreeMargin,
  incrementOrder,
  incrementVolumeDisabled,
  isSubmitting,
  maxAvailableVolumeLots,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  minSystemVolumeLots,
  volumeLotsFormValue,
  volumeLotsDecimalScale,
  volumeLotsError,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  return (
    <StepperInput
      size="sm"
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeLotsFormValue}
      suffix={` ${t("terminal.lots")}`}
      onIncrement={incrementOrder}
      onDecrement={decrementOrder}
      onChange={value => changeLots(value, { format: false })}
      invalid={volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin}
      decimalScale={volumeLotsDecimalScale}
      plusDisabled={incrementVolumeDisabled}
      minusDisabled={decrementVolumeDisabled}
      descriptor={
        <VolumeLotsFieldDescriptor
          isMobile
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          changeLots={changeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          currency={currency}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
        />
      }
    />
  );
};

export { MobileVolumeLotsField };
