import * as DialogPrimitive from "@radix-ui/react-dialog";
import { forwardRef } from "react";

import { focusModalAutocloseElement } from "@/components/dialog/helpers";
import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { IconArrowLeft } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { CSS, styled } from "@/styles";

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  inset: "0",
  zIndex: 50,

  display: "grid",
  placeItems: "center",
  overflowY: "auto",
  overflowX: "hidden",
  hideScroll: true,
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "$bgPrimary",
  width: "100%",
  height: "100%",
  position: "relative",
  zIndex: 50,

  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",

  p: "16px",

  "&:focus": {
    outline: "none",
  },
});

const IconButton = styled(UnstyledButton, {
  borderRadius: "$round",
  height: 24,
  width: 24,
  display: "grid",
  placeItems: "center",
  color: "$textMain",
  outline: "none",
});

type Props = {
  children: React.ReactNode;
  title?: string;
  css?: CSS;
};

export const TerminalBackDialogContent = forwardRef<React.ElementRef<typeof StyledContent>, Props>(
  ({ children, title, css }, forwardedRef) => {
    const { t } = useTranslation();
    return (
      <DialogPrimitive.Portal>
        <StyledOverlay>
          <StyledContent ref={forwardedRef} css={css} onOpenAutoFocus={focusModalAutocloseElement}>
            <Flex css={{ alignItems: "center", gap: 16, mb: 16 }}>
              <DialogPrimitive.Close asChild>
                <IconButton aria-label={t("button.close")!}>
                  <IconArrowLeft />
                </IconButton>
              </DialogPrimitive.Close>
              {title && (
                <DialogPrimitive.Title asChild>
                  <Text weight="bold" size="5">
                    {title}
                  </Text>
                </DialogPrimitive.Title>
              )}
            </Flex>
            <div className="-m-1 flex h-full flex-col overflow-y-auto p-1 scrollbar-hide">{children}</div>
          </StyledContent>
        </StyledOverlay>
      </DialogPrimitive.Portal>
    );
  },
);
TerminalBackDialogContent.displayName = "TerminalBackDialogContent";
