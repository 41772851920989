import { FC, memo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useField } from "../../field.hook";
import { TileRadio, TileRadioExternalProps } from "../../inputs";
import { ControllerRules } from "../../types";

type Props = TileRadioExternalProps & {
  name: string;
  rules?: ControllerRules;
};

export const _TileRadioField: FC<Props> = props => {
  const { name, rules, defaultValue, ...restProps } = props;

  const { control } = useFormContext();
  const { field } = useController({ name, control, rules, defaultValue });

  const { error, showError } = useField(name);

  return <TileRadio {...restProps} error={error} showError={showError} {...field} />;
};

export const TileRadioField = memo(_TileRadioField);
