export const WithdrawalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <g clipPath="url(#clip0_1525_176222)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.547 23.95c-6.1 0-11-4.9-11-11s4.9-11 11-11a10.965 10.965 0 0110.956 10c.049.55-.404 1-.956 1-.552 0-.994-.45-1.054-1a8.958 8.958 0 00-8.946-8c-5 0-9 4-9 9s4 9 9 9a9.13 9.13 0 004.772-1.341c.472-.286 1.097-.202 1.428.24l.015.02c.325.434.247 1.053-.208 1.348-1.755 1.133-3.843 1.732-6.007 1.732zm.997-17.47a.78.78 0 00-1.559 0v1.056c-1.273.14-2.093.635-2.578 1.302-.54.742-.539 1.537-.539 1.765v.009c0 .296.05.966.621 1.633.562.657 1.536 1.203 3.147 1.473 1.388.232 1.973.66 2.22.949.238.279.246.527.246.62 0 .174-.007.536-.24.857-.202.277-.709.702-2.098.702-1.331 0-1.851-.48-2.078-.822a1.611 1.611 0 01-.26-.745v-.001a.78.78 0 00-1.558.01l.749.001h-.749v.022a1.657 1.657 0 00.01.15 3.169 3.169 0 00.51 1.427c.467.7 1.28 1.299 2.597 1.469v1.06a.78.78 0 101.559 0v-1.054c1.274-.141 2.093-.635 2.578-1.302.54-.742.539-1.537.539-1.765v-.009c0-.296-.05-.966-.621-1.633-.562-.657-1.536-1.203-3.147-1.473-1.388-.233-1.973-.66-2.22-.95-.238-.278-.246-.526-.246-.62 0-.173.007-.535.24-.856.202-.277.709-.702 2.098-.702 1.331 0 1.851.48 2.078.822a1.61 1.61 0 01.26.745v.001a.78.78 0 001.558-.01v-.023a1.348 1.348 0 00-.01-.15 3.168 3.168 0 00-.51-1.427c-.467-.7-1.28-1.3-2.597-1.469V6.481zm-3.927 8.81l.81-.004v.001l-.81.002zm10.157 4.856l1.293 1.293a2 2 0 002.828 0l1.293-1.293a1 1 0 00-1.414-1.414l-.293.293v-4a1 1 0 00-2 0v4l-.293-.293a1 1 0 00-1.414 1.414z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1525_176222">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.481 .95)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
