/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum BarTimeframe {
    Minute1 = 'Minute1',
    Minute5 = 'Minute5',
    Minute15 = 'Minute15',
    Minute30 = 'Minute30',
    Hour1 = 'Hour1',
    Hour4 = 'Hour4',
    Day1 = 'Day1',
    Week1 = 'Week1',
    Month1 = 'Month1'
}



