import React from "react";

export const TransactionHistoryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12C20 7.58172 16.4183 4 12 4C9.7129 4 7.64918 4.95944 6.19032 6.5H8.625C9.17729 6.5 9.625 6.94772 9.625 7.5C9.625 8.05228 9.17729 8.5 8.625 8.5H4.20404C3.65175 8.5 3.20404 8.05228 3.20404 7.5V3C3.20404 2.44772 3.65175 2 4.20404 2C4.75632 2 5.20404 2.44772 5.20404 3V4.66427C6.98731 3.0115 9.37523 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 11.4477 2.44772 11 3 11C3.55228 11 4 11.4477 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
      fill="#EEEEEE"
    />
    <path
      d="M12.2261 9.00849C12.2261 8.53646 11.7784 8.15381 11.2261 8.15381C10.6738 8.15381 10.2261 8.53646 10.2261 9.00849V13.2819C10.2261 13.5677 10.3932 13.8345 10.6714 13.9931L13.6714 15.7024C14.1309 15.9643 14.7518 15.8581 15.0581 15.4654C15.3645 15.0726 15.2403 14.542 14.7808 14.2801L12.2261 12.8245V9.00849Z"
      fill="#EEEEEE"
    />
  </svg>
);
