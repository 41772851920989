import { FC } from "react";

import { PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { TwoFATabsContainer } from "@/features/two-factor/two-factor-tabs.container";
import { useHookForm } from "@/hooks/form.hook";
import { usePasswordRules } from "@/hooks/password-rules.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { confirmPasswordRules } from "@/utils/validators";

enum ChangePasswordFields {
  PASSWORD = "password",
  OLD_PASSWORD = "oldPassword",
  CONFIRM_PASSWORD = "confirmPassword",
  TWO_FA_CODE = "twoFactorCode",
  RECOVERY_CODE = "recoveryCode",
}

export interface IChangePasswordFormValues {
  [ChangePasswordFields.OLD_PASSWORD]?: string;
  [ChangePasswordFields.PASSWORD]: string;
  [ChangePasswordFields.CONFIRM_PASSWORD]: string;
  [ChangePasswordFields.TWO_FA_CODE]?: string;
  [ChangePasswordFields.RECOVERY_CODE]?: string;
}

interface Props {
  onSubmit: (values: IChangePasswordFormValues) => void;
  is2FaEnabled: boolean;
  isPasswordEnabled: boolean;
}

export const ChangePasswordForm: FC<Props> = ({ onSubmit, is2FaEnabled, isPasswordEnabled }) => {
  const { t } = useTranslation();

  const form = useHookForm<IChangePasswordFormValues>();
  const { watch } = form;

  const password = watch(ChangePasswordFields.PASSWORD);

  const { isPasswordCorrect, border, passwordText, maxLength } = usePasswordRules(password);

  return (
    <HookForm onSubmit={onSubmit} form={form} resetOnSuccess>
      <Stack gap="4" css={{ mb: "20px" }}>
        {isPasswordEnabled && (
          <PasswordField
            name={ChangePasswordFields.OLD_PASSWORD}
            label={t("profile-settings.change-password.old-password")}
            maxLength={maxLength}
          />
        )}
        <PasswordField
          name={ChangePasswordFields.PASSWORD}
          label={t("profile-settings.change-password.new-password")}
          registerOptions={{
            required: t("form-errors.required-error")!,
            deps: [ChangePasswordFields.CONFIRM_PASSWORD],
          }}
          border={border}
          passwordText={passwordText}
          maxLength={maxLength}
        />
        <PasswordField
          name={ChangePasswordFields.CONFIRM_PASSWORD}
          label={t("profile-settings.change-password.repeat-password")}
          registerOptions={confirmPasswordRules(t, password)}
          maxLength={maxLength}
        />
        {is2FaEnabled && (
          <TwoFATabsContainer
            recoveryCodeName={ChangePasswordFields.RECOVERY_CODE}
            twoFACodeName={ChangePasswordFields.TWO_FA_CODE}
          />
        )}
      </Stack>
      <SubmitButton disabled={!isPasswordCorrect}>{t("button.save")}</SubmitButton>
    </HookForm>
  );
};
