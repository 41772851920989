import { FC } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";

import { AuthFormContainer } from "../auth-form.container";
import { EmailConfirmContainer } from "./email-confirm.container";

export const EmailConfirmPage: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const code = searchParams.get("code");

  if (!userId || !code) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return (
    <AuthFormContainer title={t("auth.email-confirm.title")}>
      <EmailConfirmContainer userId={userId} code={code} />
    </AuthFormContainer>
  );
};
