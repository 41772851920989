import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { BottomSheet, BottomSheetContent } from "@/components/bottom-sheet";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { Button } from "@/shared/ui";

import img from "./assets/Shield.png";
import { Props } from "./types";
import { fromDepositState } from "./verification.container";

export const DepositVerificationBottomSheet: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <BottomSheet open={open} onOpenChange={onOpenChange}>
      <BottomSheetContent
        image={{
          src: img,
        }}
        title={t("deposit.verification.dialog.title")!}
        description={t("deposit.verification.dialog.description")!}
      >
        <div className="px-6">
          <Button variant="secondary" className="w-full" asChild>
            <Link to={onboardingRoutes.onboarding} state={fromDepositState}>
              {t("deposit.verification.dialog.button")}
            </Link>
          </Button>
        </div>
      </BottomSheetContent>
    </BottomSheet>
  );
};
