import { FC } from "react";

import { Button } from "@/components/button";
import { Flex } from "@/components/flex";
import { ExternalLink } from "@/components/link";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { TwoFactor } from "@/services/openapi";
import { useCreateTwoFAMutation } from "@/state/server/auth";

import { TwoFASteps } from "../two-factor.container";

interface Props {
  setStep: (step: TwoFASteps) => void;
  setTwoFactorData: (data: TwoFactor) => void;
}

export const DisabledTwoFAContainer: FC<Props> = ({ setStep, setTwoFactorData }) => {
  const { t } = useTranslation();

  const { mutate: createTwoFa, isLoading } = useCreateTwoFAMutation();

  const handleCreate = () => {
    createTwoFa(undefined, {
      onSuccess: res => {
        setTwoFactorData(res);
        setStep(TwoFASteps.CONFIRM);
      },
    });
  };

  return (
    <>
      <Text family="roboto" lineHeight="3">
        {t("two-factor.settings.can-use")}
      </Text>
      <Flex css={{ gap: "20px", my: "16px" }}>
        <ExternalLink href="https://apps.apple.com/app/google-authenticator/id388497605">
          <Button size="small" variant={"secondary"}>
            App store
          </Button>
        </ExternalLink>
        <ExternalLink href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
          <Button size="small" variant={"secondary"}>
            Google play
          </Button>
        </ExternalLink>
      </Flex>
      <Button disabled={isLoading} onClick={handleCreate}>
        {t("two-factor.settings.add-two-step")}
      </Button>
    </>
  );
};
