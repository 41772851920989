import { ComponentProps, type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";

import { usePlaceOrderDetails } from "../../details/use-details";
import { MobilePlaceOrderSection } from "../order-section/order-section";
import { PlaceOrderDetailsSymbolBlock as SymbolBlock } from "./symbol-block";

type Props = ComponentProps<typeof SymbolBlock> &
  Parameters<typeof usePlaceOrderDetails>[0] & {
    children: ReactNode;
  };

const MobilePlaceOrderDetails: FC<Props> = ({ children, symbol, baseCurrencyExt, group, ...props }) => {
  const { t } = useTranslation();

  const { baseCurrency, contractSize, accountCurrency } = props;

  const {
    leverage,
    unitsLabel,
    volume,
    units,
    sellCurrentPrice,
    buyCurrentPrice,
    sellTradeAmount,
    buyTradeAmount,
    sellMargin,
    buyMargin,
    swapSell,
    swapBuy,
    pipsSpread,
    pipValue,
    currencySpread,
  } = usePlaceOrderDetails(props);

  return (
    <MobilePlaceOrderSection title={t("terminal.place-order.details.title")}>
      <SymbolBlock
        instrumentType={props.instrumentType}
        baseCurrency={baseCurrency}
        contractSize={contractSize}
        symbol={symbol}
        baseCurrencyExt={baseCurrencyExt}
        group={group}
      />
      <Table.Card>
        <Table>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.lots")}</Table.Label>
            <Table.Value>
              {volume ? <NumberFormat value={volume.value} decimalScale={volume.decimalScale} /> : "–"}
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{unitsLabel}</Table.Label>
            <Table.Value>{units ? <NumberFormat value={units.value} /> : "–"}</Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.leverage")}</Table.Label>
            <Table.Value>{leverage}</Table.Value>
          </Table.Row>
        </Table>
      </Table.Card>
      <Table.Card>
        <Table>
          <Table.Row>
            <Table.Label></Table.Label>
            <Table.Value>{t("common.sell")}</Table.Value>
            <Table.Value>{t("common.buy")}</Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.current-price")}</Table.Label>
            <Table.Value>
              <NumberFormat value={sellCurrentPrice.value} decimalScale={sellCurrentPrice.decimalScale} />
            </Table.Value>
            <Table.Value>
              <NumberFormat value={buyCurrentPrice.value} decimalScale={buyCurrentPrice.decimalScale} />
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.trade-amount", { currency: accountCurrency })}</Table.Label>
            <Table.Value>
              {sellTradeAmount ? (
                <NumberFormat value={sellTradeAmount.value} decimalScale={sellTradeAmount.decimalScale} />
              ) : (
                "–"
              )}
            </Table.Value>
            <Table.Value>
              {buyTradeAmount ? (
                <NumberFormat value={buyTradeAmount.value} decimalScale={buyTradeAmount.decimalScale} />
              ) : (
                "–"
              )}
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.margin", { currency: accountCurrency })}</Table.Label>
            <Table.Value>
              {sellMargin ? <NumberFormat value={sellMargin.value} decimalScale={sellMargin.decimalScale} /> : "–"}
            </Table.Value>
            <Table.Value>
              {buyMargin ? <NumberFormat value={buyMargin.value} decimalScale={buyMargin.decimalScale} /> : "–"}
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.swap", { currency: accountCurrency })}</Table.Label>
            <Table.Value>
              {swapSell ? <NumberFormat value={swapSell.value} decimalScale={swapSell.decimalScale} /> : "–"}
            </Table.Value>
            <Table.Value>
              {swapBuy ? <NumberFormat value={swapBuy.value} decimalScale={swapBuy.decimalScale} /> : "–"}
            </Table.Value>
          </Table.Row>
        </Table>
      </Table.Card>
      <Table.Card>
        <Table>
          {pipsSpread && (
            <Table.Row>
              <Table.Label>{t("terminal.place-order.details.spread-pips")}</Table.Label>
              <Table.Value>
                <NumberFormat value={pipsSpread.value} decimalScale={pipsSpread.decimalScale} />
              </Table.Value>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Label>{t("terminal.place-order.details.pip", { currency: accountCurrency })}</Table.Label>
            <Table.Value>
              {pipValue ? <NumberFormat value={pipValue.value} decimalScale={pipValue.decimalScale} /> : "–"}
            </Table.Value>
          </Table.Row>
          {currencySpread && (
            <Table.Row>
              <Table.Label>
                {t("terminal.place-order.details.spread-currency", { currency: accountCurrency })}
              </Table.Label>
              <Table.Value className={currencySpread.className}>
                <NumberFormat value={currencySpread.value} decimalScale={currencySpread.decimalScale} />
              </Table.Value>
            </Table.Row>
          )}
        </Table>
      </Table.Card>
      <div className="h-16" />
      <div className="fixed bottom-0 end-0 start-0">
        <div className="flex gap-2 p-4 *:flex-1">{children}</div>
      </div>
    </MobilePlaceOrderSection>
  );
};

export { MobilePlaceOrderDetails };
