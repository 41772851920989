import { forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

import { StyledInput } from "../styles";
import { InputWrapper, InputWrapperExternalProps } from "../wrapper";

export type TextNumberInputExternalProps = NumericFormatProps &
  InputWrapperExternalProps &
  React.ComponentProps<typeof StyledInput>;

type Props = Omit<TextNumberInputExternalProps, "onChange"> & { onChange: (value: string) => void; value?: string };

export const TextNumberInput = forwardRef<React.ElementRef<typeof StyledInput>, Props>((props, ref) => {
  const {
    css,
    leftAdornment,
    rightAdornment,
    showError,
    error,
    bottomOffset,
    onChange,
    type = "text",
    allowLeadingZeros = true,
    decimalScale = 0,
    allowNegative = false,
    name,
    label,
    disabled,
    description,
    ...inputProps
  } = props;

  return (
    <InputWrapper
      css={css}
      leftAdornment={leftAdornment}
      rightAdornment={rightAdornment}
      showError={showError}
      error={error}
      bottomOffset={bottomOffset}
      name={name}
      label={label}
      disabled={disabled}
      description={description}
    >
      <NumericFormat
        {...inputProps}
        disabled={disabled}
        getInputRef={ref}
        onValueChange={({ value }) => {
          onChange(value);
        }}
        customInput={StyledInput}
        type={type}
        inputMode="numeric"
        valueIsNumericString
        allowLeadingZeros={allowLeadingZeros}
        decimalScale={decimalScale}
        allowNegative={allowNegative}
        id={name}
        name={name}
      />
    </InputWrapper>
  );
});
TextNumberInput.displayName = "TextNumberInput";
