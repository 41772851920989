import { Content, Overlay, Portal } from "@radix-ui/react-dialog";
import React from "react";

import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, overlayStyles } from "@/shared/ui";

type Props = {
  children: React.ReactNode;
};

const HintDialogContent: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Overlay className={overlayStyles({ hasAnimation: false })} />
      <Portal>
        <Content className={cn(centeredDialogContentStyles(), "w-[90vw] max-w-[300px]")}>{children}</Content>
      </Portal>
    </>
  );
};

export { HintDialogContent };
