import { authService } from "../api";
import {
  AuthApiAuthorizeRequest,
  AuthApiAuthorizeUsingFacebookRequest,
  AuthApiAuthorizeUsingGoogleCodeRequest,
  AuthApiChangePasswordRequest,
  AuthApiConfirmEmailRequest,
  AuthApiConfirmTwoStepAuthRequest,
  AuthApiCreateTwoStepAuthRecoveryCodesRequest,
  AuthApiDisableTwoStepAuthRequest,
  AuthApiRegisterRequest,
  AuthApiResetPasswordByLinkRequest,
  AuthApiSendRecoveryPasswordLinkRequest,
} from "../openapi";

export const signIn = async (values: AuthApiAuthorizeRequest) => await (await authService().authorize(values)).data;

export const forgotPassword = async (values: AuthApiSendRecoveryPasswordLinkRequest) =>
  await (
    await authService().sendRecoveryPasswordLink(values)
  ).data;

export const resetPassword = async (values: AuthApiResetPasswordByLinkRequest) =>
  await (
    await authService().resetPasswordByLink(values)
  ).data;

export const signUp = async (values: AuthApiRegisterRequest) => await (await authService().register(values)).data;

export const resendConfirmationLink = async () => await (await authService().resendConfirmationLink()).data;

export const confirmEmail = async (values: AuthApiConfirmEmailRequest) =>
  await (
    await authService().confirmEmail(values)
  ).data;

export const changePassword = async (values: AuthApiChangePasswordRequest) =>
  await (
    await authService().changePassword(values)
  ).data;

export const confirmTwoStepAuth = async (values: AuthApiConfirmTwoStepAuthRequest) =>
  await (
    await authService().confirmTwoStepAuth(values)
  ).data;

export const createTwoStepAuth = async () => await (await authService().createTwoStepAuth()).data;

export const disableTwoStepAuth = async (values: AuthApiDisableTwoStepAuthRequest) =>
  await (
    await authService().disableTwoStepAuth(values)
  ).data;

export const createTwoStepAuthRecoveryCodes = async (values: AuthApiCreateTwoStepAuthRecoveryCodesRequest) =>
  await (
    await authService().createTwoStepAuthRecoveryCodes(values)
  ).data;

export const signInGoogle = async (values: AuthApiAuthorizeUsingGoogleCodeRequest) =>
  await (
    await authService().authorizeUsingGoogleCode(values)
  ).data;

export const signInFacebook = async (values: AuthApiAuthorizeUsingFacebookRequest) =>
  await (
    await authService().authorizeUsingFacebook(values)
  ).data;
