import React from "react";

export const LimitsIcon = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39835 20.2995C1.65046 18.235 0 15.0037 0 11.5C0 5.42487 4.92487 0.5 11 0.5C17.0751 0.5 22 5.42487 22 11.5C22 15.0037 20.3495 18.235 17.6017 20.2995L17.3348 20.5H4.66522L4.39835 20.2995ZM16.6575 18.5C18.753 16.805 20 14.257 20 11.5C20 6.52944 15.9706 2.5 11 2.5C6.02944 2.5 2 6.52944 2 11.5C2 14.257 3.24698 16.805 5.34253 18.5H16.6575ZM16.3192 6.07346L14.6808 4.92654L12.0477 8.68803C11.7216 8.56645 11.3685 8.5 11 8.5C9.34315 8.5 8 9.84315 8 11.5C8 13.1569 9.34315 14.5 11 14.5C12.6569 14.5 14 13.1569 14 11.5C14 10.9445 13.849 10.4243 13.5859 9.9782L16.3192 6.07346ZM12 11.5C12 12.0523 11.5523 12.5 11 12.5C10.4477 12.5 10 12.0523 10 11.5C10 10.9477 10.4477 10.5 11 10.5C11.5523 10.5 12 10.9477 12 11.5Z"
      fill="#212121"
    />
  </svg>
);
