import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";

import { usePlaceOrderDetails } from "../../details/use-details";
import { DesktopPlaceOrderDetailsList as List } from "./list";

type Props = Parameters<typeof usePlaceOrderDetails>[0] & {
  type: "sell" | "buy";
};

const DesktopPlaceOrderDetails: FC<Props> = ({ type, ...props }) => {
  const { t } = useTranslation();

  const { accountCurrency } = props;

  const {
    leverage,
    unitsLabel,
    volume,
    units,
    sellCurrentPrice,
    buyCurrentPrice,
    sellTradeAmount,
    buyTradeAmount,
    sellMargin,
    buyMargin,
    swapSell,
    swapBuy,
    pipsSpread,
    pipValue,
    currencySpread,
  } = usePlaceOrderDetails(props);

  const currentPrice = type === "buy" ? buyCurrentPrice : sellCurrentPrice;
  const tradeAmount = type === "buy" ? buyTradeAmount : sellTradeAmount;
  const margin = type === "buy" ? buyMargin : sellMargin;
  const swap = type === "buy" ? swapBuy : swapSell;

  return (
    <List>
      <List.Row>
        <List.Label>{t("terminal.place-order.details.lots")}</List.Label>
        <List.Value>
          {volume ? <NumberFormat value={volume.value} decimalScale={volume.decimalScale} /> : "–"}
        </List.Value>
      </List.Row>
      <List.Row>
        <List.Label>{unitsLabel}</List.Label>
        <List.Value>{units ? <NumberFormat value={units.value} /> : "–"}</List.Value>
      </List.Row>
      <List.Row>
        <List.Label>{t("terminal.place-order.details.current-price")}</List.Label>
        <List.Value>
          <NumberFormat value={currentPrice.value} decimalScale={currentPrice.decimalScale} />
        </List.Value>
      </List.Row>
      <List.Row>
        <List.Label>{t("terminal.place-order.details.trade-amount", { currency: accountCurrency })}</List.Label>
        <List.Value>
          {tradeAmount ? <NumberFormat value={tradeAmount.value} decimalScale={tradeAmount.decimalScale} /> : "–"}
        </List.Value>
      </List.Row>
      <List.Row>
        <List.Label>{t("terminal.place-order.details.leverage")}</List.Label>
        <List.Value>{leverage}</List.Value>
      </List.Row>
      <List.Row>
        <List.Label>{t("terminal.place-order.details.margin", { currency: accountCurrency })}</List.Label>
        <List.Value>
          {margin ? <NumberFormat value={margin.value} decimalScale={margin.decimalScale} /> : "–"}
        </List.Value>
      </List.Row>
      {pipsSpread && (
        <List.Row>
          <List.Label>{t("terminal.place-order.details.spread-pips")}</List.Label>
          <List.Value>
            <NumberFormat value={pipsSpread.value} decimalScale={pipsSpread.decimalScale} />
          </List.Value>
        </List.Row>
      )}
      <List.Row>
        <List.Label>{t("terminal.place-order.details.pip", { currency: accountCurrency })}</List.Label>
        <List.Value>
          {pipValue ? <NumberFormat value={pipValue.value} decimalScale={pipValue.decimalScale} /> : "–"}
        </List.Value>
      </List.Row>
      {currencySpread && (
        <List.Row>
          <List.Label>{t("terminal.place-order.details.spread-currency", { currency: accountCurrency })}</List.Label>
          <List.Value className={currencySpread.className}>
            <NumberFormat value={currencySpread.value} decimalScale={currencySpread.decimalScale} />
          </List.Value>
        </List.Row>
      )}
      <List.Row>
        <List.Label>{t("terminal.place-order.details.swap", { currency: accountCurrency })}</List.Label>
        <List.Value>{swap ? <NumberFormat value={swap.value} decimalScale={swap.decimalScale} /> : "–"}</List.Value>
      </List.Row>
    </List>
  );
};

export { DesktopPlaceOrderDetails };
