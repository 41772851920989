import { cva } from "class-variance-authority";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { HookForm, useHookForm } from "@/app/form";
import { IconFilter, IconMinus, IconPlus } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { TradingAccountTradeMode } from "@/services/openapi";
import { cn } from "@/shared/styles";

import { useTerminalFeatureTourContext } from "../../feature-tour/context";
import { LotsIncrementOrderHintPopover } from "../../feature-tour/steps/increment-order/lots-popover";
import { MarginIncrementOrderHintPopover } from "../../feature-tour/steps/increment-order/margin-popover";
import { PlaceOrderHintPopover } from "../../feature-tour/steps/place-order/hint-popover";
import { shineAnimationClassName } from "../../feature-tour/styles";
import { usePlaceOrderContext, VolumeLotsError } from "../context";
import { PlaceOrderButtonType } from "../place-order.helpers";
import { mobilePlaceOrderButtonStyles } from "./styles";
import { MobilePlaceOrderSubmitButtons } from "./submit-buttons/buttons";
import { VolumeHintError } from "./volume-hint-error";
import { SelectVolumeModeDialog } from "./volume-mode/select-dialog";

const buttonStyles = cva(
  "grid select-none place-items-center text-text transition-colors hover:text-text-secondary disabled:pointer-events-none disabled:text-text-placeholder",
)();

type Props = {
  changeVolumeModeStyles: ReturnType<typeof useTerminalFeatureTourContext>["changeVolumeModeStyles"];
  incrementOrderStyles: ReturnType<typeof useTerminalFeatureTourContext>["incrementOrderStyles"];
  placeOrderStyles: ReturnType<typeof useTerminalFeatureTourContext>["placeOrderStyles"];
  isPlaceOrderStepActive: ReturnType<typeof useTerminalFeatureTourContext>["isPlaceOrderStepActive"];
  isIncrementOrderStepActive: ReturnType<typeof useTerminalFeatureTourContext>["isIncrementOrderStepActive"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeSellMargin: ReturnType<typeof usePlaceOrderContext>["volumeSellMargin"];
  volumeBuyMargin: ReturnType<typeof usePlaceOrderContext>["volumeBuyMargin"];
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  accountCurrency: string;
  onSubmit: (side: PlaceOrderButtonType) => Promise<unknown>;
  openVolumeSettings: () => void;
  openOrderSettings: () => void;
  incrementOrder: () => void;
  accountId: string;
  isDemoAccount: boolean;
};

const MobilePlaceOrderForm: FC<Props> = ({
  incrementOrderStyles,
  placeOrderStyles,
  isPlaceOrderStepActive,
  maxBalanceVolumeLots,
  minSystemVolumeLots,
  maxSystemVolumeLots,
  changeLots,
  volumeMode,
  volumeLots,
  volumeMargin,
  onSubmit,
  accountCurrency,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  volumeLotsStep,
  decrementVolumeDisabled,
  incrementVolumeDisabled,
  incrementOrder,
  decrementOrder,
  hasNoFreeMargin,
  volumeLotsError,
  volumeMarginDecimalScale,
  volumeLotsDecimalScale,
  volumeSellMargin,
  volumeBuyMargin,
  changeVolumeMode,
  openVolumeSettings,
  openOrderSettings,
  changeVolumeModeStyles,
  isIncrementOrderStepActive,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  const [showError, { open: showVolumeError, close: closeVolumeError }] = useDisclosure();

  const form = useHookForm();

  const handleSubmit = (_: unknown, side: PlaceOrderButtonType) => {
    if (hasNoFreeMargin || volumeLotsError !== VolumeLotsError.NONE) {
      showVolumeError();
      return Promise.resolve();
    }
    return onSubmit(side);
  };

  const incrementButton = (
    <button
      className={buttonStyles}
      style={incrementOrderStyles.target}
      disabled={incrementVolumeDisabled}
      onClick={incrementOrder}
    >
      <IconPlus />
    </button>
  );

  return (
    <HookForm form={form}>
      <VolumeHintError
        open={showError}
        onClose={closeVolumeError}
        volumeMode={volumeMode}
        changeLots={changeLots}
        currency={accountCurrency}
        hasNoFreeMargin={hasNoFreeMargin}
        maxBalanceVolumeLots={maxBalanceVolumeLots}
        maxBalanceVolumeMargin={maxBalanceVolumeMargin}
        maxSystemVolumeLots={maxSystemVolumeLots}
        maxSystemVolumeMargin={maxSystemVolumeMargin}
        minSystemVolumeLots={minSystemVolumeLots}
        minSystemVolumeMargin={minSystemVolumeMargin}
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        volumeLotsError={volumeLotsError}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        accountId={accountId}
        isDemoAccount={isDemoAccount}
      >
        <div
          className="flex justify-between gap-0.5 bg-bg-back p-2"
          style={isIncrementOrderStepActive ? incrementOrderStyles.highlight : changeVolumeModeStyles}
        >
          <SelectVolumeModeDialog
            currency={accountCurrency}
            volumeMode={volumeMode}
            changeVolumeMode={changeVolumeMode}
          />
          <div className={cn(mobilePlaceOrderButtonStyles(), "w-auto grow gap-3 px-6")}>
            <button className={buttonStyles} disabled={decrementVolumeDisabled} onClick={decrementOrder}>
              <IconMinus />
            </button>
            <button
              className="h-full grow select-none font-gilroy text-[16px] font-semibold leading-[1.2]"
              onClick={openVolumeSettings}
            >
              {volumeMode === TradingAccountTradeMode.Lots && (
                <NumberFormat
                  value={volumeLots}
                  suffix={` ${t("terminal.lots")}`}
                  decimalScale={volumeLotsDecimalScale}
                />
              )}
              {volumeMode === TradingAccountTradeMode.Margin && (
                <NumberFormat value={volumeMargin} currency={accountCurrency} decimalScale={volumeMarginDecimalScale} />
              )}
            </button>
            {volumeMode === TradingAccountTradeMode.Lots ? (
              <LotsIncrementOrderHintPopover
                volumeLotsStep={volumeLotsStep}
                currentVolume={volumeLots || 0}
                volumeLotsDecimalScale={volumeLotsDecimalScale}
              >
                {incrementButton}
              </LotsIncrementOrderHintPopover>
            ) : (
              <MarginIncrementOrderHintPopover>{incrementButton}</MarginIncrementOrderHintPopover>
            )}
          </div>
          <button className={mobilePlaceOrderButtonStyles()} onClick={openOrderSettings}>
            <IconFilter />
          </button>
        </div>
      </VolumeHintError>
      <PlaceOrderHintPopover>
        <div className="-mt-2 flex gap-2 bg-bg-back p-2 *:flex-1" style={placeOrderStyles}>
          <MobilePlaceOrderSubmitButtons
            hasNoFreeMargin={hasNoFreeMargin}
            volumeLotsError={volumeLotsError}
            volumeMode={volumeMode}
            accountCurrency={accountCurrency}
            volumeSellMargin={volumeSellMargin}
            volumeBuyMargin={volumeBuyMargin}
            volumeMarginDecimalScale={volumeMarginDecimalScale}
            onSubmit={handleSubmit}
            className={isPlaceOrderStepActive ? shineAnimationClassName : undefined}
            persistActive
          />
        </div>
      </PlaceOrderHintPopover>
    </HookForm>
  );
};

export { MobilePlaceOrderForm };
