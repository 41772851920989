import { FC, useState } from "react";

import { NumberFormat } from "@/app/components";
import { Dialog, DialogTrigger } from "@/components/dialog";
import { Popover, PopoverTrigger } from "@/components/popover";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui/button";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { TerminalPopover } from "../../components/popover";
import { TerminalBackDialogContent } from "../../components/terminal-back-dialog-content";
import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { terminalLocators } from "../../locators";
import { getNumberColorClassname } from "../../terminal.styles-helpers";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  pnl: number;
  swaps: number;
  ordersCount: number;
};

export const CloseAllOpenOrdersButton: FC<Props> = ({
  accountId,
  ordersCount,
  pnl,
  swaps,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useTerminalLayout();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useCloseTerminalOrdersMutation();

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Market } },
      {
        onSuccess: () => {
          setOpen(false);
        },
      },
    );
  };

  const trigger = (
    <Button variant="flat" size="sm" data-test={terminalLocators.tradingTables.open.closeAll}>
      {t("terminal.orders.close-all")}
    </Button>
  );

  const info = (
    <>
      <TerminalPopover.List>
        <TerminalPopover.ListItem value={ordersCount} label={t("terminal.total-trades")} />
        <TerminalPopover.ListItem
          value={<NumberFormat value={swaps} decimalScale={currencyDecimalScale} />}
          label={t("terminal.total-swap", { currency: accountCurrency })}
        />
        <TerminalPopover.ListItem
          value={
            <div className={getNumberColorClassname(pnl)}>
              <NumberFormat value={pnl} decimalScale={currencyDecimalScale} />
            </div>
          }
          label={t("terminal.total-pnl-currency", { currency: accountCurrency })}
        />
      </TerminalPopover.List>
    </>
  );

  return isMobile ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <TerminalBackDialogContent>
        <Text size="5" weight="bold" css={{ lineHeight: 1.2, mb: 24 }}>
          {t("terminal.orders.close-all-open-message")}
        </Text>
        {info}
        <TerminalPopover.Buttons isMobile>
          <Button pending={isLoading} onClick={closeOrders} className="w-full">
            {t("terminal.orders.close-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalBackDialogContent>
    </Dialog>
  ) : (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <TerminalPopover title={t("terminal.orders.close-all")}>
        {info}
        <TerminalPopover.Buttons>
          <Button pending={isLoading} onClick={closeOrders} className="w-full">
            {t("terminal.orders.close-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalPopover>
    </Popover>
  );
};
