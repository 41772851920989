import { useComposedRefs } from "@radix-ui/react-compose-refs";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  type ReactElement,
  type ReactNode,
  useRef,
} from "react";

import { IconCross, IconMinus, IconPlus } from "@/domains/icons";

import {
  _Button,
  _DescriptorButton,
  type _ExternalNumericInputProps,
  type _ExternalWrapperProps,
  _Input,
  _NumericInput,
  _Wrapper,
} from "../_base";

type Props = _ExternalNumericInputProps &
  Omit<_ExternalWrapperProps, "endSection"> & {
    value: string;
    onChange: (value: string) => void;
    renderPlusButton?: (element: ReactElement) => ReactNode; // needed for feature-tour
    onIncrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    onDecrement?: ComponentPropsWithoutRef<typeof _Button>["onClick"];
    plusDisabled?: boolean;
    minusDisabled?: boolean;
    clearable?: boolean;
  };

const NumberInput = forwardRef<ElementRef<typeof _NumericInput>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      size,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      onIncrement,
      onDecrement,
      plusDisabled,
      minusDisabled,
      descriptor,
      renderWrapper,
      renderPlusButton,
      tooltipContent,
      ...props
    },
    forwardedRef,
  ) => {
    const showClearButton = clearable && value !== null && value !== "";

    const hasEndSection = !!(showClearButton || onIncrement || onDecrement);

    const ref = useRef<ElementRef<typeof _NumericInput>>(null);
    const composedRefs = useComposedRefs(ref, forwardedRef);

    const plusButton = (
      <_Button disabled={plusDisabled || disabled} onClick={onIncrement}>
        <IconPlus />
      </_Button>
    );

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        size={size}
        disabled={disabled}
        descriptor={descriptor}
        renderWrapper={renderWrapper}
        tooltipContent={tooltipContent}
        endSection={
          hasEndSection ? (
            <>
              {showClearButton && (
                <_Button
                  disabled={disabled}
                  onClick={() => {
                    ref.current!.focus();
                    onChange("");
                  }}
                >
                  <IconCross />
                </_Button>
              )}
              {onDecrement && (
                <_Button disabled={minusDisabled || disabled} onClick={onDecrement}>
                  <IconMinus />
                </_Button>
              )}
              {onIncrement && (renderPlusButton ? renderPlusButton(plusButton) : plusButton)}
            </>
          ) : null
        }
      >
        <_NumericInput
          value={value}
          onValueChange={({ value }) => onChange(value)}
          customInput={_Input}
          disabled={disabled}
          {...props}
          ref={composedRefs}
        />
      </_Wrapper>
    );
  },
);

const Component = Object.assign(NumberInput, {
  DescriptorButton: _DescriptorButton,
});

export { Component as NumberInput };
