import { FC } from "react";

import { TextNumberField } from "@/components/form/fields";
import { useTranslation } from "@/hooks/translator.hook";
import { twoFACodeRules, twoFARecoveryRules } from "@/utils/validators";

import { TwoFATabs } from "./two-factor-tabs";

type Props = {
  twoFACodeName: string;
  recoveryCodeName: string;
  withLabels?: boolean;
};

export const TwoFATabsContainer: FC<Props> = ({ twoFACodeName, recoveryCodeName, withLabels = true }) => {
  const { t } = useTranslation();

  return (
    <TwoFATabs
      renderTwoFACode={() => (
        <TextNumberField
          name={twoFACodeName}
          label={withLabels ? t("two-factor.two-fa-code") : undefined}
          placeholder={t("two-factor.two-fa-code")!}
          rules={twoFACodeRules(t)}
        />
      )}
      renderRecoveryCode={() => (
        <TextNumberField
          name={recoveryCodeName}
          label={withLabels ? t("two-factor.recovery-code") : undefined}
          placeholder={t("two-factor.recovery-code")!}
          rules={twoFARecoveryRules(t)}
        />
      )}
    />
  );
};
