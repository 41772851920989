import { CSS } from "@/styles";

export const ButtonNewUI: Omit<CSS, "hideScroll"> = {
  width: "100%",
  borderRadius: "32px",
  background: "#19C651",
  p: "18.5px 54px",
  fontSize: "16px",
  "&:hover": {
    background: "#00B53E",
  },
  "&:disabled": {
    opacity: 1,
    background: "$textPlaceholder",
  },
};

export const ButtonNewUIFlat: Omit<CSS, "hideScroll"> = {
  ...ButtonNewUI,
  background: "$white",
  color: "$textMain",
  border: "1px solid $bgBorder",

  "&:hover": {
    background: "$white",
    color: "$textSecondary",
  },
  "&:disabled": {
    background: "$white",
    color: "$textPlaceholder",
  },
};

export const WithdrawalFormFields = {
  PAYMENT_METHOD_ID: "paymentMethodId",
  AMOUNT: "amount",
  ACCOUNT_ID: "accountId",
} as const;
