import { useTerminalAccountContext } from "../contexts/account.context";
import { TerminalDepositButton } from "./terminal-deposit-button";

export const TerminalDepositButtonContainer = () => {
  const {
    terminalType,
    account: { id, currency },
  } = useTerminalAccountContext();

  return <TerminalDepositButton accountId={id!} currency={currency!} terminalType={terminalType} />;
};
