import { cva } from "class-variance-authority";
import { type FC, useState } from "react";

import { NumberFormat } from "@/app/components";
import { IconTick } from "@/domains/icons";

import { usePlaceOrderContext } from "../context";
import { getMarginSuggesterOptions } from "./helpers";

const buttonStyles = cva(
  "flex h-14 w-full items-center justify-between gap-2 px-4 font-roboto text-[14px] leading-[1.5] text-text transition-colors hover:bg-bg-back lg:px-6 lg:py-5 lg:text-[16px]",
  {
    variants: {
      selected: {
        true: "!bg-bg-selected",
      },
    },
  },
);

type Props = {
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
  currency: string;
};

const VolumeSuggesterValuesButtons: FC<Props> = ({
  volumeLots,
  changeLots,
  currency,
  volumeMarginDecimalScale,
  marginMultiplier,
  maxAvailableVolumeLots,
  minSystemVolumeLots,
  volumeLotsStep,
}) => {
  const [options] = useState(() =>
    getMarginSuggesterOptions({
      marginMultiplier,
      minSystemVolumeLots,
      maxAvailableVolumeLots,
      volumeLots,
      volumeLotsStep,
      volumeMarginDecimalScale,
    }),
  );

  return (
    <>
      {options.map(option => (
        <button
          key={option.volumeLots}
          className={buttonStyles({ selected: option.volumeLots === volumeLots })}
          // !!! on click not working properly with onblur.
          // We need to fire event before input onBlur
          // https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
          onMouseDown={() => changeLots(option.volumeLots)}
        >
          <NumberFormat
            value={option.volumeMargin}
            currency={currency}
            decimalScale={volumeMarginDecimalScale}
            fixedDecimalScale
          />
          {option.volumeLots === volumeLots && <IconTick />}
        </button>
      ))}
    </>
  );
};

export { VolumeSuggesterValuesButtons };
