import {
  getApiUrl,
  getCountries,
  getDate,
  getPlatformInfo,
  getPlatformLocales,
  getTranslations,
} from "@/services/platform";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import { QueryOptionsType } from "../types";
import { platformQueryKeys } from "./platform.keys";

export const usePlatformCountriesQuery = (options?: QueryOptionsType<ApiResponseType<typeof getCountries>>) => {
  return useBaseQuery<ApiResponseType<typeof getCountries>>({
    queryKey: platformQueryKeys.countries(),
    queryFn: getCountries,
    staleTime: Infinity,
    cacheTime: Infinity,
    authSensitive: false,
    ...options,
  });
};

export const usePlatformInfoQuery = (options?: QueryOptionsType<ApiResponseType<typeof getPlatformInfo>>) => {
  return useBaseQuery<ApiResponseType<typeof getPlatformInfo>>({
    queryKey: platformQueryKeys.info(),
    queryFn: getPlatformInfo,
    staleTime: Infinity,
    cacheTime: Infinity,
    authSensitive: false,
    ...options,
  });
};

export const usePlatformLocalesQuery = (options?: QueryOptionsType<ApiResponseType<typeof getPlatformLocales>>) => {
  return useBaseQuery<ApiResponseType<typeof getPlatformLocales>>({
    queryKey: platformQueryKeys.locales(),
    queryFn: getPlatformLocales,
    staleTime: Infinity,
    cacheTime: Infinity,
    authSensitive: false,
    ...options,
  });
};

export const useDateQuery = (options?: QueryOptionsType<any>) => {
  return useBaseQuery<any>({
    queryKey: ["date"],
    queryFn: getDate,
    refetchOnWindowFocus: false,
    authSensitive: false,
    ...options,
  });
};

export const usePlatformTranslationsQuery = (
  filters: ApiRequestParamsType<typeof getTranslations>,
  options?: QueryOptionsType<ApiResponseType<typeof getTranslations>>,
) => {
  const newFilters = {
    ...filters,
    keys: [
      "Footer::Text-1",
      "Footer::Text-2",
      "Footer::Text-3",
      "Footer::Text-4",
      "Footer::Text-5",
      "Footer::Text-6",
      "Footer::Text-7",
      "Footer::ContactUs",
      "Footer::Email",
      "Footer::Copyright",
      "Footer::about-us::doc",
      "Footer::about-us::word",
      "Footer::legal-documents::doc",
      "Footer::legal-documents::word",
      "Footer::affiliate-program::doc",
      "Footer::affiliate-program::word",
      "Footer::help-center::doc",
      "Footer::help-center::word",
      "legal::sign_up",
    ],
  };

  return useBaseQuery<ApiResponseType<typeof getTranslations>>({
    queryKey: platformQueryKeys.translations(),
    queryFn: () => getTranslations(newFilters),
    ...options,
  });
};

export const usePlatformApiUrlQuery = (options?: QueryOptionsType<ApiResponseType<typeof getApiUrl>>) => {
  return useBaseQuery<ApiResponseType<typeof getApiUrl>>({
    queryKey: platformQueryKeys.apiUrl(),
    queryFn: getApiUrl,
    staleTime: Infinity,
    cacheTime: Infinity,
    authSensitive: false,
    ...options,
  });
};
