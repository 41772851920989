import Big from "big.js";

import { TerminalDealType, TradingServerSymbolType } from "@/services/openapi";

import { getBaseSymbol, getQuoteSymbol } from "../helpers";
import { getOrderCurrentPrice } from "../trading-tables/trading-tables.helpers";
import { getInstrumentType, MergedTerminalSymbol } from "./symbols";

type PipSizeParams = { priceDecimalScale: number };
const calculatePipSize = ({ priceDecimalScale }: PipSizeParams) => {
  const denominator = new Big(10).pow(priceDecimalScale - 1);
  return new Big(1).div(denominator).toNumber();
};

type PipValueParams = PipSizeParams & { volumeLots: number | string; contractSize: number };
const calculatePipValue = ({ volumeLots, contractSize, priceDecimalScale }: PipValueParams) => {
  const pipSize = calculatePipSize({ priceDecimalScale });
  return new Big(volumeLots).mul(contractSize).mul(pipSize).toNumber();
};

const calculateLeverage = ({
  instrumentType,
  leverage,
  marginRateInitialMarketBuy,
}: {
  leverage: number;
  instrumentType: TradingServerSymbolType;
  marginRateInitialMarketBuy: number;
}) => {
  const isForex = getInstrumentType(instrumentType) === "forex";

  if (isForex) {
    return new Big(leverage).div(marginRateInitialMarketBuy).toNumber();
  }

  return new Big(1).div(marginRateInitialMarketBuy).toNumber();
};

export const calculateCurrency = ({
  accountCurrency,
  volumeLots,
  contractSize,
  quoteCurrency,
  baseCurrency,
  ask,
  bid,
  type,
  symbols,
}: {
  volumeLots: number | string;
  contractSize: number;
  quoteCurrency: string;
  accountCurrency: string;
  baseCurrency: string;
  bid: number | undefined;
  ask: number | undefined;
  type: "buy" | "sell";
  symbols: MergedTerminalSymbol[];
}) => {
  if (!volumeLots) {
    return 0;
  }

  const price = (type === "buy" ? ask : bid) || 0;

  if (quoteCurrency === accountCurrency) {
    return new Big(volumeLots).mul(contractSize).mul(price).toNumber();
  }

  if (baseCurrency === accountCurrency) {
    return new Big(volumeLots).mul(contractSize).toNumber();
  }

  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: accountCurrency,
    quoteCurrencyPredicate: baseCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 0;
    }
    const baseCurrentPrice = type === "buy" ? baseSymbol.ask : baseSymbol.bid;
    return new Big(volumeLots).mul(contractSize).mul(baseCurrentPrice).toNumber();
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    quoteCurrencyPredicate: accountCurrency,
    baseCurrencyPredicate: baseCurrency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 0;
    }
    const quoteCurrentPrice = type === "buy" ? quoteSymbol.ask : quoteSymbol.bid;
    return new Big(volumeLots).mul(contractSize).mul(quoteCurrentPrice).toNumber();
  }

  return 0;
};

type CurrencyRateParams = {
  ask: number;
  bid: number;
  accountCurrency: string;
  baseCurrency: string;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  type: TerminalDealType;
};
const calculateCurrencyRate = ({
  accountCurrency,
  baseCurrency,
  ask,
  bid,
  quoteCurrency,
  symbols,
  type,
}: CurrencyRateParams) => {
  if (baseCurrency === accountCurrency) {
    return 1;
  }

  if (quoteCurrency === accountCurrency) {
    return getOrderCurrentPrice(type, ask, bid)!;
  }

  const baseSymbol = getBaseSymbol({
    symbols,
    baseCurrencyPredicate: accountCurrency,
    quoteCurrencyPredicate: quoteCurrency,
  });

  if (baseSymbol) {
    if (!baseSymbol.ask || !baseSymbol.bid) {
      return 1;
    }
    const baseCurrentPrice = getOrderCurrentPrice(type, baseSymbol.ask, baseSymbol.bid)!;
    return new Big(1).div(baseCurrentPrice);
  }

  const quoteSymbol = getQuoteSymbol({
    symbols,
    quoteCurrencyPredicate: accountCurrency,
    baseCurrencyPredicate: quoteCurrency,
  });

  if (quoteSymbol) {
    if (!quoteSymbol.ask || !quoteSymbol.bid) {
      return 1;
    }
    const quoteCurrentPrice = getOrderCurrentPrice(type, quoteSymbol.ask, quoteSymbol.bid)!;
    return quoteCurrentPrice;
  }

  return 1;
};

type SwapParams = Omit<CurrencyRateParams, "type"> &
  PipSizeParams & {
    volumeLots: number | string;
    type: "buy" | "sell";
    swapLong: number;
    swapShort: number;
    contractSize: number;
  };

const calculateSwap = ({
  volumeLots,
  type,
  swapLong,
  swapShort,
  priceDecimalScale,
  ask,
  bid,
  accountCurrency,
  baseCurrency,
  quoteCurrency,
  symbols,
  contractSize,
}: SwapParams) => {
  const swapRate = type === "buy" ? swapLong : swapShort;
  const pointSize = new Big(calculatePipSize({ priceDecimalScale })).div(10);

  const currencyRate = calculateCurrencyRate({
    ask,
    bid,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    symbols,
    type: type === "buy" ? TerminalDealType.Buy : TerminalDealType.Sell,
  });

  return new Big(volumeLots).mul(swapRate).mul(pointSize).mul(contractSize).mul(currencyRate).toNumber();
};

type PipsSpreadParams = PipSizeParams & {
  ask: number;
  bid: number;
};

const pipsSpreadDecimalScale = 1;
const calculatePipsSpread = ({ ask, bid, priceDecimalScale }: PipsSpreadParams) => {
  const pipSize = calculatePipSize({ priceDecimalScale });
  return new Big(ask).minus(bid).div(pipSize).toNumber();
};

type CurrencySpreadParams = Omit<CurrencyRateParams, "type"> & {
  contractSize: number;
};

const calculateCurrencySpread = ({
  ask,
  bid,
  accountCurrency,
  baseCurrency,
  symbols,
  quoteCurrency,
  contractSize,
}: CurrencySpreadParams) => {
  const currencyRate = calculateCurrencyRate({
    ask,
    bid,
    accountCurrency,
    baseCurrency,
    quoteCurrency,
    symbols,
    type: TerminalDealType.Buy,
  });

  return new Big(ask).minus(bid).mul(contractSize).mul(currencyRate).toNumber();
};

export {
  calculateCurrencySpread,
  calculatePipValue,
  calculatePipsSpread,
  calculateSwap,
  calculateLeverage,
  pipsSpreadDecimalScale,
};
