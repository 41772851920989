import { FC, memo } from "react";
import { Link, To } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { IconDeposit } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";
import { Button } from "@/shared/ui";

import { useTerminalLayout } from "../contexts/terminal-layout.context";

type Props = {
  accountId: string;
  currency: string;
  terminalType: TradingAccountType;
};

const _TerminalDepositButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();
  const { isMobile, isMobileAppMode } = useTerminalLayout();

  const link: To = { pathname: cabinetRoutes.deposit, search: `accountId=${accountId}` };

  if (isMobile) {
    return (
      <Button onClick={() => track(amplitudeEvents.deposit.fromTerminal)} variant="secondary" size="sm" asChild>
        {isMobileAppMode ? (
          <a href={cabinetRoutes.deposit}>{t("button.deposit")}</a>
        ) : (
          <Link to={link}>{t("button.deposit")}</Link>
        )}
      </Button>
    );
  }

  return (
    <Button
      onClick={() => track(amplitudeEvents.deposit.fromTerminal)}
      startSection={<IconDeposit />}
      variant="secondary"
      className="w-full"
      asChild
    >
      <Link to={link}>{t("button.deposit")}</Link>
    </Button>
  );
};

export const TerminalDepositButton = memo(_TerminalDepositButton);
