import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
};

const CheckFreeMarginHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { isCheckFreeMarginStepActive, interruptTour, checkFreeMargin, totalSteps, currentStep, isMobile } =
    useTerminalFeatureTourContext();

  if (!isCheckFreeMarginStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      sideOffset={isMobile ? 16 : 8}
      content={
        <Hint
          title={t("terminal.onboarding.check-free-margin.title")}
          text={t("terminal.onboarding.check-free-margin.description")}
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
            <Button size="sm" onClick={checkFreeMargin}>
              {t("terminal.onboarding.buttons.next")}
            </Button>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { CheckFreeMarginHintPopover };
