export const AutoTerminalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2.481 1.95a1 1 0 011 1v16a3 3 0 003 3h16a1 1 0 010 2h-16a5 5 0 01-5-5v-16a1 1 0 011-1z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M20.188 10.242a1 1 0 010 1.414l-2.586 2.586a3 3 0 01-4.242 0l-1.172-1.172a1 1 0 00-1.414 0l-2.586 2.586a1 1 0 01-1.414-1.414l2.586-2.586a3 3 0 014.242 0l1.172 1.172a1 1 0 001.414 0l2.586-2.586a1 1 0 011.414 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.981 9.95a1 1 0 011-1h1.5a2 2 0 012 2v1.5a1 1 0 01-2 0v-1.5h-1.5a1 1 0 01-1-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);
