import { FC, memo } from "react";

import { NumberFormat } from "@/app/components";
import { useTranslation } from "@/hooks/translator.hook";

import { TerminalBackDialogContent } from "../../components/terminal-back-dialog-content";
import { PendingOrder } from "../../contexts/extended-orders.context";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { ClosePendingOrderButton } from "../close/close-pending-order-button";
import { MobileModifyOrderTextButton } from "../components/trading-table/mobile-modify-order-text-button";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTableCard } from "../components/trading-table/trading-table-card";
import { OpeningPriceContainer } from "../modify-order/opening-price/opening-price.container";
import { ModifyPendingOrderStopLossContainer } from "../modify-order/stop-loss/pending-order-stop-loss.container";
import { ModifyPendingOrderTakeProfitContainer } from "../modify-order/take-profit/pending-order-take-profit.container";

type Props = {
  order: PendingOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onCardClose?: () => void;
};

const _PendingOrderCard: FC<Props> = ({
  order,
  accountId,
  onCardClose,
  setSymbol,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    ask,
    bid,
    currentPrice,
    volumeDecimalScale,
    digits,
    baseCurrency,
    contractSize,
    quoteCurrency,
    margin,
  } = order;

  const { addChartSymbol } = useAddChartSymbol({
    setSymbol,
    symbol,
    accountId,
    callback: onCardClose,
  });

  return (
    <TradingTableCard
      type={type}
      volume={volume}
      volumeDecimalScale={volumeDecimalScale}
      rightBottomBlock={<>{price ? <NumberFormat value={price} decimalScale={digits} /> : "—"}</>}
      onSymbolClick={addChartSymbol}
      symbol={symbol}
      text={t("terminal.open-price")!}
      button={
        <ClosePendingOrderButton
          accountId={accountId}
          orderId={id}
          symbol={symbol}
          orderType={type}
          volume={volume}
          volumeDecimalScale={volumeDecimalScale!}
        />
      }
    >
      <TradingTableCard.List>
        <TradingTableCard.ItemLabel>{t("common.type")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <OrderType type={type} withCircle={false} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.volume-lot")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>
          {t("terminal.margin-currency", { currency: accountCurrency })}
        </TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.open-price")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue hasBackground>
          <MobileModifyOrderTextButton value={<NumberFormat value={price} decimalScale={digits} />}>
            {onClose => (
              <TerminalBackDialogContent title={t("terminal.open-price")!}>
                <OpeningPriceContainer
                  onClose={onClose}
                  openPrice={price}
                  orderType={type}
                  orderId={id}
                  priceDecimalScale={digits!}
                  stopLoss={stopLoss}
                  takeProfit={takeProfit}
                  ask={ask}
                  bid={bid}
                  accountId={accountId}
                  currentPrice={currentPrice!}
                  symbol={symbol}
                  volume={volume}
                  volumeDecimalScale={volumeDecimalScale!}
                  isMobile
                />
              </TerminalBackDialogContent>
            )}
          </MobileModifyOrderTextButton>
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.current-price")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          {currentPrice ? <NumberFormat value={currentPrice} decimalScale={digits} /> : "—"}
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.take-profit")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue hasBackground>
          <MobileModifyOrderTextButton
            value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={digits} /> : null}
          >
            {onClose => (
              <TerminalBackDialogContent title={t("terminal.take-profit")!}>
                <ModifyPendingOrderTakeProfitContainer
                  onClose={onClose}
                  openPrice={price}
                  orderType={type}
                  orderId={id}
                  priceDecimalScale={digits!}
                  stopLoss={stopLoss}
                  takeProfit={takeProfit}
                  ask={ask}
                  bid={bid}
                  accountId={accountId}
                  baseCurrency={baseCurrency!}
                  quoteCurrency={quoteCurrency!}
                  contractSize={contractSize!}
                  volume={volume}
                  currency={accountCurrency}
                  currentPrice={currentPrice!}
                  symbol={symbol}
                  currencyDecimalScale={currencyDecimalScale}
                  isMobile
                />
              </TerminalBackDialogContent>
            )}
          </MobileModifyOrderTextButton>
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.stop-loss")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue hasBackground>
          <MobileModifyOrderTextButton
            value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={digits} /> : null}
          >
            {onClose => (
              <TerminalBackDialogContent title={t("terminal.stop-loss")!}>
                <ModifyPendingOrderStopLossContainer
                  onClose={onClose}
                  openPrice={price}
                  orderType={type}
                  orderId={id}
                  priceDecimalScale={digits!}
                  stopLoss={stopLoss}
                  takeProfit={takeProfit}
                  ask={ask}
                  bid={bid}
                  accountId={accountId}
                  baseCurrency={baseCurrency!}
                  quoteCurrency={quoteCurrency!}
                  contractSize={contractSize!}
                  volume={volume}
                  currency={accountCurrency}
                  currentPrice={currentPrice!}
                  symbol={symbol}
                  currencyDecimalScale={currencyDecimalScale}
                  isMobile
                />
              </TerminalBackDialogContent>
            )}
          </MobileModifyOrderTextButton>
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.time")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>{terminalFormatDate(date)}</TradingTableCard.ItemValue>
      </TradingTableCard.List>
    </TradingTableCard>
  );
};

export const PendingOrderCard = memo(_PendingOrderCard);
