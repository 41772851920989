import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/components/dialog";
import { TerminalDialogContent } from "@/features/terminal/components/terminal-dialog-content";
import { TradingAccountTradeMode } from "@/services/openapi";

import { usePlaceOrderContext } from "../../context";
import { VolumeSettingsContainer } from "./container";

type Props = {
  open: boolean;
  onClose: () => void;
};

const VolumeSettingsDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { resetForm, volumeMode, currency } = usePlaceOrderContext();

  const handleClose = () => {
    onClose();
    resetForm();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <TerminalDialogContent
        title={
          volumeMode === TradingAccountTradeMode.Margin
            ? t("terminal.place-order.margin.title", { currency })
            : t("terminal.place-order.lots.title")
        }
        description={
          volumeMode === TradingAccountTradeMode.Margin
            ? t("terminal.place-order.margin.description")!
            : t("terminal.place-order.lots.description")!
        }
      >
        <VolumeSettingsContainer onClose={handleClose} />
      </TerminalDialogContent>
    </Dialog>
  );
};

export { VolumeSettingsDialog };
