import { TFunction } from "i18next";
import React from "react";

import {
  DeclarationIcon,
  DisclosuresIcon,
  FinancialStatusIcon,
  InvestingObjectivesIcon,
  ProfileIcon,
  TaxPEPStatusIcon,
  TradingExperienceIcon,
  UploadDocumentsIcon,
} from "@/components/icons";
import { FeatureTours, KycInfo, ProfileHeader, SurveyGroup } from "@/services/openapi";

import { NavigationUIStatus } from "./onboarding-navigation";

export enum NavigationTitle {
  PROFILE = "profile",
  SUMSUB = "sumsub",
}

export const getNavigationUIStatus = (
  { id, filled }: NavigationItem,
  currentStep: string,
  previousItem: NavigationItem | undefined,
): NavigationUIStatus => {
  if (id === NavigationTitle.SUMSUB) {
    return "disabled";
  }

  if (currentStep === id) {
    return "active";
  }
  if (filled) {
    return "success";
  }
  if (previousItem?.filled) {
    return "common";
  }

  return "disabled";
};

export type NavigationItem = {
  title: string;
  filled: boolean;
  Icon: React.ComponentType<any>;
  id: string;
};

export interface NavigationTypes {
  activeStep: string;
  setStep: (step: string) => any;
  navigation: NavigationItem[];
  onComplete?: () => void;
}

const getSurveyIcon = (icon: string) => {
  switch (icon) {
    case "chartUp":
      return TradingExperienceIcon;
    case "briefcase":
      return FinancialStatusIcon;
    case "task":
      return DeclarationIcon;
    default:
      return ProfileIcon; // TODO: fallback icon
  }
};

const createSurveyNavigationItem = ({ name, isFilled, id, icon }: SurveyGroup): NavigationItem => {
  return { Icon: getSurveyIcon(icon!), title: name!, filled: isFilled!, id: id! };
};

export const createOnboardingNavigation = (
  t: TFunction,
  kycInfo: KycInfo,
  profileHeader: ProfileHeader,
): NavigationItem[] => {
  const profileFilled = profileHeader.options!.isProfileFilled!;

  const profileItem: NavigationItem = {
    Icon: ProfileIcon,
    title: t("onboarding.profile.title"),
    filled: profileFilled,
    id: NavigationTitle.PROFILE,
  };

  const sumsubItem: NavigationItem = {
    Icon: UploadDocumentsIcon,
    title: kycInfo.sumSub!.levelNames![0].title!,
    filled: profileHeader.options!.isKycCompleted!,
    id: NavigationTitle.SUMSUB,
  };

  const surveyNavigationItems: NavigationItem[] = kycInfo.survey!.surveyGroups!.map(createSurveyNavigationItem);

  const featureTourInfo: FeatureTours = { ...profileHeader.featureTours };

  return [profileItem, ...surveyNavigationItems, sumsubItem];
};
