import { cva } from "class-variance-authority";
import { type ComponentProps, type CSSProperties, type ElementRef, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TradingAccountTradeMode } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Button } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../context";
import { PlaceOrderButtonType } from "../../place-order.helpers";
import { DesktopPlaceOrderDetails } from "../details/details";
import { PlaceOrderHoverCard } from "../details/hover-card";

const buttonStyles = cva("select-none justify-center", {
  variants: {
    showMargin: {
      true: "justify-between",
    },
    pending: {
      true: "justify-center",
    },
  },
});

const textStyles = cva("font-gilroy text-[22px] font-semibold leading-[1.2] text-text-inverse")();

const valueStyles = cva("font-roboto text-[16px] font-normal leading-[1.5] text-text-inverse")();

type Props = Omit<ComponentProps<typeof DesktopPlaceOrderDetails>, "type"> & {
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  onSubmit: (values: any, side: PlaceOrderButtonType) => Promise<unknown>;
  disableHoverCard?: boolean;
  wrapperStyle?: CSSProperties;
  className?: string;
};

const DesktopPlaceOrderSubmitButtons = forwardRef<ElementRef<"div">, Props>(
  (
    { hasNoFreeMargin, volumeMode, onSubmit, className, wrapperStyle, disableHoverCard = false, ...detailsProps },
    ref,
  ) => {
    const { t } = useTranslation();

    const { accountCurrency, volumeMarginDecimalScale, volumeSellMargin, volumeBuyMargin, volumeLotsError } =
      detailsProps;

    const { formState, handleSubmit } = useFormContext();
    const { isSubmitting } = formState;

    const disabled = volumeLotsError !== VolumeLotsError.NONE || hasNoFreeMargin;

    const showMargin = !(disabled || volumeMode === TradingAccountTradeMode.Lots);

    return (
      <div className="-m-1 flex flex-col gap-1 rounded-[32px] bg-bg-back p-1" style={wrapperStyle} ref={ref}>
        <PlaceOrderHoverCard
          disableHoverCard={disableHoverCard}
          content={<DesktopPlaceOrderDetails type="buy" {...detailsProps} />}
        >
          <Button
            onClick={handleSubmit(values => onSubmit(values, "Buy"))}
            className={cn(buttonStyles({ showMargin, pending: isSubmitting }), className)}
            pending={isSubmitting}
            disabled={disabled}
          >
            <div className={textStyles}>{t("common.buy")}</div>
            {showMargin && (
              <div className={valueStyles}>
                <NumberFormat
                  value={volumeBuyMargin}
                  currency={accountCurrency}
                  decimalScale={volumeMarginDecimalScale}
                />
              </div>
            )}
          </Button>
        </PlaceOrderHoverCard>
        <PlaceOrderHoverCard
          disableHoverCard={disableHoverCard}
          content={<DesktopPlaceOrderDetails type="sell" {...detailsProps} />}
        >
          <Button
            onClick={handleSubmit(values => onSubmit(values, "Sell"))}
            className={cn(buttonStyles({ showMargin, pending: isSubmitting }), className)}
            pending={isSubmitting}
            disabled={disabled}
            variant="danger"
          >
            <div className={textStyles}>{t("common.sell")}</div>
            {showMargin && (
              <div className={valueStyles}>
                <NumberFormat
                  value={volumeSellMargin}
                  currency={accountCurrency}
                  decimalScale={volumeMarginDecimalScale}
                />
              </div>
            )}
          </Button>
        </PlaceOrderHoverCard>
      </div>
    );
  },
);

export { DesktopPlaceOrderSubmitButtons };
