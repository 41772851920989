import { LanguageCode } from "public/charting_library/charting_library";

// https://www.tradingview.com/charting-library-docs/latest/core_concepts/Localization

const getTradingViewLocale = (lang: string): LanguageCode => {
  if (lang === "ms") {
    return "ms_MY";
  }
  if (lang === "id") {
    return "id_ID";
  }
  return lang as LanguageCode;
};

export { getTradingViewLocale };
