import { lazy, Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import { initializeSentry, wrapCreateBrowserRouter } from "@/app/libs/sentry";
import { GlobalLoader } from "@/components/loader";
import { EmailConfirmPage } from "@/features/auth/components/email-confirm/email-confirm.page";
import { ForgotPasswordContainer } from "@/features/auth/components/forgot-password/forgot-password.container";
import { ResetPasswordContainer } from "@/features/auth/components/reset-password/reset-password.container";
import { SignInContainer } from "@/features/auth/components/signin/signin.container";
import { SignUpContainer } from "@/features/auth/components/signup/signup.container";
import { TwoFactorContainer } from "@/features/auth/components/two-factor/two-factor.container";
import { TwoFactorRecoveryContainer } from "@/features/auth/components/two-factor-recovery/two-factor-recovery.container";
import { VerifyEmailContainer } from "@/features/auth/components/verify-email/verify-email.container";
import { DashboardPage } from "@/features/dashboard/dashboard.page";
import { DepositPage } from "@/features/deposit/deposit.page";
import { DepositDetailsPage } from "@/features/deposit/deposit-details.page";
import { DepositRedirectPage } from "@/features/deposit/deposit-redirect.page";
import { IframeDepositPage } from "@/features/deposit/iframe-deposit.page";
import { MT4TerminalPage } from "@/features/mt-terminal/mt4-terminal.page";
import { MT5TerminalPage } from "@/features/mt-terminal/mt5-terminal.page";
import { NotificationsPage } from "@/features/notifications/notifications.page";
import { OnboardingPage } from "@/features/onboarding/onboarding.page";
import { PrivacyPolicyPage } from "@/features/privacy-policy/privacy-policy.page";
import { ProfileSettingsPage } from "@/features/profile-settings/profile-settings.page";
import TerminalPage from "@/features/terminal/terminal.page";
import { TerminalAutoRoutePage } from "@/features/terminal/terminal-auto-route.page";
import { TournamentPage } from "@/features/tournaments/tournament.page";
import { TransactionsPage } from "@/features/transactions/transactions.page";
import { TransferPage } from "@/features/transfer/transfer.page";
import { WithdrawalPage } from "@/features/withdrawal/withdrawal.page";
import { AuthLayout } from "@/layouts/auth.layout";
import { CabinetLayout } from "@/layouts/cabinet/cabinet.layout";
import { DepositLayout } from "@/layouts/deposit.layout";
import { OnboardingLayout } from "@/layouts/onboarding/onboarding.layout";
import { AuthDataProvider } from "@/providers/auth/auth-data.provider";
import { DepositDataProvider } from "@/providers/deposit-data.provider";
import { OnboardingDataProvider } from "@/providers/onboarding-data.provider";
import { ProfileDataProvider } from "@/providers/profile-data.provider";
import { billingRoutes } from "@/routes/billing.routes";
import { UnauthenticatedRoute } from "@/routes/unauthenticated.route";

import { authRoutes } from "./auth.routes";
import { cabinetRoutes } from "./cabinet.routes";
import { ErrorPage } from "./error.page";
import { onboardingRoutes } from "./onboarding.routes";
import { PrivateRoute } from "./private.route";
import { RootRoute } from "./root.route";
import { terminalRoutes } from "./terminal.routes";

// const TerminalPage = lazy(() => import("@/features/terminal/terminal.page"));

initializeSentry();

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootRoute />} errorElement={<ErrorPage />}>
      <Route path={cabinetRoutes.mt4Terminal} element={<MT4TerminalPage />} />
      <Route path={cabinetRoutes.mt5Terminal} element={<MT5TerminalPage />} />
      <Route path={billingRoutes.depositIframe} element={<IframeDepositPage />} />
      <Route path={billingRoutes.privacyPolicy} element={<PrivacyPolicyPage />} />
      <Route path={cabinetRoutes.depositRedirect} element={<DepositRedirectPage />} />

      <Route
        element={
          <UnauthenticatedRoute>
            <AuthDataProvider>
              <AuthLayout />
            </AuthDataProvider>
          </UnauthenticatedRoute>
        }
      >
        <Route path={authRoutes.signUp} element={<SignUpContainer />} />
        <Route path={authRoutes.signIn} element={<SignInContainer />} />
        <Route path={authRoutes.forgotPassword} element={<ForgotPasswordContainer />} />
        <Route path={authRoutes.resetPassword} element={<ResetPasswordContainer />} />
        <Route path={authRoutes.twoFactor} element={<TwoFactorContainer />} />
        <Route path={authRoutes.twoFactorRecovery} element={<TwoFactorRecoveryContainer />} />
        <Route path={authRoutes.verifyEmail} element={<VerifyEmailContainer />} />
        <Route path={authRoutes.emailConfirm} element={<EmailConfirmPage />} />
      </Route>
      <Route
        element={
          <PrivateRoute>
            <OnboardingDataProvider>
              <OnboardingLayout />
            </OnboardingDataProvider>
          </PrivateRoute>
        }
      >
        <Route path={onboardingRoutes.onboarding} element={<OnboardingPage />} />
      </Route>
      <Route
        element={
          <PrivateRoute>
            <ProfileDataProvider>
              <CabinetLayout />
            </ProfileDataProvider>
          </PrivateRoute>
        }
      >
        <Route path={cabinetRoutes.dashboard} element={<DashboardPage />} />

        <Route
          element={
            <DepositDataProvider>
              <DepositLayout />
            </DepositDataProvider>
          }
        >
          <Route path={cabinetRoutes.deposit} element={<DepositPage />} />
          <Route path={cabinetRoutes.depositDetails} element={<DepositDetailsPage />} />
        </Route>

        <Route path={cabinetRoutes.withdrawal} element={<WithdrawalPage />} />
        <Route path={cabinetRoutes.transfer} element={<TransferPage />} />
        <Route path={cabinetRoutes.transactionHistory} element={<TransactionsPage />} />
        <Route path={cabinetRoutes.notifications} element={<NotificationsPage />} />
        <Route path={cabinetRoutes.profileSettings} element={<ProfileSettingsPage />} />
      </Route>
      <Route
        path={terminalRoutes.autoRoute}
        element={
          <PrivateRoute>
            <TerminalAutoRoutePage />
          </PrivateRoute>
        }
      />
      <Route
        element={
          <Suspense fallback={<GlobalLoader />}>
            <PrivateRoute>
              <ProfileDataProvider />
            </PrivateRoute>
          </Suspense>
        }
      >
        <Route path={terminalRoutes.terminal} element={<TerminalPage />} />
        <Route path={terminalRoutes.terminalMobile} element={<TerminalPage />} />
      </Route>

      <Route path="/tournaments/:id" element={<TournamentPage />} />
    </Route>,
  ),
);

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export { router };
