import {
  getLastIndicator,
  getSymbolsLastPrices,
  getTerminalAccountSymbols,
  getTerminalAccountToken,
  getTerminalPositionsHistory,
  getTerminalSymbols,
} from "@/services/terminal";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseInfiniteQuery, useBaseQuery } from "..";
import { InfiniteQueryOptionsType, QueryOptionsType, RequestInfiniteParams } from "../types";
import { terminalQueryKeys } from "./terminal.keys";

export const useTerminalSymbolsQuery = (
  serverId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getTerminalSymbols>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTerminalSymbols>>({
    queryKey: terminalQueryKeys.symbols(serverId),
    queryFn: () => getTerminalSymbols({ tradingServerId: serverId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useSymbolsLastPricesQuery = (
  filters: ApiRequestParamsType<typeof getSymbolsLastPrices>,
  options?: QueryOptionsType<ApiResponseType<typeof getSymbolsLastPrices>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getSymbolsLastPrices>>({
    queryKey: terminalQueryKeys.symbolLastPrices(filters),
    queryFn: () => getSymbolsLastPrices(filters),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useInfiniteTerminalPositionsQuery = (
  filters: RequestInfiniteParams<ApiRequestParamsType<typeof getTerminalPositionsHistory>>,
  options?: InfiniteQueryOptionsType<ApiResponseType<typeof getTerminalPositionsHistory>>,
) => {
  const { pageSize, ...restFilters } = filters;
  return useBaseInfiniteQuery<
    ApiResponseType<typeof getTerminalPositionsHistory>,
    ApiRequestParamsType<typeof getTerminalPositionsHistory>
  >(terminalQueryKeys.positionsInfinite(restFilters), getTerminalPositionsHistory, filters, {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useTerminalSymbolsFavoritesQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getTerminalAccountSymbols>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTerminalAccountSymbols>>({
    queryKey: terminalQueryKeys.symbolsFavorites(accountId),
    queryFn: () => getTerminalAccountSymbols({ tradingAccountId: accountId }),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useTerminalTokenQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getTerminalAccountToken>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTerminalAccountToken>>({
    queryKey: terminalQueryKeys.token(accountId),
    queryFn: () => getTerminalAccountToken({ tradingAccountId: accountId }),
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useTerminalLastIndicatorQuery = (
  accountId: string,
  options?: QueryOptionsType<ApiResponseType<typeof getLastIndicator>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getLastIndicator>>({
    queryKey: terminalQueryKeys.lastIndicator(accountId),
    queryFn: () => getLastIndicator({ tradingAccountId: accountId }),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};
