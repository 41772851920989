import { FC, useState } from "react";

import { Grid } from "@/components/grid";
import { SidebarContent, sidebarWidthCollapsed } from "@/components/sidebar";
import { LanguageSwitcherContainer } from "@/features/language-switcher";

import { AccountSelect } from "./account-select/account-select";
import { AccountSummaryContainer } from "./account-summary/container";
import { ChartSymbolsContainer } from "./chart-symbols/chart-symbols.container";
import { TerminalDepositButtonContainer } from "./deposit/terminal-deposit-button.container";
import { TerminalSymbolBlock } from "./terminal-symbol-block";
import { DesktopTradingTables } from "./trading-tables/desktop-trading-tables";

export const TerminalDesktop: FC = () => {
  const [languageSwitcherOpen, setLanguageSwitcherOpen] = useState(false);

  return (
    <div className="flex h-full flex-col bg-bg-deep">
      <div className="flex grow flex-col bg-bg-deep">
        <SidebarContent initiallyCollapsed onLanguageDialogOpen={() => setLanguageSwitcherOpen(true)} />
        <LanguageSwitcherContainer onOpenChange={setLanguageSwitcherOpen} open={languageSwitcherOpen} />
        <div className="flex grow flex-col gap-1 p-2" style={{ marginLeft: sidebarWidthCollapsed }}>
          <div className="grow rounded-[8px] bg-bg p-2">
            <Grid css={{ gap: "10px", gridTemplateRows: "auto 1fr", height: "100%", position: "relative" }}>
              <Grid css={{ gridTemplateColumns: "1fr 240px" }}>
                <div className="flex justify-between gap-2 overflow-auto scrollbar-hide">
                  <ChartSymbolsContainer />
                  <AccountSelect />
                </div>
                <div className="px-2">
                  <TerminalDepositButtonContainer />
                </div>
              </Grid>
              <TerminalSymbolBlock />
            </Grid>
          </div>
          <DesktopTradingTables />
          <AccountSummaryContainer />
        </div>
      </div>
    </div>
  );
};
