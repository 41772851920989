import { FC, memo } from "react";

import { Dialog, DialogTrigger } from "@/components/dialog";
import { Flex } from "@/components/flex";
import { Popover, PopoverTrigger } from "@/components/popover";
import { Text } from "@/components/text";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui/button";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { TerminalPopover } from "../../components/popover";
import { TerminalBackDialogContent } from "../../components/terminal-back-dialog-content";
import { useTerminalLayout } from "../../contexts/terminal-layout.context";

type Props = {
  accountId: string;
  ordersCount: number;
};

const _CloseAllPendingOrdersButton: FC<Props> = ({ accountId, ordersCount }) => {
  const { t } = useTranslation();
  const { isMobile } = useTerminalLayout();

  const [open, { onOpenChange, close }] = useDisclosure();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation({
    successMessage: "pending-orders-cancelled",
  });

  const closeOrders = () => {
    mutate(
      { tradingAccountId: accountId, terminalCloseOrderRequest: { type: TerminalCloseOrderType.Limit } },
      {
        onSuccess: () => {
          close();
        },
      },
    );
  };

  const trigger = isMobile ? (
    <Button className="w-full shrink-0" variant="tertiary" size="sm">
      {t("terminal.orders.delete-all")}
    </Button>
  ) : (
    <Button variant="flat" size="sm">
      {t("terminal.orders.delete-all")}
    </Button>
  );

  return isMobile ? (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <TerminalBackDialogContent>
        <Flex css={{ alignItems: "center", flexGrow: 1 }}>
          <Text size="5" weight="bold" align="center" css={{ lineHeight: 1.2 }}>
            {t("terminal.orders.close-all-pending-message")}
          </Text>
        </Flex>
        <TerminalPopover.Buttons isMobile>
          <Button pending={isLoading} onClick={closeOrders}>
            {t("terminal.orders.delete-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalBackDialogContent>
    </Dialog>
  ) : (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <TerminalPopover title={t("terminal.orders.delete-count", { value: ordersCount })}>
        <TerminalPopover.Buttons>
          <Button pending={isLoading} onClick={closeOrders}>
            {t("terminal.orders.delete-all")}
          </Button>
        </TerminalPopover.Buttons>
      </TerminalPopover>
    </Popover>
  );
};

export const CloseAllPendingOrdersButton = memo(_CloseAllPendingOrdersButton);
