import { type FC, type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { Switch } from "@/shared/ui";

import { MobilePlaceOrderSection } from "../order-section/order-section";

type Props = {
  children: ReactNode;
};

const MobileAutocloseBlock: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  return (
    <MobilePlaceOrderSection
      title={t("terminal.place-order.autoclose.title")}
      description={t("terminal.place-order.autoclose.description")!}
      endSection={<Switch checked={checked} onCheckedChange={setChecked} />}
    >
      {checked && children}
    </MobilePlaceOrderSection>
  );
};

export { MobileAutocloseBlock };
