import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Tabs } from "@/shared/ui";

import { PlaceOrderType, usePlaceOrderContext } from "../context";
import { DesktopMarketOrderContainer } from "./market/container";
import { DesktopPendingOrderContainer } from "./pending/container";

const DesktopPlaceOrderContainer: FC = () => {
  const { t } = useTranslation();

  const { orderType, changeOrderType } = usePlaceOrderContext();

  return (
    <Tabs className="h-fit rounded-[24px] bg-bg-back p-2" value={orderType} onValueChange={changeOrderType}>
      <Tabs.List grow className="mb-4">
        <Tabs.Trigger value={PlaceOrderType.MARKET}>{t("terminal.market")}</Tabs.Trigger>
        <Tabs.Trigger value={PlaceOrderType.PENDING}>{t("terminal.pending")}</Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value={PlaceOrderType.MARKET}>
        <DesktopMarketOrderContainer />
      </Tabs.Content>
      <Tabs.Content value={PlaceOrderType.PENDING}>
        <DesktopPendingOrderContainer />
      </Tabs.Content>
    </Tabs>
  );
};

export { DesktopPlaceOrderContainer };
