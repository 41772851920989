import React, { createContext, memo, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalEventType, TerminalNotificationType, TradingAccount } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";

import { useTerminalFeatureTourContext } from "../feature-tour/context";
import {
  isMarketOrder,
  isPendingOrder,
  LiveOrder,
  normalizeOrdersList,
  transformTerminalOrder,
  updateOrdersList,
} from "../helpers/orders";
import { filterTerminalSocket } from "../helpers/socket.commands";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useSymbolsContext } from "./symbols.context";
import { useTerminalLayout } from "./terminal-layout.context";

type OrdersContextState = {
  openOrders: LiveOrder[];
  pendingOrders: LiveOrder[];
};

const OrdersContext = createContext<OrdersContextState>({} as OrdersContextState);

export const TerminalOrdersContextProvider = memo(
  ({ account, children }: { children: React.ReactNode; account: TradingAccount }) => {
    const { t } = useTranslation();

    const { leaveIdleState, isFeatureTourDisabled } = useTerminalFeatureTourContext();
    const { changeTable } = useTerminalLayout();

    const [list, setList] = useState<{
      [key: string]: LiveOrder;
    }>({});

    const { lastJsonMessage: socketMessage } = useTerminalSocket({
      filter: e =>
        filterTerminalSocket(e, [
          TerminalEventType.Init,
          TerminalEventType.Deal,
          TerminalEventType.TradingNotification,
        ]),
    });

    const { symbols } = useSymbolsContext();

    useEffect(() => {
      try {
        if (socketMessage?.t === TerminalEventType.Init) {
          const normalizedOrders = normalizeOrdersList(socketMessage.dds!);
          setList(normalizedOrders);
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (socketMessage?.t === TerminalEventType.Deal) {
          const order = transformTerminalOrder(socketMessage.dd!);

          setList(previousList =>
            updateOrdersList({
              t,
              newOrder: order,
              currency: account.currency!,
              list: previousList,
              symbols,
              currencyDecimalScale: account.digits!,
              leaveIdleState,
              isFeatureTourDisabled,
              changeTable,
            }),
          );
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (socketMessage?.t === TerminalEventType.TradingNotification) {
          const { m: message, t: type, p: isPermanent, h: title } = socketMessage.dtn!;
          switch (type) {
            case TerminalNotificationType.Info:
            case TerminalNotificationType.Danger:
            case TerminalNotificationType.Default:
            case TerminalNotificationType.Waring:
            default: {
              toast({ title: title!, description: message, icon: ToastIcons.WARNING, autoClose: !isPermanent });
            }
          }
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const orders = useMemo(() => {
      if (!list) {
        return [];
      }

      return Object.values(list).sort((a, b) => +new Date(b.date) - +new Date(a.date));
    }, [list]);

    const openOrders = useMemo(() => orders.filter(({ type }) => isMarketOrder(type)), [orders]);
    const pendingOrders = useMemo(() => orders.filter(({ type }) => isPendingOrder(type)), [orders]);

    const value: OrdersContextState = useMemo(() => ({ openOrders, pendingOrders }), [openOrders, pendingOrders]);

    return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
  },
);
TerminalOrdersContextProvider.displayName = "TerminalOrdersContextProvider";

export const useOrdersContext = () => {
  return useContext(OrdersContext);
};
