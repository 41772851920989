import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useLocalStorage } from "@/hooks/localstorage.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useSignInMutation } from "@/state/server/auth";

import { TwoFactorLocalStorageSignInType, TwoFactorMode } from "../two-factor/two-factor.helpers";
import { ITwoFARecoveryFormValues, TwoFactorRecoveryForm } from "./two-factor-recovery.form";

export const TwoFactorRecoverySigninContainer: FC = () => {
  const [signInValues, setSignInValues] = useLocalStorage<TwoFactorLocalStorageSignInType>(TwoFactorMode.SIGN_IN, {});

  const navigate = useNavigate();
  const { mutateAsync: signIn } = useSignInMutation();

  const submitSignIn = useCallback(
    ({ recoveryCode }: ITwoFARecoveryFormValues) => {
      return signIn(
        {
          email: signInValues.email!,
          logInRequest: { ...signInValues, recoveryCode },
        },
        {
          onSuccess: () => {
            setSignInValues({});
            navigate(cabinetRoutes.dashboard);
          },
        },
      );
    },
    [signIn, navigate, signInValues],
  );

  return <TwoFactorRecoveryForm onSubmit={submitSignIn} />;
};
