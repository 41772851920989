import { FC } from "react";
import { NumericFormat } from "react-number-format";

import { Flex } from "@/components/flex";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";
import { PaymentMethod, PaymentProcessingTime } from "@/services/openapi";
import { styled } from "@/styles";

import { getPaymentMethodProcessingTime } from "./payment-method.helpers";

const Item = styled("div", {
  position: "relative",
  padding: "24px",
  border: "1px solid",
  borderColor: "$gray",
  borderRadius: "16px",
  background: "$white",
  lineHeight: "$3",
  cursor: "pointer",

  "@bp2": {
    padding: "24px ",
  },

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        background: "none",

        "&::before": {
          content: "",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: "$bgBack",
          opacity: "0.6",
          borderRadius: "16px",
        },
      },
    },
  },
});

const PaymentIcon = styled("div", {
  width: "24px",
  height: "24px",

  img: {
    width: "100%",
    height: "100%",
  },
});

const InfoText = styled(Text, {
  fontSize: "14px !important",

  "@bp2": {
    fontSize: "16px !important",
  },

  defaultVariants: {
    family: "roboto",
    color: "gray",
  },
});

type Props = {
  item: PaymentMethod;
  onMethodClick: (method: PaymentMethod) => void;
};

export const PaymentMethodItem: FC<Props> = ({ item, onMethodClick }) => {
  const { t } = useTranslation();

  const onClick = () => {
    if (item.isAvailable === false) return;
    else {
      onMethodClick(item);
    }
  };

  const paymentMethodProcessingTimeResults = getPaymentMethodProcessingTime(item.details?.paymentProcessingTime!);

  return (
    <Item onClick={onClick} disabled={!item.isAvailable} data-test={`payment-tile-${item.title}`}>
      <Flex css={{ gap: "12px", mb: "12px", "@bp2": { mb: "16px" } }}>
        <PaymentIcon>
          <img src={item.images?.mini || ""} />
        </PaymentIcon>
        <Text size={{ "@initial": "2", "@bp2": "4" }} weight="bold">
          {item.title}
        </Text>
      </Flex>
      <Flex css={{ gap: "12px", alignItems: "center", "@bp2": { gap: "16px" } }}>
        {item.details?.limit?.isUnlimited ? (
          <InfoText>
            {t("deposit.payments.from")} {`${item.details?.limit?.from} ${item.currency}`}
          </InfoText>
        ) : (
          <InfoText>
            {item.details?.limit?.from}
            -
            <NumericFormat
              decimalScale={2}
              value={item.details?.limit?.to}
              thousandSeparator=","
              displayType="text"
              suffix={` ${item.currency}`}
            />
          </InfoText>
        )}

        {item.details?.paymentProcessingTime && (
          <InfoText>
            {paymentMethodProcessingTimeResults.time === PaymentProcessingTime.Instant &&
              `${t("deposit.payments.instant")}`}

            {paymentMethodProcessingTimeResults.time !== PaymentProcessingTime.Instant &&
              `${t("deposit.payments.upTo")} `}

            {paymentMethodProcessingTimeResults.unit && (
              <>
                {paymentMethodProcessingTimeResults.time}{" "}
                {t(`deposit.payments.${paymentMethodProcessingTimeResults.unit}`)}
              </>
            )}
          </InfoText>
        )}

        <InfoText>
          {item.details?.fee?.isNetworkFee && t("deposit.payments.network-fee")}
          {item.details?.fee?.percent && `${item.details.fee.percent}% ${t("deposit.payments.fee")}`}
          {item.details?.fee?.fixed?.toString() &&
            `${t("deposit.payments.fixed-fee", {
              number: item.details.fee.fixed,
              currency: item.currency,
            })}`}
        </InfoText>
      </Flex>
    </Item>
  );
};
