import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@/components/box";
import { NumberField, SelectField, TextField } from "@/components/form/fields";
import { Text } from "@/components/text";
import { Field } from "@/services/openapi";

import * as Styled from "./withdrawal.page.form.styled";

export const AdditionalField: FC<Field> = ({ id, title, type, validation, value, options }) => {
  const { t } = useTranslation();

  const fieldsValidate = (validation: Field["validation"]) => (value: string) => {
    const regexpData = validation?.regexp;
    if (!regexpData) return true;

    const regexp = new RegExp(regexpData.expression!, regexpData.flags!);

    // TODO: В типах title нет, по факту приходит, когда типы починяться удалить ts-ignore
    // @ts-ignore
    return regexp.test(value) ? true : regexpData.error?.title!;
  };

  const selectOptions = useMemo(
    () => options?.map(option => ({ value: option.value!, label: option.title! })),
    [options],
  );

  return (
    <Box>
      <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
        {title}
      </Text>

      <Styled.FieldWrapper>
        {type === "text" && (
          <TextField
            name={id!}
            placeholder={title ?? ""}
            registerOptions={{
              required: t("form-errors.required-error")!,
              validate: fieldsValidate(validation),
            }}
          />
        )}

        {type === "number" && (
          <NumberField
            name={id!}
            placeholder={title ?? ""}
            rules={{
              required: t("validation.required")!,
              validate: fieldsValidate(validation),
            }}
          />
        )}

        {type === "select" && selectOptions && (
          <SelectField
            name={id!}
            placeholder={title ?? ""}
            options={selectOptions}
            rules={{
              required: t("validation.required")!,
              validate: fieldsValidate(validation),
            }}
          />
        )}
      </Styled.FieldWrapper>
    </Box>
  );
};
