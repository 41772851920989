/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { ExternalKycAccessToken } from '../models';
// @ts-ignore
import { KycInfo } from '../models';
// @ts-ignore
import { ProfileUpdate } from '../models';
// @ts-ignore
import { SurveyUpdate } from '../models';
/**
 * KycApi - axios parameter creator
 * @export
 */
export const KycApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenToken: async (levelName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/sumsub/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (levelName !== undefined) {
                localVarQueryParameter['levelName'] = levelName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (profileUpdate?: ProfileUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey: async (surveyUpdate?: SurveyUpdate, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/kyc/survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycApi - functional programming interface
 * @export
 */
export const KycApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KycApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKycInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KycInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKycInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSumSubAccessTokenToken(levelName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalKycAccessToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSumSubAccessTokenToken(levelName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(profileUpdate?: ProfileUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(profileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSurvey(surveyUpdate?: SurveyUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSurvey(surveyUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KycApi - factory interface
 * @export
 */
export const KycApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KycApiFp(configuration)
    return {
        /**
         * 
         * @summary Get kyc info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKycInfo(options?: any): AxiosPromise<KycInfo> {
            return localVarFp.getKycInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SumSub access token
         * @param {string} [levelName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenToken(levelName?: string, options?: any): AxiosPromise<ExternalKycAccessToken> {
            return localVarFp.getSumSubAccessTokenToken(levelName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update profile
         * @param {ProfileUpdate} [profileUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(profileUpdate?: ProfileUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfile(profileUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update survey
         * @param {SurveyUpdate} [surveyUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSurvey(surveyUpdate?: SurveyUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateSurvey(surveyUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSumSubAccessTokenToken operation in KycApi.
 * @export
 * @interface KycApiGetSumSubAccessTokenTokenRequest
 */
export interface KycApiGetSumSubAccessTokenTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof KycApiGetSumSubAccessTokenToken
     */
    readonly levelName?: string
}

/**
 * Request parameters for updateProfile operation in KycApi.
 * @export
 * @interface KycApiUpdateProfileRequest
 */
export interface KycApiUpdateProfileRequest {
    /**
     * 
     * @type {ProfileUpdate}
     * @memberof KycApiUpdateProfile
     */
    readonly profileUpdate?: ProfileUpdate
}

/**
 * Request parameters for updateSurvey operation in KycApi.
 * @export
 * @interface KycApiUpdateSurveyRequest
 */
export interface KycApiUpdateSurveyRequest {
    /**
     * 
     * @type {SurveyUpdate}
     * @memberof KycApiUpdateSurvey
     */
    readonly surveyUpdate?: SurveyUpdate
}

/**
 * KycApi - object-oriented interface
 * @export
 * @class KycApi
 * @extends {BaseAPI}
 */
export class KycApi extends BaseAPI {
    /**
     * 
     * @summary Get kyc info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public getKycInfo(options?: any) {
        return KycApiFp(this.configuration).getKycInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SumSub access token
     * @param {KycApiGetSumSubAccessTokenTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public getSumSubAccessTokenToken(requestParameters: KycApiGetSumSubAccessTokenTokenRequest = {}, options?: any) {
        return KycApiFp(this.configuration).getSumSubAccessTokenToken(requestParameters.levelName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update profile
     * @param {KycApiUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public updateProfile(requestParameters: KycApiUpdateProfileRequest = {}, options?: any) {
        return KycApiFp(this.configuration).updateProfile(requestParameters.profileUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update survey
     * @param {KycApiUpdateSurveyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycApi
     */
    public updateSurvey(requestParameters: KycApiUpdateSurveyRequest = {}, options?: any) {
        return KycApiFp(this.configuration).updateSurvey(requestParameters.surveyUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
