import { styled } from "@/styles";

export const Info = styled("div", {
  padding: "24px",
  maxWidth: "400px",
  width: "100%",
  boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",
  borderRadius: "16px",
  lineHeight: "$3",
  fontFamily: "$roboto",
  color: "$darkGray",
  background: "$lightGray",
});

export const InfoWrapper = styled("div", {
  display: "flex",
  padding: "16px",
  alignItems: "flex-start",
  gap: "12px",
  alignSelf: "stretch",

  borderRadius: "16px",
  background: "$bgPrimary",

  "@bp1": {
    width: "100%",
    maxWidth: "440px",
  },
});

export const InfoIcon = styled("svg", {
  flexShrink: "0",
  fill: "$warningDefault",
});

export const InfoTitle = styled("p", {
  color: "$textMain",

  fontFamily: "$roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
});

export const Wrapper = styled("div", {
  "@bp1": {
    width: "100%",
    maxWidth: "440px",
  },
});

export const FieldWrapper = styled("div", {
  "@bp1": {
    width: "100%",
    maxWidth: "440px",
  },
});

export const Title = styled("span", {
  display: "block",
  fontFamily: "$roboto",
  fontWeight: "$normal",
  fontSize: "16px",
  color: "$textMain",
  lineHeight: "$3",
  marginBottom: "16px",
});

export const MinMax = styled("p", {
  color: "$textSecondary",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",

  span: {
    textDecorationLine: "underline",
  },
});

export const ErrorMessage = styled("div", {
  span: {
    textDecorationLine: "underline",
  },
});

export const DialogContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  marginBottom: "24px",
  textAlign: "center",
});

export const DialogTitle = styled("div", {
  color: "$textMain",
  fontFamily: "$gilroy",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: "$bold",
  lineHeight: "120%",
});

export const DialogDescription = styled("div", {
  color: "$textMain",
  fontFamily: "$roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "$normal",
  lineHeight: "150%",
});

export const ButtonsWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "8px",
});

export const TooltipSaveAmount = styled("p", {
  color: "$textInverse",

  fontFamily: "$roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",

  span: {
    textDecorationLine: "underline",
  },
});

export const ClickSpan = styled("span", {
  cursor: "pointer",
});
