import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { FieldType, SurveyField as SurveyFieldType } from "@/services/openapi";

import { SurveyBooleanField } from "./fields/survey-boolean-field";
import { SurveyCountryListField } from "./fields/survey-country-list-field";
import { SurveyDateField } from "./fields/survey-date-field";
import { SurveyDeclarationField } from "./fields/survey-declaration-field";
import { SurveyNumberField } from "./fields/survey-number-field";
import { SurveyPollMultiField } from "./fields/survey-poll-multi-field";
import { SurveyPollSingleField } from "./fields/survey-poll-single-field";
import { SurveyStringField } from "./fields/survey-string-field";
import { generateSurveyFieldId } from "./survey.helpers";

const renderField = (field: SurveyFieldType, isMobile: boolean) => {
  switch (field.type) {
    case FieldType.String:
    case FieldType.PhoneNumber:
      return <SurveyStringField field={field} />;
    case FieldType.Decimal:
    case FieldType.Int:
      return <SurveyNumberField field={field} />;
    case FieldType.Date:
      return <SurveyDateField field={field} />;
    case FieldType.Boolean:
      return <SurveyBooleanField field={field} />;
    case FieldType.PollSingle:
      return <SurveyPollSingleField field={{ ...field, isRequired: true }} />;
    case FieldType.PollMulti:
      return <SurveyPollMultiField field={field} />;
    case FieldType.Declaration:
      return <SurveyDeclarationField field={field} />;
    case FieldType.CountryList:
      return <SurveyCountryListField field={field} />;
    default:
      return null;
  }
};

interface Props {
  field: SurveyFieldType;
}

export const SurveyField: FC<Props> = ({ field }) => {
  const { watch } = useFormContext();
  const { isMobile } = useScreenSize();

  return (
    <>
      {renderField(field, isMobile)}
      {!!watch(generateSurveyFieldId(field.id!, field.type!)) &&
        field.fields!.map(f => <SurveyField field={{ ...f }} key={f.id} />)}
    </>
  );
};
