import { FC, memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { TerminalPosition } from "@/services/openapi";

import { AddChartSymbolButton } from "../../components/symbol/add-chart-symbol-button";
import { getDecimalScaleFromTickSize } from "../../helpers/formatting";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { OrderSymbol } from "../components/trading-table/order-symbol";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTable } from "../components/trading-table/trading-table";
import { ClosedPositionVolume } from "./closed-position-volume";

type Props = {
  position: TerminalPosition;
  accountId: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
};

const _ClosedPositionTableRow: FC<Props> = ({ position, setSymbol, accountId, currencyDecimalScale }) => {
  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, symbol: symbol!, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell>{terminalFormatDate(dateOpen!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <AddChartSymbolButton onClick={addChartSymbol}>
          <OrderSymbol symbol={symbol!} />
        </AddChartSymbolButton>
      </TradingTable.Cell>
      <TradingTable.Cell>
        <OrderType type={type!} />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <ClosedPositionVolume
          decimalScale={volumeDecimalScale}
          type={type!}
          volumeBuy={volumeBuy}
          volumeSell={volumeSell}
        />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell>{positionNumber}</TradingTable.Cell>
      <TradingTable.Cell>
        {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>
        {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>{terminalFormatDate(dateClose!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceClose} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end">
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end">
        <div className={getNumberColorClassname(profit!)}>
          <PnlFormat value={profit} decimalScale={currencyDecimalScale} />
        </div>
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

export const ClosedPositionTableRow = memo(_ClosedPositionTableRow);
