import { cva, VariantProps } from "class-variance-authority";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { MarginFreeFormat } from "@/app/components";
import { AccountBadge } from "@/components/badge/account-badge";
import { TradingAccount } from "@/services/openapi";
import { cn } from "@/shared/styles";

const buttonStyles = cva("flex h-16 w-full items-center gap-4 bg-bg px-4 transition-colors hover:bg-bg-back lg:px-6", {
  variants: {
    active: {
      true: "!bg-bg-selected",
    },
  },
});

const textStyles = cva("whitespace-nowrap font-roboto text-[14px] leading-[1.5] text-text lg:text-[16px]")();

type Props = Pick<ComponentPropsWithoutRef<"button">, "onClick" | "children"> &
  VariantProps<typeof buttonStyles> & {
    account: TradingAccount;
  };

const AccountsSelectRow = forwardRef<ElementRef<"button">, Props>(({ account, active, children, ...props }, ref) => {
  const { type, name, login, currency, marginFree, digits } = account;

  return (
    <button ref={ref} className={buttonStyles({ active })} {...props}>
      <AccountBadge accountType={type!} />
      <div className="flex grow flex-col items-start truncate lg:flex-row lg:gap-2">
        <div className={cn(textStyles, "truncate")}>{name}</div>
        <div className={textStyles}>{login}</div>
      </div>
      <div className={textStyles}>
        <MarginFreeFormat currency={currency!} value={marginFree} decimalScale={digits} />
      </div>
      {children}
    </button>
  );
});

export { AccountsSelectRow };
