import { forwardRef } from "react";

import { RadioCheckbox } from "../../inputs";
import { TileWrapper, TileWrapperExternalProps, TileWrapperRefType } from "./tile-wrapper";

export type TileCheckboxValueType = string | number | undefined;
type OptionType = {
  label: string;
  value: TileCheckboxValueType;
};

export type TileRadioExternalProps = TileWrapperExternalProps & {
  options: OptionType[];
};

type TileRadioInternalProps = {
  value: TileCheckboxValueType;
  onChange: (value: TileCheckboxValueType) => void;
};

type Props = TileRadioInternalProps & TileRadioExternalProps;

export const TileRadio = forwardRef<TileWrapperRefType, Props>(({ value, onChange, options, ...restProps }, ref) => {
  return (
    <TileWrapper
      ref={ref}
      {...restProps}
      type="radio"
      showDescription={false}
      css={{
        ".checkbox-wrapper": {
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          border: "none",
          overflow: "visible",
        },
      }}
    >
      {options.map(radio => (
        <RadioCheckbox
          key={radio.value}
          label={radio.label}
          onChange={() => onChange(radio.value)}
          checked={value === radio.value ? true : false}
        />
      ))}
    </TileWrapper>
  );
});
TileRadio.displayName = "TileRadio";
