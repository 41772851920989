import { FC, memo } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../../feature-tour/context";
import { CloseOrderHintPopover } from "../../feature-tour/steps/close-order/hint-popover";

type Props = {
  accountId: string;
  orderId: number;
  isLastOrder?: boolean;
};

const _CloseOpenOrderButton: FC<Props> = ({ accountId, orderId, isLastOrder }) => {
  const { t } = useTranslation();
  const { isMobile } = useTerminalLayout();
  const { closeOrder: closeOrderTour, closeOrderStyles } = useTerminalFeatureTourContext();

  const { mutate: closeOrder, isLoading } = useCloseTerminalOrdersMutation({
    onSuccess: () => {
      closeOrderTour(true);
    },
  });

  const handleClick = () => {
    closeOrder({
      tradingAccountId: accountId,
      terminalCloseOrderRequest: { id: orderId, type: TerminalCloseOrderType.Market },
    });
  };

  if (!isLastOrder) {
    return (
      <>
        {isMobile ? (
          <Button className="w-full" variant="tertiary" size="sm" onClick={handleClick} pending={isLoading}>
            {t("button.close")}
          </Button>
        ) : (
          <Button onClick={handleClick} pending={isLoading} variant="flat" size="sm">
            {t("button.close")}
          </Button>
        )}
      </>
    );
  }

  return isMobile ? (
    <Button
      style={closeOrderStyles.target}
      className="w-full"
      variant="tertiary"
      size="sm"
      onClick={handleClick}
      pending={isLoading}
    >
      {t("button.close")}
    </Button>
  ) : (
    <CloseOrderHintPopover>
      <Button style={closeOrderStyles.target} onClick={handleClick} pending={isLoading} variant="flat" size="sm">
        {t("button.close")}
      </Button>
    </CloseOrderHintPopover>
  );
};

export const CloseOpenOrderButton = memo(_CloseOpenOrderButton);
