import { FC, memo } from "react";

import fallbackDarkImg from "@/assets/images/symbol-fallback-dark.svg";
import fallbackLightImg from "@/assets/images/symbol-fallback-light.svg";
import { useTheme } from "@/contexts/theme.context";
import { styled } from "@/styles";

const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  minWidth: "27px",

  img: {
    height: "16px",
  },
});

const IconContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  minWidth: 24,

  img: {
    height: 16,
  },
});

type Props = {
  symbol: string;
};

const Icon: FC<Props> = ({ symbol }) => {
  const { isDarkTheme } = useTheme();

  return (
    <img
      src={`${import.meta.env.VITE_SYMBOL_IMAGE_URL}/${symbol}.png`}
      alt={symbol}
      loading="lazy"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = isDarkTheme ? fallbackDarkImg : fallbackLightImg;
      }}
    />
  );
};

const _SymbolIcon: FC<Props> = props => {
  return (
    <Container>
      <Icon {...props} />
    </Container>
  );
};

const _ToastSymbolIcon: FC<Props> = props => {
  return (
    <IconContainer>
      <Icon {...props} />
    </IconContainer>
  );
};

export const SymbolIcon = memo(_SymbolIcon);
export const ToastSymbolIcon = memo(_ToastSymbolIcon);
