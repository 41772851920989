import { useGoogleLogin } from "@react-oauth/google";
import { useCallback } from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import { useNavigate } from "react-router-dom";

import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { useSignInFacebookMutation, useSignInGoogleMutation } from "@/state/server/auth";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

export const useGoogleSignIn = () => {
  const navigate = useNavigate();
  const navigateToOnboarding = () => {
    navigate({ pathname: onboardingRoutes.onboarding });
  };

  const { mutate } = useSignInGoogleMutation();

  const signIn = useGoogleLogin({
    onSuccess: ({ code }) => {
      mutate(
        { stringContainer: { value: code } },
        {
          onSuccess: ({ isFirstLogin }) => {
            if (isFirstLogin) {
              window.dataLayer?.push({
                event: "gtm_events",
                ec: "register",
                ea: "register",
                el: "Google",
                ga4_event_name: "register",
              });
              // TODO: убрать задержку, она нужна тк после логина юзер всё ещё не isAuthenticated
              setTimeout(() => navigateToOnboarding(), 500);
            } else {
              navigate(cabinetRoutes.dashboard);
            }
          },
        },
      );
    },
    flow: "auth-code",
  });

  return { signIn };
};

export const useFacebookSignIn = () => {
  const { signIn } = usePlatformData();
  const navigate = useNavigate();

  const { mutate } = useSignInFacebookMutation();

  const navigateToOnboarding = () => {
    navigate({ pathname: onboardingRoutes.onboarding });
  };

  const callback = useCallback((userInfo: ReactFacebookLoginInfo) => {
    if (userInfo.accessToken) {
      mutate(
        { stringContainer: { value: userInfo.accessToken } },
        {
          onSuccess: ({ isFirstLogin }) => {
            if (isFirstLogin) {
              window.dataLayer?.push({
                event: "gtm_events",
                ec: "register",
                ea: "register",
                el: "Facebook",
                ga4_event_name: "register",
              });
              // TODO: убрать задержку, она нужна тк после логина юзер всё ещё не isAuthenticated
              setTimeout(() => navigateToOnboarding(), 500);
              navigate(authRoutes.verifyEmail);
            } else {
              navigate(cabinetRoutes.dashboard);
            }
          },
        },
      );
    }
  }, []);

  return { callback, appId: signIn!.facebook!.appId! };
};
