import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/components/dialog";
import { TerminalDialogContent } from "@/features/terminal/components/terminal-dialog-content";

import { usePlaceOrderContext } from "../../context";
import { MobilePlaceOrderSettings } from "./settings";

type Props = {
  symbol: string;
  open: boolean;
  onClose: () => void;
};

const OrderSettingsDialog: FC<Props> = ({ open, onClose, symbol }) => {
  const { t } = useTranslation();

  const { resetForm, resetOrderType } = usePlaceOrderContext();

  const handleClose = () => {
    onClose();
    resetForm();
    resetOrderType();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <TerminalDialogContent title={t("terminal.place-order.order-settings.title", { symbol })}>
        <MobilePlaceOrderSettings onClose={handleClose} />
      </TerminalDialogContent>
    </Dialog>
  );
};

export { OrderSettingsDialog };
