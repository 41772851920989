import {
  PaymentsApiGetDepositFeeRequest,
  PaymentsApiGetMaximumWithdrawAmountRequest,
  PaymentsApiGetWithdrawFeeRequest,
  PaymentsApiMakeDepositRequest,
  PaymentsApiMakeWithdrawRequest,
} from "@/services/openapi";

import { paymentService } from "../api";

export const getDepositMethods = async () => await (await paymentService().getPaymentMethods()).data;

export const getWithdrawMethods = async () => await (await paymentService().getWithdrawMethods()).data;

export const getDepositFee = async (values: PaymentsApiGetDepositFeeRequest) =>
  await (
    await paymentService().getDepositFee(values)
  ).data;

export const getWithdrawFee = async (values: PaymentsApiGetWithdrawFeeRequest) =>
  await (
    await paymentService().getWithdrawFee(values)
  ).data;

export const getMaximumWithdrawAmount = async (values: PaymentsApiGetMaximumWithdrawAmountRequest) =>
  await (
    await paymentService().getMaximumWithdrawAmount(values)
  ).data;

export const makeDeposit = async (values: PaymentsApiMakeDepositRequest) =>
  await (
    await paymentService().makeDeposit(values)
  ).data;

export const makeWithdraw = async (values: PaymentsApiMakeWithdrawRequest) =>
  await (
    await paymentService().makeWithdraw(values)
  ).data;

export const getDepositWidgets = async () => await (await paymentService().getDepositWidgets()).data;
export const getWithdrawWidgets = async () => await (await paymentService().getWithdrawWidgets()).data;
