import copyToClipboard from "copy-to-clipboard";
import { useCallback, useState } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { toast, ToastIcons } from "@/shared/ui";
import { postponeCallback } from "@/utils/helpers";

export const useCopy = () => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  const copy = useCallback(
    (value: string) => {
      try {
        setIsSuccess(true);
        copyToClipboard(value);

        toast({ text: t("success-message.successful-copy"), icon: ToastIcons.SUCCESS });
      } catch (error: any) {
        toast({ text: t("failed-message.failed-copy"), icon: ToastIcons.ERROR });
      } finally {
        postponeCallback(() => setIsSuccess(false));
      }
    },
    [t],
  );
  return { isSuccess, copy };
};
