import { hideBanner } from "@/services/banners";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import { MutationOptionsType } from "../types";

const useHideBannerMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof hideBanner>, ApiRequestParamsType<typeof hideBanner>>,
) => {
  return useBaseMutation<ApiResponseType<typeof hideBanner>, ApiRequestParamsType<typeof hideBanner>>(
    hideBanner,
    options,
  );
};

export { useHideBannerMutation };
