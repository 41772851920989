import { FC, memo, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { SelectOptionType } from "@/components/form";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalAccountSymbols } from "@/services/openapi";
import {
  terminalQueryKeys,
  useTerminalSymbolsFavoritesQuery,
  useUpdateFavoriteSymbolsGroupMutation,
} from "@/state/server/terminal";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { filterSymbolsSearch, getSymbolsFilteringFunction, sortWatchlistSymbols } from "../helpers/symbols";
import { Watchlist } from "./watchlist";

type Props = {
  isWatchlist?: boolean;
  onClose?: () => void;
};

const _WatchlistContainer: FC<Props> = ({ isWatchlist = true, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { account } = useTerminalAccountContext();
  const { setSymbol } = useCurrentSymbolContext();
  const { symbols, symbolGroups, group, setGroup } = useSymbolsContext();

  const { mutate } = useUpdateFavoriteSymbolsGroupMutation();

  const updateGroup = useCallback(
    (group: any) => {
      setGroup(group);
      mutate({ group, tradingAccountId: account.id! });
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(account.id!), oldData => {
        return { ...oldData, group };
      });
    },
    [account, mutate, setGroup, queryClient],
  );

  const { data: favoriteData } = useTerminalSymbolsFavoritesQuery(account.id!, {
    enabled: false,
  });
  const chartFavoritesCount = favoriteData ? favoriteData.charts!.length : 0;

  const [search, setSearch] = useState("");

  const groupOptions: SelectOptionType[] = useMemo(
    () => symbolGroups.map(group => ({ value: group, label: t(`terminal.groups.${group}`)! })),
    [symbolGroups, t],
  );

  const filteringFunction = useMemo(
    () =>
      getSymbolsFilteringFunction({
        popular: favoriteData?.popular || [],
        recent: favoriteData?.recent || [],
        group,
        hasSearch: !!search,
      }),
    [group, favoriteData, search],
  );

  const visibleSymbols = symbols.filter(filterSymbolsSearch(search)).filter(filteringFunction);

  const sortedSymbols = sortWatchlistSymbols(visibleSymbols, group, favoriteData!);

  return (
    <Watchlist
      symbols={sortedSymbols}
      group={group}
      groupOptions={groupOptions}
      search={search}
      setGroup={updateGroup}
      setSearch={setSearch}
      isWatchlist={isWatchlist}
      onClose={onClose}
      chartFavoritesCount={chartFavoritesCount}
      setSymbol={setSymbol}
    />
  );
};

export const WatchlistContainer = memo(_WatchlistContainer);
