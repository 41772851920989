import { CSSProperties } from "react";

type FeatureTourStyles = {
  highlight?: CSSProperties;
  target?: CSSProperties;
  highlightAndTarget?: CSSProperties;
};

// TODO: better type names
type SeparateStyles = Omit<FeatureTourStyles, "highlightAndTarget">;
type HighlightAsTargetStyles = FeatureTourStyles["highlightAndTarget"];

const getStyles = (isStepActive: boolean): FeatureTourStyles => {
  if (!isStepActive) {
    return {};
  }

  // z-index must be higher than popper elements default index, but lower than hint popover index
  return {
    highlight: { zIndex: 60, position: "relative", pointerEvents: "none" },
    target: { pointerEvents: "auto" },
    highlightAndTarget: {
      zIndex: 60,
      position: "relative",
      pointerEvents: "auto",
    },
  };
};

const getSelectSymbolStyles = (isActive: boolean): SeparateStyles => {
  const { highlight, target } = getStyles(isActive);
  return { highlight, target };
};

const getChartStyles = (isActive: boolean): HighlightAsTargetStyles => {
  return getStyles(isActive).highlight;
};

const getIncrementOrderStyles = (isActive: boolean): SeparateStyles => {
  const { highlight, target } = getStyles(isActive);
  return { highlight, target };
};

const getPlaceOrderStyles = (isActive: boolean): HighlightAsTargetStyles => {
  return getStyles(isActive).highlightAndTarget;
};

const getCheckFreeMarginStyles = (isActive: boolean): HighlightAsTargetStyles => {
  return getStyles(isActive).highlight;
};

const getCloseOrderStyles = (isActive: boolean): SeparateStyles => {
  const { highlight, target } = getStyles(isActive);
  return { highlight, target };
};

const getChangeVolumeModeStyles = (isActive: boolean): HighlightAsTargetStyles => {
  return getStyles(isActive).highlight;
};

const shineAnimationClassName = "shine-container after:shine";

export {
  getSelectSymbolStyles,
  getChartStyles,
  getIncrementOrderStyles,
  getPlaceOrderStyles,
  getCloseOrderStyles,
  getCheckFreeMarginStyles,
  getChangeVolumeModeStyles,
  shineAnimationClassName,
  type SeparateStyles,
  type HighlightAsTargetStyles,
};
