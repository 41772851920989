import { FC, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Popover, PopoverTrigger } from "@/components/popover";
import { UnstyledText } from "@/components/text";
import { styled } from "@/styles";

const Button = styled("button", {
  all: "unset",
  cursor: "pointer",
});

type Props = {
  value: ReactNode;
  children: (onClose: () => void) => ReactNode;
};

export const ModifyOrderDesktopTextButton: FC<Props> = ({ value, children }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button>
          <UnstyledText
            css={{ textDecoration: "underline", "&:hover": { color: "$textMain" } }}
            color={open || value ? "black" : "dimmed"}
          >
            {value ? value : t("button.add")}
          </UnstyledText>
        </Button>
      </PopoverTrigger>
      {open && children(onClose)}
    </Popover>
  );
};
