import { ComponentProps, FC } from "react";

import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { TerminalDealType, TradingAccountType } from "@/services/openapi";
import { useOpenTerminalOrderMutation } from "@/state/server/terminal";

import { usePlaceOrderContext } from "../../context";
import { getMaximumOrderVolume, getOriginalVolume } from "../../place-order.helpers";
import { VolumeSettingsForm } from "./form";

type Props = {
  onClose: () => void;
};

const VolumeSettingsContainer: FC<Props> = ({ onClose }) => {
  const { symbolInfo } = useCurrentSymbolContext();

  const {
    volumeMode,
    volumeLots,
    volumeMargin,
    changeLots,
    changeMargin,
    maxBalanceVolumeLots,
    maxBalanceVolumeMargin,
    maxSystemVolumeLots,
    minSystemVolumeLots,
    maxSystemVolumeMargin,
    minSystemVolumeMargin,
    marginMultiplier,
    maxAvailableVolumeLots,
    volumeLotsStep,
    decrementVolumeDisabled,
    incrementVolumeDisabled,
    decrementOrder,
    incrementOrder,
    maxAvailableVolumeMargin,
    volumeLotsDecimalScale,
    volumeMarginDecimalScale,
    hasNoFreeMargin,
    volumeLotsError,
    volumeSellMargin,
    volumeBuyMargin,
    ask,
    bid,
    swapLong,
    swapShort,
    currency,
    volumeLotsFormValue,
    volumeMarginFormValue,
    marginFree,
  } = usePlaceOrderContext();

  const {
    account: { id: accountId, leverage, type },
  } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();

  const {
    digits,
    symbol,
    contractSize,
    baseCurrency,
    baseCurrencyExt,
    group,
    type: instrumentType,
    quoteCurrency,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
  } = symbolInfo!;

  const { mutateAsync: openMarketOrder } = useOpenTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof VolumeSettingsForm>["onSubmit"] = side => {
    return openMarketOrder(
      {
        tradingAccountId: accountId!,
        terminalOpenOrderRequest: {
          volume: getMaximumOrderVolume({
            maxBalanceVolumeLots,
            volumeLots,
            marginFree,
            baseCurrency: baseCurrency!,
            bid,
            contractSize: contractSize!,
            currency,
            instrumentType: instrumentType!,
            leverage: leverage!,
            marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
            marginRateInitialMarketSell: marginRateInitialMarketSell!,
            marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
            marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
            quoteCurrency: quoteCurrency!,
            symbols,
            volumeLotsDecimalScale,
            volumeLotsStep,
          }),
          symbol,
          type: side === "Buy" ? TerminalDealType.Buy : TerminalDealType.Sell,
          volumeOriginal: getOriginalVolume({ volumeMode, volumeLots, volumeMargin }),
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <VolumeSettingsForm
      marginRateInitialMarketBuy={marginRateInitialMarketBuy!}
      baseCurrencyExt={baseCurrencyExt!}
      group={group!}
      accountId={accountId!}
      isDemoAccount={type === TradingAccountType.Demo}
      volumeLotsFormValue={volumeLotsFormValue}
      volumeMarginFormValue={volumeMarginFormValue}
      leverage={leverage!}
      hasNoFreeMargin={hasNoFreeMargin}
      volumeLotsStep={volumeLotsStep}
      volumeMode={volumeMode}
      changeLots={changeLots}
      changeMargin={changeMargin}
      onSubmit={handleSubmit}
      priceDecimalScale={digits!}
      ask={ask}
      bid={bid}
      contractSize={contractSize!}
      baseCurrency={baseCurrency!}
      accountCurrency={currency}
      symbol={symbol!}
      volumeLots={volumeLots}
      volumeMargin={volumeMargin}
      maxSystemVolumeLots={maxSystemVolumeLots}
      maxBalanceVolumeLots={maxBalanceVolumeLots}
      minSystemVolumeLots={minSystemVolumeLots}
      maxBalanceVolumeMargin={maxBalanceVolumeMargin}
      maxSystemVolumeMargin={maxSystemVolumeMargin}
      minSystemVolumeMargin={minSystemVolumeMargin}
      marginMultiplier={marginMultiplier}
      maxAvailableVolumeLots={maxAvailableVolumeLots}
      incrementVolumeDisabled={incrementVolumeDisabled}
      decrementVolumeDisabled={decrementVolumeDisabled}
      decrementOrder={decrementOrder}
      incrementOrder={incrementOrder}
      volumeLotsError={volumeLotsError}
      maxAvailableVolumeMargin={maxAvailableVolumeMargin}
      volumeMarginDecimalScale={volumeMarginDecimalScale}
      volumeLotsDecimalScale={volumeLotsDecimalScale}
      volumeSellMargin={volumeSellMargin}
      volumeBuyMargin={volumeBuyMargin}
      instrumentType={instrumentType!}
      symbols={symbols}
      quoteCurrency={quoteCurrency!}
      swapLong={swapLong}
      swapShort={swapShort}
    />
  );
};

export { VolumeSettingsContainer };
