import { Flex } from "@/components/flex";
import { styled } from "@/styles";

export const Container = styled(Flex, {
  height: "100%",
  flexDirection: "column",
  gap: "8px",

  "&p": {
    fontSize: "14px",
  },

  "@bp3": {
    p: "8px",
    height: "fit-content",
  },
});

export const Block = styled(Flex, {
  flexDirection: "column",
  gap: "8px",
  padding: "16px",
  backgroundColor: "$lightGray",

  fontFamily: "$roboto",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "150%",

  "@bp3": {
    borderRadius: "16px",
  },
});
