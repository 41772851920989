export const terminalQueryKeys = {
  base: ["terminal"],
  symbols: (serverId: string) => [...terminalQueryKeys.base, "symbols", serverId],
  symbolCharts: (filters: any) => [...terminalQueryKeys.base, "symbolChart", { filters }],
  symbolLastPrices: (filters: any) => [...terminalQueryKeys.base, "symbolLastPrices", { filters }],
  positionsInfinite: (filters: any) => [...terminalQueryKeys.base, "positions", "infinite", { filters }],
  symbolsFavorites: (accountId: string) => [...terminalQueryKeys.base, "symbolsFavorites", accountId],
  chartFirstDate: (filters: any) => [...terminalQueryKeys.base, "chart/first/date", { filters }],
  token: (accountId: string) => [...terminalQueryKeys.base, "token", accountId],
  lastIndicator: (accountId: string) => [...terminalQueryKeys.base, "lastIndicator", accountId],
};
