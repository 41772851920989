import { WriteSignal } from "@maverick-js/signals";
import dayjs from "dayjs";
import { IExternalSaveLoadAdapter, ResolutionString } from "public/charting_library/charting_library";

import {
  addOrUpdateChartData,
  addStudyTemplateData,
  getChartData,
  getChartsMetaInfo,
  getStudyTemplateData,
  getStudyTemplatesMetaInfo,
  removeChartData,
  removeStudyTemplateData,
} from "@/services/terminal";

// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.ChartingLibraryWidgetOptions/#save_load_adapter
// https://www.tradingview.com/charting-library-docs/latest/saving_loading/#api-handlers
// https://www.tradingview.com/charting-library-docs/latest/saving_loading/Save-Load-Adapter-Example
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IExternalSaveLoadAdapter/

const getLoadAdapter = ({
  accountId,
  $symbol,
}: {
  accountId: string;
  $symbol: WriteSignal<string>;
}): IExternalSaveLoadAdapter => ({
  ////////////////////////////////////////////////////////////////
  getAllCharts: () =>
    getChartsMetaInfo({ tradingAccountId: accountId }).then(({ items }) =>
      items!
        .filter(({ symbol }) => symbol === $symbol())
        .map(({ name, date, id, resolution, symbol }) => ({
          id: id as any as number,
          name: name!,
          symbol: symbol!,
          timestamp: dayjs(date).unix(),
          resolution: resolution as ResolutionString,
        })),
    ),
  removeChart: id => removeChartData({ tradingAccountId: accountId, id: id as string }),
  getChartContent: id => getChartData({ tradingAccountId: accountId, id: id as any }).then(({ content }) => content!),
  saveChart: ({ resolution, content, id, name, symbol }) =>
    addOrUpdateChartData({
      tradingAccountId: accountId,
      terminalChartUpdateData: {
        symbol,
        content,
        id,
        name,
        resolution,
      },
    }).then(({ id }) => id!),
  ////////////////////////////////////////////////////////////////
  getAllStudyTemplates: () =>
    getStudyTemplatesMetaInfo({ tradingAccountId: accountId }).then(({ items }) =>
      items!.map(({ name }) => ({ name: name! })),
    ),
  removeStudyTemplate: ({ name }) => removeStudyTemplateData({ tradingAccountId: accountId, name }),
  getStudyTemplateContent: ({ name }) =>
    getStudyTemplateData({ tradingAccountId: accountId, name }).then(({ content }) => content!),
  saveStudyTemplate: ({ content, name }) =>
    addStudyTemplateData({ tradingAccountId: accountId, terminalStudyTemplateAddData: { content, name } }),
  ////////////////////////////////////////////////////////////////
  getDrawingTemplates: () => Promise.reject(),
  loadDrawingTemplate: () => Promise.reject(),
  removeDrawingTemplate: () => Promise.reject(),
  saveDrawingTemplate: () => Promise.reject(),
  ////////////////////////////////////////////////////////////////
  getAllChartTemplates: () => Promise.reject(),
  getChartTemplateContent: () => Promise.reject(),
  removeChartTemplate: () => Promise.reject(),
  saveChartTemplate: () => Promise.reject(),
  ////////////////////////////////////////////////////////////////
  loadLineToolsAndGroups: () => Promise.reject(),
  saveLineToolsAndGroups: () => Promise.reject(),
});

export { getLoadAdapter };
