import { type FC, type ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { Tooltip } from "@/shared/ui";

type Props = {
  title: string;
  children: ReactNode;
  endSection?: ReactNode;
  description?: string;
};

const MobilePlaceOrderSection: FC<Props> = ({ children, title, description, endSection }) => {
  return (
    <div className="mt-6 flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <h3 className="text-[18px] font-semibold leading-[1.2] text-text">{title}</h3>
          {!!description && (
            <Tooltip content={description}>
              <TooltipQuestionIcon />
            </Tooltip>
          )}
        </div>
        {endSection}
      </div>
      {children}
    </div>
  );
};

export { MobilePlaceOrderSection };
