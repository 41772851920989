import { FC } from "react";

import { NumberField } from "@/components/form/fields";
import { useTranslation } from "@/hooks/translator.hook";
import { FieldType, SurveyField } from "@/services/openapi";

import { OnboardingFieldWrapper } from "../../styles";
import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyNumberField: FC<Props> = ({ field: { isRequired, title, type, id, value, description } }) => {
  const { t } = useTranslation();
  return (
    <OnboardingFieldWrapper>
      <NumberField
        name={generateSurveyFieldId(id!, type!)}
        label={generateSurveyFieldTitle(title!, isRequired!)}
        decimalScale={type === FieldType.Int ? 0 : undefined}
        rules={{
          required: isRequired ? t("form-errors.required-error")! : false,
        }}
        defaultValue={value ? value : undefined}
        description={description!}
      />
    </OnboardingFieldWrapper>
  );
};
