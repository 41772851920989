import { Grid } from "@/components/grid";
import { useTerminalLayout } from "@/features/terminal/contexts/terminal-layout.context";
import { TradingReopenMessage } from "@/features/terminal/place-order/components/trading-unavailable/components/trading-reopen-message/trading-reopen-message";

import { ChartContainer } from "./chart/container";
import { useCurrentSymbolContext } from "./contexts/current-symbol-context";
import { PlaceOrderBlock } from "./place-order/block";

export const TerminalSymbolBlock = () => {
  const { isMobile } = useTerminalLayout();
  const { isTradingAvailable, symbolInfo, currentSymbol } = useCurrentSymbolContext();

  if (!symbolInfo || !currentSymbol) {
    return null;
  }

  return (
    <Grid
      css={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        "@bp3": { display: "grid", gridTemplateColumns: "1fr 240px" },
      }}
    >
      {isMobile && !isTradingAvailable.available && <TradingReopenMessage />}
      <ChartContainer />
      <PlaceOrderBlock key={symbolInfo.symbol} />
    </Grid>
  );
};
