import { To } from "react-router-dom";

export const terminalRoutes = {
  autoRoute: "/web-trading",
  terminal: "/web-trading/doto",
  terminalMobile: "/web-trading/doto-mobile",
};

export const terminalAccountParamsName = "accountId";

export const getTerminalRoute = (accountId: string, searchParams: string = "", isMoble?: boolean): To => ({
  pathname: !isMoble ? terminalRoutes.terminal : terminalRoutes.terminalMobile,
  search: `${terminalAccountParamsName}=${accountId}${searchParams}`,
});
