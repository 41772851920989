import Big from "big.js";

import { usePlaceOrderContext } from "../context";

type Params = {
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
};

const getVolumeLotsValues = ({
  minSystemVolumeLots,
  volumeLotsStep,
  maxAvailableVolumeLots,
  volumeLots,
}: Pick<Params, "minSystemVolumeLots" | "volumeLotsStep" | "volumeLots" | "maxAvailableVolumeLots">) => {
  if (volumeLots === null || volumeLots <= minSystemVolumeLots) {
    return [
      minSystemVolumeLots,
      new Big(minSystemVolumeLots).plus(volumeLotsStep).toNumber(),
      new Big(minSystemVolumeLots).plus(volumeLotsStep).plus(volumeLotsStep).toNumber(),
    ];
  }

  if (volumeLots >= maxAvailableVolumeLots) {
    return [
      new Big(maxAvailableVolumeLots).minus(volumeLotsStep).minus(volumeLotsStep).toNumber(),
      new Big(maxAvailableVolumeLots).minus(volumeLotsStep).toNumber(),
      maxAvailableVolumeLots,
    ];
  }

  return [
    new Big(volumeLots).minus(volumeLotsStep).toNumber(),
    volumeLots,
    new Big(volumeLots).plus(volumeLotsStep).toNumber(),
  ];
};

const filterValue = ({
  volumeLots,
  minSystemVolumeLots,
  maxAvailableVolumeLots,
}: Pick<Params, "minSystemVolumeLots" | "maxAvailableVolumeLots"> & {
  volumeLots: number;
}) => {
  return volumeLots <= maxAvailableVolumeLots && volumeLots >= minSystemVolumeLots;
};

const transformValue = ({
  volumeLots,
  volumeMarginDecimalScale,
  marginMultiplier,
}: Pick<Params, "marginMultiplier" | "volumeMarginDecimalScale"> & {
  volumeLots: number;
}) => ({
  volumeLots,
  volumeMargin: new Big(volumeLots).mul(marginMultiplier).round(volumeMarginDecimalScale).toNumber(),
});

const getOptions = ({
  volumeLots,
  minSystemVolumeLots,
  volumeLotsStep,
  maxAvailableVolumeLots,
  volumeMarginDecimalScale,
  marginMultiplier,
}: Params) =>
  getVolumeLotsValues({ maxAvailableVolumeLots, minSystemVolumeLots, volumeLotsStep, volumeLots })
    .filter(volumeLots => filterValue({ volumeLots, maxAvailableVolumeLots, minSystemVolumeLots }))
    .map(volumeLots => transformValue({ marginMultiplier, volumeLots, volumeMarginDecimalScale }));

export { getOptions as getMarginSuggesterOptions };
