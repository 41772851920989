import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { IconSearch } from "@/domains/icons";

import { TextInput } from "../text/text-input";

const SearchInput = forwardRef<ElementRef<typeof TextInput>, ComponentPropsWithoutRef<typeof TextInput>>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <TextInput
        clearable
        placeholder={t("common.search")!}
        inputMode="search"
        startSection={<IconSearch />}
        {...props}
        ref={ref}
      />
    );
  },
);

export { SearchInput };
