import { type FC, type ReactNode } from "react";

type Props = {
  title: ReactNode;
  banner?: ReactNode;
  children?: ReactNode;
};

const CabinetPage: FC<Props> = ({ title, children, banner }) => {
  return (
    <>
      {title}
      <div className="my-2 lg:mb-4">{banner}</div>
      {children}
    </>
  );
};

export { CabinetPage };
