import * as DialogPrimitive from "@radix-ui/react-dialog";
import { forwardRef } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { IconCross } from "@/domains/icons";
import { Tooltip } from "@/shared/ui";
import { CSS, styled } from "@/styles";

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  position: "fixed",
  inset: "0",
  zIndex: 50,

  display: "grid",
  placeItems: "center",
  overflowY: "auto",
  overflowX: "hidden",
  hideScroll: true,
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "$bgPrimary",
  width: "100%",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  zIndex: 50,

  p: "16px",

  "&:focus": {
    outline: "none",
  },
});

type Props = {
  title: string;
  children: React.ReactNode;
  showCloseButton?: boolean;
  description?: string;
  css?: CSS;
};

const TerminalDialogContent = forwardRef<React.ElementRef<typeof StyledContent>, Props>(
  ({ children, title, description, css, showCloseButton = true }, forwardedRef) => {
    return (
      <DialogPrimitive.Portal>
        <StyledOverlay>
          <StyledContent ref={forwardedRef} css={css}>
            <div className="flex items-center justify-between gap-2 py-4">
              <h2 className="font-gilroy text-[24px] font-semibold leading-[1.2] text-text">{title}</h2>
              <div className="flex items-center gap-4">
                {description && (
                  <Tooltip content={description}>
                    <div className="relative grid size-6 place-items-center before:absolute before:size-10">
                      <TooltipQuestionIcon />
                    </div>
                  </Tooltip>
                )}
                {showCloseButton && (
                  <DialogPrimitive.Close className="relative grid size-6 place-items-center outline-none before:absolute before:size-10">
                    <IconCross />
                  </DialogPrimitive.Close>
                )}
              </div>
            </div>
            <div className="-mx-4 flex h-full flex-col overflow-y-auto px-4 scrollbar-hide">{children}</div>
          </StyledContent>
        </StyledOverlay>
      </DialogPrimitive.Portal>
    );
  },
);

TerminalDialogContent.displayName = "TerminalDialogContent";

export { TerminalDialogContent };
