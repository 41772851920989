import { type FC } from "react";

import { HookForm, useHookForm } from "@/app/form";
import { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { TradingAccountTradeMode, TradingServerSymbolType } from "@/services/openapi";

import { usePlaceOrderContext } from "../../context";
import { PlaceOrderButtonType } from "../../place-order.helpers";
import { MobilePlaceOrderDetails } from "../details/details";
import { MobileVolumeLotsField } from "../fields/volume-lots/field";
import { MobileVolumeMarginField } from "../fields/volume-margin/field";
import { MobilePlaceOrderSubmitButtons } from "../submit-buttons/buttons";

type Props = {
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeLotsFormValue: ReturnType<typeof usePlaceOrderContext>["volumeLotsFormValue"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  changeMargin: ReturnType<typeof usePlaceOrderContext>["changeMargin"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  volumeMarginFormValue: ReturnType<typeof usePlaceOrderContext>["volumeMarginFormValue"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  maxAvailableVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeSellMargin: ReturnType<typeof usePlaceOrderContext>["volumeSellMargin"];
  volumeBuyMargin: ReturnType<typeof usePlaceOrderContext>["volumeBuyMargin"];
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  swapLong: ReturnType<typeof usePlaceOrderContext>["swapLong"];
  swapShort: ReturnType<typeof usePlaceOrderContext>["swapShort"];
  symbol: string;
  instrumentType: TradingServerSymbolType;
  marginRateInitialMarketBuy: number;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  priceDecimalScale: number;
  contractSize: number;
  baseCurrency: string;
  baseCurrencyExt: string;
  group: string;
  accountCurrency: string;
  leverage: number;
  accountId: string;
  isDemoAccount: boolean;
  onSubmit: (side: PlaceOrderButtonType) => Promise<unknown>;
};

const VolumeSettingsForm: FC<Props> = ({
  marginRateInitialMarketBuy,
  accountId,
  isDemoAccount,
  maxAvailableVolumeMargin,
  maxBalanceVolumeLots,
  minSystemVolumeLots,
  maxSystemVolumeLots,
  changeLots,
  changeMargin,
  volumeMode,
  volumeLots,
  volumeMargin,
  onSubmit,
  leverage,
  ask,
  bid,
  priceDecimalScale,
  accountCurrency,
  baseCurrency,
  contractSize,
  symbol,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  marginMultiplier,
  maxAvailableVolumeLots,
  volumeLotsStep,
  decrementVolumeDisabled,
  incrementVolumeDisabled,
  incrementOrder,
  decrementOrder,
  hasNoFreeMargin,
  volumeLotsError,
  volumeMarginDecimalScale,
  volumeLotsDecimalScale,
  volumeSellMargin,
  volumeBuyMargin,
  instrumentType,
  quoteCurrency,
  symbols,
  swapLong,
  swapShort,
  volumeLotsFormValue,
  volumeMarginFormValue,
  baseCurrencyExt,
  group,
}) => {
  const form = useHookForm();

  const { formState } = form;
  const { isSubmitting } = formState;

  const handleSubmit = (_: unknown, side: PlaceOrderButtonType) => onSubmit(side);

  return (
    <HookForm form={form} className="mt-2">
      {volumeMode === TradingAccountTradeMode.Lots && (
        <MobileVolumeLotsField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          changeLots={changeLots}
          currency={accountCurrency}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
          volumeLotsFormValue={volumeLotsFormValue}
        />
      )}
      {volumeMode === TradingAccountTradeMode.Margin && (
        <MobileVolumeMarginField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeMargin={minSystemVolumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeMargin={changeMargin}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={accountCurrency}
          volumeMargin={volumeMargin}
          marginMultiplier={marginMultiplier}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          volumeLotsStep={volumeLotsStep}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeMarginFormValue={volumeMarginFormValue}
        />
      )}
      <MobilePlaceOrderDetails
        marginRateInitialMarketBuy={marginRateInitialMarketBuy}
        baseCurrency={baseCurrency}
        contractSize={contractSize}
        symbol={symbol}
        accountCurrency={accountCurrency}
        leverage={leverage}
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        volumeLots={volumeLots}
        volumeLotsError={volumeLotsError}
        ask={ask}
        bid={bid}
        priceDecimalScale={priceDecimalScale}
        volumeSellMargin={volumeSellMargin}
        volumeBuyMargin={volumeBuyMargin}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        instrumentType={instrumentType}
        quoteCurrency={quoteCurrency}
        symbols={symbols}
        swapLong={swapLong}
        swapShort={swapShort}
        baseCurrencyExt={baseCurrencyExt}
        group={group}
      >
        <MobilePlaceOrderSubmitButtons
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          volumeMode={volumeMode}
          accountCurrency={accountCurrency}
          volumeSellMargin={volumeSellMargin}
          volumeBuyMargin={volumeBuyMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          onSubmit={handleSubmit}
        />
      </MobilePlaceOrderDetails>
    </HookForm>
  );
};

export { VolumeSettingsForm };
