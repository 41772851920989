import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { NumberInput, StepperInput } from "@/shared/ui";

import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { DemoAddFundsDialog } from "../../demo/add-funds-dialog";

type Props = {
  currency: string;
  accountId: string;
  isDemoAccount: boolean;
  isMobile: boolean;
};

const PlaceOrderDepositButton: FC<Props> = ({ accountId, currency, isDemoAccount, isMobile }) => {
  const { t } = useTranslation();
  const { isMobileAppMode } = useTerminalLayout();

  if (isDemoAccount) {
    return (
      <DemoAddFundsDialog accountCurrency={currency} accountId={accountId}>
        {isMobile ? (
          <StepperInput.DescriptorButton>{t("accounts.demo.deposit.button")}</StepperInput.DescriptorButton>
        ) : (
          <NumberInput.DescriptorButton>{t("accounts.demo.deposit.button")}</NumberInput.DescriptorButton>
        )}
      </DemoAddFundsDialog>
    );
  }

  return isMobile ? (
    <StepperInput.DescriptorButton asChild>
      {isMobileAppMode ? (
        <a href={cabinetRoutes.deposit}>{t("button.deposit")}</a>
      ) : (
        <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
      )}
    </StepperInput.DescriptorButton>
  ) : (
    <NumberInput.DescriptorButton asChild>
      <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
    </NumberInput.DescriptorButton>
  );
};

export { PlaceOrderDepositButton };
