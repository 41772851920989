import { t } from "i18next";
import { Outlet } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { CabinetPage } from "@/features/cabinet/components/cabinet-page";
import { CabinetTitle } from "@/features/cabinet/components/cabinet-title";

export const DepositLayout = () => {
  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.deposit")}>
          <CabinetTitle.TradeButton onClick={() => track(amplitudeEvents.terminal.fromDeposit)} />
        </CabinetTitle>
      }
    >
      <Outlet />
    </CabinetPage>
  );
};
