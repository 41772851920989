import { FC, memo, useEffect } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useField } from "@/components/form/field.hook";

import { Select, SelectExternalProps } from "../../inputs";
import { ControllerRules } from "../../types";

export type SelectFieldExternalProps = Omit<SelectExternalProps, "onChange" | "value"> & {
  name: string;
  defaultValue?: any; // TODO:
  rules?: ControllerRules;
  resetFieldOnOptionsChanged?: boolean;
};

export const _SelectField: FC<SelectFieldExternalProps> = ({
  name,
  rules,
  defaultValue,
  resetFieldOnOptionsChanged = true,
  options, // memoize options everytime to prevent infinite loop!!!
  ...restProps
}) => {
  const { control, setValue, resetField } = useFormContext();
  const { field } = useController({ name, control, rules, defaultValue });

  const { error, showError } = useField(name);

  useEffect(() => {
    if (options.length === 1) {
      setValue(name, options[0].value, { shouldValidate: true });
    } else if (resetFieldOnOptionsChanged) {
      resetField(name);
    }
  }, [options]);

  return (
    <Select options={options} errorMessage={error?.message || ""} showError={showError} {...restProps} {...field} />
  );
};

export const SelectField = memo(_SelectField);
