import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { CloseIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { fromDepositState } from "@/features/deposit/components/verification";
import { useScreenSize } from "@/hooks/screen-size.hook";

import * as Styled from "./Header.styled";

type Props = {
  title: ReactNode;
  onClose?: () => void;
};

export const Header: FC<Props> = ({ title, onClose }) => {
  const { isMobile } = useScreenSize();
  const headRef = useRef<HTMLDivElement>(null);
  const { state } = useLocation();
  const [sticky, setSticky] = useState(false);
  const isFromDeposit = state === fromDepositState;

  useLayoutEffect(() => {
    const header = headRef.current?.getBoundingClientRect();

    const handleScrollEvent = () => {
      if (isMobile) {
        if (header) {
          const topHalf = header.top - header.top / 4;

          setSticky(window.scrollY >= topHalf);
        }
      }
    };

    window.addEventListener("scroll", handleScrollEvent, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const header = headRef.current?.getBoundingClientRect();

    const handleEnd = () => {
      if (header) {
        const topHalf = header.top - header.top / 2;

        // NOTE: по какой-то причине тут header.top не статичный в отличии от useLayoutEffect,
        // поэтому мы не можем завязываться на него, в следвие чего приходиться хардкотить числом
        // top: 32 потому что
        if (window.scrollY >= topHalf) {
          if (!sticky) {
            window.scroll({
              top: 32,
              left: 0,
              behavior: "smooth",
            });
          }
        } else {
          window.scroll({
            top: -32,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };
    document.addEventListener("touchend", handleEnd);

    return () => {
      document.removeEventListener("touchend", handleEnd);
    };
  }, [sticky]);

  return (
    <Styled.HeaderWrapper ref={headRef}>
      {isMobile && (
        <Styled.HeadStatic animate={sticky}>
          <Styled.Header as="h1">{title}</Styled.Header>

          <UnstyledButton onClick={onClose}>
            <CloseIcon />
          </UnstyledButton>
        </Styled.HeadStatic>
      )}
      <Styled.Head animate={sticky}>
        <Styled.Header as="h1">{title}</Styled.Header>

        {isMobile && (
          <UnstyledButton onClick={onClose}>
            <CloseIcon />
          </UnstyledButton>
        )}
      </Styled.Head>
    </Styled.HeaderWrapper>
  );
};
