import { FC } from "react";

import { SidebarButton } from "@/components/sidebar";
import { TerminalTableState, useTerminalLayout } from "@/features/terminal/contexts/terminal-layout.context";
import { cn } from "@/shared/styles";

import { AccountSelect } from "./account-select/account-select";
import { AccountSummaryContainer } from "./account-summary/container";
import { ChartContainer } from "./chart/container";
import { ChartSymbolsContainer } from "./chart-symbols/chart-symbols.container";
import { TerminalDepositButtonContainer } from "./deposit/terminal-deposit-button.container";
import { useTerminalFeatureTourContext } from "./feature-tour/context";
import { TerminalSymbolBlock } from "./terminal-symbol-block";
import { MobileTradingTables } from "./trading-tables/mobile-trading-tables";

export const TerminalMobile: FC = () => {
  const { isMobileAppMode, isChartExpanded, table } = useTerminalLayout();
  const { selectSymbolStyles, checkFreeMarginStyles, isCheckFreeMarginStepActive } = useTerminalFeatureTourContext();

  return (
    <>
      {isChartExpanded && table === TerminalTableState.NONE ? (
        <ChartContainer />
      ) : (
        <div className="flex h-full flex-col bg-bg">
          <div
            className={cn(
              "grid grid-cols-[1fr_auto_1fr] items-center gap-2 bg-bg px-2 py-3",
              isCheckFreeMarginStepActive && "bg-bg-back",
            )}
            style={checkFreeMarginStyles}
          >
            <div className="flex ps-2">{!isMobileAppMode && <SidebarButton isMobileAppMode={isMobileAppMode} />}</div>
            <AccountSelect />
            <div className="flex justify-end pe-2">
              <TerminalDepositButtonContainer />
            </div>
          </div>
          <AccountSummaryContainer />
          <div className="bg-bg px-2 py-4" style={selectSymbolStyles.highlight}>
            <ChartSymbolsContainer />
          </div>
          <div className="flex grow flex-col">
            <TerminalSymbolBlock />
          </div>
          <MobileTradingTables />
        </div>
      )}
    </>
  );
};
