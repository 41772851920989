import { useReducer } from "react";

import { ProfileHeader, TradingAccountTradeMode, TradingAccountType } from "@/services/openapi";

import { FeatureTourSteps } from "./types";

type State = {
  currentStep: FeatureTourSteps;
  previousStep: FeatureTourSteps;
};

type Action =
  | {
      type: "REAL_TRADE";
    }
  | {
      type: "LEAVE_IDLE"; // needed for toasts
    }
  | {
      type: "TERMINATE_TOUR";
    }
  | {
      type: "CONTINUE_TOUR";
    }
  | {
      type: "INTERRUPT_TOUR";
    }
  | {
      type: "START_TOUR";
    }
  | {
      type: "SELECT_SYMBOL";
    }
  | {
      type: "CHART_EXAMINE";
    }
  | {
      type: "INCREMENT_ORDER";
    }
  | {
      type: "PLACE_ORDER";
      payload: {
        isMobile: boolean;
        tradeMode: TradingAccountTradeMode;
      };
    }
  | {
      type: "CHECK_FREE_MARGIN";
      payload: {
        isMobile: boolean;
      };
    }
  | {
      type: "CLOSE_ORDER";
      payload: {
        tradeMode: TradingAccountTradeMode;
        closeOrder: boolean;
      };
    }
  | {
      type: "CHANGE_VOLUME_MODE";
    }
  | {
      type: "REPEAT_TOUR";
    }
  | {
      type: "FINISH_TOUR";
    };

const reducer = (state: State, action: Action): State => {
  const newPreviousStep = state.currentStep;

  if (state.currentStep === FeatureTourSteps.NONE) {
    return state;
  }

  switch (action.type) {
    case "LEAVE_IDLE":
      return {
        currentStep: state.previousStep,
        previousStep: newPreviousStep,
      };
    case "REAL_TRADE":
      return {
        currentStep: FeatureTourSteps.NONE,
        previousStep: newPreviousStep,
      };
    case "TERMINATE_TOUR":
      return {
        currentStep: FeatureTourSteps.NONE,
        previousStep: newPreviousStep,
      };
    case "CONTINUE_TOUR":
      return {
        currentStep: state.previousStep,
        previousStep: newPreviousStep,
      };
    case "INTERRUPT_TOUR":
      return {
        currentStep: FeatureTourSteps.INTERRUPT,
        previousStep: newPreviousStep,
      };
    case "START_TOUR":
      return {
        currentStep: FeatureTourSteps.SELECT_SYMBOL,
        previousStep: newPreviousStep,
      };
    case "SELECT_SYMBOL":
      return {
        currentStep: FeatureTourSteps.CHART,
        previousStep: newPreviousStep,
      };
    case "CHART_EXAMINE":
      return {
        currentStep: FeatureTourSteps.INCREMENT_ORDER,
        previousStep: newPreviousStep,
      };
    case "INCREMENT_ORDER":
      return {
        currentStep: FeatureTourSteps.PLACE_ORDER,
        previousStep: newPreviousStep,
      };
    case "PLACE_ORDER":
      return {
        currentStep: FeatureTourSteps.IDLE,
        previousStep:
          action.payload.tradeMode === TradingAccountTradeMode.Lots
            ? action.payload.isMobile
              ? FeatureTourSteps.FINAL
              : FeatureTourSteps.CLOSE_ORDER
            : FeatureTourSteps.CHECK_FREE_MARGIN,
      };
    case "CHECK_FREE_MARGIN":
      return {
        currentStep: action.payload.isMobile ? FeatureTourSteps.CHANGE_VOLUME_MODE : FeatureTourSteps.CLOSE_ORDER,
        previousStep: newPreviousStep,
      };
    case "CLOSE_ORDER":
      return {
        currentStep: action.payload.closeOrder
          ? FeatureTourSteps.IDLE
          : action.payload.tradeMode === TradingAccountTradeMode.Lots
            ? FeatureTourSteps.FINAL
            : FeatureTourSteps.CHANGE_VOLUME_MODE,
        previousStep: action.payload.closeOrder
          ? action.payload.tradeMode === TradingAccountTradeMode.Lots
            ? FeatureTourSteps.FINAL
            : FeatureTourSteps.CHANGE_VOLUME_MODE
          : newPreviousStep,
      };
    case "CHANGE_VOLUME_MODE":
      return {
        currentStep: FeatureTourSteps.FINAL,
        previousStep: newPreviousStep,
      };
    case "REPEAT_TOUR":
      return {
        currentStep: FeatureTourSteps.SELECT_SYMBOL,
        previousStep: newPreviousStep,
      };
    case "FINISH_TOUR":
      return {
        currentStep: FeatureTourSteps.NONE,
        previousStep: newPreviousStep,
      };

    default:
      return state;
  }
};

const getInitialState = ({
  accountType,
  profile,
}: {
  accountType: TradingAccountType;
  isMobileAppMode: boolean;
  profile: ProfileHeader;
}): State => {
  const isDemoAccount = accountType === TradingAccountType.Demo;
  const shouldShowFeatureTour = profile.featureTours!.shouldShowFeatureTour;

  if (isDemoAccount && shouldShowFeatureTour) {
    return { currentStep: FeatureTourSteps.WELCOME, previousStep: FeatureTourSteps.WELCOME };
  }

  return { currentStep: FeatureTourSteps.NONE, previousStep: FeatureTourSteps.NONE };
};

const useFeatureTour = (params: Parameters<typeof getInitialState>[0]) => {
  return useReducer(reducer, params, getInitialState);
};

export { useFeatureTour };
