import React, { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { setWebviewSentry } from "@/app/libs/sentry";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { terminalRoutes } from "@/routes/terminal.routes";

export enum TerminalTableState {
  NONE = "closePositions",
  OPEN = "openPositions",
  PENDING = "pendingPositions",
  CLOSED_POSITIONS = "closedPositions",
}

type ContextProps = {
  table: string;
  watchlistOpen: boolean;
  isMobile: boolean;
  isMobileAppMode: boolean;
  openWatchlist: () => void;
  closeWatchlist: () => void;
  watchlistOnChange: (value: boolean) => void;
  closeTable: () => void;
  toggleTable: () => void;
  changeTable: (table: string) => void;
  isChartExpanded: boolean;
  setIsChartExpanded: SetState<boolean>;
};

const TerminalLayoutContext = createContext<ContextProps>({} as ContextProps);

// for webview
const openPositionsEventName = "open-positions";
const pendingPositionsEventName = "pending-positions";
const closedPositionsEventName = "closed-positions";
const closePositionsEventName = "close-positions";

window.openPositionsEvent = new CustomEvent(openPositionsEventName);
window.pendingPositionsEvent = new CustomEvent(pendingPositionsEventName);
window.closedPositionsEvent = new CustomEvent(closedPositionsEventName);
window.closePositionsEvent = new CustomEvent(closePositionsEventName);

export const TerminalLayoutContextProvider = memo(({ children }: { children: React.ReactNode }) => {
  const [table, setTable] = useState<string>(TerminalTableState.NONE);
  const [watchlistOpen, { open: openWatchlist, close: closeWatchlist, onOpenChange: watchlistOnChange }] =
    useDisclosure(false);

  const location = useLocation();

  const isMobileAppMode = useMemo(() => location.pathname === terminalRoutes.terminalMobile, [location]);

  const { isDesktop } = useScreenSize();

  const isMobile = !isDesktop;

  const [_isChartExpanded, setIsChartExpanded] = useState<ContextProps["isChartExpanded"]>(false);
  const isChartExpanded = _isChartExpanded || isDesktop;

  const changeTable: ContextProps["changeTable"] = useCallback(table => {
    setTable(table);

    if (window["WEBVIEW_CHANNEL"]) {
      if (table === TerminalTableState.OPEN) {
        window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.OPEN);
        return;
      }
      if (table === TerminalTableState.PENDING) {
        window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.PENDING);
        return;
      }
      if (table === TerminalTableState.CLOSED_POSITIONS) {
        window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.CLOSED_POSITIONS);
        return;
      }
      window["WEBVIEW_CHANNEL"].postMessage(TerminalTableState.NONE);
    }
  }, []);

  useEffect(() => {
    const handleOpen = () => {
      changeTable(TerminalTableState.OPEN);
    };
    const handlePending = () => {
      changeTable(TerminalTableState.PENDING);
    };
    const handleClosed = () => {
      changeTable(TerminalTableState.CLOSED_POSITIONS);
    };
    const handleClose = () => {
      changeTable(TerminalTableState.NONE);
    };

    // document.dispatchEvent(event);
    document.addEventListener(openPositionsEventName, handleOpen);
    document.addEventListener(pendingPositionsEventName, handlePending);
    document.addEventListener(closedPositionsEventName, handleClosed);
    document.addEventListener(closePositionsEventName, handleClose);

    return () => {
      document.removeEventListener(openPositionsEventName, handleOpen);
      document.removeEventListener(pendingPositionsEventName, handlePending);
      document.removeEventListener(closedPositionsEventName, handleClosed);
      document.removeEventListener(closePositionsEventName, handleClose);
    };
  }, []);

  const toggleTable = useCallback(() => {
    if (table === TerminalTableState.NONE) {
      changeTable(TerminalTableState.OPEN);
    } else {
      changeTable(TerminalTableState.NONE);
    }
  }, [table, changeTable]);

  const closeTable = useCallback(() => {
    changeTable(TerminalTableState.NONE);
  }, []);

  useEffect(() => {
    setWebviewSentry(isMobileAppMode);
  }, [isMobileAppMode]);

  const value: ContextProps = useMemo(
    () => ({
      watchlistOpen,
      isMobileAppMode,
      table,
      isMobile,
      toggleTable,
      watchlistOnChange,
      closeTable,
      changeTable,
      openWatchlist,
      closeWatchlist,
      setIsChartExpanded,
      isChartExpanded,
    }),
    [
      closeTable,
      isMobile,
      isMobileAppMode,
      table,
      toggleTable,
      watchlistOpen,
      openWatchlist,
      closeWatchlist,
      watchlistOnChange,
      changeTable,
      setIsChartExpanded,
      isChartExpanded,
    ],
  );
  return <TerminalLayoutContext.Provider value={value}>{children}</TerminalLayoutContext.Provider>;
});
TerminalLayoutContextProvider.displayName = "TerminalLayoutContextProvider";

export const useTerminalLayout = () => {
  return useContext(TerminalLayoutContext);
};
