import { styled } from "@/styles";

export const WatchMoreButton = styled("button", {
  width: "100%",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 24px",

  borderRadius: "32px",
  backgroundColor: "$white",

  fontFamily: "$gilroy",
  fontSize: "14px",
  fontWeight: "$bolder",
  lineHeight: "120%",

  "&:active": {
    transition: "all 0.1 linear",
    transform: "scale(0.98)",
  },

  "@bp3": {
    backgroundColor: "transparent",
    border: "1px solid #E8E8E8",
  },
});
