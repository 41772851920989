import Big from "big.js";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { type UseFormSetValue } from "react-hook-form";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import {
  TerminalDealType,
  TerminalOpenOrderVolumeOriginal,
  TerminalOpenOrderVolumeOriginalType,
  TradingAccountTradeMode,
  TradingServerSymbolType,
} from "@/services/openapi";
import { Toast, toast, ToastIcons } from "@/shared/ui";

import { formatInputNumberValue, getInputNumberValue } from "../helpers/formatting";
import { countMargin } from "../helpers/orders";
import { MergedTerminalSymbol } from "../helpers/symbols";
import { usePlaceOrderContext } from "./context";

export type PlaceOrderButtonType = "Buy" | "Sell";

// type MarketButtonParams = {
//   price: number | undefined;
//   tradeMode: TradingServerSymbolTradeMode;
//   takeProfit: number | null;
//   stopLoss: number | null;
//   volume: number;
// };

// const getDisabledMarketBuyButton = ({ stopLoss, volume, tradeMode, takeProfit, price }: MarketButtonParams) => {
//   if (!price || !volume) {
//     return true;
//   }
//   if (
//     tradeMode === TradingServerSymbolTradeMode.Disabled ||
//     tradeMode === TradingServerSymbolTradeMode.LongOnly ||
//     tradeMode === TradingServerSymbolTradeMode.CloseOnly
//   ) {
//     return true;
//   }

//   if (takeProfit && stopLoss) {
//     if (takeProfit < price && stopLoss > price) {
//       return true;
//     }
//   }

//   if (takeProfit) {
//     if (takeProfit < price) {
//       return true;
//     }
//   }
//   if (stopLoss) {
//     if (stopLoss > price) {
//       return true;
//     }
//   }

//   return false;
// };

// const getDisabledMarketSellButton = ({ stopLoss, volume, tradeMode, takeProfit, price }: MarketButtonParams) => {
//   if (!price || !volume) {
//     return true;
//   }
//   if (
//     tradeMode === TradingServerSymbolTradeMode.Disabled ||
//     tradeMode === TradingServerSymbolTradeMode.ShortOnly ||
//     tradeMode === TradingServerSymbolTradeMode.CloseOnly
//   ) {
//     return true;
//   }

//   if (takeProfit && stopLoss) {
//     if (takeProfit > price && stopLoss < price) {
//       return true;
//     }
//   }

//   if (takeProfit) {
//     if (takeProfit > price) {
//       return true;
//     }
//   }
//   if (stopLoss) {
//     if (stopLoss < price) {
//       return true;
//     }
//   }

//   return false;
// };

// type PendingButtonParams = {
//   openingPrice: number;
//   price: number | undefined;
//   tradeMode: TradingServerSymbolTradeMode;
//   takeProfit: number | null;
//   stopLoss: number | null;
//   volume: number;
// };

// const getDisabledPendingBuyButton = ({
//   stopLoss,
//   volume,
//   tradeMode,
//   takeProfit,
//   price,
//   openingPrice,
// }: PendingButtonParams) => {
//   if (!price || !volume || !openingPrice) {
//     return true;
//   }
//   if (
//     tradeMode === TradingServerSymbolTradeMode.Disabled ||
//     tradeMode === TradingServerSymbolTradeMode.LongOnly ||
//     tradeMode === TradingServerSymbolTradeMode.CloseOnly
//   ) {
//     return true;
//   }

//   if (takeProfit && stopLoss) {
//     if (takeProfit < openingPrice && stopLoss > openingPrice) {
//       return true;
//     }
//   }

//   if (takeProfit) {
//     if (takeProfit < openingPrice) {
//       return true;
//     }
//   }

//   if (stopLoss) {
//     if (stopLoss > openingPrice) {
//       return true;
//     }
//   }

//   return false;
// };

// const getDisabledPendingSellButton = ({
//   stopLoss,
//   volume,
//   tradeMode,
//   takeProfit,
//   price,
//   openingPrice,
// }: PendingButtonParams) => {
//   if (!price || !volume || !openingPrice) {
//     return true;
//   }
//   if (
//     tradeMode === TradingServerSymbolTradeMode.Disabled ||
//     tradeMode === TradingServerSymbolTradeMode.ShortOnly ||
//     tradeMode === TradingServerSymbolTradeMode.CloseOnly
//   ) {
//     return true;
//   }

//   if (takeProfit && stopLoss) {
//     if (takeProfit > openingPrice && stopLoss < openingPrice) {
//       return true;
//     }
//   }

//   if (takeProfit) {
//     if (takeProfit > openingPrice) {
//       return true;
//     }
//   }

//   if (stopLoss) {
//     if (stopLoss < openingPrice) {
//       return true;
//     }
//   }

//   return false;
// };

const terminalFormatDate = (date: string) => {
  return dayjs(date).format("DD.MM.YY HH:mm:ss");
};

const getPendingPlaceOrderType = ({
  side,
  openPrice,
  ask,
  bid,
}: {
  side: PlaceOrderButtonType;
  openPrice: number;
  ask: number;
  bid: number;
}) => {
  const currentPrice = side === "Buy" ? ask : bid;
  const openNumPrice = +openPrice;

  if (openNumPrice > currentPrice) {
    return side === "Buy" ? TerminalDealType.BuyStop : TerminalDealType.SellLimit;
  }

  return side === "Buy" ? TerminalDealType.BuyLimit : TerminalDealType.SellStop;
};

const getOriginalVolume = ({
  volumeLots,
  volumeMargin,
  volumeMode,
}: {
  volumeMode: TradingAccountTradeMode;
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
}): TerminalOpenOrderVolumeOriginal => {
  if (volumeMode === TradingAccountTradeMode.Margin) {
    return { type: TerminalOpenOrderVolumeOriginalType.Margin, amount: volumeMargin! };
  }

  return { type: TerminalOpenOrderVolumeOriginalType.Lots, amount: volumeLots! };
};

const getMaximumOrderVolume = ({
  quoteCurrency,
  contractSize,
  currency,
  baseCurrency,
  leverage,
  instrumentType,
  symbols,
  marginFree,
  volumeLots,
  maxBalanceVolumeLots,
  volumeLotsDecimalScale,
  volumeLotsStep,
  bid,
  marginRateInitialMarketBuy,
  marginRateInitialMarketSell,
  marginRateMaintenanceMarketBuy,
  marginRateMaintenanceMarketSell,
}: {
  contractSize: number;
  currency: string;
  quoteCurrency: string;
  baseCurrency: string;
  leverage: number;
  instrumentType: TradingServerSymbolType;
  symbols: MergedTerminalSymbol[];
  marginRateInitialMarketBuy: number;
  marginRateInitialMarketSell: number;
  marginRateMaintenanceMarketBuy: number;
  marginRateMaintenanceMarketSell: number;
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  marginFree: ReturnType<typeof usePlaceOrderContext>["marginFree"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
}): number => {
  if (maxBalanceVolumeLots !== volumeLots) {
    return volumeLots!;
  }

  const volumeStepMargin = new Big(
    countMargin({
      accountCurrency: currency,
      baseCurrency,
      contractSize,
      leverage,
      quoteCurrency,
      volume: volumeLotsStep,
      type: TerminalDealType.Sell,
      symbols,
      openPrice: bid,
      instrumentType: instrumentType,
      marginRate: "initial",
      marginRateInitialMarketBuy,
      marginRateInitialMarketSell,
      marginRateMaintenanceMarketBuy,
      marginRateMaintenanceMarketSell,
    }),
  ).round(2, 3);

  return new Big(marginFree).div(volumeStepMargin).mul(volumeLotsStep).round(volumeLotsDecimalScale, 0).toNumber();
};

//----------------------------------------------------------------
//----------------------------------------------------------------
//----------------------------------------------------------------

type TakeProfitStopLossUtils = {
  takeProfitName: string;
  stopLossName: string;
  setValue: UseFormSetValue<any>;
};

enum Error {
  NONE = "none",
  BOTH = "both",
  TAKE_PROFIT = "takeProfit",
  STOP_LOSS = "stopLoss",
}

const showError = ({
  error,
  side,
  ask,
  bid,
  priceDecimalScale,
  t,
  setValue,
  stopLossName,
  takeProfitName,
  isMarket,
}: {
  error: Error;
  side: PlaceOrderButtonType;
  ask: number;
  bid: number;
  priceDecimalScale: number;
  t: TFunction;
  isMarket?: boolean;
} & TakeProfitStopLossUtils) => {
  const isBuyOrder = side === "Buy";
  const price = side === "Buy" ? ask : bid;

  const altText = "set price"; // TODO:

  const changeStopLoss = (price: number) =>
    setValue(stopLossName, formatInputNumberValue(price, priceDecimalScale), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

  const changeTakeProfit = (price: number) =>
    setValue(takeProfitName, formatInputNumberValue(price, priceDecimalScale), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

  switch (error) {
    case Error.BOTH:
      toast({ text: t("terminal.place-order.take-profit-stop-loss-errors.both"), icon: ToastIcons.ERROR });
      break;
    case Error.STOP_LOSS:
      if (isBuyOrder) {
        const stopLossBuyPrice = isMarket ? new Big(price).mul(0.99).toNumber() : price;
        toast({
          text: (
            <Trans
              i18nKey="terminal.place-order.take-profit-stop-loss-errors.stop-loss-buy"
              components={{
                valueButton: <Toast.TextButton altText={altText} onClick={() => changeStopLoss(stopLossBuyPrice)} />,
                value: <NumberFormat value={stopLossBuyPrice} decimalScale={priceDecimalScale} />,
              }}
            />
          ),
          icon: ToastIcons.ERROR,
          autoClose: false,
        });
      } else {
        const stopLossSellPrice = isMarket ? new Big(price).mul(1.01).toNumber() : price;
        toast({
          text: (
            <Trans
              i18nKey="terminal.place-order.take-profit-stop-loss-errors.stop-loss-sell"
              components={{
                valueButton: <Toast.TextButton altText={altText} onClick={() => changeStopLoss(stopLossSellPrice)} />,
                value: <NumberFormat value={stopLossSellPrice} decimalScale={priceDecimalScale} />,
              }}
            />
          ),
          icon: ToastIcons.ERROR,
          autoClose: false,
        });
      }
      break;
    case Error.TAKE_PROFIT:
      if (isBuyOrder) {
        const takeProfitBuyPrice = isMarket ? new Big(price).mul(1.01).toNumber() : price;
        toast({
          text: (
            <Trans
              i18nKey="terminal.place-order.take-profit-stop-loss-errors.take-profit-buy"
              components={{
                valueButton: (
                  <Toast.TextButton altText={altText} onClick={() => changeTakeProfit(takeProfitBuyPrice)} />
                ),
                value: <NumberFormat value={takeProfitBuyPrice} decimalScale={priceDecimalScale} />,
              }}
            />
          ),
          icon: ToastIcons.ERROR,
        });
      } else {
        const takeProfitSellPrice = isMarket ? new Big(price).mul(0.99).toNumber() : price;
        toast({
          text: (
            <Trans
              i18nKey="terminal.place-order.take-profit-stop-loss-errors.take-profit-sell"
              components={{
                valueButton: (
                  <Toast.TextButton altText={altText} onClick={() => changeTakeProfit(takeProfitSellPrice)} />
                ),
                value: <NumberFormat value={takeProfitSellPrice} decimalScale={priceDecimalScale} />,
              }}
            />
          ),
          icon: ToastIcons.ERROR,
        });
      }
      break;
  }
};

const getBuyError = ({
  price,
  takeProfit,
  stopLoss,
}: {
  price: number;
  takeProfit: number;
  stopLoss: number;
}): Error => {
  if (takeProfit && stopLoss) {
    if (takeProfit < price && stopLoss > price) {
      return Error.BOTH;
    }
  }
  if (takeProfit) {
    if (takeProfit < price) {
      return Error.TAKE_PROFIT;
    }
  }
  if (stopLoss) {
    if (stopLoss > price) {
      return Error.STOP_LOSS;
    }
  }
  return Error.NONE;
};

const getSellError = ({
  price,
  takeProfit,
  stopLoss,
}: {
  price: number;
  takeProfit: number;
  stopLoss: number;
}): Error => {
  if (takeProfit && stopLoss) {
    if (takeProfit > price && stopLoss < price) {
      return Error.BOTH;
    }
  }
  if (takeProfit) {
    if (takeProfit > price) {
      return Error.TAKE_PROFIT;
    }
  }
  if (stopLoss) {
    if (stopLoss < price) {
      return Error.STOP_LOSS;
    }
  }
  return Error.NONE;
};

const handleMarketTakeProfitStopLossErrors = ({
  ask,
  bid,
  takeProfit: tp,
  stopLoss: sl,
  side,
  priceDecimalScale,
  t,
  setValue,
  stopLossName,
  takeProfitName,
}: {
  ask: number;
  bid: number;
  takeProfit: string | undefined;
  stopLoss: string | undefined;
  side: PlaceOrderButtonType;
  priceDecimalScale: number;
  t: TFunction;
} & TakeProfitStopLossUtils): boolean => {
  const takeProfit = getInputNumberValue(tp)!;
  const stopLoss = getInputNumberValue(sl)!;
  const error =
    side === "Buy"
      ? getBuyError({
          price: ask,
          takeProfit,
          stopLoss,
        })
      : getSellError({
          price: bid,
          takeProfit,
          stopLoss,
        });
  showError({ error, side, ask, bid, priceDecimalScale, t, setValue, stopLossName, takeProfitName, isMarket: true });
  return error === Error.NONE;
};

const handlePendingTakeProfitStopLossErrors = ({
  openPrice,
  takeProfit: tp,
  stopLoss: sl,
  side,
  priceDecimalScale,
  t,
  setValue,
  stopLossName,
  takeProfitName,
}: {
  openPrice: number;
  takeProfit: string | undefined;
  stopLoss: string | undefined;
  side: PlaceOrderButtonType;
  priceDecimalScale: number;
  t: TFunction;
} & TakeProfitStopLossUtils): boolean => {
  const takeProfit = getInputNumberValue(tp)!;
  const stopLoss = getInputNumberValue(sl)!;
  const error =
    side === "Buy"
      ? getBuyError({
          price: openPrice,
          takeProfit,
          stopLoss,
        })
      : getSellError({
          price: openPrice,
          takeProfit,
          stopLoss,
        });
  showError({
    error,
    side,
    ask: openPrice,
    bid: openPrice,
    priceDecimalScale,
    t,
    setValue,
    takeProfitName,
    stopLossName,
  });
  return error === Error.NONE;
};

export {
  getPendingPlaceOrderType,
  terminalFormatDate,
  getOriginalVolume,
  handlePendingTakeProfitStopLossErrors,
  handleMarketTakeProfitStopLossErrors,
  getMaximumOrderVolume,
};

export type { TakeProfitStopLossUtils };
