import { FC, useCallback } from "react";

import { Text } from "@/components/text";
import { CabinetInputWrapper } from "@/features/cabinet/components/cabinet-styles";
import { useTranslation } from "@/hooks/translator.hook";
import { useChangePasswordMutation } from "@/state/server/auth";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { ChangePasswordForm, IChangePasswordFormValues } from "./change-password.form";

export const ChangePasswordContainer: FC = () => {
  const { t } = useTranslation();

  const { options } = useProfileData();

  const { mutateAsync: changePassword } = useChangePasswordMutation({ successMessage: "password-updated" });

  const handleSubmit = useCallback(
    (values: IChangePasswordFormValues) => {
      return changePassword({ passwordChangeRequest: values });
    },
    [changePassword],
  );

  return (
    <CabinetInputWrapper>
      <Text as="h2" weight="bold" size="4" css={{ mb: "30px" }}>
        {t("profile-settings.change-password.title")}
      </Text>
      <ChangePasswordForm
        onSubmit={handleSubmit}
        is2FaEnabled={options!.is2FaEnabled!}
        isPasswordEnabled={options!.isPasswordEnabled!}
      />
    </CabinetInputWrapper>
  );
};
