import { type ComponentProps, type FC } from "react";

import { HookForm, useHookForm } from "@/app/form";
import { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { TradingServerSymbolType } from "@/services/openapi";

import { usePlaceOrderContext } from "../../../context";
import { PlaceOrderButtonType, type TakeProfitStopLossUtils } from "../../../place-order.helpers";
import { MobileAutocloseBlock } from "../../autoclose/block";
import { MobilePlaceOrderDetails } from "../../details/details";
import { MobileOpenPriceBlock } from "../../fields/open-price/block";
import { MobileStopLossField } from "../../fields/stop-loss/field";
import { MobileTakeProfitField } from "../../fields/take-profit/field";
import { MobilePlaceOrderSubmitButtons } from "../../submit-buttons/buttons";
import { MobileValueTypeBlock } from "../../value-type/block";

enum Fields {
  STOP_LOSS = "stopLoss",
  TAKE_PROFIT = "takeProfit",
}

type FormValues = {
  [Fields.STOP_LOSS]?: string;
  [Fields.TAKE_PROFIT]?: string;
};

type Props = {
  maxAvailableVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeLots"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  changeLots: ReturnType<typeof usePlaceOrderContext>["changeLots"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeLotsFormValue: ReturnType<typeof usePlaceOrderContext>["volumeLotsFormValue"];
  maxBalanceVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeLots"];
  minSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeLots"];
  maxSystemVolumeLots: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeLots"];
  changeMargin: ReturnType<typeof usePlaceOrderContext>["changeMargin"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  volumeMarginFormValue: ReturnType<typeof usePlaceOrderContext>["volumeMarginFormValue"];
  maxBalanceVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxBalanceVolumeMargin"];
  minSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["minSystemVolumeMargin"];
  maxSystemVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxSystemVolumeMargin"];
  maxAvailableVolumeMargin: ReturnType<typeof usePlaceOrderContext>["maxAvailableVolumeMargin"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  volumeLotsDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeLotsDecimalScale"];
  volumeSellMargin: ReturnType<typeof usePlaceOrderContext>["volumeSellMargin"];
  volumeBuyMargin: ReturnType<typeof usePlaceOrderContext>["volumeBuyMargin"];
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  swapLong: ReturnType<typeof usePlaceOrderContext>["swapLong"];
  swapShort: ReturnType<typeof usePlaceOrderContext>["swapShort"];
  openPrice: ReturnType<typeof usePlaceOrderContext>["openPrice"];
  openPriceFormValue: ReturnType<typeof usePlaceOrderContext>["openPriceFormValue"];
  changeOpenPrice: ReturnType<typeof usePlaceOrderContext>["changeOpenPrice"];
  resetOpenPrice: ReturnType<typeof usePlaceOrderContext>["resetOpenPrice"];
  symbol: string;
  instrumentType: TradingServerSymbolType;
  marginRateInitialMarketBuy: number;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  priceDecimalScale: number;
  contractSize: number;
  baseCurrency: string;
  baseCurrencyExt: string;
  group: string;
  accountCurrency: string;
  leverage: number;
  accountId: string;
  isDemoAccount: boolean;
  onSubmit: (values: FormValues, side: PlaceOrderButtonType, tpsl: TakeProfitStopLossUtils) => Promise<unknown>;
};

const MobilePendingOrderForm: FC<Props> = ({
  marginRateInitialMarketBuy,
  maxAvailableVolumeMargin,
  maxBalanceVolumeLots,
  minSystemVolumeLots,
  maxSystemVolumeLots,
  changeLots,
  changeMargin,
  volumeMode,
  volumeLots,
  volumeMargin,
  onSubmit,
  leverage,
  ask,
  bid,
  priceDecimalScale,
  accountCurrency,
  baseCurrency,
  contractSize,
  symbol,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  marginMultiplier,
  maxAvailableVolumeLots,
  volumeLotsStep,
  decrementVolumeDisabled,
  incrementVolumeDisabled,
  incrementOrder,
  decrementOrder,
  hasNoFreeMargin,
  volumeLotsError,
  volumeMarginDecimalScale,
  volumeLotsDecimalScale,
  volumeSellMargin,
  volumeBuyMargin,
  instrumentType,
  quoteCurrency,
  symbols,
  swapLong,
  swapShort,
  changeVolumeMode,
  changeOpenPrice,
  openPrice,
  resetOpenPrice,
  openPriceFormValue,
  volumeLotsFormValue,
  volumeMarginFormValue,
  accountId,
  isDemoAccount,
  baseCurrencyExt,
  group,
}) => {
  const form = useHookForm<FormValues>();

  const { formState, setValue } = form;
  const { isSubmitting } = formState;

  const handleSubmit: ComponentProps<typeof MobilePlaceOrderSubmitButtons>["onSubmit"] = (values, side) =>
    onSubmit(values, side, {
      stopLossName: Fields.STOP_LOSS,
      takeProfitName: Fields.TAKE_PROFIT,
      setValue,
    });

  return (
    <HookForm form={form}>
      <MobileOpenPriceBlock
        ask={ask}
        openPriceFormValue={openPriceFormValue}
        isSubmitting={isSubmitting}
        changeOpenPrice={changeOpenPrice}
        resetOpenPrice={resetOpenPrice}
        priceDecimalScale={priceDecimalScale}
      />
      <MobileValueTypeBlock
        accountId={accountId}
        isDemoAccount={isDemoAccount}
        volumeMode={volumeMode}
        changeVolumeMode={changeVolumeMode}
        currency={accountCurrency}
        isSubmitting={isSubmitting}
        volumeLots={volumeLots}
        changeLots={changeLots}
        maxAvailableVolumeLots={maxAvailableVolumeLots}
        minSystemVolumeLots={minSystemVolumeLots}
        decrementVolumeDisabled={decrementVolumeDisabled}
        incrementVolumeDisabled={incrementVolumeDisabled}
        incrementOrder={incrementOrder}
        decrementOrder={decrementOrder}
        hasNoFreeMargin={hasNoFreeMargin}
        maxBalanceVolumeLots={maxBalanceVolumeLots}
        maxSystemVolumeLots={maxSystemVolumeLots}
        volumeLotsError={volumeLotsError}
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        maxBalanceVolumeMargin={maxBalanceVolumeMargin}
        maxAvailableVolumeMargin={maxAvailableVolumeMargin}
        maxSystemVolumeMargin={maxSystemVolumeMargin}
        minSystemVolumeMargin={minSystemVolumeMargin}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        changeMargin={changeMargin}
        volumeMargin={volumeMargin}
        marginMultiplier={marginMultiplier}
        volumeLotsStep={volumeLotsStep}
        volumeLotsFormValue={volumeLotsFormValue}
        volumeMarginFormValue={volumeMarginFormValue}
      />
      <MobileAutocloseBlock>
        <MobileStopLossField name={Fields.STOP_LOSS} priceDecimalScale={priceDecimalScale} />
        <MobileTakeProfitField name={Fields.TAKE_PROFIT} priceDecimalScale={priceDecimalScale} />
      </MobileAutocloseBlock>
      <MobilePlaceOrderDetails
        marginRateInitialMarketBuy={marginRateInitialMarketBuy}
        baseCurrency={baseCurrency}
        contractSize={contractSize}
        symbol={symbol}
        accountCurrency={accountCurrency}
        leverage={leverage}
        volumeLotsDecimalScale={volumeLotsDecimalScale}
        volumeLots={volumeLots}
        volumeLotsError={volumeLotsError}
        ask={ask}
        bid={bid}
        priceDecimalScale={priceDecimalScale}
        volumeSellMargin={volumeSellMargin}
        volumeBuyMargin={volumeBuyMargin}
        volumeMarginDecimalScale={volumeMarginDecimalScale}
        instrumentType={instrumentType}
        quoteCurrency={quoteCurrency}
        symbols={symbols}
        swapLong={swapLong}
        swapShort={swapShort}
        openPrice={openPrice}
        baseCurrencyExt={baseCurrencyExt}
        group={group}
        isPendingOrder
      >
        <MobilePlaceOrderSubmitButtons
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          volumeMode={volumeMode}
          accountCurrency={accountCurrency}
          volumeSellMargin={volumeSellMargin}
          volumeBuyMargin={volumeBuyMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          onSubmit={handleSubmit}
        />
      </MobilePlaceOrderDetails>
    </HookForm>
  );
};

export { MobilePendingOrderForm };
