import * as DialogPrimitive from "@radix-ui/react-dialog";
import { ComponentProps, ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";

import { Text } from "../Text/Text";
import { preventCloseModalOnToastClick } from "./Dialog.helpers";
import * as Styled from "./Dialog.styled";
import { DialogContentProps } from "./Dialog.types";

const Content = forwardRef<ElementRef<typeof DialogPrimitive.Overlay>, DialogContentProps>(
  ({ children, closeBtnCSS, disableCloseBtn, ...props }, forwardedRef) => {
    const { t } = useTranslation();
    const { isLargeDesktop } = useScreenSize();

    return (
      <DialogPrimitive.Portal>
        <Styled.Overlay as={DialogPrimitive.Overlay}>
          <Styled.Content
            as={DialogPrimitive.Content}
            onOpenAutoFocus={e => !isLargeDesktop && e.preventDefault()}
            onCloseAutoFocus={e => !isLargeDesktop && e.preventDefault()}
            onInteractOutside={preventCloseModalOnToastClick}
            {...props}
            ref={forwardedRef}
          >
            {children}

            {!disableCloseBtn && (
              <DialogPrimitive.Close asChild>
                <Styled.ButtonIcon aria-label={t("button.close")!} css={closeBtnCSS} type="button">
                  <IconCross />
                </Styled.ButtonIcon>
              </DialogPrimitive.Close>
            )}
          </Styled.Content>
        </Styled.Overlay>
      </DialogPrimitive.Portal>
    );
  },
);

const TitleText = forwardRef<ElementRef<typeof Text>, ComponentProps<typeof Text> & { as?: any }>(
  ({ children, as = "h2", size = 6, ...restProps }, ref) => {
    return (
      <Text as={as} weight="bold" size={size} align="center" lineHeight="4" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);

const DescriptionText = forwardRef<ElementRef<typeof Text>, ComponentProps<typeof Text> & { as?: any }>(
  ({ children, as = "p", ...restProps }, ref) => {
    return (
      <Text as={as} family="roboto" color="dimmed" align="center" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);

type DialogAPI = typeof DialogPrimitive.Root & {
  Close: typeof DialogPrimitive.Close;
  Content: typeof Content;
  Description: typeof DialogPrimitive.Description;
  Title: typeof DialogPrimitive.Title;
  Trigger: typeof DialogPrimitive.Trigger;
  TitleText: typeof TitleText;
  DescriptionText: typeof DescriptionText;
};

const DialogRoot: typeof DialogPrimitive.Root = props => {
  return <DialogPrimitive.Root {...props} />;
};
DialogRoot.displayName = DialogPrimitive.Root.displayName;

export const Dialog: DialogAPI = Object.assign(DialogRoot, {
  Close: DialogPrimitive.Close,
  Content,
  Description: DialogPrimitive.Description,
  Title: DialogPrimitive.Title,
  Trigger: DialogPrimitive.Trigger,
  TitleText,
  DescriptionText,
});
