import { cva } from "class-variance-authority";
import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const cellStyles = cva("px-2 py-[10px] first:ps-0 last:pe-0 md:py-3")();

const Row: FC<{ children: ReactNode }> = ({ children }) => <tr className="border-b last:border-none">{children}</tr>;

const Label: FC<{ children?: ReactNode }> = ({ children }) => (
  <td className={cn("text-start font-roboto text-[14px] leading-[1.5] text-text-secondary md:text-[16px]", cellStyles)}>
    {children}
  </td>
);

const Value: FC<{ children: ReactNode; className?: string }> = ({ children, className }) => (
  <td className={cn("text-end font-roboto text-[14px] leading-[1.5] text-text md:text-[16px]", cellStyles, className)}>
    {children}
  </td>
);

const Table: FC<{ children: ReactNode }> = ({ children }) => (
  <table className="w-full">
    <tbody>{children}</tbody>
  </table>
);

const Card: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <div className={cn("rounded-[24px] bg-bg-back px-6 py-4", className)}>{children}</div>;
};

const Component = Object.assign(Table, {
  Row,
  Label,
  Value,
  Card,
});

export { Component as DetailsTable };
