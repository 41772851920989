import { FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { SelectField, TileRadioField } from "@/components/form/fields";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField as SurveyFieldType } from "@/services/openapi";
import { FieldView } from "@/services/openapi/models/field-view";

import { OnboardingFieldWrapper } from "../../styles";
import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";
import { SurveyField } from "../survey-field";
import { usePollOptions } from "../use.poll.options.hook";

type Props = {
  field: SurveyFieldType;
};

export const SurveyPollSingleField: FC<Props> = ({
  field: { isRequired, title, id, pollFields, type, view, pollValues, description },
}) => {
  const { defaultValue, options } = usePollOptions({ pollFields, pollValues });
  const { t } = useTranslation();
  const fieldId = generateSurveyFieldId(id!, type!);

  const { watch } = useFormContext();

  return (
    <>
      {view === FieldView.Select && (
        <OnboardingFieldWrapper>
          <SelectField
            name={fieldId}
            label={generateSurveyFieldTitle(title!, isRequired!)}
            rules={{
              required: isRequired,
            }}
            options={options}
            defaultValue={defaultValue}
            description={description!}
            resetFieldOnOptionsChanged={false}
          />
        </OnboardingFieldWrapper>
      )}
      {view === FieldView.Radio && (
        <TileRadioField
          name={fieldId}
          label={generateSurveyFieldTitle(title!, isRequired!)}
          rules={{
            required: isRequired ? t("form-errors.required-error")! : false,
          }}
          options={options}
          description={description!}
          defaultValue={defaultValue}
        />
      )}
      {pollFields!.map(({ id: pollId, title: pollTitle, fields }) => {
        if (watch(fieldId) === pollId) {
          return (
            <Fragment key={pollTitle}>
              {fields!.map(field => (
                <SurveyField
                  field={{ ...field, isRequired: field.isRequired ? field.isRequired : isRequired }}
                  key={field.id}
                />
              ))}
            </Fragment>
          );
        }
        return null;
      })}
    </>
  );
};
