import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Dialog } from "@/components/dialog";
import { Button, Hint } from "@/shared/ui";

import { FeatureTourButton } from "../../components/button/button";
import { HintDialogContent } from "../../components/hint/hint-dialog";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  onInterrupt: ReturnType<typeof useTerminalFeatureTourContext>["interruptTour"];
  onStart: ReturnType<typeof useTerminalFeatureTourContext>["startTour"];
};

const WelcomeHintDialog: React.FC<Props> = ({ onInterrupt, onStart }) => {
  const { t } = useTranslation();

  useEffect(() => {
    track(amplitudeEvents.featureTour.view);
  }, []);

  return (
    <Dialog open>
      <HintDialogContent>
        <Hint title={t("terminal.onboarding.welcome.title")} text={t("terminal.onboarding.welcome.description")}>
          <Hint.Footer grow>
            <FeatureTourButton size="sm" onClick={onInterrupt}>
              {t("terminal.onboarding.buttons.skip")}
            </FeatureTourButton>
            <Button size="sm" onClick={onStart}>
              {t("terminal.onboarding.buttons.start")}
            </Button>
          </Hint.Footer>
        </Hint>
      </HintDialogContent>
    </Dialog>
  );
};

export { WelcomeHintDialog };
