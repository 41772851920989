import { PaymentProcessingTime } from "@/services/openapi";

export const getPaymentMethodProcessingTime = (
  paymentProcessingTime: PaymentProcessingTime,
): { time: string; unit: string } => {
  let time: string = "";
  let unit: string = "";

  switch (paymentProcessingTime) {
    case PaymentProcessingTime.Instant:
      time = "Instant";
      break;
    case PaymentProcessingTime.FifteenMinutes:
      time = "15";
      unit = "minutes";
      break;
    case PaymentProcessingTime.OneDay:
      time = "1";
      unit = "day";
      break;
    case PaymentProcessingTime.ThreeHours:
      time = "3";
      unit = "hours";
      break;
    case PaymentProcessingTime.None:
      time = PaymentProcessingTime.None;
  }

  return { time, unit };
};
