/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientPlatform } from '../models';
// @ts-ignore
import { CountriesInfo } from '../models';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { LegalDocumentItemItemsContainer } from '../models';
// @ts-ignore
import { LocaleInfoItemsContainer } from '../models';
// @ts-ignore
import { ModelsContainer } from '../models';
// @ts-ignore
import { PlatformInfo } from '../models';
// @ts-ignore
import { PlatformUrlInfo } from '../models';
/**
 * PlatformApi - axios parameter creator
 * @export
 */
export const PlatformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDocuments: async (platform?: ClientPlatform, locale?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/legal/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (platform !== undefined) {
                localVarQueryParameter['platform'] = platform;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/platform/locales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformUrlInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/info/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (keys?: Array<string>, locale?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/platform/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keys) {
                localVarQueryParameter['keys'] = keys;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformApi - functional programming interface
 * @export
 */
export const PlatformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountriesInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalDocuments(platform?: ClientPlatform, locale?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalDocumentItemItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalDocuments(platform, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocales(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocaleInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocales(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlatformInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlatformInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlatformUrlInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformUrlInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlatformUrlInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(keys?: Array<string>, locale?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslations(keys, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlatformApi - factory interface
 * @export
 */
export const PlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlatformApiFp(configuration)
    return {
        /**
         * 
         * @summary Countries info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<CountriesInfo> {
            return localVarFp.getCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDate(options?: any): AxiosPromise<ModelsContainer> {
            return localVarFp.getDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available legal documents
         * @param {ClientPlatform} [platform] 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalDocuments(platform?: ClientPlatform, locale?: string, options?: any): AxiosPromise<LegalDocumentItemItemsContainer> {
            return localVarFp.getLegalDocuments(platform, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available localizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocales(options?: any): AxiosPromise<LocaleInfoItemsContainer> {
            return localVarFp.getLocales(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformInfo(options?: any): AxiosPromise<PlatformInfo> {
            return localVarFp.getPlatformInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Platform url info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlatformUrlInfo(options?: any): AxiosPromise<PlatformUrlInfo> {
            return localVarFp.getPlatformUrlInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available localizations
         * @param {Array<string>} [keys] Translation keys that look like \&#39;Area::Context:KeyName\&#39;
         * @param {string} [locale] One of supported languages: en, vi, etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(keys?: Array<string>, locale?: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getTranslations(keys, locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLegalDocuments operation in PlatformApi.
 * @export
 * @interface PlatformApiGetLegalDocumentsRequest
 */
export interface PlatformApiGetLegalDocumentsRequest {
    /**
     * 
     * @type {ClientPlatform}
     * @memberof PlatformApiGetLegalDocuments
     */
    readonly platform?: ClientPlatform

    /**
     * 
     * @type {string}
     * @memberof PlatformApiGetLegalDocuments
     */
    readonly locale?: string
}

/**
 * Request parameters for getTranslations operation in PlatformApi.
 * @export
 * @interface PlatformApiGetTranslationsRequest
 */
export interface PlatformApiGetTranslationsRequest {
    /**
     * Translation keys that look like \&#39;Area::Context:KeyName\&#39;
     * @type {Array<string>}
     * @memberof PlatformApiGetTranslations
     */
    readonly keys?: Array<string>

    /**
     * One of supported languages: en, vi, etc
     * @type {string}
     * @memberof PlatformApiGetTranslations
     */
    readonly locale?: string
}

/**
 * PlatformApi - object-oriented interface
 * @export
 * @class PlatformApi
 * @extends {BaseAPI}
 */
export class PlatformApi extends BaseAPI {
    /**
     * 
     * @summary Countries info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getCountries(options?: any) {
        return PlatformApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getDate(options?: any) {
        return PlatformApiFp(this.configuration).getDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available legal documents
     * @param {PlatformApiGetLegalDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getLegalDocuments(requestParameters: PlatformApiGetLegalDocumentsRequest = {}, options?: any) {
        return PlatformApiFp(this.configuration).getLegalDocuments(requestParameters.platform, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available localizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getLocales(options?: any) {
        return PlatformApiFp(this.configuration).getLocales(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getPlatformInfo(options?: any) {
        return PlatformApiFp(this.configuration).getPlatformInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Platform url info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getPlatformUrlInfo(options?: any) {
        return PlatformApiFp(this.configuration).getPlatformUrlInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available localizations
     * @param {PlatformApiGetTranslationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformApi
     */
    public getTranslations(requestParameters: PlatformApiGetTranslationsRequest = {}, options?: any) {
        return PlatformApiFp(this.configuration).getTranslations(requestParameters.keys, requestParameters.locale, options).then((request) => request(this.axios, this.basePath));
    }
}
