import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
} from "public/charting_library/charting_library";
import { FC, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

import { logError } from "@/app/libs/sentry";
import { useSignal } from "@/hooks/signal.hook";
import i18n from "@/i18n";
import { TerminalEvent } from "@/services/openapi";

import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../feature-tour/context";
import { ChartHintPopover } from "../feature-tour/steps/chart/hint-popover";
import { MergedTerminalSymbol } from "../helpers/symbols";
import { getChartColors } from "./config/colors";
import { getDrawingAccess } from "./config/drawings";
import { getTradingViewFeatures } from "./config/features";
import { getLoadAdapter } from "./config/load-adapter";
import { getTradingViewLocale } from "./config/localization";
import { getChartOverrides } from "./config/overrides";
import { useChartContext } from "./context";
import { getDatafeed } from "./datafeed";
import { ChartWidget } from "./widget";

declare global {
  interface Window {
    tvWidget: IChartingLibraryWidget;
  }
}

type Props = {
  symbolInfo: MergedTerminalSymbol;
  isMobile: boolean;
  symbols: MergedTerminalSymbol[];
  accountId: string;
  accountCurrency: string;
  tradingServerId: string;
  tickMessage: TerminalEvent;
  isDarkTheme: boolean;
  isChartExpanded: boolean;
  currencyDecimalScale: number;
  chartInfo: ReturnType<typeof useChartContext>["chartInfo"];
  lastInterval: string | undefined;
  chartStyles: ReturnType<typeof useTerminalFeatureTourContext>["chartStyles"];
  setIsChartExpanded: ReturnType<typeof useTerminalLayout>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
};

const Chart: FC<Props> = ({
  symbolInfo,
  isMobile,
  symbols,
  accountId,
  accountCurrency,
  tradingServerId,
  chartInfo,
  isChartExpanded,
  tickMessage,
  isDarkTheme,
  lastInterval,
  chartStyles,
  setChartInfo,
  setIsChartExpanded,
  currencyDecimalScale,
}) => {
  const queryClient = useQueryClient();

  const [widgetReady, setWidgetReady] = useState<boolean>();

  const chartColors = useMemo(() => getChartColors(isDarkTheme), [isDarkTheme]);

  const $tick = useSignal(tickMessage);
  const $symbol = useSignal(symbolInfo.symbol!);

  useEffect(() => {
    import("public/charting_library/charting_library.esm").then(TradingView => {
      try {
        // https://www.tradingview.com/charting-library-docs/latest/core_concepts/Widget-Constructor/
        const widgetOptions: ChartingLibraryWidgetOptions = {
          symbol: symbolInfo.symbol!,
          interval: (lastInterval || "60") as ResolutionString,
          container: "tv_chart_container",
          autosize: true,
          datafeed: getDatafeed({ symbols, tradingServerId, queryClient, $tick }),
          custom_css_url: "/chart-styles.css",
          library_path: "/charting_library/",
          timezone: "exchange",
          locale: getTradingViewLocale(i18n.resolvedLanguage),
          theme: isDarkTheme ? "dark" : "light",
          disabled_features: getTradingViewFeatures({ isMobile, isChartExpanded }).disabledFeatures,
          enabled_features: getTradingViewFeatures({ isMobile, isChartExpanded }).enabledFeatures,
          loading_screen: {
            backgroundColor: chartColors.loadingBackground,
            foregroundColor: chartColors.loadingForeground,
          },
          settings_overrides: getChartOverrides({ chartColors, isChartExpanded }),
          save_load_adapter: getLoadAdapter({ accountId, $symbol }),
          drawings_access: getDrawingAccess(),
          saved_data: chartInfo.data ? chartInfo.data : undefined,
          timeframe: chartInfo.range ? chartInfo.range : undefined,
          auto_save_delay: 5,
        };

        window.tvWidget = new TradingView.widget(widgetOptions);

        window.tvWidget.onChartReady(() => {
          setWidgetReady(true);
          const pane = window.tvWidget.activeChart().getPanes()[0];
          if (pane) {
            const priceScale = pane.getMainSourcePriceScale();

            priceScale?.setAutoScale(true);
          }
        });
      } catch (error) {
        logError(error);
      }
    });

    return () => window.tvWidget && window.tvWidget.remove();
  }, []);

  return (
    <>
      {widgetReady && (
        <ChartWidget
          tickMessage={tickMessage}
          accountId={accountId}
          accountCurrency={accountCurrency}
          symbolInfo={symbolInfo}
          chartColors={chartColors}
          isMobile={isMobile}
          isDarkTheme={isDarkTheme}
          isChartExpanded={isChartExpanded}
          setChartInfo={setChartInfo}
          setIsChartExpanded={setIsChartExpanded}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
      <div className="relative h-full">
        <div className="h-full" style={chartStyles} id="tv_chart_container" />
        <ChartHintPopover>
          <div className="absolute start-1/2 top-1/2" />
        </ChartHintPopover>
      </div>
    </>
  );
};

Chart.displayName = "Chart";

export { Chart };
