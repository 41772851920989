import { ComponentProps, FC, useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { TerminalDealType } from "@/services/openapi";
import { useModifyTerminalOrderMutation } from "@/state/server/terminal";

import { OpeningPriceForm } from "./opening-price.form";

type Props = {
  accountId: string;
  openPrice: number;
  orderId: number;
  priceDecimalScale: number;
  takeProfit: number | undefined;
  stopLoss: number | undefined;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  currentPrice: number;
  onClose: () => void;
  symbol: string;
  volumeDecimalScale: number;
  volume: number;
  isMobile?: boolean;
};

const OpeningPriceContainer: FC<Props> = ({
  accountId,
  orderId,
  onClose,
  priceDecimalScale,
  stopLoss,
  takeProfit,
  ask,
  bid,
  orderType,
  openPrice,
  currentPrice,
  symbol,
  isMobile,
  volume,
  volumeDecimalScale,
}) => {
  const { mutateAsync: modifyOrder } = useModifyTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof OpeningPriceForm>["onSubmit"] = useCallback(
    ({ openingPrice }) => {
      return modifyOrder(
        {
          tradingAccountId: accountId,
          id: orderId,
          terminalModifyOrderRequest: { stopLoss, takeProfit, price: getInputNumberValue(openingPrice) },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [modifyOrder, accountId, orderId, onClose, takeProfit, stopLoss],
  );

  return (
    <OpeningPriceForm
      onSubmit={handleSubmit}
      priceDecimalScale={priceDecimalScale}
      ask={ask}
      bid={bid}
      orderType={orderType}
      openPrice={openPrice}
      currentPrice={currentPrice}
      symbol={symbol}
      isMobile={isMobile}
      volume={volume}
      volumeDecimalScale={volumeDecimalScale}
    />
  );
};

export { OpeningPriceContainer };
