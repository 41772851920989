import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext } from "../../../context";

type Props = {
  changeOpenPrice: ReturnType<typeof usePlaceOrderContext>["changeOpenPrice"];
  resetOpenPrice: ReturnType<typeof usePlaceOrderContext>["resetOpenPrice"];
  priceDecimalScale: number;
  openPriceFormValue: ReturnType<typeof usePlaceOrderContext>["openPriceFormValue"];
  isSubmitting: boolean;
  ask: number;
};

const MobileOpenPriceField: FC<Props> = ({
  changeOpenPrice,
  isSubmitting,
  openPriceFormValue,
  priceDecimalScale,
  resetOpenPrice,
  ask,
}) => {
  const { t } = useTranslation();

  return (
    <NumberInput
      size="sm"
      pending={isSubmitting}
      value={openPriceFormValue}
      onChange={value => changeOpenPrice(value, { format: false })}
      onBlur={resetOpenPrice}
      decimalScale={priceDecimalScale}
      placeholder={t("terminal.place-order.open-price.input-placeholder")!}
      descriptor={
        <Trans
          i18nKey="terminal.place-order.open-price.input-descriptor"
          components={{
            value: <NumberFormat value={ask} decimalScale={priceDecimalScale} />,
            valueButton: <NumberInput.DescriptorButton onClick={() => changeOpenPrice(ask)} />,
          }}
        />
      }
    />
  );
};

export { MobileOpenPriceField };
