import { FC, memo } from "react";

import { ClosedPositionsTable } from "./closed-positions-table";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

const _ClosedPositionsContainer: FC<Props> = ({
  accountId,
  setSymbol,
  onClose,
  accountCurrency,
  currencyDecimalScale,
}) => {
  return (
    <ClosedPositionsTable
      accountId={accountId}
      onClose={onClose}
      setSymbol={setSymbol}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
    />
  );
};

export const ClosedPositionsContainer = memo(_ClosedPositionsContainer);
