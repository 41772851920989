import * as SelectPrimitive from "@radix-ui/react-select";
import { Slot } from "@radix-ui/react-slot";
import { type FC, type ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { IconTick } from "@/domains/icons";
import { useTerminalFeatureTourContext } from "@/features/terminal/feature-tour/context";
import { ChangeVolumeModeHintPopover } from "@/features/terminal/feature-tour/steps/change-volume-mode/hint-popover";
import { TradingAccountTradeMode } from "@/services/openapi";
import { Select } from "@/shared/ui";

import { usePlaceOrderContext } from "../../context";

type Props = {
  volumeMode: ReturnType<typeof usePlaceOrderContext>["volumeMode"];
  changeVolumeMode: ReturnType<typeof usePlaceOrderContext>["changeVolumeMode"];
  currency: string;
  changeVolumeModeStyles?: ReturnType<typeof useTerminalFeatureTourContext>["changeVolumeModeStyles"];
};

const Item: FC<{ children: ReactNode; description: string; value: string }> = ({ children, description, value }) => (
  <SelectPrimitive.Item
    value={value}
    className="flex w-full cursor-pointer select-none flex-col gap-1 px-6 py-4 outline-none transition-colors data-[highlighted]:bg-bg-back data-[state=checked]:bg-bg-selected"
  >
    <div className="flex items-center justify-between gap-3 font-roboto text-[16px] leading-[1.5] text-text">
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <SelectPrimitive.ItemIndicator>
        <IconTick />
      </SelectPrimitive.ItemIndicator>
    </div>
    <div className="font-roboto text-[12px] leading-[1.3] text-text-secondary">{description}</div>
  </SelectPrimitive.Item>
);

const DesktopVolumeModeSelect: FC<Props> = ({ volumeMode, changeVolumeMode, currency, changeVolumeModeStyles }) => {
  const { t } = useTranslation();

  return (
    <Select
      onValueChange={changeVolumeMode}
      value={volumeMode}
      label={t("terminal.place-order.value-type")!}
      renderWrapper={element => (
        <ChangeVolumeModeHintPopover>
          <Slot style={changeVolumeModeStyles}>{element}</Slot>
        </ChangeVolumeModeHintPopover>
      )}
    >
      <Item value={TradingAccountTradeMode.Margin} description={t("terminal.place-order.margin.description")}>
        {t("terminal.place-order.margin.title", { currency })}
      </Item>
      <Item value={TradingAccountTradeMode.Lots} description={t("terminal.place-order.lots.description")}>
        {t("terminal.place-order.lots.title")}
      </Item>
    </Select>
  );
};

export { DesktopVolumeModeSelect };
