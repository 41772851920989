import { FC, useMemo } from "react";

import { Box } from "@/components/box";
import { useTournamentQuery } from "@/state/server/tournaments";

import { TournamentParticipantCard } from "./tournament-participant-card";
import { TournamentParticipantsTable } from "./tournament-participants-table";

type Props = {
  tournamentId: string;
};

export const TournamentContainer: FC<Props> = ({ tournamentId }) => {
  const { data } = useTournamentQuery(tournamentId, { refetchOnWindowFocus: true });

  const firstColumnItems = useMemo(() => {
    return (data?.items || []).slice(0, 10);
  }, [data]);

  const secondColumnItems = useMemo(() => {
    return (data?.items || []).slice(10, 20);
  }, [data]);

  const thirdColumnItems = useMemo(() => {
    return (data?.items || []).slice(20, 30);
  }, [data]);

  if (!data) return null;

  return (
    <>
      <div className="hidden md:block">
        <Box
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
            alignItems: "start",
            gap: thirdColumnItems.length > 0 ? 53 : 152,
          }}
        >
          {firstColumnItems.length > 0 && <TournamentParticipantsTable participants={firstColumnItems} />}
          {secondColumnItems.length > 0 && <TournamentParticipantsTable participants={secondColumnItems} />}
          {thirdColumnItems.length > 0 && <TournamentParticipantsTable participants={thirdColumnItems} />}
        </Box>
      </div>
      <div className="flex flex-col gap-[24px] md:hidden">
        {data.items!.map(participant => (
          <TournamentParticipantCard key={participant.id} participant={participant} />
        ))}
      </div>
    </>
  );
};
