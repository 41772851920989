import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { cva } from "class-variance-authority";
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef, memo, useEffect, useRef } from "react";
import { useQueryClient } from "react-query";

import { IconCross } from "@/domains/icons";
import { TerminalAccountSymbols } from "@/services/openapi";
import { cn } from "@/shared/styles";
import {
  terminalQueryKeys,
  useAddSymbolToChartsMutation,
  useRemoveSymbolFromChartsMutation,
} from "@/state/server/terminal";

import { SymbolIcon } from "../components/symbol/symbol-icon";
import { useTerminalLayout } from "../contexts/terminal-layout.context";

const buttonStyles = cva(
  "group relative flex h-12 shrink-0 items-center justify-center gap-2 whitespace-nowrap rounded-[16px] border px-3 transition-colors first:border-e-[1px] hover:bg-bg-back lg:h-14 lg:min-w-24 lg:flex-col lg:gap-1 lg:rounded-none lg:border-e-0 lg:bg-bg lg:px-4 lg:first:rounded-e-[8px] lg:last:rounded-s-[8px]",
  {
    variants: {
      active: {
        true: "!bg-bg-border",
      },
    },
  },
);

type Props = ComponentPropsWithoutRef<"div"> & {
  symbol: string;
  accountId: string;
  chartFavoritesCount: number;
  setSymbol: (symbol: string) => void;
  currentSymbol: string | undefined;
  showRemoveButton?: boolean;
};

const _ChartSymbolButton = forwardRef<ElementRef<"div">, Props>(
  (
    { symbol, chartFavoritesCount, accountId, currentSymbol, setSymbol, showRemoveButton = true, className, ...props },
    forwardedRef,
  ) => {
    const queryClient = useQueryClient();
    const { isMobile } = useTerminalLayout();
    const ref = useRef<HTMLDivElement>(null);

    const { mutate: addFavorite, isLoading } = useAddSymbolToChartsMutation();
    const { mutate: removeFavorite } = useRemoveSymbolFromChartsMutation();

    const composedRefs = useComposedRefs(ref, forwardedRef);

    useEffect(() => {
      if (currentSymbol === symbol) {
        ref.current?.scrollIntoView(true);
      }
    }, [currentSymbol]);

    const removeFavoriteFn = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      let oldCharts: string[] = [];
      let currentSymbolIndex = 0;
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        currentSymbolIndex = oldData!.charts!.findIndex(item => item === symbol);
        oldCharts = oldData!.charts!;
        const newChartFavorites: string[] = oldData!.charts!.filter(item => item !== symbol);
        const newData: TerminalAccountSymbols = {
          ...oldData,
          charts: newChartFavorites,
        };
        return newData;
      });
      removeFavorite({ symbol, tradingAccountId: accountId });
      if (currentSymbol === symbol) {
        const newSymbol = oldCharts[currentSymbolIndex === 0 ? 1 : currentSymbolIndex - 1];

        queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
          return {
            ...oldData,
            chartsSelected: newSymbol,
            recent: [newSymbol, ...oldData!.recent!.filter(s => s !== newSymbol)].slice(0, 10),
          };
        });
        addFavorite({ symbol: newSymbol, tradingAccountId: accountId });
        setSymbol(newSymbol);
      }
    };

    const selectSymbol = () => {
      if (isLoading) {
        return;
      }
      queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
        const newRecent = [symbol, ...oldData!.recent!.filter(s => s !== symbol)];

        const newData: TerminalAccountSymbols = {
          ...oldData,
          chartsSelected: symbol,
          recent: newRecent,
        };
        return newData;
      });
      addFavorite({ symbol, tradingAccountId: accountId });
      setSymbol(symbol);
    };

    return (
      <div
        ref={composedRefs}
        onClick={selectSymbol}
        className={cn(buttonStyles({ active: currentSymbol === symbol }), className)}
        role="button"
        {...props}
      >
        <SymbolIcon symbol={symbol} />
        <div className="text-[16px] font-semibold leading-[1.2] text-text lg:font-roboto lg:text-[14px] lg:font-normal lg:leading-[1.5]">
          {symbol}
        </div>
        {showRemoveButton && chartFavoritesCount > 1 && (
          <>
            {isMobile ? (
              <button className="text-text-secondary" onClick={removeFavoriteFn}>
                <IconCross />
              </button>
            ) : (
              <button
                className="absolute end-2 top-2 hidden size-3 text-text-secondary group-hover:flex"
                onClick={removeFavoriteFn}
              >
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.49643 1.51072C9.77454 1.2326 9.77454 0.781688 9.49643 0.503573C9.21832 0.225458 8.7674 0.225458 8.48929 0.503573L5 3.99287L1.51072 0.503573C1.2326 0.225458 0.781686 0.225458 0.50357 0.503573C0.225456 0.781688 0.225456 1.2326 0.50357 1.51072L3.99286 5.00001L0.50357 8.4893C0.225456 8.76742 0.225456 9.21833 0.50357 9.49645C0.781686 9.77456 1.2326 9.77456 1.51072 9.49645L5 6.00715L8.48929 9.49645C8.7674 9.77456 9.21832 9.77456 9.49643 9.49645C9.77454 9.21833 9.77454 8.76742 9.49643 8.4893L6.00714 5.00001L9.49643 1.51072Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            )}
          </>
        )}
      </div>
    );
  },
);

export const ChartSymbolButton = memo(_ChartSymbolButton);
