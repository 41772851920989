import { useTranslation } from "react-i18next";

import { useOnline } from "@/hooks/online.hook";
import { Tooltip } from "@/shared/ui";

export const InternetConnectionInfo = () => {
  const { t } = useTranslation();

  const isOnline = useOnline();

  return (
    <div className={isOnline ? "text-action" : "text-warning"}>
      <Tooltip content={isOnline ? t("common.stable-internet") : t("common.no-internet")}>
        {/* FIXME: */}
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.631 7.51306L15.0697 6.12374C11.2332 2.15093 4.90249 2.04043 0.929688 5.87692L2.319 7.3156C5.49725 4.24641 10.5618 4.33481 13.631 7.51306ZM12.1923 8.90237L10.7536 10.2917C9.21903 8.70257 6.68676 8.65837 5.09764 10.193L3.70832 8.75428C6.092 6.45239 9.89041 6.51869 12.1923 8.90237ZM7.87627 13.0703L6.48695 11.6316C7.28152 10.8643 8.54765 10.8864 9.31495 11.681L7.87627 13.0703Z"
            fill="currentColor"
          />
        </svg>
      </Tooltip>
    </div>
  );
};
