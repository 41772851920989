import { type FC, type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { TooltipQuestionIcon } from "@/components/icons";
import { Switch, Tooltip } from "@/shared/ui";

type Props = {
  children: ReactNode;
};

const DesktopAutocloseBlock: FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center justify-between rounded-[16px] border px-4 py-3">
        <label className="flex items-center gap-3 font-roboto text-[16px] leading-[1.5] text-text">
          <Switch size="sm" checked={checked} onCheckedChange={setChecked} />
          {t("terminal.place-order.autoclose.title")}
        </label>
        <Tooltip content={t("terminal.place-order.autoclose.description")}>
          <TooltipQuestionIcon />
        </Tooltip>
      </div>
      {checked && children}
    </div>
  );
};

export { DesktopAutocloseBlock };
