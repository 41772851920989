import { FC, memo } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType, TerminalDealType } from "@/services/openapi";
import { Button, toast, ToastIcons } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { getOrderTypeText } from "../components/trading-table/order-type";

type Props = {
  accountId: string;
  orderId: number;
  orderType: TerminalDealType;
  volume: number;
  symbol: string;
  volumeDecimalScale: number;
};

const _ClosePendingOrderButton: FC<Props> = ({ accountId, orderId, symbol, orderType, volume, volumeDecimalScale }) => {
  const { t } = useTranslation();
  const { isMobile } = useTerminalLayout();

  const { mutate: closeOrder, isLoading } = useCloseTerminalOrdersMutation({
    onSuccess: () => {
      toast({
        icon: ToastIcons.SUCCESS,
        text: t("terminal.messages.pending-order-cancelled", {
          id: orderId,
          type: getOrderTypeText(t, orderType),
          volume: volume.toFixed(volumeDecimalScale),
          symbol,
        }),
      });
    },
  });

  const handleClick = () => {
    closeOrder({
      tradingAccountId: accountId,
      terminalCloseOrderRequest: { id: orderId, type: TerminalCloseOrderType.Limit },
    });
  };

  return isMobile ? (
    <Button className="w-full" variant="tertiary" size="sm" onClick={handleClick} pending={isLoading}>
      {t("terminal.orders.delete-order")}
    </Button>
  ) : (
    <Button onClick={handleClick} pending={isLoading} variant="flat" size="sm">
      {t("terminal.orders.delete-order")}
    </Button>
  );
};

export const ClosePendingOrderButton = memo(_ClosePendingOrderButton);
