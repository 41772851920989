import * as Popover from "@radix-ui/react-popover";
import { type ComponentProps, type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/shared/styles";
import { popoverContentStyles } from "@/shared/ui";

import { usePlaceOrderContext } from "../context";
import { VolumeSuggesterValuesButtons } from "./values-buttons";

type Props = ComponentProps<typeof VolumeSuggesterValuesButtons> & {
  children: ReactElement;
  open: boolean;
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
};

const VolumeSuggester: FC<Props> = ({
  children,
  open,
  volumeMargin,
  volumeLots,
  changeLots,
  currency,
  volumeMarginDecimalScale,
  marginMultiplier,
  maxAvailableVolumeLots,
  minSystemVolumeLots,
  volumeLotsStep,
}) => {
  const { t } = useTranslation();
  return (
    <Popover.Root open={open}>
      <Popover.Anchor asChild>{children}</Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          onOpenAutoFocus={e => e.preventDefault()}
          side="bottom"
          sideOffset={8}
          align="center"
          hideWhenDetached
          className={cn(popoverContentStyles(), "w-[--radix-popover-trigger-width] rounded-[16px] bg-bg py-2 shadow")}
        >
          <div className="px-4 py-2 font-roboto text-[14px] leading-[1.5] text-text-secondary lg:px-6 lg:py-3 lg:text-[16px]">
            {t("terminal.place-order.margin.suggester.title")}
          </div>
          <VolumeSuggesterValuesButtons
            key={volumeMargin} // needed to reset values
            volumeLots={volumeLots}
            changeLots={changeLots}
            currency={currency}
            volumeMarginDecimalScale={volumeMarginDecimalScale}
            marginMultiplier={marginMultiplier}
            maxAvailableVolumeLots={maxAvailableVolumeLots}
            minSystemVolumeLots={minSystemVolumeLots}
            volumeLotsStep={volumeLotsStep}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export { VolumeSuggester };
