import parse from "html-react-parser";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExternalLink } from "@/components/link";
import { Text } from "@/components/text";
import { useAuth } from "@/contexts/auth.context";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { usePlatformTranslationsQuery } from "@/state/server/platform";
import { styled } from "@/styles/stitches.config";

const Wrapper = styled("div", {
  background: "$bgPrimary",
});

const StyledFooter = styled("footer", {
  py: "44px",
  px: "$containerXPadding",
  maxWidth: "$appContainer",
  margin: "0 auto",

  "@bp3": {
    px: "50px",
  },
});

const FooterBlock = styled("div", {
  mb: "30px",

  "@bp3": {
    mb: "50px",
  },

  "& span a": {
    color: "$primary",
  },
});

const Separator = styled("div", {
  backgroundColor: "$bgBorder",
  height: "1px",
});

const BottomContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "25px",

  mt: "32px",

  "@bp3": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const FooterLinks = styled("ul", {
  "@bp2": {
    display: "flex",
    gap: "80px",
  },

  "@bp3": {
    gap: "125px",
  },
});

const FooterLinkWrapper = styled("li", {
  mt: "25px",
  "@bp2": {
    mt: "0",
  },
});

const FooterLink = styled("a", {
  fontWeight: "bold",
  color: "$textSecondary",
  transition: "color 0.1s",

  "&:hover": {
    color: "$primary",
  },
});

export const Footer: FC = () => {
  const { i18n } = useTranslation();
  const { isMobile } = useScreenSize();
  const { isAuthenticated } = useAuth();
  const { data } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });
  const [open, setOpen] = useState(false);

  if (!data) return null;

  return (
    <Wrapper>
      <StyledFooter>
        {!isAuthenticated && isMobile && (
          <>
            <LanguageSwitcherButton light onOpen={() => setOpen(true)} />
            <LanguageSwitcherContainer open={open} onOpenChange={setOpen} />
          </>
        )}

        <FooterBlock css={{ mt: "24px" }}>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::Text-1"]}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::Text-2"]}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::Text-3"]}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::Text-4"]}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::Text-5"]}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {parse(data["Footer::Text-6"])}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {parse(data["Footer::Text-7"])}
          </Text>
          <Text color="dimmed" family="roboto" css={{ textAlign: "justify", fontSize: "12px", marginBottom: "10px" }}>
            {data["Footer::ContactUs"]}{" "}
            <ExternalLink href={`mailto:${data["Footer::Email"]}`} target="_blank">
              {data["Footer::Email"]}
            </ExternalLink>{" "}
            tel:
            <a href="tel:+2304687113"> +2304687113</a>
          </Text>
        </FooterBlock>
        <FooterBlock>
          <FooterLinks>
            <FooterLinkWrapper>
              <FooterLink target="_blank" rel="noreferrer" href={data["Footer::about-us::doc"]}>
                {data["Footer::about-us::word"]}
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLink target="_blank" rel="noreferrer" href={data["Footer::legal-documents::doc"]}>
                {data["Footer::legal-documents::word"]}
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLink target="_blank" rel="noreferrer" href={data["Footer::affiliate-program::doc"]}>
                {data["Footer::affiliate-program::word"]}
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper>
              <FooterLink target="_blank" rel="noreferrer" href={data["Footer::help-center::doc"]}>
                {data["Footer::help-center::word"]}
              </FooterLink>
            </FooterLinkWrapper>
          </FooterLinks>
        </FooterBlock>
        <Separator />

        <BottomContainer>
          <Text family="roboto" color={"dimmed"}>
            © {new Date().getFullYear()}, {data["Footer::Copyright"]}
          </Text>
        </BottomContainer>
      </StyledFooter>
    </Wrapper>
  );
};
