import React, { FC } from "react";

import * as Styled from "./ErrorMessage.styled";

type Props = {
  hasError: boolean;
  className?: string;
  children: React.ReactNode;
};

export const ErrorMessage: FC<Props> = ({ className, hasError, children }) => {
  return (
    <Styled.ErrorMessage className={className} hasError={hasError}>
      {children}
    </Styled.ErrorMessage>
  );
};
