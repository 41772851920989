import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const TooltipQuestionIcon = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>(
  ({ className, ...props }, ref) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      className={cn(
        "text-text-secondary transition-colors data-[state=delayed-open]:text-bg-additional data-[state=instant-open]:text-bg-additional",
        className,
      )}
      {...props}
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        className="fill-bg-deep"
      />
      <path
        d="M6.76953 10.0293C6.76953 9.48242 6.83594 9.04688 6.96875 8.72266C7.10156 8.39844 7.34375 8.08008 7.69531 7.76758C8.05078 7.45117 8.28711 7.19531 8.4043 7C8.52148 6.80078 8.58008 6.5918 8.58008 6.37305C8.58008 5.71289 8.27539 5.38281 7.66602 5.38281C7.37695 5.38281 7.14453 5.47266 6.96875 5.65234C6.79688 5.82812 6.70703 6.07227 6.69922 6.38477H5C5.00781 5.63867 5.24805 5.05469 5.7207 4.63281C6.19727 4.21094 6.8457 4 7.66602 4C8.49414 4 9.13672 4.20117 9.59375 4.60352C10.0508 5.00195 10.2793 5.56641 10.2793 6.29688C10.2793 6.62891 10.2051 6.94336 10.0566 7.24023C9.9082 7.5332 9.64844 7.85938 9.27734 8.21875L8.80273 8.66992C8.50586 8.95508 8.33594 9.28906 8.29297 9.67188L8.26953 10.0293H6.76953ZM6.59961 11.8281C6.59961 11.5664 6.6875 11.3516 6.86328 11.1836C7.04297 11.0117 7.27148 10.9258 7.54883 10.9258C7.82617 10.9258 8.05273 11.0117 8.22852 11.1836C8.4082 11.3516 8.49805 11.5664 8.49805 11.8281C8.49805 12.0859 8.41016 12.2988 8.23438 12.4668C8.0625 12.6348 7.83398 12.7188 7.54883 12.7188C7.26367 12.7188 7.0332 12.6348 6.85742 12.4668C6.68555 12.2988 6.59961 12.0859 6.59961 11.8281Z"
        fill="currentColor"
      />
    </svg>
  ),
);

TooltipQuestionIcon.displayName = "TooltipQuestionIcon";

export { TooltipQuestionIcon };
