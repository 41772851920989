import React from "react";

export const LiveTradingIcon = () => (
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.334 0.5C17.4091 0.5 22.334 5.42487 22.334 11.5C22.334 17.5751 17.4091 22.5 11.334 22.5C5.25885 22.5 0.333984 17.5751 0.333984 11.5C0.333984 5.42487 5.25885 0.5 11.334 0.5ZM2.39963 10.4074C2.69117 10.7805 3.5018 11.258 4.74224 11.6475C5.23066 11.8009 5.76558 11.9357 6.33954 12.0502C6.33584 11.8679 6.33398 11.6844 6.33398 11.5C6.33398 8.17236 6.93955 5.1673 7.98853 3.14231C4.9981 4.34042 2.80026 7.09699 2.39963 10.4074ZM6.46312 14.1108C4.85732 13.8317 3.48043 13.4093 2.43695 12.8658C2.92235 16.0542 5.08075 18.6927 7.98854 19.8577C7.22266 18.3792 6.69315 16.3783 6.46312 14.1108ZM8.51289 14.3773C9.40999 14.4581 10.3549 14.5 11.334 14.5C12.2973 14.5 13.2464 14.4454 14.1593 14.3441C13.7082 17.9417 12.461 20.5 11.334 20.5C10.2105 20.5 8.96745 17.9574 8.51289 14.3773ZM14.3203 12.3045C13.3707 12.4275 12.362 12.5 11.334 12.5C10.2898 12.5 9.28739 12.4483 8.3493 12.3502C8.3392 12.0706 8.33398 11.787 8.33398 11.5C8.33398 6.48399 9.92759 2.5 11.334 2.5C12.7404 2.5 14.334 6.48399 14.334 11.5C14.334 11.7713 14.3293 12.0397 14.3203 12.3045ZM16.2132 14.0269C15.9878 16.3289 15.4548 18.361 14.6794 19.8577C17.6385 18.6721 19.8216 15.9606 20.2552 12.6964C19.195 13.2479 17.7987 13.709 16.2132 14.0269ZM20.2625 10.3601C19.7919 10.8883 18.3077 11.5157 16.3302 11.9541C16.3327 11.8035 16.334 11.6521 16.334 11.5C16.334 8.17236 15.7284 5.1673 14.6794 3.14231C17.6556 4.33471 19.8468 7.07077 20.2625 10.3601Z"
      fill="#212121"
    />
  </svg>
);
