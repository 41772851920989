import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Slot } from "@radix-ui/react-slot";
import { type ComponentProps, ElementRef, type FC, useRef } from "react";
import { flushSync } from "react-dom";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { overlayStyles, StepperInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../../context";
import { VolumeMarginFieldDescriptor } from "../../../volume-margin/descriptor";
import { VolumeSuggester } from "../../../volume-suggester/volume-suggester";

type Props = Omit<ComponentProps<typeof VolumeMarginFieldDescriptor>, "isMobile"> & {
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  volumeMarginFormValue: ReturnType<typeof usePlaceOrderContext>["volumeMarginFormValue"];
  changeMargin: ReturnType<typeof usePlaceOrderContext>["changeMargin"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  isSubmitting: boolean;
};

const MobileVolumeMarginField: FC<Props> = ({
  volumeMarginDecimalScale,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  changeMargin,
  volumeLots,
  changeLots,
  currency,
  volumeMargin,
  volumeMarginFormValue,
  marginMultiplier,
  maxAvailableVolumeLots,
  minSystemVolumeLots,
  volumeLotsStep,
  decrementVolumeDisabled,
  incrementVolumeDisabled,
  decrementOrder,
  incrementOrder,
  hasNoFreeMargin,
  volumeLotsError,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  maxAvailableVolumeMargin,
  isSubmitting,
  accountId,
  isDemoAccount,
}) => {
  const [suggesterOpen, { open: openSuggester, close: closeSuggester }] = useDisclosure();
  const [dialogOpen, { open: openDialog, close: closeDialog }] = useDisclosure();

  const ref = useRef<HTMLElement>(null);
  const inputRef = useRef<ElementRef<typeof StepperInput>>(null);

  const handleFocus = () => {
    flushSync(() => {
      openDialog();
    });
    openSuggester();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const content = (
    <StepperInput
      ref={inputRef}
      size="sm"
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeMarginFormValue}
      suffix={` ${currency}`}
      renderWrapper={element => (
        <VolumeSuggester
          open={suggesterOpen}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={currency}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          volumeMargin={volumeMargin}
          marginMultiplier={marginMultiplier}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          volumeLotsStep={volumeLotsStep}
        >
          <Slot ref={ref}>{element}</Slot>
        </VolumeSuggester>
      )}
      onIncrement={e => {
        e.preventDefault();
        incrementOrder();
      }}
      onDecrement={e => {
        e.preventDefault();
        decrementOrder();
      }}
      onFocus={handleFocus}
      onBlur={() => {
        closeSuggester();
        closeDialog();
        if (volumeLotsError === VolumeLotsError.NONE) {
          changeLots(volumeLots!);
        }
      }}
      onChange={value => {
        changeMargin(value, { format: false });
      }}
      invalid={volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin}
      decimalScale={volumeMarginDecimalScale}
      plusDisabled={incrementVolumeDisabled}
      minusDisabled={decrementVolumeDisabled}
      descriptor={
        <VolumeMarginFieldDescriptor
          isMobile
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          changeLots={changeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxSystemVolumeLots={maxSystemVolumeLots}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeLots={minSystemVolumeLots}
          minSystemVolumeMargin={minSystemVolumeMargin}
          currency={currency}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
        />
      }
    />
  );

  return (
    <DialogPrimitive.Root open={dialogOpen}>
      {!dialogOpen && content}
      <DialogPrimitive.Portal>
        <DialogPrimitive.DialogOverlay className={overlayStyles({ hasAnimation: false })}>
          <DialogPrimitive.Content className="relative z-50 h-full w-full bg-bg p-4">{content}</DialogPrimitive.Content>
        </DialogPrimitive.DialogOverlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export { MobileVolumeMarginField };
