import axios, { AxiosRequestConfig } from "axios";
import { v4 as uuidv4 } from "uuid";

import { amplitudeParameterName } from "@/app/libs/amplitude";
import { $serverConnectionStatus } from "@/components/server-connection/store";
import { getCookie, getUserPlatformAndOS } from "@/utils/helpers";
import { token } from "@/utils/token";

import { ErrorResult } from "./openapi";
import { ResponseError } from "./types";

const handleErrorResponse = (error: any): ResponseError => {
  const responseData: ErrorResult | undefined = error.response?.data;

  if (!responseData) {
    if (error.code === "ERR_NETWORK") {
      $serverConnectionStatus.set("failed");
    }
    return {
      errorMessage: "",
    };
  }

  $serverConnectionStatus.set("restored");

  return {
    errorMessage: responseData.errors
      ? responseData.errors
          .filter((x: any) => !x.property)
          .map((x: any) => x.message)
          .join(", ")
      : "",
    code: responseData.code,
    status: error.response.status,
    payload: responseData.data,
  };
};

const handleMarketingData = () => {
  return {
    analyticsUtm: getCookie("Analytics-Utm"),
    analyticsGoogle: getCookie("_ga"),
    analyticsClick: getCookie("Analytics-Click"),
  };
};
const appVersion = window.sessionStorage.getItem("app_version");

export const gaSessionId = uuidv4();

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = config.headers || {};
  config.headers.authorization = token.getHeaderString();
  const deviceIdAmplitude = getCookie(amplitudeParameterName);

  const { platform, os } = getUserPlatformAndOS();

  const { analyticsUtm, analyticsGoogle, analyticsClick } = handleMarketingData();

  if (analyticsUtm) config.headers["Analytics-Utm"] = decodeURIComponent(analyticsUtm);
  if (analyticsGoogle) config.headers["Analytics-Google"] = decodeURIComponent(analyticsGoogle);
  if (analyticsClick) config.headers["Analytics-Click"] = decodeURIComponent(analyticsClick);
  if (gaSessionId) config.headers["Analytics-Ga-Session-Id"] = decodeURIComponent(gaSessionId);
  if (appVersion) config.headers["Analytics-AppVersion"] = decodeURIComponent(appVersion);

  config.headers["Analytics-Platform"] = platform;
  config.headers["Analytics-Os"] = os;
  config.headers[amplitudeParameterName] = deviceIdAmplitude!;

  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    $serverConnectionStatus.set("restored");
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      token.clearToken();
      window.location.reload();
    }
    return Promise.reject(handleErrorResponse(error));
  },
);
