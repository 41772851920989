export const UploadDocumentsIcon = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4142 0H2C0.89543 0 0 0.89543 0 2V20C0 21.1046 0.89543 22 2 22H16C17.1046 22 18 21.1046 18 20V5.58579L12.4142 0ZM2 2H10V6C10 7.10457 10.8954 8 12 8H16V20H2V2ZM12 6V2.41421L15.5858 6H12ZM8 9V13.5858L6.70711 12.2929L5.29289 13.7071L9 17.4142L12.7071 13.7071L11.2929 12.2929L10 13.5858V9H8Z"
      fill="#7C7C7C"
    />
  </svg>
);
