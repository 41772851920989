import { LogInRequest } from "@/services/openapi";

export const twoFactorParamsName = "mode";

export enum TwoFactorMode {
  SIGN_IN = "sign-in",
  RESET_PASSWORD = "reset-password",
}

export const getTwoFactorSearchParams = (mode: TwoFactorMode) => {
  return `${twoFactorParamsName}=${mode}`;
};

type CaptchaTemporalTokenType = {
  captcha?: {
    token: string;
  };
};
export type TwoFactorLocalStorageSignInType = Omit<LogInRequest, "captcha" | "twoFactorCode" | "recoveryCode"> &
  CaptchaTemporalTokenType;
