import * as SelectPrimitive from "@radix-ui/react-select";
import React, { ReactNode, useState } from "react";

import { IconChevronDown, IconChevronUp, IconTick } from "@/domains/icons";
import { ErrorMessage } from "@/domains/ui-kit";
import { cn } from "@/shared/styles";

import * as Styled from "./Select.styled";

type Option = {
  value: string;
  label: ReactNode;
  icon?: string;
  // disabled?: boolean;
};

// TODO: separator пока не поддерживаются на уровне дизайна
type Group = {
  label?: ReactNode;
  // separator?: boolean;
  options: Array<Option>;
};

export type OptionList = Array<Option> | Array<Group>;

type Props = {
  optionList: OptionList;
  placeholder?: ReactNode;
  position?: "popper";
  value?: string;
  setValue?: (value: string) => void;
  errorMessage?: string;
  disabled?: boolean;
};

// TODO: компонент зависит от внешних value, setValue, в дальнейшем переработать на ref
export const Select = ({
  value,
  setValue,
  optionList,
  placeholder,
  position = "popper",
  errorMessage,
  disabled,
}: Props) => {
  const [open, setOpen] = useState(false);

  const hasErrorMessage = Boolean(errorMessage);
  const groupOptions =
    "value" in optionList[0] ? [{ options: optionList as Array<Option> }] : (optionList as Array<Group>);

  const currentItem = groupOptions.flatMap(group => group.options).find(option => option.value === value);

  return (
    <SelectPrimitive.Root
      open={open}
      onOpenChange={setOpen}
      value={value}
      onValueChange={currentValue => {
        setValue && setValue(currentValue === value ? "" : currentValue);
      }}
    >
      <Styled.Trigger
        as={SelectPrimitive.Trigger}
        error={hasErrorMessage}
        disabled={disabled}
        iconPadding={!!currentItem?.icon}
      >
        <Styled.IconStart>
          {currentItem?.icon && (
            <img src={currentItem.icon} alt={`select-icon:${currentItem.label}`} width={24} height={24} />
          )}
        </Styled.IconStart>

        <SelectPrimitive.Value placeholder={placeholder} />

        <SelectPrimitive.Icon asChild>
          <Styled.IconEnd isIcon className="opacity-50">
            {open ? <IconChevronUp /> : <IconChevronDown />}
          </Styled.IconEnd>
        </SelectPrimitive.Icon>
      </Styled.Trigger>
      {errorMessage && <ErrorMessage hasError={hasErrorMessage}>{errorMessage}</ErrorMessage>}

      <SelectPrimitive.Portal>
        <Styled.Content
          as={SelectPrimitive.Content}
          className={cn(
            `
              relative
              z-50
              overflow-hidden
              data-[state=open]:animate-in
              data-[state=closed]:animate-out
              data-[state=closed]:fade-out-0
              data-[state=open]:fade-in-0
              data-[state=closed]:zoom-out-95
              data-[state=open]:zoom-in-95
              data-[side=bottom]:slide-in-from-top-2
              data-[side=left]:slide-in-from-right-2
              data-[side=right]:slide-in-from-left-2
              data-[side=top]:slide-in-from-bottom-2`,
            position === "popper" &&
              `data-[side=bottom]:translate-y-1
              data-[side=left]:-translate-x-1
              data-[side=right]:translate-x-1
              data-[side=top]:-translate-y-1`,
          )}
          position={position}
        >
          {/*<SelectPrimitive.ScrollUpButton />*/}
          <SelectPrimitive.Viewport
            className={cn(
              position === "popper" &&
                `h-[var(--radix-select-trigger-height)]
                w-full
                min-w-[var(--radix-select-trigger-width)]`,
            )}
          >
            {groupOptions.map((group, index) => (
              <>
                <SelectPrimitive.Group key={`${group.label}:${index}`}>
                  {group.label && <Styled.Label as={SelectPrimitive.Label}>{group.label}</Styled.Label>}

                  {group.options.map(option => (
                    <Styled.Item
                      as={SelectPrimitive.Item}
                      key={option.value}
                      value={option.value}
                      // disabled={option.disabled}
                    >
                      {option.icon && (
                        <Styled.ItemContent>
                          <img src={option.icon} alt={`select-icon:${option.label}`} width={24} height={24} />
                        </Styled.ItemContent>
                      )}

                      <SelectPrimitive.ItemText asChild>
                        <Styled.ItemText selected={value === option.value}>{option.label}</Styled.ItemText>
                      </SelectPrimitive.ItemText>

                      {value === option.value && (
                        <Styled.Icon as={SelectPrimitive.ItemIndicator}>
                          <IconTick />
                        </Styled.Icon>
                      )}
                    </Styled.Item>
                  ))}
                </SelectPrimitive.Group>

                {/*{group.separator && <Styled.Separator as={SelectPrimitive.Separator} />}*/}
              </>
            ))}
          </SelectPrimitive.Viewport>
          {/*<SelectPrimitive.ScrollDownButton />*/}
        </Styled.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
};
