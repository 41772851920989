import { FC, lazy, Suspense } from "react";

import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { useHookForm } from "@/hooks/form.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyGroup } from "@/services/openapi";

import { OnboardingContainer } from "../../onboarding.container";
import { NavigationTypes } from "../navigation/navigation.helpers";
import { OnboardingMobileNavigation } from "../navigation/onboarding-mobile-navigation";
import { SurveyField } from "./survey-field";

const LazyOnboardingFooter = lazy(() =>
  import("../footer/onboarding-footer").then(module => ({
    default: module.OnboardingFooter,
  })),
);

interface Props {
  surveyGroup: SurveyGroup;
  navigationParams: NavigationTypes;
  setBackStep: () => void;
  onSubmit: (values: SurveyGroup) => void;
}

export const SurveyForm: FC<Props> = ({ surveyGroup, navigationParams, setBackStep, onSubmit }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const form = useHookForm();

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <OnboardingContainer title={surveyGroup.title!} description={surveyGroup.description!} {...navigationParams}>
        <Stack gap="6">
          {surveyGroup.fields!.map(field => (
            <SurveyField field={field} key={field.id} />
          ))}
        </Stack>
      </OnboardingContainer>
      <Suspense fallback={<></>}>
        {isMobile ? (
          <OnboardingMobileNavigation {...navigationParams} />
        ) : (
          <LazyOnboardingFooter
            backButtonFn={setBackStep}
            right={
              <SubmitButton checkDirty={false} disabled={false} checkValid={false}>
                {t("button.next")}
              </SubmitButton>
            }
          />
        )}
      </Suspense>
    </HookForm>
  );
};
