import { Slot } from "@radix-ui/react-slot";
import { type ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { useTerminalFeatureTourContext } from "@/features/terminal/feature-tour/context";
import { MarginIncrementOrderHintPopover } from "@/features/terminal/feature-tour/steps/increment-order/margin-popover";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext, VolumeLotsError } from "../../../context";
import { VolumeMarginFieldDescriptor } from "../../../volume-margin/descriptor";
import { VolumeSuggester } from "../../../volume-suggester/volume-suggester";

type Props = Omit<ComponentProps<typeof VolumeMarginFieldDescriptor>, "isMobile"> & {
  incrementOrder: ReturnType<typeof usePlaceOrderContext>["incrementOrder"];
  decrementOrder: ReturnType<typeof usePlaceOrderContext>["decrementOrder"];
  marginMultiplier: ReturnType<typeof usePlaceOrderContext>["marginMultiplier"];
  volumeLotsStep: ReturnType<typeof usePlaceOrderContext>["volumeLotsStep"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
  volumeMarginFormValue: ReturnType<typeof usePlaceOrderContext>["volumeMarginFormValue"];
  changeMargin: ReturnType<typeof usePlaceOrderContext>["changeMargin"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  incrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["incrementVolumeDisabled"];
  decrementVolumeDisabled: ReturnType<typeof usePlaceOrderContext>["decrementVolumeDisabled"];
  isSubmitting: boolean;
  incrementOrderStyles?: ReturnType<typeof useTerminalFeatureTourContext>["incrementOrderStyles"];
};

const emptyObject = {} as ReturnType<typeof useTerminalFeatureTourContext>["incrementOrderStyles"];

const DesktopVolumeMarginField: FC<Props> = ({
  volumeMarginDecimalScale,
  maxBalanceVolumeMargin,
  maxSystemVolumeMargin,
  minSystemVolumeMargin,
  changeMargin,
  volumeLots,
  changeLots,
  currency,
  volumeMargin,
  volumeMarginFormValue,
  marginMultiplier,
  maxAvailableVolumeLots,
  minSystemVolumeLots,
  volumeLotsStep,
  decrementVolumeDisabled,
  incrementVolumeDisabled,
  decrementOrder,
  incrementOrder,
  hasNoFreeMargin,
  volumeLotsError,
  maxBalanceVolumeLots,
  maxSystemVolumeLots,
  maxAvailableVolumeMargin,
  isSubmitting,
  incrementOrderStyles = emptyObject,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  const [suggesterOpen, { open: openSuggester, close: closeSuggester }] = useDisclosure();

  return (
    <NumberInput
      label={t("common.margin")!}
      disabled={hasNoFreeMargin}
      pending={isSubmitting}
      value={volumeMarginFormValue}
      suffix={` ${currency}`}
      renderPlusButton={button => (
        <MarginIncrementOrderHintPopover>
          <Slot style={incrementOrderStyles.target}>{button}</Slot>
        </MarginIncrementOrderHintPopover>
      )}
      renderWrapper={element => (
        <VolumeSuggester
          open={suggesterOpen}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={currency}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          volumeMargin={volumeMargin}
          marginMultiplier={marginMultiplier}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          volumeLotsStep={volumeLotsStep}
        >
          <Slot style={incrementOrderStyles.highlight}>{element}</Slot>
        </VolumeSuggester>
      )}
      onIncrement={incrementOrder}
      onFocus={openSuggester}
      onBlur={() => {
        closeSuggester();
        if (volumeLotsError === VolumeLotsError.NONE) {
          changeLots(volumeLots!);
        }
      }}
      onDecrement={decrementOrder}
      onChange={value => changeMargin(value, { format: false })}
      invalid={volumeLotsError !== VolumeLotsError.NONE && !hasNoFreeMargin}
      decimalScale={volumeMarginDecimalScale}
      plusDisabled={incrementVolumeDisabled}
      minusDisabled={decrementVolumeDisabled}
      descriptor={
        <VolumeMarginFieldDescriptor
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          changeLots={changeLots}
          hasNoFreeMargin={hasNoFreeMargin}
          volumeLotsError={volumeLotsError}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxSystemVolumeLots={maxSystemVolumeLots}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeLots={minSystemVolumeLots}
          minSystemVolumeMargin={minSystemVolumeMargin}
          currency={currency}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
        />
      }
    />
  );
};

export { DesktopVolumeMarginField };
