import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva } from "class-variance-authority";

import { Grid } from "@/components/grid";
import { IconChevronDown } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useOrdersContext } from "../contexts/orders.context";
import { TerminalTableState, useTerminalLayout } from "../contexts/terminal-layout.context";
import { terminalLocators } from "../locators";
import { ClosedPositionsContainer } from "./closed-positions/closed-positions.container";
import { OpenOrdersContainer } from "./open-orders/open-orders.container";
import { PendingOrdersContainer } from "./pending-orders/pending-orders.container";

const toggleButtonStyles = cva(
  "inline-flex items-center justify-center bg-bg-back px-14 py-2 font-roboto text-[16px] leading-[1.5] text-text transition-colors first:rounded-s-[24px] last:rounded-e-[24px] hover:bg-bg-selected focus-visible:outline-none data-[state=on]:bg-bg-selected",
)();

const DesktopTradingTables = () => {
  const { t } = useTranslation();
  const { table, changeTable, toggleTable } = useTerminalLayout();
  const { openOrders, pendingOrders } = useOrdersContext();
  const { account } = useTerminalAccountContext();

  const { setSymbol } = useCurrentSymbolContext();
  return (
    <div
      className="flex overflow-auto rounded-[8px] bg-bg px-4 py-2"
      style={{
        height: table === TerminalTableState.NONE ? 56 : 280,
      }}
    >
      <div className="flex w-full flex-col">
        <Grid
          css={{
            alignItems: "center",
            mb: table === TerminalTableState.NONE ? "0" : "8px",
            gridTemplateColumns: "1fr auto 1fr",
          }}
        >
          <h2 className="font-gilroy text-[22px] font-semibold leading-[1.2] text-text">
            {t("terminal.trades.title")}
          </h2>
          <ToggleGroup.Root
            value={table}
            onValueChange={value => {
              if (!value) {
                changeTable(TerminalTableState.NONE);
                return;
              }
              changeTable(value);
            }}
            className="flex"
            type="single"
          >
            <ToggleGroup.Item
              data-test={terminalLocators.tradingTables.tabs.open}
              className={toggleButtonStyles}
              value={TerminalTableState.OPEN}
            >
              {t("terminal.open")}: {openOrders.length}
            </ToggleGroup.Item>
            <ToggleGroup.Item
              data-test={terminalLocators.tradingTables.tabs.pending}
              className={toggleButtonStyles}
              value={TerminalTableState.PENDING}
            >
              {t("terminal.pending")}: {pendingOrders.length}
            </ToggleGroup.Item>
            <ToggleGroup.Item
              data-test={terminalLocators.tradingTables.tabs.closed}
              className={toggleButtonStyles}
              value={TerminalTableState.CLOSED_POSITIONS}
            >
              {t("terminal.closed-positions")}
            </ToggleGroup.Item>
          </ToggleGroup.Root>
          <button
            className="relative ms-auto grid place-items-center text-text after:absolute after:size-11"
            onClick={toggleTable}
          >
            <IconChevronDown />
          </button>
        </Grid>
        {table === TerminalTableState.OPEN && (
          <OpenOrdersContainer
            accountId={account.id!}
            accountCurrency={account.currency!}
            currencyDecimalScale={account.digits!}
            setSymbol={setSymbol}
          />
        )}
        {table === TerminalTableState.PENDING && (
          <PendingOrdersContainer
            accountId={account.id!}
            setSymbol={setSymbol}
            accountCurrency={account.currency!}
            currencyDecimalScale={account.digits!}
          />
        )}
        {table === TerminalTableState.CLOSED_POSITIONS && (
          <ClosedPositionsContainer
            accountId={account.id!}
            setSymbol={setSymbol}
            accountCurrency={account.currency!}
            currencyDecimalScale={account.digits!}
          />
        )}
      </div>
    </div>
  );
};

export { DesktopTradingTables };
