import * as Popover from "@radix-ui/react-popover";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { popoverContentStyles } from "../popper";

type FooterProps = ComponentPropsWithoutRef<"div"> & {
  grow?: boolean;
};

const Footer = forwardRef<ElementRef<"div">, FooterProps>(({ className, grow, ...props }, ref) => {
  return (
    <div
      className={cn("mt-4 flex flex-wrap-reverse items-center justify-between gap-2", grow && "*:flex-1", className)}
      ref={ref}
      {...props}
    />
  );
});

const textStyles = cva("font-roboto text-[14px] font-normal leading-[1.4] text-text-inverse")();

const Text = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => {
  return <div className={cn(textStyles, className)} ref={ref} {...props} />;
});

const TextButton = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button">>(
  ({ className, type = "button", ...props }, ref) => {
    return <button className={cn("underline", className)} type={type} ref={ref} {...props} />;
  },
);

const Strong = forwardRef<ElementRef<"strong">, ComponentPropsWithoutRef<"strong">>(({ className, ...props }, ref) => {
  return <strong className={cn(textStyles, "inline font-bold", className)} ref={ref} {...props} />;
});

const CloseButton = forwardRef<ElementRef<"button">, ComponentPropsWithoutRef<"button">>(
  ({ className, type = "button", ...props }, ref) => {
    return (
      <button
        className={cn("relative grid place-items-center outline-none before:absolute before:size-11", className)}
        type={type}
        ref={ref}
        {...props}
      >
        <IconCross />
      </button>
    );
  },
);

type HintProps = ComponentPropsWithoutRef<"div"> & {
  title: string;
  text: React.ReactNode;
  closeButton?: React.ReactNode;
};

const Hint = forwardRef<ElementRef<"div">, HintProps>(
  ({ title, className, text, closeButton, children, ...props }, ref) => {
    return (
      <div className={cn("rounded-2xl bg-bg-additional p-6 text-text-inverse shadow", className)} ref={ref} {...props}>
        <div className="mb-1 flex items-center justify-between">
          <h3 className="font-gilroy text-[18px] font-semibold leading-[1.2] text-text-inverse">{title}</h3>
          {closeButton}
        </div>
        <Text>{text}</Text>
        {children}
      </div>
    );
  },
);

type PopoverContentProps = ComponentPropsWithoutRef<typeof Popover.Content> & Pick<HintProps, "text" | "title">;

const PopoverContent = forwardRef<ElementRef<typeof Popover.Content>, PopoverContentProps>(
  ({ title, className, text, children, collisionPadding = 10, ...props }, ref) => {
    return (
      <Popover.Portal>
        <Popover.Content
          collisionPadding={collisionPadding}
          className={cn(popoverContentStyles(), "w-[300px]", className)}
          {...props}
          ref={ref}
        >
          <Hint
            title={title}
            text={text}
            closeButton={
              <Popover.Close asChild>
                <CloseButton />
              </Popover.Close>
            }
          >
            {children}
          </Hint>
          <Popover.Arrow width={16} height={8} className="fill-bg-additional" />
        </Popover.Content>
      </Popover.Portal>
    );
  },
);

const Component = Object.assign(Hint, { Text, CloseButton, Footer, Strong, PopoverContent, TextButton });

export { Component as Hint };
