import { Timezone } from "public/charting_library/charting_library";

// https://www.tradingview.com/charting-library-docs/latest/ui_elements/timezones

const supportedTimezones: { [key in Timezone]: true } = {
  "Africa/Cairo": true,
  "Africa/Casablanca": true,
  "Africa/Johannesburg": true,
  "Africa/Lagos": true,
  "Africa/Nairobi": true,
  "Africa/Tunis": true,
  "America/Anchorage": true,
  "America/Argentina/Buenos_Aires": true,
  "America/Bogota": true,
  "America/Caracas": true,
  "America/Chicago": true,
  "America/El_Salvador": true,
  "America/Juneau": true,
  "America/Lima": true,
  "America/Los_Angeles": true,
  "America/Mexico_City": true,
  "America/New_York": true,
  "America/Phoenix": true,
  "America/Santiago": true,
  "America/Sao_Paulo": true,
  "America/Toronto": true,
  "America/Vancouver": true,
  "Asia/Almaty": true,
  "Asia/Ashkhabad": true,
  "Asia/Bahrain": true,
  "Asia/Bangkok": true,
  "Asia/Chongqing": true,
  "Asia/Colombo": true,
  "Asia/Dhaka": true,
  "Asia/Dubai": true,
  "Asia/Ho_Chi_Minh": true,
  "Asia/Hong_Kong": true,
  "Asia/Jakarta": true,
  "Asia/Jerusalem": true,
  "Asia/Karachi": true,
  "Asia/Kathmandu": true,
  "Asia/Kolkata": true,
  "Asia/Kuwait": true,
  "Asia/Manila": true,
  "Asia/Muscat": true,
  "Asia/Nicosia": true,
  "Asia/Qatar": true,
  "Asia/Riyadh": true,
  "Asia/Seoul": true,
  "Asia/Shanghai": true,
  "Asia/Singapore": true,
  "Asia/Taipei": true,
  "Asia/Tehran": true,
  "Asia/Tokyo": true,
  "Asia/Yangon": true,
  "Atlantic/Reykjavik": true,
  "Australia/Adelaide": true,
  "Australia/Brisbane": true,
  "Australia/Perth": true,
  "Australia/Sydney": true,
  "Europe/Amsterdam": true,
  "Europe/Athens": true,
  "Europe/Belgrade": true,
  "Europe/Berlin": true,
  "Europe/Bratislava": true,
  "Europe/Brussels": true,
  "Europe/Bucharest": true,
  "Europe/Budapest": true,
  "Europe/Copenhagen": true,
  "Europe/Dublin": true,
  "Europe/Helsinki": true,
  "Europe/Istanbul": true,
  "Europe/Lisbon": true,
  "Europe/London": true,
  "Europe/Luxembourg": true,
  "Europe/Madrid": true,
  "Europe/Malta": true,
  "Europe/Moscow": true,
  "Europe/Oslo": true,
  "Europe/Paris": true,
  "Europe/Prague": true,
  "Europe/Riga": true,
  "Europe/Rome": true,
  "Europe/Stockholm": true,
  "Europe/Tallinn": true,
  "Europe/Vienna": true,
  "Europe/Vilnius": true,
  "Europe/Warsaw": true,
  "Europe/Zurich": true,
  "Pacific/Auckland": true,
  "Pacific/Chatham": true,
  "Pacific/Fakaofo": true,
  "Pacific/Honolulu": true,
  "Pacific/Norfolk": true,
  "US/Mountain": true,
  "Etc/UTC": true,
};

// it is very unstable solution but it works for now
const utcOffsetsTimezoneMap: Record<string, Timezone> = {
  "-10": "Pacific/Honolulu",
  "-8": "America/Juneau",
  "-7": "America/Los_Angeles",
  "-6": "US/Mountain",
  "-5": "America/Chicago",
  "-4": "America/New_York",
  "-3": "America/Sao_Paulo",
  "0": "Atlantic/Reykjavik",
  "1": "Europe/London",
  "2": "Europe/Paris",
  "3": "Europe/Istanbul",
  "4": "Asia/Dubai",
  "5": "Asia/Karachi",
  "6": "Asia/Almaty",
  "7": "Asia/Ho_Chi_Minh",
  "8": "Asia/Hong_Kong",
  "9": "Asia/Tokyo",
  "10": "Australia/Sydney",
  "11": "Pacific/Norfolk",
  "12": "Pacific/Auckland",
  "13": "Pacific/Fakaofo",
};

export const getSymbolTimezone = () => {
  let timezone: Timezone = "Etc/UTC";
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;

    if (!supportedTimezones[timezone]) {
      const utcOffsetInHours = new Date().getTimezoneOffset() / -60;
      timezone = utcOffsetsTimezoneMap[utcOffsetInHours.toString()] || "Etc/UTC";
    }
  } catch (e) {}

  return timezone;
};
