import { FC, useEffect, useMemo } from "react";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { getOrderTypeText } from "../../components/trading-table/order-type";
import { getModifyOrderRules, getOpeningPriceThresholdValue } from "../helpers";
import { ModifyMoreLessDescription } from "../more-less-description";
import { ModifyOrderSymbol } from "../order-symbol";

enum Fields {
  OPENING_PRICE = "openingPrice",
}

type FormValues = {
  [Fields.OPENING_PRICE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  priceDecimalScale: number;
  volumeDecimalScale: number;
  openPrice: number;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
  currentPrice: number;
  symbol: string;
  volume: number;
  isMobile?: boolean;
};

const OpeningPriceForm: FC<Props> = ({
  onSubmit,
  priceDecimalScale,
  ask,
  bid,
  orderType,
  openPrice,
  currentPrice,
  isMobile,
  symbol,
  volumeDecimalScale,
  volume,
}) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.OPENING_PRICE]: formatInputNumberValue(openPrice, priceDecimalScale),
    },
  });
  const { watch, formState, trigger, control } = form;
  const { touchedFields } = formState;

  const isTouched = touchedFields[Fields.OPENING_PRICE];

  const { openingPrice: op } = watch();
  const openingPrice = getInputNumberValue(op);

  const thresholdValue = useMemo(() => getOpeningPriceThresholdValue({ orderType, bid, ask }), [orderType, ask, bid]);

  useEffect(() => {
    if (isTouched) {
      trigger(Fields.OPENING_PRICE);
    }
  }, [thresholdValue, isTouched, openingPrice]);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.OPENING_PRICE,
    control,
    rules: getModifyOrderRules({
      thresholdValue: thresholdValue,
      type: "openingPrice",
      orderType,
      value: openingPrice,
      required: true,
    }),
  });

  return (
    <HookForm form={form} onSubmit={onSubmit} className="flex grow flex-col">
      <ModifyOrderSymbol symbol={symbol} isMobile={isMobile} />
      <NumberInput
        size={isMobile ? "sm" : "md"}
        autoFocus
        decimalScale={priceDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.not-set")!}
        descriptor={
          <ModifyMoreLessDescription
            decimalScale={priceDecimalScale}
            thresholdValue={thresholdValue}
            orderType={orderType}
            onChange={field.onChange}
            type="openingPrice"
          />
        }
        {...field}
      />
      {isMobile && (
        <TerminalPopover.List className="mt-6">
          <TerminalPopover.ListItem
            value={<NumberFormat value={currentPrice} decimalScale={priceDecimalScale} />}
            label={`${t("terminal.current-price")}:`}
          />
          <TerminalPopover.ListItem value={getOrderTypeText(t, orderType)} label={`${t("common.type")}:`} />
          <TerminalPopover.ListItem
            value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}
            label={`${t("terminal.volume-lot")}:`}
          />
        </TerminalPopover.List>
      )}
      <TerminalPopover.Buttons isMobile={isMobile}>
        <SubmitButton>{t("button.confirm")}</SubmitButton>
      </TerminalPopover.Buttons>
    </HookForm>
  );
};

export { OpeningPriceForm };
