import { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";

import { AuthFormContainer } from "../auth-form.container";
import { TwoFactorMode, twoFactorParamsName } from "../two-factor/two-factor.helpers";
import { TwoFactorRecoveryResetContainer } from "./two-factor-recovery-reset.container";
import { TwoFactorRecoverySigninContainer } from "./two-factor-recovery-signin.container";

export const TwoFactorRecoveryContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get(twoFactorParamsName) as TwoFactorMode | null;

  const { t } = useTranslation();
  return (
    <AuthFormContainer
      title={mode === TwoFactorMode.SIGN_IN ? t("auth.sign-in.login") : t("auth.reset-password.title")}
      backLink
    >
      {mode === TwoFactorMode.SIGN_IN && <TwoFactorRecoverySigninContainer />}
      {mode === TwoFactorMode.RESET_PASSWORD && <TwoFactorRecoveryResetContainer />}
    </AuthFormContainer>
  );
};
