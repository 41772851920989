import * as Popover from "@radix-ui/react-popover";
import { cva } from "class-variance-authority";
import { type FC, useMemo } from "react";

import { MarginFreeFormat } from "@/app/components";
import { AccountBadge } from "@/components/badge/account-badge";
import { Dialog } from "@/components/dialog";
import { IconChevronDown } from "@/domains/icons";
import { BonusDialog } from "@/entities/bonuses/dialog/bonus-dialog";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { popoverContentStyles } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { useTerminalLayout } from "../contexts/terminal-layout.context";
import { useTerminalFeatureTourContext } from "../feature-tour/context";
import { CheckFreeMarginHintPopover } from "../feature-tour/steps/check-free-margin/hint-popover";
import { AccountsSelectPopoverContent } from "./popover-content";

const buttonStyles = cva(
  "inline-flex flex-col items-center gap-0.5 rounded-[8px] px-2 py-1 transition-colors hover:bg-bg-back data-[state=open]:bg-bg-selected lg:items-end",
  {
    variants: {
      isCheckFreeMarginStepActive: {
        true: "!bg-bg-back lg:!bg-bg",
      },
    },
  },
);

const AccountSelect: FC = () => {
  const { account, setAccount, bonus, showBonus } = useTerminalAccountContext();

  const [opened, { onOpenChange, close: closePopover }] = useDisclosure();

  const { checkFreeMarginStyles, isCheckFreeMarginStepActive } = useTerminalFeatureTourContext();

  const { isMobile } = useTerminalLayout();

  const { symbolsIsLoaded } = useSymbolsContext();

  const { data } = useAllAccountsQuery({
    isTerminalEnabledOnly: true,
    status: AvailabilityStatus.Active,
    sorting: TradingAccountSorting.Newest,
  });

  const accounts = useMemo(
    () =>
      (data || [])
        .filter(({ id }) => id !== account.id)
        .sort(a => {
          if (account.type === TradingAccountType.Demo) {
            return a.type === TradingAccountType.Demo ? -1 : 1;
          } else {
            return a.type === TradingAccountType.Real ? -1 : 1;
          }
        }),
    [data, account.id, account.type],
  );

  return (
    <Dialog>
      <Popover.Root modal open={opened} onOpenChange={onOpenChange}>
        <CheckFreeMarginHintPopover>
          <Popover.Trigger asChild>
            <button className={buttonStyles({ isCheckFreeMarginStepActive })} style={checkFreeMarginStyles}>
              <AccountBadge accountType={account.type!} size="small" fullTitle={!isMobile} />
              {symbolsIsLoaded && (
                <div className="flex items-center gap-1">
                  <div className="whitespace-nowrap font-gilroy text-[18px] font-semibold leading-[1.2] text-text lg:text-[22px]">
                    <MarginFreeFormat
                      currency={account.currency!}
                      value={account.marginFree}
                      decimalScale={account.digits}
                    />
                  </div>
                  <button className="hidden lg:block">{<IconChevronDown />}</button>
                </div>
              )}
            </button>
          </Popover.Trigger>
        </CheckFreeMarginHintPopover>

        <Popover.Portal>
          <Popover.Content
            align={isMobile ? "center" : "end"}
            className={cn(
              popoverContentStyles(),
              "max-h-[40vh] w-[min(440px,_90vw)] overflow-y-auto rounded-[16px] bg-bg-back py-3 shadow scrollbar-hide",
            )}
            sideOffset={8}
          >
            <AccountsSelectPopoverContent
              onClose={closePopover}
              accounts={accounts}
              currentAccount={account}
              setAccount={setAccount}
              showBonus={showBonus}
              bonus={bonus}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {bonus && <BonusDialog bonus={bonus} />}
    </Dialog>
  );
};

AccountSelect.displayName = "TerminalAccountSelect";

export { AccountSelect };
