import React from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/components/dialog";
import { Button, Hint } from "@/shared/ui";

import { HintDialogContent } from "../../components/hint/hint-dialog";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  onFinish: ReturnType<typeof useTerminalFeatureTourContext>["finishTour"];
  isLoading: ReturnType<typeof useTerminalFeatureTourContext>["isLoading"];
};

const MarginFinalHintDialog: React.FC<Props> = ({ onFinish, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Dialog open>
      <HintDialogContent>
        <Hint
          title={t("terminal.onboarding.final.margin.title")}
          text={t("terminal.onboarding.final.margin.description")}
          closeButton={<Hint.CloseButton onClick={onFinish} />}
        >
          <Hint.Footer grow>
            <Button size="sm" onClick={onFinish} pending={isLoading}>
              {t("terminal.onboarding.buttons.got-it")}
            </Button>
          </Hint.Footer>
        </Hint>
      </HintDialogContent>
    </Dialog>
  );
};

export { MarginFinalHintDialog };
