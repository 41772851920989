export const TransferIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.774 6.242a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L9.48 9.363v9.586a1 1 0 11-2 0V9.363l-1.293 1.293a1 1 0 11-1.414-1.414l3-3zM17.188 19.656a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414l1.293 1.293V6.949a1 1 0 112 0v9.586l1.293-1.293a1 1 0 011.414 1.414l-3 3z"
      clipRule="evenodd"
    ></path>
  </svg>
);
