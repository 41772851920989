import { FC } from "react";

import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";

import { AuthBox } from "../styles";

export const ForgotPasswordSuccess: FC = () => {
  const { t } = useTranslation();

  return (
    <AuthBox css={{ mt: "16px" }}>
      <Text family="roboto" align="center" lineHeight="3" css={{ mb: "16px" }}>
        {t("auth.forgot-password.success-text")}
      </Text>
    </AuthBox>
  );
};
