import { forwardRef } from "react";

import { useTranslation } from "@/hooks/translator.hook";

import { RadioCheckbox } from "../../inputs";
import { TileWrapper, TileWrapperExternalProps, TileWrapperRefType } from "./tile-wrapper";

export type TileCheckboxType = boolean | undefined;

export type TileCheckboxExternalProps = TileWrapperExternalProps;

type TileRadioInternalProps = {
  value: TileCheckboxType;
  onChange: (value: TileCheckboxType) => void;
};

type Props = TileRadioInternalProps & TileCheckboxExternalProps;

export const TileCheckbox = forwardRef<TileWrapperRefType, Props>(
  ({ value, defaultValue, onChange, ...restProps }, ref) => {
    const { t } = useTranslation();

    return (
      <>
        <TileWrapper
          ref={ref}
          {...restProps}
          type="checkbox"
          css={{
            ".checkbox-wrapper": {
              display: "flex",
              flexDirection: "column",
              gap: "14px",
            },
          }}
        >
          <RadioCheckbox label={t("common.yes")} onChange={() => onChange(true)} checked={value === true} />
          <RadioCheckbox label={t("common.no")} onChange={() => onChange(false)} checked={value === false} />
        </TileWrapper>
      </>
    );
  },
);
TileCheckbox.displayName = "TileCheckbox";
