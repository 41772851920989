import { ComponentProps, type FC } from "react";
import { useTranslation } from "react-i18next";

import { TradingAccountTradeMode } from "@/services/openapi";

import { MobileVolumeLotsField } from "../fields/volume-lots/field";
import { MobileVolumeMarginField } from "../fields/volume-margin/field";
import { MobilePlaceOrderSection } from "../order-section/order-section";
import { MobileVolumeModeSelect } from "../volume-mode/select";

type Props = ComponentProps<typeof MobileVolumeModeSelect> &
  ComponentProps<typeof MobileVolumeLotsField> &
  ComponentProps<typeof MobileVolumeMarginField>;

const MobileValueTypeBlock: FC<Props> = ({
  changeLots,
  changeMargin,
  currency,
  decrementOrder,
  decrementVolumeDisabled,
  hasNoFreeMargin,
  incrementOrder,
  changeVolumeMode,
  incrementVolumeDisabled,
  isSubmitting,
  marginMultiplier,
  maxAvailableVolumeLots,
  maxAvailableVolumeMargin,
  maxBalanceVolumeLots,
  maxBalanceVolumeMargin,
  maxSystemVolumeLots,
  maxSystemVolumeMargin,
  minSystemVolumeLots,
  minSystemVolumeMargin,
  volumeLots,
  volumeLotsDecimalScale,
  volumeLotsError,
  volumeLotsStep,
  volumeMargin,
  volumeMarginDecimalScale,
  volumeMode,
  volumeMarginFormValue,
  volumeLotsFormValue,
  accountId,
  isDemoAccount,
}) => {
  const { t } = useTranslation();

  return (
    <MobilePlaceOrderSection title={t("terminal.place-order.value-type")}>
      <MobileVolumeModeSelect volumeMode={volumeMode} changeVolumeMode={changeVolumeMode} currency={currency} />
      {volumeMode === TradingAccountTradeMode.Lots && (
        <MobileVolumeLotsField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          volumeLotsFormValue={volumeLotsFormValue}
          changeLots={changeLots}
          currency={currency}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeLotsDecimalScale={volumeLotsDecimalScale}
        />
      )}
      {volumeMode === TradingAccountTradeMode.Margin && (
        <MobileVolumeMarginField
          accountId={accountId}
          isDemoAccount={isDemoAccount}
          isSubmitting={isSubmitting}
          maxBalanceVolumeMargin={maxBalanceVolumeMargin}
          maxAvailableVolumeMargin={maxAvailableVolumeMargin}
          maxSystemVolumeMargin={maxSystemVolumeMargin}
          minSystemVolumeMargin={minSystemVolumeMargin}
          volumeMarginDecimalScale={volumeMarginDecimalScale}
          changeMargin={changeMargin}
          volumeLots={volumeLots}
          changeLots={changeLots}
          currency={currency}
          volumeMargin={volumeMargin}
          marginMultiplier={marginMultiplier}
          maxAvailableVolumeLots={maxAvailableVolumeLots}
          minSystemVolumeLots={minSystemVolumeLots}
          volumeLotsStep={volumeLotsStep}
          decrementVolumeDisabled={decrementVolumeDisabled}
          incrementVolumeDisabled={incrementVolumeDisabled}
          incrementOrder={incrementOrder}
          decrementOrder={decrementOrder}
          hasNoFreeMargin={hasNoFreeMargin}
          maxBalanceVolumeLots={maxBalanceVolumeLots}
          maxSystemVolumeLots={maxSystemVolumeLots}
          volumeLotsError={volumeLotsError}
          volumeMarginFormValue={volumeMarginFormValue}
        />
      )}
    </MobilePlaceOrderSection>
  );
};

export { MobileValueTypeBlock };
