import { FC } from "react";

import { TileCheckboxField } from "@/components/form/fields";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField } from "@/services/openapi";

import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyBooleanField: FC<Props> = ({ field: { isRequired, title, id, type, value, description } }) => {
  const { t } = useTranslation();

  return (
    <TileCheckboxField
      description={description!}
      name={generateSurveyFieldId(id!, type!)}
      label={generateSurveyFieldTitle(title!, isRequired!)}
      rules={{
        validate: (value: boolean) => (value !== undefined ? true : t("form-errors.required-error")!),
      }}
      // @ts-ignore
      defaultValue={value === "true" ? true : value === "false" ? false : undefined}
    />
  );
};
