import React, { useCallback } from "react";

import { useTerminalFeatureTourContext } from "@/features/terminal/feature-tour/context";
import { useTerminalSymbolsFavoritesQuery } from "@/state/server/terminal";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { ChartSymbols } from "./chart-symbols";

const _ChartSymbolsContainer = () => {
  const { account } = useTerminalAccountContext();
  const { currentSymbol, setSymbol: setSymbolFromContext } = useCurrentSymbolContext();
  const { symbolsIsLoaded } = useSymbolsContext();
  const { selectSymbol } = useTerminalFeatureTourContext();

  const { data: favoriteData } = useTerminalSymbolsFavoritesQuery(account.id!, {
    enabled: false,
  });

  const favoriteSymbols = favoriteData ? favoriteData.charts! : [];

  const setSymbol = useCallback(
    (symbol: string) => {
      setSymbolFromContext(symbol);
      selectSymbol();
    },
    [setSymbolFromContext, selectSymbol],
  );

  return (
    <ChartSymbols
      accountId={account.id!}
      favoriteSymbols={favoriteSymbols}
      currentSymbol={currentSymbol}
      setSymbol={setSymbol}
      symbolsIsLoaded={symbolsIsLoaded}
    />
  );
};

export const ChartSymbolsContainer = React.memo(_ChartSymbolsContainer);
