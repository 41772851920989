import React from "react";

export const StarIcon = () => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83758 14.3942L4.46136 22.4182L11.6673 18.6298L18.8733 22.4182L17.4971 14.3942L23.3269 8.71162L15.2703 7.54094L11.6673 0.240479L8.06435 7.54094L0.0078125 8.71162L5.83758 14.3942ZM15.3481 13.696L16.2171 18.7622L11.6673 16.3703L7.11762 18.7622L7.98654 13.696L4.30573 10.1081L9.39248 9.36895L11.6673 4.75958L13.9422 9.36895L19.0289 10.1081L15.3481 13.696Z"
      fill="#212121"
    />
  </svg>
);
