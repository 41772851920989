import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";
import { Button } from "@/shared/ui";

// TODO: remove
const FeatureTourButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        className={cn("border border-bg-border bg-bg-additional text-white", className)}
        variant="flat"
        {...props}
        ref={ref}
      />
    );
  },
);

export { FeatureTourButton };
