import { ComponentProps, FC } from "react";
import { useTranslation } from "react-i18next";

import { useTerminalAccountContext } from "@/features/terminal/contexts/account.context";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { useSymbolsContext } from "@/features/terminal/contexts/symbols.context";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import {
  TerminalDealExpirationType,
  TerminalDealFillPolicy,
  TerminalDealType,
  TradingAccountType,
} from "@/services/openapi";
import { useOpenTerminalOrderMutation } from "@/state/server/terminal";

import { usePlaceOrderContext } from "../../../context";
import {
  getOriginalVolume,
  getPendingPlaceOrderType,
  handlePendingTakeProfitStopLossErrors,
} from "../../../place-order.helpers";
import { MobilePendingOrderForm } from "./form";

type Props = {
  onClose: () => void;
};

const MobilePendingOrderContainer: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const { symbolInfo } = useCurrentSymbolContext();

  const {
    volumeMode,
    volumeLots,
    volumeMargin,
    changeLots,
    changeMargin,
    maxBalanceVolumeLots,
    maxBalanceVolumeMargin,
    maxSystemVolumeLots,
    minSystemVolumeLots,
    maxSystemVolumeMargin,
    minSystemVolumeMargin,
    marginMultiplier,
    maxAvailableVolumeLots,
    volumeLotsStep,
    decrementVolumeDisabled,
    incrementVolumeDisabled,
    decrementOrder,
    incrementOrder,
    maxAvailableVolumeMargin,
    volumeLotsDecimalScale,
    volumeMarginDecimalScale,
    hasNoFreeMargin,
    volumeLotsError,
    volumeSellMargin,
    volumeBuyMargin,
    ask,
    bid,
    swapLong,
    swapShort,
    currency,
    changeVolumeMode,
    changeOpenPrice,
    openPrice,
    resetOpenPrice,
    openPriceFormValue,
    volumeLotsFormValue,
    volumeMarginFormValue,
  } = usePlaceOrderContext();

  const {
    account: { id: accountId, leverage, type },
  } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();

  const {
    digits,
    symbol,
    contractSize,
    baseCurrency,
    baseCurrencyExt,
    group,
    type: instrumentType,
    quoteCurrency,
    marginRateInitialMarketBuy,
  } = symbolInfo!;

  const { mutateAsync: openPendingOrder } = useOpenTerminalOrderMutation();

  const handleSubmit: ComponentProps<typeof MobilePendingOrderForm>["onSubmit"] = (
    { stopLoss, takeProfit },
    side,
    takeProfitStopLossUtils,
  ) => {
    const type = getPendingPlaceOrderType({ ask, bid, openPrice: openPrice!, side });

    const sendRequest = handlePendingTakeProfitStopLossErrors({
      openPrice: openPrice!,
      stopLoss,
      takeProfit,
      side,
      priceDecimalScale: digits!,
      t,
      ...takeProfitStopLossUtils,
    });

    if (!sendRequest) {
      return Promise.resolve();
    }

    return openPendingOrder(
      {
        tradingAccountId: accountId!,
        terminalOpenOrderRequest: {
          volume: volumeLots!,
          symbol,
          type,
          takeProfit: getInputNumberValue(takeProfit),
          stopLoss: getInputNumberValue(stopLoss),
          price: openPrice!,
          expirationType: TerminalDealExpirationType.Gtc,
          fillPolicy:
            type === TerminalDealType.BuyLimit || type === TerminalDealType.SellLimit
              ? TerminalDealFillPolicy.Return
              : undefined,
          volumeOriginal: getOriginalVolume({ volumeMode, volumeLots, volumeMargin }),
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <MobilePendingOrderForm
      marginRateInitialMarketBuy={marginRateInitialMarketBuy!}
      baseCurrencyExt={baseCurrencyExt!}
      group={group!}
      accountId={accountId!}
      isDemoAccount={type === TradingAccountType.Demo}
      volumeLotsFormValue={volumeLotsFormValue}
      volumeMarginFormValue={volumeMarginFormValue}
      openPriceFormValue={openPriceFormValue}
      changeOpenPrice={changeOpenPrice}
      openPrice={openPrice}
      changeVolumeMode={changeVolumeMode}
      leverage={leverage!}
      hasNoFreeMargin={hasNoFreeMargin}
      volumeLotsStep={volumeLotsStep}
      volumeMode={volumeMode}
      changeLots={changeLots}
      changeMargin={changeMargin}
      onSubmit={handleSubmit}
      priceDecimalScale={digits!}
      ask={ask}
      bid={bid}
      contractSize={contractSize!}
      baseCurrency={baseCurrency!}
      accountCurrency={currency}
      symbol={symbol!}
      volumeLots={volumeLots}
      volumeMargin={volumeMargin}
      maxSystemVolumeLots={maxSystemVolumeLots}
      maxBalanceVolumeLots={maxBalanceVolumeLots}
      minSystemVolumeLots={minSystemVolumeLots}
      maxBalanceVolumeMargin={maxBalanceVolumeMargin}
      maxSystemVolumeMargin={maxSystemVolumeMargin}
      minSystemVolumeMargin={minSystemVolumeMargin}
      marginMultiplier={marginMultiplier}
      maxAvailableVolumeLots={maxAvailableVolumeLots}
      incrementVolumeDisabled={incrementVolumeDisabled}
      decrementVolumeDisabled={decrementVolumeDisabled}
      decrementOrder={decrementOrder}
      incrementOrder={incrementOrder}
      volumeLotsError={volumeLotsError}
      maxAvailableVolumeMargin={maxAvailableVolumeMargin}
      volumeMarginDecimalScale={volumeMarginDecimalScale}
      volumeLotsDecimalScale={volumeLotsDecimalScale}
      volumeSellMargin={volumeSellMargin}
      volumeBuyMargin={volumeBuyMargin}
      instrumentType={instrumentType!}
      symbols={symbols}
      quoteCurrency={quoteCurrency!}
      swapLong={swapLong}
      swapShort={swapShort}
      resetOpenPrice={resetOpenPrice}
    />
  );
};

export { MobilePendingOrderContainer };
