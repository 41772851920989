import { FC, memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalPosition } from "@/services/openapi";

import { getDecimalScaleFromTickSize } from "../../helpers/formatting";
import { useAddChartSymbol } from "../../hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "../../place-order/place-order.helpers";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { OrderType } from "../components/trading-table/order-type";
import { TradingTableCard } from "../components/trading-table/trading-table-card";
import { ClosedPositionVolume } from "./closed-position-volume";

type Props = {
  position: TerminalPosition;
  accountCurrency: string;
  accountId: string;
  setSymbol: (symbol: string) => void;
  currencyDecimalScale: number;
  onCardClose?: () => void;
};

const _ClosedPositionCard: FC<Props> = ({
  position,
  accountCurrency,
  onCardClose,
  setSymbol,
  accountId,
  currencyDecimalScale,
}) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  const { addChartSymbol } = useAddChartSymbol({
    setSymbol,
    symbol: symbol!,
    accountId,
    callback: onCardClose,
  });

  return (
    <TradingTableCard
      symbol={symbol!}
      onSymbolClick={addChartSymbol}
      type={type!}
      volume={
        <ClosedPositionVolume
          decimalScale={volumeDecimalScale}
          type={type!}
          volumeBuy={volumeBuy}
          volumeSell={volumeSell}
        />
      }
      rightBottomBlock={<> {terminalFormatDate(dateClose!)}</>}
      pnl={
        <>
          <div className={getNumberColorClassname(profit!)}>
            <PnlFormat value={profit} decimalScale={currencyDecimalScale} currency={accountCurrency} />
          </div>
        </>
      }
    >
      <TradingTableCard.List>
        <TradingTableCard.ItemLabel>{t("common.type")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <OrderType type={type!} withCircle={false} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.volume-lot")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <ClosedPositionVolume
            decimalScale={volumeDecimalScale}
            type={type!}
            volumeBuy={volumeBuy}
            volumeSell={volumeSell}
          />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.open-time")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>{terminalFormatDate(dateOpen!)}</TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.open-price")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.stop-loss")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.take-profit")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.close-time")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>{terminalFormatDate(dateClose!)}</TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.close-price")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <NumberFormat value={priceClose} decimalScale={symbolDigits} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>{t("terminal.order")}</TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>{positionNumber}</TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>
          {t("terminal.swap")}, {accountCurrency}
        </TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
        </TradingTableCard.ItemValue>
        <TradingTableCard.ItemLabel>
          {t("terminal.pnl-currency", { currency: accountCurrency })}
        </TradingTableCard.ItemLabel>
        <TradingTableCard.ItemValue>
          <div className={getNumberColorClassname(profit!)}>
            <PnlFormat value={profit} decimalScale={currencyDecimalScale} />
          </div>
        </TradingTableCard.ItemValue>
      </TradingTableCard.List>
    </TradingTableCard>
  );
};

export const ClosedPositionCard = memo(_ClosedPositionCard);
