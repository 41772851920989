import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/shared/ui";

import { PendingOrder } from "../../contexts/extended-orders.context";
import { useTerminalLayout } from "../../contexts/terminal-layout.context";
import { CloseAllPendingOrdersButton } from "../close/close-all-pending-orders-button";
import { TradingCardsContainer } from "../components/trading-table/trading-cards.container";
import { TradingTable } from "../components/trading-table/trading-table";
import { TradingTableEmptyState } from "../components/trading-table/trading-table-empty-state";
import { PendingOrderCard } from "./pending-order-card";
import { PendingOrdersHeaderRow } from "./pending-orders-header-row";
import { PendingOrdersTableRow } from "./pending-orders-table-row";

type Props = {
  orders: PendingOrder[];
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
  onClose?: () => void;
};

export const PendingOrdersTable: FC<Props> = ({
  orders,
  accountId,
  onClose,
  setSymbol,
  accountCurrency,
  currencyDecimalScale,
}) => {
  const { isMobile, openWatchlist } = useTerminalLayout();
  const { t } = useTranslation();

  const emptyState = (
    <TradingTableEmptyState
      title={t("terminal.empty-state.pending-title")}
      description={t("terminal.empty-state.pending-description")}
      action={
        <Button
          size="sm"
          onClick={() => {
            onClose && onClose();
            openWatchlist();
          }}
        >
          {t("terminal.go-to-watchlist")}
        </Button>
      }
    />
  );

  return isMobile ? (
    <TradingCardsContainer
      button={orders.length > 1 && <CloseAllPendingOrdersButton accountId={accountId} ordersCount={orders.length} />}
    >
      {orders.length === 0 && emptyState}
      {orders.map(order => (
        <PendingOrderCard
          key={order.id}
          order={order}
          accountId={accountId}
          onCardClose={onClose}
          setSymbol={setSymbol}
          accountCurrency={accountCurrency}
          currencyDecimalScale={currencyDecimalScale}
        />
      ))}
    </TradingCardsContainer>
  ) : (
    <TradingTable
      items={orders}
      emptyState={emptyState}
      renderHeader={() => (
        <PendingOrdersHeaderRow accountId={accountId} ordersCount={orders.length} accountCurrency={accountCurrency} />
      )}
      renderRow={order => (
        <PendingOrdersTableRow
          key={order.id}
          order={order}
          accountId={accountId}
          setSymbol={setSymbol}
          accountCurrency={accountCurrency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    />
  );
};
