import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { ButtonGroup } from "@/shared/ui";

import { PlaceOrderType, usePlaceOrderContext } from "../../context";
import { MobileMarketOrderContainer } from "./market/container";
import { MobilePendingOrderContainer } from "./pending/container";

type Props = {
  onClose: () => void;
};

const MobilePlaceOrderSettings: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const { orderType, changeOrderType } = usePlaceOrderContext();

  return (
    <>
      <ButtonGroup className="mt-2" value={orderType} onValueChange={changeOrderType}>
        <ButtonGroup.Item value={PlaceOrderType.MARKET}>{t("terminal.market")}</ButtonGroup.Item>
        <ButtonGroup.Item value={PlaceOrderType.PENDING}>{t("terminal.pending")}</ButtonGroup.Item>
      </ButtonGroup>
      {orderType === PlaceOrderType.MARKET && <MobileMarketOrderContainer onClose={onClose} />}
      {orderType === PlaceOrderType.PENDING && <MobilePendingOrderContainer onClose={onClose} />}
    </>
  );
};

export { MobilePlaceOrderSettings };
