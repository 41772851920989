export const SupportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="#EEE"
      fillRule="evenodd"
      d="M23 12c0-5.523-4.477-10-10-10h-2C5.477 2 1 6.477 1 12s4.477 10 10 10h7a5 5 0 005-5v-5zM13 4a8 8 0 018 8v5a3 3 0 01-3 3h-7a8 8 0 110-16h2zm-1 4.727c-.661 0-1.197.536-1.197 1.197a1 1 0 01-2 0 3.197 3.197 0 115.428 2.29l-1.681 1.681a1 1 0 01-1.415-1.414l1.692-1.692.006-.005A1.197 1.197 0 0012 8.727zm.873 7.546a1 1 0 11-1.999 0 1 1 0 011.999 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);
