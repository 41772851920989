import { FC } from "react";

import { TerminalFeatureTourContextProvider } from "@/features/terminal/feature-tour/context";
import { $wsUrl } from "@/services/api";
import { BonusUserPlatform, TradingAccount } from "@/services/openapi";

import { TerminalAccountSummaryContextProvider } from "../account-summary/context";
import { TerminalChartContextProvider } from "../chart/context";
import { TerminalAccountContextProvider } from "./account.context";
import { TerminalCurrentSymbolContextProvider } from "./current-symbol-context";
import { TerminalExtendedOrdersContextProvider } from "./extended-orders.context";
import { TerminalOrdersContextProvider } from "./orders.context";
import { TerminalSocketContextProvider } from "./socket.context";
import { TerminalSymbolsContextProvider } from "./symbols.context";
import { TerminalLayoutContextProvider } from "./terminal-layout.context";

interface Props {
  account: TradingAccount;
  token: string;
  isMobileAppMode: boolean;
  bonuses: BonusUserPlatform[];
  children: React.ReactNode;
}

export const TerminalProvider: FC<Props> = ({ children, account, token, isMobileAppMode, bonuses }) => {
  const socketUrl = `${$wsUrl()}/v1.0/ws/private/${token}`;

  return (
    <TerminalLayoutContextProvider>
      <TerminalFeatureTourContextProvider
        accountType={account.type!}
        tradeMode={account.tradeMode!}
        isMobileAppMode={isMobileAppMode}
      >
        <TerminalSocketContextProvider socketUrl={socketUrl} accountId={account.id!}>
          <TerminalAccountContextProvider account={account} bonuses={bonuses}>
            <TerminalSymbolsContextProvider serverId={account.serverId!} accountId={account.id!}>
              <TerminalCurrentSymbolContextProvider accountId={account.id!}>
                <TerminalOrdersContextProvider account={account}>
                  <TerminalExtendedOrdersContextProvider
                    currencyDecimalScale={account.digits!}
                    accountCurrency={account.currency!}
                    leverage={account.leverage!}
                  >
                    <TerminalAccountSummaryContextProvider account={account}>
                      <TerminalChartContextProvider accountId={account.id!}>{children}</TerminalChartContextProvider>
                    </TerminalAccountSummaryContextProvider>
                  </TerminalExtendedOrdersContextProvider>
                </TerminalOrdersContextProvider>
              </TerminalCurrentSymbolContextProvider>
            </TerminalSymbolsContextProvider>
          </TerminalAccountContextProvider>
        </TerminalSocketContextProvider>
      </TerminalFeatureTourContextProvider>
    </TerminalLayoutContextProvider>
  );
};
