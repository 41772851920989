import { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";

import { useTerminalAccountSummary } from "../../account-summary/context";
import { getNumberColorClassname } from "../../terminal.styles-helpers";
import { CloseAllOpenOrdersButton } from "../close/close-all-open-orders-button";

type Props = {
  accountId: string;
  ordersCount: number;
};

const OpenOrdersInfoCard: FC<Props> = ({ accountId, ordersCount }) => {
  const { t } = useTranslation();

  const { pnl, currency, swaps, equity, currencyDecimalScale } = useTerminalAccountSummary();

  return (
    <div className="flex flex-col gap-3 rounded-[24px] bg-bg-back px-4 py-3">
      <div className="flex flex-col gap-1">
        <div className="font-roboto text-[14px] leading-[1.5] text-text">{t("terminal.account-summary.equity")}</div>
        <div className="flex items-center gap-2 font-gilroy text-[16px] font-semibold leading-[1.2] text-text">
          <div>
            <NumberFormat value={equity} decimalScale={currencyDecimalScale} currency={currency} />
          </div>
          {ordersCount > 0 && (
            <div className={getNumberColorClassname(pnl)}>
              (<PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />)
            </div>
          )}
        </div>
      </div>
      {ordersCount > 1 && (
        <CloseAllOpenOrdersButton
          accountId={accountId}
          pnl={pnl}
          ordersCount={ordersCount}
          swaps={swaps}
          accountCurrency={currency}
          currencyDecimalScale={currencyDecimalScale}
        />
      )}
    </div>
  );
};

export { OpenOrdersInfoCard };
