import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";

import { authRoutes } from "./auth.routes";

export const PrivateRoute = ({ children }: { children?: React.ReactElement }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={authRoutes.signIn} replace />;
  }

  return children ? children : <Outlet />;
};
