import { FC, useMemo } from "react";

import { SubmitButton } from "@/app/form";
import { DialogButtons, DialogClose } from "@/components/dialog";
import { SelectField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Button } from "@/shared/ui";

enum LeverageFields {
  VALUE = "value",
}

export interface ILeverageFormValues {
  [LeverageFields.VALUE]: number;
}

type Props = {
  onSubmit: (values: ILeverageFormValues) => void;
  leverage: number;
  availableLeverages: number[];
};

export const LeverageForm: FC<Props> = ({ onSubmit, leverage, availableLeverages }) => {
  const { t } = useTranslation();
  const form = useHookForm<ILeverageFormValues>({
    defaultValues: {
      [LeverageFields.VALUE]: leverage,
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div data-test="dialog-leverage-switcher">
        <SelectField
          name={LeverageFields.VALUE}
          options={useMemo(
            () => availableLeverages.map(leverage => ({ value: leverage, label: `1:${leverage}` })),
            [availableLeverages],
          )}
        />
      </div>
      <Text css={{ mt: 8 }} family="roboto" color="dimmed" lineHeight="3">
        {t("accounts.leverage.description")}
      </Text>
      <DialogButtons>
        <SubmitButton data-test="dialog-leverage-submit">{t("button.set")}</SubmitButton>
        <DialogClose asChild>
          <Button variant="tertiary" data-test="dialog-leverage-cancel">
            {t("button.cancel")}
          </Button>
        </DialogClose>
      </DialogButtons>
    </HookForm>
  );
};
