import { FC } from "react";

import { Box } from "@/components/box";
import { PasswordField, TextNumberField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { twoFACodeRules } from "@/utils/validators";

enum TwoFaFields {
  CODE = "code",
  PASSWORD = "password",
}

export interface IConfirmTwoFaFormValues {
  [TwoFaFields.CODE]: string;
  [TwoFaFields.PASSWORD]: string;
}

interface Props {
  onSubmit: (values: IConfirmTwoFaFormValues) => void;
}

export const ConfirmTwoFaForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const form = useHookForm<IConfirmTwoFaFormValues>();

  return (
    <HookForm onSubmit={onSubmit} form={form}>
      <Stack>
        <Text family="roboto">{t("two-factor.enter-authenticator-code")}</Text>
        <TextNumberField
          name={TwoFaFields.CODE}
          placeholder={t("two-factor.authenticator-code")!}
          rules={twoFACodeRules(t)}
        />
        <PasswordField name={TwoFaFields.PASSWORD} placeholder={t("fields.password.profile-password")!} />
        <Box>
          <SubmitButton>{t("button.confirm")}</SubmitButton>
        </Box>
      </Stack>
    </HookForm>
  );
};
