import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";

import { CloseAllPendingOrdersButton } from "../close/close-all-pending-orders-button";
import { TradingTable } from "../components/trading-table/trading-table";

type Props = {
  accountId: string;
  ordersCount: number;
  accountCurrency: string;
};

export const PendingOrdersHeaderRow: FC<Props> = ({ accountId, ordersCount, accountCurrency }) => {
  const { t } = useTranslation();

  return (
    <TradingTable.Row showBorder>
      <TradingTable.Head>{t("terminal.symbol")}</TradingTable.Head>
      <TradingTable.Head>{t("common.type")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.volume-lot")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.margin-currency", { currency: accountCurrency })}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.open-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.current-price")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.take-profit")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.stop-loss")}</TradingTable.Head>
      <TradingTable.Head>{t("terminal.time")}</TradingTable.Head>
      <TradingTable.Head align="end">
        {ordersCount > 1 && <CloseAllPendingOrdersButton accountId={accountId} ordersCount={ordersCount} />}
      </TradingTable.Head>
    </TradingTable.Row>
  );
};
