import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Dialog, DialogContent } from "@/components/dialog";
import { InfoDialog } from "@/components/info-dialog";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { Button } from "@/shared/ui";

import img from "./assets/Shield.png";
import { Props } from "./types";
import { fromDepositState } from "./verification.container";

export const DepositVerificationDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <InfoDialog
          imageSrc={img}
          title={t("deposit.verification.dialog.title")}
          description={t("deposit.verification.dialog.description")}
        >
          <Button variant="secondary" className="w-full" asChild>
            <Link to={onboardingRoutes.onboarding} state={fromDepositState}>
              {t("deposit.verification.dialog.button")}
            </Link>
          </Button>
        </InfoDialog>
      </DialogContent>
    </Dialog>
  );
};
