import { styled } from "@/styles";

import { Block as MainBlock } from "../../trading-unavailable.styled";

export const Block = styled(MainBlock, {
  flexDirection: "row",
  alignItems: "flex-start",

  "@bp3": {
    flexDirection: "column",
  },
});
