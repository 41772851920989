import { css, darkThemeSelector, styled } from "@/styles";

const Scroll = styled("div", {
  width: "100%",
  overflow: "auto",

  "&::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#909090",
    borderRadius: "2px",
  },

  [darkThemeSelector]: {
    "&::-webkit-scrollbar-thumb": {
      background: "#4A4A4A",
    },
  },

  scrollbarWidth: "thin",
});

const StyledTable = styled("table", {
  width: "100%",
  borderSpacing: 0,
});

const tableCellStyles = css({
  fontFamily: "$roboto",
  fontWeight: "$normal",
  lineHeight: "$1",
  whiteSpace: "nowrap",
  height: "40px",
  px: 8,

  variants: {
    align: {
      start: {
        textAlign: "start",
      },
      center: {
        textAlign: "center",
      },
      end: {
        textAlign: "end",
      },
    },
    wide: { true: { width: "100%" } },
  },
  defaultVariants: {
    align: "start",
  },
});

const Row = styled("tr", {
  borderBottom: "1px solid $colors$bgBorder",
  background: "$bgPrimary",

  variants: {
    hoverable: {
      true: {
        cursor: "pointer",
        "&:hover": {
          elementTransition: "backgroundColor",
          backgroundColor: "$bgBack",
        },
      },
    },
    showBorder: {
      true: {
        borderBottom: "1px solid $colors$bgBorder !important",
      },
    },
  },

  "&:last-child": {
    border: "none",
  },
});

const Head = styled("th", tableCellStyles, {
  fontSize: "12px",

  color: "$textSecondary",

  position: "sticky",
  top: 0,
  background: "$bgPrimary",

  "&:first-child": {
    pl: "0px",
  },
});

const Cell = styled("td", tableCellStyles, {
  fontSize: "16px",

  color: "$textMain",

  "&:first-child": {
    pl: "0px",
  },
});

type Props<T> = {
  renderHeader: () => React.ReactNode;
  emptyState: React.ReactNode;
  renderRow: (items: T, index: number) => React.ReactNode;
  items: T[];
};

export const TradingTableRoot = <T,>({ renderRow, items, renderHeader, emptyState }: Props<T>) => {
  if (items.length === 0) {
    return <>{emptyState}</>;
  }

  return (
    <Scroll>
      <StyledTable>
        <thead>{renderHeader()}</thead>
        <tbody>{items.map(renderRow)}</tbody>
      </StyledTable>
    </Scroll>
  );
};

const Component = Object.assign(TradingTableRoot, { Scroll, StyledTable, Row, Head, Cell });

export { Component as TradingTable };
