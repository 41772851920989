import * as SwitchPrimitive from "@radix-ui/react-switch";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const rootStyles = cva(
  "inline-flex items-center rounded-[32px] bg-bg-border outline-none transition-colors data-[state=checked]:bg-action",
  {
    variants: {
      size: {
        sm: "h-5 w-8",
        md: "h-8 w-[52px]",
      },
    },
  },
);

const thumbStyles = cva("block rounded-full bg-text-inverse transition-transform", {
  variants: {
    size: {
      sm: "size-4 translate-x-0.5 data-[state=checked]:translate-x-[14px]",
      md: "size-7 translate-x-0.5 data-[state=checked]:translate-x-[22px]",
    },
  },
});

type Props = ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & VariantProps<typeof rootStyles>;

const Switch = forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, Props>(
  ({ className, size = "md", ...props }, ref) => (
    <SwitchPrimitive.Root className={cn(rootStyles({ size }), className)} {...props} ref={ref}>
      <SwitchPrimitive.Thumb className={thumbStyles({ size })} />
    </SwitchPrimitive.Root>
  ),
);

export { Switch };
