import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { IconCross } from "@/domains/icons";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, overlayStyles, ScrollArea } from "@/shared/ui";

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="absolute bottom-0 end-0 start-0 px-4 pb-4 md:px-14 md:pb-16">{children}</div>;
};

const Separator: FC = () => {
  return <div className="my-6 min-h-px w-full bg-bg-border md:my-8" />;
};

const descriptionStyles = cva("text-center font-roboto text-[14px] leading-normal text-text md:text-[16px]")();

const Description = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>((props, ref) => {
  return <DialogPrimitive.Description className={descriptionStyles} {...props} ref={ref} />;
});

const DescriptionStrong: FC<ComponentPropsWithoutRef<"span">> = ({ className, ...props }) => {
  return <span className={cn(descriptionStyles, "font-semibold", className)} {...props} />;
};

type Props = ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  title: string;
  footer: ReactNode;
  description?: ReactNode;
  image?: string;
};

const Content = forwardRef<ElementRef<typeof DialogPrimitive.Content>, Props>(
  ({ children, title, description, image, className, footer, ...props }, forwardedRef) => {
    const { isMobile } = useScreenSize();

    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className={overlayStyles({ hasAnimation: !isMobile })} />
        <DialogPrimitive.Content
          className={cn(
            centeredDialogContentStyles({ hasAnimation: !isMobile }),
            "flex h-full w-full flex-col overflow-auto bg-bg shadow outline-none md:h-auto md:max-h-[calc(100dvh-112px)] md:max-w-[640px] md:rounded-[24px]",
            className,
          )}
          {...props}
          ref={forwardedRef}
        >
          <div className="flex justify-end bg-bg p-4 md:p-6 md:pb-2">
            <DialogPrimitive.Close>
              <IconCross />
            </DialogPrimitive.Close>
          </div>
          <ScrollArea scrollbars="vertical" className="mb-[72px] md:mb-[120px]" viewportClassname="px-4 pb-8 md:px-14">
            <div className="mb-6 flex justify-center md:mb-8">
              <img alt="" src={image} className="h-[85px] w-[85px]" />
            </div>
            <DialogPrimitive.Title className="text-center font-gilroy text-[24px] font-semibold leading-[1.2] text-text md:text-[32px]">
              {title}
            </DialogPrimitive.Title>
            <div className="mb-6 mt-4 md:mb-8">{description}</div>
            {children}
          </ScrollArea>
          <Footer>{footer}</Footer>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  },
);

const Component = Object.assign(Content, { Description, Separator, DescriptionStrong });

export { Component as BonusDialogContent };
