import { useScreenSize } from "@/hooks/screen-size.hook";

import { Toast } from ".";
import { useToast } from "./toast.hook";

export function Toaster() {
  const { toasts, remove } = useToast();

  const { isMobile } = useScreenSize();

  const swipeDirection = isMobile ? "up" : "right";

  const swipeThreshold = isMobile ? 50 : 100;

  return (
    <Toast.Provider swipeDirection={swipeDirection} swipeThreshold={swipeThreshold}>
      {toasts.map(({ id, title, description, text, icon, autoClose = true, onClose, action, ...props }) => (
        <Toast
          key={id}
          onOpenChange={() => {
            // https://github.com/radix-ui/primitives/issues/2233#issuecomment-1821222005
            document.getElementById("radix-toast-vp")?.blur();
            setTimeout(() => {
              remove(id);
              onClose?.();
            }, 300);
          }}
          swipeDirection={swipeDirection}
          // https://github.com/radix-ui/primitives/issues/2268
          // https://github.com/radix-ui/primitives/issues/2461
          duration={autoClose ? 3000 : Infinity}
          {...props}
        >
          <div className="pointer-events-auto relative flex w-full items-start justify-between gap-[12px] rounded-[16px] bg-bg p-[16px] shadow transition-all">
            <Toast.Icon icon={icon} />
            <div className="flex grow flex-col justify-center gap-1">
              {title && <Toast.Title>{title}</Toast.Title>}
              {description && <Toast.Description>{description}</Toast.Description>}
              {text && <Toast.Text>{text}</Toast.Text>}
              {action && action}
            </div>
            <Toast.Close />
          </div>
        </Toast>
      ))}
      <Toast.Viewport />
    </Toast.Provider>
  );
}
