import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import { SelectOptionType } from "@/components/form";
import { PaymentMethod } from "@/services/openapi";

type DepositContextState = {
  setPaymentOptions: (value: SelectOptionType[] | undefined) => void;
  paymentOptions: SelectOptionType[] | undefined;
  setPaymentMethod: (value: PaymentMethod & { amount?: number }) => void;
  paymentMethod: (PaymentMethod & { amount?: number }) | null;
  accountId: string | undefined;
  setAccountId: (value: string) => void;
};

export const DepositContext = createContext<DepositContextState>({} as DepositContextState);

export const DepositContextProvider = ({ children }: { children: ReactNode }) => {
  const [paymentOptions, setPaymentOptions] = useState<DepositContextState["paymentOptions"]>();
  const [paymentMethod, setPaymentMethod] = useState<DepositContextState["paymentMethod"]>(null);
  const [accountId, setAccountId] = useState<DepositContextState["accountId"]>();

  const value = useMemo(
    (): DepositContextState => ({
      setPaymentOptions,
      setPaymentMethod,
      paymentOptions,
      paymentMethod,
      accountId,
      setAccountId,
    }),
    [paymentMethod, accountId, paymentOptions, setPaymentOptions, setPaymentMethod, setAccountId],
  );
  return <DepositContext.Provider value={value}>{children}</DepositContext.Provider>;
};

export const useDepositContext = () => {
  return useContext(DepositContext);
};
