import {
  addBreadcrumb,
  captureException,
  globalHandlersIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  setContext,
  setTag,
  setUser,
  wrapCreateBrowserRouter,
} from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import { ProfileHeader } from "@/services/openapi";

const isProd = import.meta.env.PROD;
const sentryDsn = import.meta.env.VITE_SENTRY_API_DSN as string | undefined;

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/

const initializeSentry = () => {
  // change useSentry var in dev mode if you want to inspect issues in sentry
  const useSentry = isProd;
  // const useSentry = true;
  if (sentryDsn && useSentry) {
    init({
      dsn: sentryDsn,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration(), // maybe we should remove this, because it's too heavy
        globalHandlersIntegration({
          onunhandledrejection: false,
        }),
      ],
      debug: !isProd,
      sampleRate: 1.0,
      tracesSampleRate: 1.0,

      // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#verify
      replaysSessionSampleRate: isProd ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,

      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        "top.GLOBALS",
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        "fb_xd_fragment",
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        "conduitPage",
      ],
      denyUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /127\.0\.0\.1:4001\/isrunning/i,
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
};

const assignError = (maybeError: any) => {
  if (typeof maybeError === "string") {
    return new Error(maybeError);
  }
  if (typeof maybeError === "object") {
    const error = new Error(maybeError?.message ?? String(maybeError));
    if (maybeError?.stack) {
      error.stack = maybeError.stack;
    }
    if (maybeError?.code) {
      error.name = maybeError.code;
    }
    return error;
  }
  return maybeError;
};

const logError = (error: Error | unknown, hint?: Parameters<typeof captureException>[1]) => {
  if (error instanceof Error) {
    captureException(error, hint);
  } else {
    captureException(assignError(error), hint);
  }
  console.error(error);
};

const resetUserContext = () => {
  setUser(null);
  setContext("header", null);
};

const setUserContext = (profile: ProfileHeader) => {
  const { email, id } = profile;

  setUser({ email: email!, id });
  setContext("header", profile);
};

const setLocaleSentry = (lang: string) => {
  setTag("locale", lang);
};

const setWebviewSentry = (flag: boolean) => {
  setTag("webview", flag);
};

export {
  initializeSentry,
  setUserContext,
  resetUserContext,
  wrapCreateBrowserRouter,
  setLocaleSentry,
  setWebviewSentry,
  logError,
  addBreadcrumb,
};
