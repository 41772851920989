import { FC } from "react";

import { TradingAccountTradeMode } from "@/services/openapi";
import { overlayStyles } from "@/shared/ui";

import { useTerminalFeatureTourContext } from "./context";
import { LotsFinalHintDialog } from "./steps/final/lots-hint-dialog";
import { MarginFinalHintDialog } from "./steps/final/margin-hint-dialog";
import { InterruptTourDialog } from "./steps/interrupt/dialog";
import { WelcomeHintDialog } from "./steps/welcome/hint-dialog";

type Props = {
  tour: ReturnType<typeof useTerminalFeatureTourContext>;
};

const FeatureTour: FC<Props> = ({ tour }) => {
  const {
    isFeatureTourIdle,
    isWelcomeStepActive,
    isTourInterrupted,
    isFinalStepActive,
    isLoading,
    startTour,
    interruptTour,
    continueTour,
    terminateTour,
    finishTour,
    repeatTour,
    tradeReal,
    tradeMode,
  } = tour;

  return (
    <>
      {isFeatureTourIdle && <div className={overlayStyles()} />}
      {isWelcomeStepActive && <WelcomeHintDialog onInterrupt={interruptTour} onStart={startTour} />}
      {isTourInterrupted && (
        <InterruptTourDialog onContinue={continueTour} onTerminate={terminateTour} isLoading={isLoading} />
      )}
      {isFinalStepActive &&
        (tradeMode === TradingAccountTradeMode.Lots ? (
          <LotsFinalHintDialog onFinish={finishTour} onRepeat={repeatTour} onTrade={tradeReal} isLoading={isLoading} />
        ) : (
          <MarginFinalHintDialog onFinish={finishTour} isLoading={isLoading} />
        ))}
    </>
  );
};

export { FeatureTour };
