import Big from "big.js";
import React, { createContext, FC, useContext, useMemo, useState } from "react";

import { TerminalDealType, TradingAccountTradeMode } from "@/services/openapi";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { formatInputNumberValue, getInputNumberValue, getTickSizeFromDecimalScale } from "../helpers/formatting";
import { countMargin } from "../helpers/orders";

enum VolumeLotsError {
  NONE = "none",
  MORE_THAN_FREE_MARGIN = "moreThanFreeMargin",
  LESS_THAN_SYSTEM = "lessThanSystem",
  MORE_THAN_SYSTEM = "moreThanSystem",
}

enum PlaceOrderType {
  MARKET = "market",
  PENDING = "pending",
}

type FormValueType = string;
type ChangeInput = (value: FormValueType | number, config?: { format: boolean }) => void;

type ContextProps = {
  // open price
  openPrice: number | null;
  openPriceFormValue: FormValueType;
  changeOpenPrice: ChangeInput;
  resetOpenPrice: () => void;
  // lots
  volumeLotsError: VolumeLotsError;
  volumeLots: number | null;
  volumeLotsFormValue: FormValueType;
  changeLots: ChangeInput;
  volumeLotsDecimalScale: number;
  volumeLotsStep: number;
  maxBalanceVolumeLots: number; // max available lots considering account free margin
  minSystemVolumeLots: number; // min valid lots considering symbol settings
  maxSystemVolumeLots: number; // max valid lots considering symbol settings
  maxAvailableVolumeLots: number; // max valid lots considering symbol settings and account free margin
  // margin
  volumeSellMargin: number | null; // for info display
  volumeBuyMargin: number | null; // for info display
  volumeMargin: number | null;
  volumeMarginFormValue: FormValueType;
  changeMargin: ChangeInput;
  marginMultiplier: number; // it's needed to reduce number of countMargin function calls
  volumeMarginDecimalScale: number;
  maxBalanceVolumeMargin: number; // max available margin considering account free margin
  minSystemVolumeMargin: number; // min valid margin considering symbol settings
  maxSystemVolumeMargin: number; // max valid margin considering symbol settings
  maxAvailableVolumeMargin: number; // max valid margin considering symbol settings and account free margin
  // increment / decrement
  incrementVolumeDisabled: boolean;
  decrementVolumeDisabled: boolean;
  incrementOrder: () => void;
  decrementOrder: () => void;
  //
  volumeMode: TradingAccountTradeMode;
  changeVolumeMode: (mode: TradingAccountTradeMode) => void;
  orderType: PlaceOrderType;
  changeOrderType: (type: PlaceOrderType) => void;
  hasNoFreeMargin: boolean;
  resetForm: () => void;
  refreshForm: () => void; // it's needed to reset open price. Only mobile
  resetOrderType: () => void; // Only mobile
  currency: string;
  ask: number;
  bid: number;
  swapLong: number;
  swapShort: number;
  marginFree: number;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { symbolInfo } = useCurrentSymbolContext();

  const { changeAccountTradeMode, account } = useTerminalAccountContext();

  const { symbols } = useSymbolsContext();

  const {
    volumeMin: minSystemVolumeLots,
    volumeMax: maxSystemVolumeLots,
    volumeStep: volumeLotsStep,
    volumeDecimalScale: volumeLotsDecimalScale,
    baseCurrency,
    contractSize,
    quoteCurrency,
    type: instrumentType,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    digits,
  } = symbolInfo!;

  const ask = symbolInfo!.ask || 0;
  const bid = symbolInfo!.bid || 0;
  const swapLong = symbolInfo!.swapLong || 0;
  const swapShort = symbolInfo!.swapShort || 0;

  const [orderType, setOrderType] = useState<ContextProps["orderType"]>(PlaceOrderType.MARKET);

  const [openPriceFormValue, setOpenPrice] = useState<ContextProps["openPriceFormValue"]>(() =>
    formatInputNumberValue(bid, digits!),
  );
  const openPrice = useMemo(() => getInputNumberValue(openPriceFormValue), [openPriceFormValue]);

  const pendingAsk = orderType === PlaceOrderType.PENDING && openPrice ? openPrice : ask;
  const pendingBid = orderType === PlaceOrderType.PENDING && openPrice ? openPrice : bid;

  const { marginFree, currency, leverage, currencyDecimalScale } = useTerminalAccountSummary();

  const volumeStepMargin = useMemo(() => {
    const result = new Big(
      countMargin({
        accountCurrency: currency,
        baseCurrency: baseCurrency!,
        contractSize: contractSize!,
        leverage,
        quoteCurrency: quoteCurrency!,
        volume: volumeLotsStep!,
        type: TerminalDealType.Sell,
        symbols,
        openPrice: pendingBid,
        instrumentType: instrumentType!,
        marginRate: "initial",
        marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
        marginRateInitialMarketSell: marginRateInitialMarketSell!,
        marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
        marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
      }),
    )
      .round(currencyDecimalScale, 1)
      .toNumber();
    // Assigning as minimal available value, if it rounds to zero
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [
    volumeLotsStep,
    currency,
    contractSize,
    instrumentType,
    baseCurrency,
    leverage,
    quoteCurrency,
    symbols,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    pendingBid,
    currencyDecimalScale,
  ]);

  const volumeStepBuyMargin = useMemo(() => {
    const result = new Big(
      countMargin({
        accountCurrency: currency,
        baseCurrency: baseCurrency!,
        contractSize: contractSize!,
        leverage,
        quoteCurrency: quoteCurrency!,
        volume: volumeLotsStep!,
        type: TerminalDealType.Buy,
        symbols,
        openPrice: pendingAsk,
        instrumentType: instrumentType!,
        marginRate: "initial",
        marginRateInitialMarketBuy: marginRateInitialMarketBuy!,
        marginRateInitialMarketSell: marginRateInitialMarketSell!,
        marginRateMaintenanceMarketBuy: marginRateMaintenanceMarketBuy!,
        marginRateMaintenanceMarketSell: marginRateMaintenanceMarketSell!,
      }),
    )
      .round(currencyDecimalScale, 1)
      .toNumber();
    // Assigning as minimal available value, if it rounds to zero
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [
    volumeLotsStep,
    currency,
    contractSize,
    instrumentType,
    baseCurrency,
    leverage,
    quoteCurrency,
    symbols,
    marginRateInitialMarketBuy,
    marginRateInitialMarketSell,
    marginRateMaintenanceMarketBuy,
    marginRateMaintenanceMarketSell,
    pendingAsk,
    currencyDecimalScale,
  ]);

  const marginBuyMultiplier = useMemo(() => {
    const result = new Big(volumeStepBuyMargin).div(volumeLotsStep!).round(currencyDecimalScale).toNumber();
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [volumeStepBuyMargin, volumeLotsStep, currencyDecimalScale]);

  const marginMultiplier = useMemo(() => {
    const result = new Big(volumeStepMargin).div(volumeLotsStep!).round(currencyDecimalScale).toNumber();
    return result ? result : +getTickSizeFromDecimalScale(currencyDecimalScale);
  }, [volumeStepMargin, volumeLotsStep, currencyDecimalScale]);

  const [volumeLotsFormValue, setVolumeLots] = useState<ContextProps["volumeLotsFormValue"]>(() =>
    formatInputNumberValue(minSystemVolumeLots, volumeLotsDecimalScale!),
  );
  const volumeLots = useMemo(() => getInputNumberValue(volumeLotsFormValue), [volumeLotsFormValue]);

  const [volumeMarginFormValue, setVolumeMargin] = useState<ContextProps["volumeMarginFormValue"]>(() =>
    formatInputNumberValue(
      new Big(minSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
      currencyDecimalScale,
    ),
  );
  const volumeMargin = useMemo(() => getInputNumberValue(volumeMarginFormValue), [volumeMarginFormValue]);

  const changeLots: ContextProps["changeLots"] = (value, config) => {
    const parsedValue = getInputNumberValue(value);
    if (parsedValue === null) {
      setVolumeLots(value as string);
      setVolumeMargin("");
      return;
    }
    const { format } = config || { format: true };
    const newValue = format ? formatInputNumberValue(parsedValue, volumeLotsDecimalScale!)! : (value as string);

    setVolumeLots(newValue);
    setVolumeMargin(
      formatInputNumberValue(
        new Big(parsedValue).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
        currencyDecimalScale,
      ),
    );
  };

  const changeMargin: ContextProps["changeMargin"] = (value, config) => {
    const parsedValue = getInputNumberValue(value);
    if (parsedValue === null) {
      setVolumeMargin(value as string);
      setVolumeLots("");
      return;
    }
    const { format } = config || { format: true };
    const newValue = format ? formatInputNumberValue(value, volumeLotsDecimalScale!)! : (value as string);

    setVolumeMargin(newValue);
    setVolumeLots(
      formatInputNumberValue(
        new Big(parsedValue).div(marginMultiplier).round(volumeLotsDecimalScale, 1).toNumber(),
        volumeLotsDecimalScale!,
      ),
    );
  };

  const maxBalanceVolumeLots = useMemo(
    () => new Big(marginFree).div(volumeStepMargin).mul(volumeLotsStep!).round(volumeLotsDecimalScale, 0).toNumber(),
    [marginFree, volumeStepMargin, volumeLotsStep, volumeLotsDecimalScale],
  );

  const minSystemVolumeMargin = useMemo(
    () => new Big(minSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [minSystemVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxSystemVolumeMargin = useMemo(
    () => new Big(maxSystemVolumeLots!).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [maxSystemVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxBalanceVolumeMargin = useMemo(
    () => new Big(maxBalanceVolumeLots).mul(marginMultiplier).round(currencyDecimalScale).toNumber(),
    [maxBalanceVolumeLots, marginMultiplier, currencyDecimalScale],
  );

  const maxAvailableVolumeLots = useMemo(
    () => Math.min(maxBalanceVolumeLots, maxSystemVolumeLots!),
    [maxBalanceVolumeLots, maxSystemVolumeLots],
  );

  const maxAvailableVolumeMargin = useMemo(
    () => Math.min(maxBalanceVolumeMargin, maxSystemVolumeMargin),
    [maxBalanceVolumeMargin, maxSystemVolumeMargin],
  );

  const decrementVolumeDisabled = useMemo(() => {
    if (!volumeLots) {
      return true;
    }
    return new Big(volumeLots).minus(volumeLotsStep!).lt(minSystemVolumeLots!);
  }, [volumeLots, volumeLotsStep, minSystemVolumeLots]);

  const incrementVolumeDisabled = useMemo(() => {
    if (volumeLots) {
      return new Big(volumeLots).plus(volumeLotsStep!).gt(maxAvailableVolumeLots);
    }
    return false;
  }, [volumeLots, volumeLotsStep, maxAvailableVolumeLots]);

  const incrementOrder: ContextProps["incrementOrder"] = () => {
    if (!volumeLots) {
      changeLots(formatInputNumberValue(minSystemVolumeLots, volumeLotsDecimalScale!));
      return;
    }

    // is it possible case???
    if (new Big(volumeLots).lt(minSystemVolumeLots!)) {
      changeLots(formatInputNumberValue(minSystemVolumeLots, volumeLotsDecimalScale!));
      return;
    }

    changeLots(formatInputNumberValue(new Big(volumeLots).plus(volumeLotsStep!).toNumber(), volumeLotsDecimalScale!));
  };

  const decrementOrder: ContextProps["decrementOrder"] = () => {
    if (!volumeLots) {
      return;
    }

    if (new Big(volumeLots).gt(maxAvailableVolumeLots)) {
      changeLots(formatInputNumberValue(maxAvailableVolumeLots, volumeLotsDecimalScale!));
      return;
    }

    changeLots(formatInputNumberValue(new Big(volumeLots).minus(volumeLotsStep!).toNumber(), volumeLotsDecimalScale!));
  };

  const hasNoFreeMargin = useMemo(
    () => maxBalanceVolumeLots < minSystemVolumeLots!,
    [minSystemVolumeLots, maxBalanceVolumeLots],
  );

  const volumeLotsError = useMemo(() => {
    if (volumeLots === null || volumeLots < minSystemVolumeLots!) {
      return VolumeLotsError.LESS_THAN_SYSTEM;
    }

    if (volumeLots > maxAvailableVolumeLots) {
      return maxBalanceVolumeLots > maxSystemVolumeLots!
        ? VolumeLotsError.MORE_THAN_SYSTEM
        : VolumeLotsError.MORE_THAN_FREE_MARGIN;
    }

    if (volumeLots > maxSystemVolumeLots!) {
      return VolumeLotsError.MORE_THAN_SYSTEM;
    }
    if (volumeLots > maxBalanceVolumeLots) {
      return VolumeLotsError.MORE_THAN_FREE_MARGIN;
    }

    return VolumeLotsError.NONE;
  }, [volumeLots, minSystemVolumeLots, maxBalanceVolumeLots, maxSystemVolumeLots, maxAvailableVolumeLots]);

  const changeOpenPrice: ContextProps["changeOpenPrice"] = (openPrice, config) => {
    const { format } = config || { format: true };
    const newOpenPrice = format ? formatInputNumberValue(openPrice, digits!) : (openPrice as string);
    setOpenPrice(newOpenPrice);
  };

  const resetForm: ContextProps["resetForm"] = () => {
    if (!openPrice) {
      changeOpenPrice(formatInputNumberValue(bid, digits!));
    }
    if (volumeLotsError === VolumeLotsError.NONE) {
      changeLots(formatInputNumberValue(volumeLots, volumeLotsDecimalScale!));
      return;
    }
    changeLots(formatInputNumberValue(minSystemVolumeLots, volumeLotsDecimalScale!));
  };

  const refreshForm: ContextProps["refreshForm"] = () => {
    resetForm();
    changeOpenPrice(formatInputNumberValue(bid, digits!));
  };

  const resetOrderType: ContextProps["resetOrderType"] = () => {
    setOrderType(PlaceOrderType.MARKET);
  };

  const changeVolumeMode: ContextProps["changeVolumeMode"] = mode => {
    changeAccountTradeMode(mode);
    resetForm();
  };

  const changeOrderType: ContextProps["changeOrderType"] = type => {
    setOrderType(type);
    resetForm();
  };

  const resetOpenPrice: ContextProps["resetOpenPrice"] = () => {
    if (!openPrice) {
      changeOpenPrice(formatInputNumberValue(bid, digits!));
    }
  };

  const volumeSellMargin = useMemo(() => {
    if (!volumeLots) {
      return null;
    }

    return new Big(volumeLots).mul(marginMultiplier).round(currencyDecimalScale).toNumber();
  }, [volumeLots, marginMultiplier, currencyDecimalScale]);

  const volumeBuyMargin = useMemo(() => {
    if (volumeLots === null || +volumeLots === 0) {
      return null;
    }

    return new Big(volumeLots).mul(marginBuyMultiplier).round(currencyDecimalScale).toNumber();
  }, [volumeLots, marginBuyMultiplier, currencyDecimalScale]);

  return (
    <Context.Provider
      value={{
        marginFree,
        openPriceFormValue,
        volumeLotsFormValue,
        volumeMarginFormValue,
        resetOrderType,
        refreshForm,
        resetOpenPrice,
        openPrice,
        changeOpenPrice,
        orderType,
        swapShort,
        swapLong,
        ask,
        bid,
        volumeSellMargin,
        volumeBuyMargin,
        volumeLotsError,
        hasNoFreeMargin,
        incrementOrder,
        decrementOrder,
        volumeLotsDecimalScale: volumeLotsDecimalScale!,
        volumeLotsStep: volumeLotsStep!,
        maxAvailableVolumeLots,
        maxAvailableVolumeMargin,
        volumeMarginDecimalScale: currencyDecimalScale,
        marginMultiplier,
        maxBalanceVolumeLots,
        maxBalanceVolumeMargin,
        volumeMode: account.tradeMode!,
        volumeLots,
        volumeMargin,
        maxSystemVolumeMargin,
        changeVolumeMode,
        changeLots,
        changeMargin,
        minSystemVolumeLots: minSystemVolumeLots!,
        maxSystemVolumeLots: maxSystemVolumeLots!,
        minSystemVolumeMargin,
        incrementVolumeDisabled,
        decrementVolumeDisabled,
        resetForm,
        currency,
        changeOrderType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.displayName = "TerminalPlaceOrderProvider";

const usePlaceOrderContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("usePlaceOrderContext must be used within a TerminalPlaceOrderProvider");
  }

  return context;
};

export { Provider as TerminalPlaceOrderProvider, usePlaceOrderContext, VolumeLotsError, PlaceOrderType };
