import { useEffect } from "react";

// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#setcharttype
// https://www.tradingview.com/charting-library-docs/latest/api/enums/Charting_Library.SeriesType/

export const useDefaultChart = () => {
  useEffect(() => {
    const lastUsedStyle = window.localStorage.getItem("tradingview.chart.lastUsedStyle");

    if (lastUsedStyle !== null) return;

    window.tvWidget.activeChart().setChartType(3); // area
  }, []);
};
