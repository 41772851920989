import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { LocaleInfo } from "@/services/openapi";
import { usePlatformLocalesQuery } from "@/state/server/platform";
import { useUpdateUserSettingMutation } from "@/state/server/profile/profile.mutations";

import { LanguageSwitcherBottomSheet } from "./bottom-sheet/language-switcher-bottom-sheet";
import { LanguageSwitcherDialog } from "./dialogs/language-switcher-dialog";

type Props = {
  open: boolean;
  onOpenChange: Dispatch<SetStateAction<boolean>>;
};

export const LanguageSwitcherContainer: FC<Props> = ({ open, onOpenChange }) => {
  const { i18n } = useTranslation();
  const { data: platformLocales } = usePlatformLocalesQuery();
  const { isMobile } = useScreenSize();
  const { mutate } = useUpdateUserSettingMutation();
  const [searchParams] = useSearchParams();
  const localeParam = searchParams.get("locale");

  const onChangeHandler = (locale: LocaleInfo) => {
    if (i18n.resolvedLanguage === locale.code) {
      onOpenChange(false);
    } else {
      mutate({ profileSettingsRequest: { languageCode: locale.code } });
      i18n.changeLanguage(locale.code!);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (localeParam) i18n.changeLanguage(localeParam);
  }, [localeParam]);

  if (!platformLocales) return null;

  return (
    <>
      {!isMobile && (
        <LanguageSwitcherDialog
          open={open}
          onOpenChange={onOpenChange}
          languagesInfo={platformLocales}
          onLocaleChange={onChangeHandler}
        />
      )}

      {isMobile && (
        <LanguageSwitcherBottomSheet
          open={open}
          onOpenChange={onOpenChange}
          languagesInfo={platformLocales}
          onLocaleChange={onChangeHandler}
        />
      )}
    </>
  );
};
