import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
};

const ChartHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { isChartStepActive, chartExamine, interruptTour, totalSteps, currentStep } = useTerminalFeatureTourContext();

  if (!isChartStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      side="top"
      sideOffset={100}
      content={
        <Hint
          title={t("terminal.onboarding.chart.title")}
          text={t("terminal.onboarding.chart.description")}
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
            <Button size="sm" onClick={chartExamine}>
              {t("terminal.onboarding.buttons.next")}
            </Button>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { ChartHintPopover };
