import { FC, memo } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { useField } from "../../field.hook";
import { TextInput, TextInputExternalProps } from "../../inputs";

export type TextFieldProps = TextInputExternalProps & {
  name: string;
  registerOptions?: RegisterOptions;
};

export const _TextField: FC<TextFieldProps> = props => {
  const { name, registerOptions, ...restProps } = props;

  const { register } = useFormContext();
  const { error, showError } = useField(name);

  return <TextInput {...restProps} error={error} showError={showError} {...register(name, registerOptions)} />;
};

export const TextField = memo(_TextField);
