import { getUserBonuses } from "@/services/bonuses";
import { ApiResponseType } from "@/services/types";

import { useBaseQuery } from "..";
import { QueryOptionsType } from "../types";
import { bonusesQueryKeys } from "./bonuses.keys";

const useBonusesQuery = (options?: QueryOptionsType<ApiResponseType<typeof getUserBonuses>>) => {
  return useBaseQuery<ApiResponseType<typeof getUserBonuses>>({
    queryKey: bonusesQueryKeys.base,
    queryFn: getUserBonuses,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export { useBonusesQuery };
