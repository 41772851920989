import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Hint } from "@/shared/ui";

import { HintPopover } from "../../components/hint/hint-popover";
import { useTerminalFeatureTourContext } from "../../context";

type Props = {
  children: React.ReactNode;
};

const PlaceOrderHintPopover: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();

  const { isPlaceOrderStepActive, interruptTour, totalSteps, currentStep, isMobile } = useTerminalFeatureTourContext();

  if (!isPlaceOrderStepActive) {
    return <>{children}</>;
  }

  return (
    <HintPopover
      side={isMobile ? "top" : "left"}
      sideOffset={-8}
      content={
        <Hint
          title={t("terminal.onboarding.place-order.title")}
          text={
            <Trans i18nKey="terminal.onboarding.place-order.description" components={{ strong: <Hint.Strong /> }} />
          }
          closeButton={<Hint.CloseButton onClick={interruptTour} />}
        >
          <Hint.Footer>
            <Hint.Text>
              {currentStep}/{totalSteps}
            </Hint.Text>
          </Hint.Footer>
        </Hint>
      }
    >
      {children}
    </HintPopover>
  );
};

export { PlaceOrderHintPopover };
