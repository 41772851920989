import { BarTimeframe } from "@/services/openapi";

// https://www.tradingview.com/charting-library-docs/latest/core_concepts/Resolution

const formatTimeResolution = (resolution: string): BarTimeframe => {
  const time: Record<string, BarTimeframe> = {
    "1": BarTimeframe.Minute1,
    "5": BarTimeframe.Minute5,
    "15": BarTimeframe.Minute15,
    "30": BarTimeframe.Minute30,
    "60": BarTimeframe.Hour1,
    "240": BarTimeframe.Hour4,
    "1D": BarTimeframe.Day1,
    "1W": BarTimeframe.Week1,
    "1M": BarTimeframe.Month1,
  };
  return time[resolution];
};

export { formatTimeResolution };
