import { ProfileHeader, ProfileSettings } from "@/services/openapi";

import { useProfileHeaderQuery, useProfileSettingsQuery } from "./profile.queries";

export const useProfileData = () => {
  const { data } = useProfileHeaderQuery();
  return data as ProfileHeader;
};

export const useIsReadOnly = () => {
  const { permissions } = useProfileData();
  return !permissions!.isEnabled;
};

export const useProfileSettigsData = () => {
  const { data, refetch, isSuccess } = useProfileSettingsQuery();
  return { data: data as ProfileSettings, refetch, isSuccess };
};
