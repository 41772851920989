import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Button } from "@/components/button";
import { styled } from "@/styles";

import { NavigationItem, NavigationTitle } from "./navigation.helpers";

const Container = styled("aside", {
  background: "$white",
  boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(10.5043px)",
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "100px",
  padding: "12px 24px 8px",

  overflowX: "auto",

  "@bp3": {
    p: "24px 16px",
  },
});

type Props = {
  activeStep: string;
  navigation: NavigationItem[];
  onComplete?: () => void;
};

export const OnboardingMobileNavigation: FC<Props> = ({ activeStep, navigation, onComplete }) => {
  const { t } = useTranslation();

  const [currentStep] = useState<string>(activeStep);
  const currentStepIndex = useMemo<number>(
    () => navigation.findIndex(step => step.id === currentStep),
    [navigation, currentStep],
  );

  const isLastStep = useMemo<boolean>(() => currentStepIndex + 1 === navigation.length, [currentStepIndex, navigation]);

  const goNext = () => {
    track(amplitudeEvents.kyc.next(currentStepIndex + 1));

    if (isLastStep) {
      if (onComplete) {
        onComplete();
      }
    }
  };

  return (
    <Container>
      {activeStep !== NavigationTitle.SUMSUB && (
        <Button wide type="submit" css={{ padding: "18px 30px" }} onClick={goNext}>
          {t("onboarding.navigation.button-next")}
        </Button>
      )}
    </Container>
  );
};

OnboardingMobileNavigation.defaultProps = {
  onComplete: () => {},
};
